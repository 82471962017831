import React, { useState, useEffect, useRef } from "react";
import SoundIcon from "../../../../assets/admintools/sound.svg";
import { TextBox, RadioButton, InputPrefix } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Icon } from "@progress/kendo-react-common";
import api from "../../../../config/api";
import config from "../../../../config/config";
// import { IFEEDBACK_LIST } from "../../../../routes";
import { useNavigate } from "react-router";
import { MicImageDialog } from "../../../../components/dialog/mic_image_dialog";
// import { getCurrentLoggedInSessionToken } from "../../../../config/userPool";

export const IFeedbackForm = ({
    data,
    createdUser,
    updatedUser
    // isEdit,
    // isCreate,
    // setIsEdit,
    // submitForm
}) => {
    const navigate = useNavigate();
    const [description, setName] = useState(null);
    // const [no, setCode] = useState(null);
    const [clickedImage, setClickedImage] = useState({});
    const [showImageDialog, setShowImageDialog] = useState(false);
    const handleImageDialogClose = () => setShowImageDialog(false);
    useEffect(() => {
        if (data) {
            setName(data.description);
            // setCode(data.no);
        }
    }, [])

    // const submitMatter = () => {
    //     let data = {
    //         description,
    //         no
    //     }
    //     submitForm(data)
    // }
    // const cancelForm = ()=>{
    //     if(data && isEdit){
    //         setIsEdit(false);
    //     }else{
    //         navigate(ADMIN_TOOLS_MATTER_LIST);
    //     }
    // }
    console.log(updatedUser);
    return (
        <>
            {showImageDialog && (
                <MicImageDialog
                image={clickedImage}
                onImageDialogClose={handleImageDialogClose}
                />
            )}
            {/* <table>
                <tr>
                    <td>iFeedback</td>
                        <td>{data.description}</td>
                </tr>
                <tr>
                     <td>Status</td>
                     <td>{data.status}</td>
                 </tr>
                 <tr>
                     <td>iFeedback Description</td>
                     <td>{data.description}</td>
                 </tr>
                 <tr>
                     <td>Matter</td>
                     <td>{data.matter}</td>
                 </tr>
                 <tr>
                     <td>Created On</td>
                     <td>{data.created_on}</td>
                 </tr>
                 <tr>
                     <td>Updated On</td>
                     <td>{data.updated_on}</td>
                 </tr>
            </table> */}

            <div className='ifeedback-details__body'>
              <>
                    <div className='ifeedback-details-info'>
                    <div className='ifeedback-details-info__item'>
                        <div className='ifeedback-details-info__desc'>iFeedback No.</div>
                        <div className='ifeedback-details-info__value'>{data?.no}</div>
                    </div>
                    <div className='ifeedback-details-info__item'>
                        <div className='ifeedback-details-info__desc'>Status</div>
                        <div className='ifeedback-details-info__value'>{data?.status}</div>
                    </div>

                    <div className='ifeedback-details-info__item'>
                        <div className='ifeedback-details-info__desc'>Matter</div>
                        <div className='ifeedback-details-info__value'>{data?.matter}</div>
                    </div>

                    <div className='ifeedback-details-info__item'>
                        <div className='ifeedback-details-info__desc'>Description</div>
                        <div className='ifeedback-details-info__value'>{data?.description}</div>
                    </div>
                    
                    <div className='ifeedback-details-info__item'>
                        <div className='ifeedback-details-info__desc'>Attachment</div>
                        <div className="feedback-details-info__value">
                        {data?.images?.map((x) => (
                            <img
                            src={x.s3_url ?? ""}
                            alt="attachment"
                            height={100}
                            // style={{ paddingRight: "10px" }}
                            onClick={() => {
                                setClickedImage(x);
                                setShowImageDialog(true);
                            }}
                            />
                        ))}
                        </div>
                    </div>

                    <div className='ifeedback-details-info__item'>
                        <div className='ifeedback-details-info__desc'>Created By</div>
                        <div className='ifeedback-details-info__value'>{data?.created_by?.name}</div>
                    </div>
                    <div className='ifeedback-details-info__item'>
                        <div className='ifeedback-details-info__desc'>&nbsp;&nbsp;&nbsp;SAP/BC No</div>
                        <div className='ifeedback-details-info__value'>{createdUser.username}</div>
                    </div>                    
                    <div className='ifeedback-details-info__item'>
                        <div className='ifeedback-details-info__desc'>&nbsp;&nbsp;&nbsp;Department</div>
                        <div className='ifeedback-details-info__value'>{createdUser.dept_desc}</div>
                    </div>
                    <div className='ifeedback-details-info__item'>
                        <div className='ifeedback-details-info__desc'>&nbsp;&nbsp;&nbsp;Section</div>
                        <div className='ifeedback-details-info__value'>{createdUser.sect_desc}</div>
                    </div>
                    <div className='ifeedback-details-info__item'>
                        <div className='ifeedback-details-info__desc'>&nbsp;&nbsp;&nbsp;Created On</div>
                        <div className='ifeedback-details-info__value'>{data?.created_on}</div>
                    </div>

                    <div className='ifeedback-details-info__item'>
                        <div className='ifeedback-details-info__desc'>Updated By</div>
                        <div className='ifeedback-details-info__value'>{data?.updated_by?.name}</div>
                    </div>
                    <div className='ifeedback-details-info__item'>
                        <div className='ifeedback-details-info__desc'>&nbsp;&nbsp;&nbsp;SAP/BC No</div>
                        <div className='ifeedback-details-info__value'>{updatedUser.username}</div>
                    </div>                    
                    <div className='ifeedback-details-info__item'>
                        <div className='ifeedback-details-info__desc'>&nbsp;&nbsp;&nbsp;Department</div>
                        <div className='ifeedback-details-info__value'>{updatedUser.dept_desc}</div>
                    </div>
                    <div className='ifeedback-details-info__item'>
                        <div className='ifeedback-details-info__desc'>&nbsp;&nbsp;&nbsp;Section</div>
                        <div className='ifeedback-details-info__value'>{updatedUser.sect_desc}</div>
                    </div>
                    <div className='ifeedback-details-info__item'>
                        <div className='ifeedback-details-info__desc'>&nbsp;&nbsp;&nbsp;Updated On</div>
                        <div className='ifeedback-details-info__value'>{data?.updated_on}</div>
                    </div>

                    
                    </div>
                    
                </>
                {/* )} */}
            </div>
        </>
    );
};

// import React, { useState, useEffect, useRef } from "react";
// import SoundIcon from "../../../../assets/admintools/sound.svg";
// import { TextBox, RadioButton,InputPrefix } from "@progress/kendo-react-inputs";
// import { Button } from "@progress/kendo-react-buttons";
// import { Icon } from "@progress/kendo-react-common";
// import api from "../../../../config/api";
// import config from "../../../../config/config";
// import { getCurrentLoggedInSessionToken } from "../../../../config/userPool";
// import { useNavigate } from "react-router";
// import { IFEEDBACK_LIST } from "../../../../routes";

// // const { UPLOAD_ENDPOINT } = config.api_endpoint;

// // const yes_no_values = [{ label: "Yes", value: true }, { label: "No", value: false }]

// export const IFeedbackForm = ({
//     data,
//     // handleAudioPlay,
//     isEdit,
//     setIsEdit,
//     // submitForm,
//     setLoading,
//     isCreate
// }) => {
//     const navigate = useNavigate();
//     const [no, setNo] = useState(null);
//     // const [code, setCode] = useState(null);
//     // const [textColor, setTextColor] = useState(null);
//     // const [ack_required, setAckRequired] = useState(false);
//     // const [push_silently, setPushSilently] = useState(false);

//     // const hiddeniconRef = useRef(null);
//     // const hiddenSoundRef = useRef(null);

//     useEffect(() => {
//         if (data) {
//             setNo(data.no);
//             // setDepots(data.depot);
//             // setCode(data.code);
//         }
//     }, [])

//     return (
//         <>
//             <table>
//                 <tr>
//                     <td>iFeedback No</td>
//                     {/* {isEdit ? (
//                         <td><TextBox value={name} onChange={(e)=> setName(e.target.value)}/></td>
//                     ) : ( */}
//                         <td>{data}</td>
//                     {/* )
//                     } */}
//                 </tr>
//                 {/* <tr>
//                     <td>Status</td>
//                     <td>{data.status}</td>
//                 </tr>
//                 <tr>
//                     <td>Matter Description</td>
//                     <td>{data.description}</td>
//                 </tr>
//                 <tr>
//                     <td>Created By</td>
//                     <td>{data.created_by.name}</td>
//                 </tr>
//                 <tr>
//                     <td>Created On</td>
//                     <td>{data.created_on}</td>
//                 </tr>
//                 <tr>
//                     <td>Updated By</td>
//                     <td>{data.updated_by.name}</td>
//                 </tr>
//                 <tr>
//                     <td>Updated On</td>
//                     <td>{data.updated_on}</td>
//                 </tr> */}
//                 {/* <tr>
//                     <td>Code</td>
//                     {!isEdit ?(
//                         <td>{data.code}</td>
//                     ):(
//                         <>
//                         {isCreate ?(
//                             <td><TextBox value={code} onChange={(e)=> setCode(e.target.value)}/></td>
//                         ):(
//                             <td>{data.code}</td>
//                         )}
//                         </>
//                     )}
//                 </tr>
//                 <tr>
//                     <td>Icon</td>
//                     <td>
//                         <input ref={hiddeniconRef} type="file" accept="image/png, image/jpeg" hidden onChange={handleIconImage} />
//                         {isEdit ? (
//                             <>
//                                 {iconImage.file || iconImage.url ? (
//                                     <div className="detail-file-div">
//                                         <img src={iconImage.file ? URL.createObjectURL(iconImage.file) : iconImage.url} width={36} />
//                                         <label className="detail-file-name">{iconImage.name}</label>
//                                         <Icon name="delete" size="large" onClick={() => setIconImage({ file: null, url: null, name: null })}></Icon>
//                                     </div>
//                                 ) : (
//                                     <>
//                                         <Button onClick={(e) => hiddeniconRef.current.click()}>
//                                             + <span className="gray-color-text">Add</span>
//                                         </Button>
//                                     </>
//                                 )}
//                             </>
//                         ) : (
//                             <div className="detail-file-div">
//                                 <img src={iconImage.url} width={36} />
//                                 <label className="detail-file-name">{iconImage.name}</label>
//                             </div>
//                         )}

//                     </td>
//                 </tr>
//                 <tr>
//                     <td>Notification Sound</td>
//                     <td>
//                         <input ref={hiddenSoundRef} type="file" accept=".mp3" hidden onChange={handleSoundChange} />
//                         {isEdit ?(
//                             <>
//                             {sound.file || sound.url ? (
//                                 <div className="detail-file-div">
//                                     <label className="detail-file-name">{sound.name}</label>
//                                     <img src={SoundIcon} width={24} onClick={handleAudioPlay} />
//                                     <Icon name="delete" size="large" onClick={() => setSound({ file: null, url: null, name: null })}></Icon>
//                                 </div>
//                             ) : (
//                                 <>
//                                     <Button onClick={(e) => hiddenSoundRef.current.click()}>
//                                         + <span className="gray-color-text">Add</span>
//                                     </Button>
//                                 </>
//                             )}                           
//                             </>
//                         ):(
//                             <>
//                             {sound.url && (
//                                 <div className="detail-file-div">
//                                     <label className="detail-file-name">{sound.name}</label>
//                                     <img src={SoundIcon} width={24} onClick={handleAudioPlay} />
//                                 </div>
//                             )}
//                             </>
//                         )}

//                     </td>
//                 </tr>
//                 <tr>
//                     <td>Acknowledgement required</td>
//                     <td>
//                         {isEdit ? (
//                             <div className="detail-radio-div">
//                                 {yes_no_values.map((v) => (
//                                     <RadioButton
//                                         name="ack-required-radio"
//                                         key={v.value}
//                                         label={v.label}
//                                         value={v.value}
//                                         onChange={(e) => setAckRequired(e.value)}
//                                         checked={ack_required === v.value}
//                                     />
//                                 ))}
//                             </div>
//                         ) : (
//                             <>{ack_required ? "Yes" : "No"}</>
//                         )}
//                     </td>
//                 </tr>
//                 <tr>
//                     <td>Push silently</td>
//                     <td>
//                         {isEdit ? (
//                             <div className="detail-radio-div">
//                                 {yes_no_values.map((v) => (
//                                     <RadioButton
//                                         name="push-silently-radio"
//                                         key={v.value}
//                                         label={v.label}
//                                         value={v.value}
//                                         onChange={(e) => setPushSilently(e.value)}
//                                         checked={push_silently === v.value}
//                                     />
//                                 ))}
//                             </div>
//                         ) : (
//                             <>{push_silently ? "Yes" : "No"}</>
//                         )}
//                     </td>
//                 </tr>
//                 <tr>
//                     <td>Text colour code</td>
//                     <td>
//                         {isEdit ? (
//                             <TextBox prefix={()=>(<InputPrefix>#</InputPrefix>)} value={textColor} onChange={(e) => setTextColor(e.target.value)} />
//                         ) : (
//                             <>#{data.text_color}</>
//                         )}
//                     </td>
//                 </tr>
//                 {isEdit && (
//                     <tr>
//                         <td colSpan={2}>
//                             <div className="detail-button-div">
//                                 <Button className="detail-button detail-cancel" onClick={cancelForm}>Cancel</Button>
//                                 <Button className="detail-button detail-submit" onClick={submitCategory}>Submit</Button>
//                             </div>
//                         </td>
//                     </tr>
//                 )} */}
//             </table>
//         </>
//     );
// };