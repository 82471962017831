export const ConfigData = [
  {
    id: "title",
    name: "Title",
    isShow: true,
    width: "500px",
    filter: false,
  },
  {
    id: "category",
    name: "Category",
    isShow: true,
    width: "160px",
    filter: false,
  },
  {
    id: "start_date",
    name: "Start Date",
    isShow: true,
    width: "160px",
    filter: false,
    format: "date",
  },
  {
    id: "end_date",
    name: "End Date",
    isShow: true,
    width: "160px",
    filter: false,
    format: "date",
  },
  {
    id: "created_by.name",
    name: "Created By",
    isShow: true,
    width: "160px",
    filter: false,
  },
  {
    id: "created_on",
    name: "Created On",
    isShow: true,
    width: "160px",
    filter: false,
    format: "date-time",
  },
  {
    id: "status",
    name: "Status",
    isShow: true,
    width: "160px",
    filter: false,
  },
  {
    id: "button",
    name: "Action",
    type: "dropdown",
    isShow: true,
    width: "117px",
    action: ["View Details", "Duplicate", "Publish", "Unpublish", "Delete"],
    permission: {
      Publish: {
        id: "Publish",
        permission: "happening.update_happening_promotion_status",
      },
      Duplicate: {
        id: "Duplicate",
        permission: "happening.create_happening_promotion",
      },
      ["View Details"]: {
        id: "View Details",
        permission: "happening.access_happening_promotion_list",
      },
      Unpublish: {
        id: "Unpublish",
        permission: "happening.update_happening_promotion_status",
      },
      Delete: {
        id: "Delete",
        permission: "happening.delete_happening_promotion",
      },
    },
    isshowAction: {
      item: "status",
      children: [
        {
          id: "Publish",
          value: "Published",
          // notShowCondition: "Submitted",
        },
        {
          id: "Unpublish",
          value: "Unpublished",
          notShowCondition: "Submitted",
        },
      ],
    },
  },
];
