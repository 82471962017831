// kendo
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
// icons
import ExportIcon from "../../../../assets/dr_safe/export.png";
import FilterIcon from "../../../../assets/dr_safe/filter.png";
import SearchIcon from "../../../../assets/dr_safe/search.png";
// components
import PageTitle from "../../../../components/pageTitle/page_title";
import DrSecureListSearchDialog from "./dr_secure_list_search_dialog";
import DrSecureListFilterDialog from "./dr_secure_list_filter_dialog";

const DrSecureListHeader = ({
  showDrSearchDialog,
  onSearchBtnClicked,
  onSearchDialogClose,
  onSearchAction,
  showDrFilterDialog,
  onFilterBtnClicked,
  onFilterDialogClose,
  incidentTypes,
  onFilterAction
}) => {
  return (
    <div className="dr-secure-list-header">
      <PageTitle title="DR Secure Reported Incident" />
      <div className="dr-secure-list-header-right">
        <div className="dr-infra-list-header-icon">
          <img src={ExportIcon} />
        </div>
        <Button className="dr-secure-list-header-icon" title="Filter" onClick={onFilterBtnClicked}>
          <img src={FilterIcon} />
        </Button>
        <Button className="dr-secure-list-header-icon" title="Search" onClick={onSearchBtnClicked}>
          <img src={SearchIcon} />
        </Button>
      </div>
      {showDrSearchDialog && (
        <DrSecureListSearchDialog
          onToggleDialog={onSearchDialogClose}
          onSubmitShare={onSearchAction}
          
        />
      )}

      {showDrFilterDialog && (
        <DrSecureListFilterDialog
          onToggleDialog={onFilterDialogClose}
          incidentTypes={incidentTypes}
          onSubmitFilter={onFilterAction}
        />
      )}
    </div>
  );
};

export default DrSecureListHeader;
