export const ConfigData = [
  {
    id: "title_en",
    name: "Title",
    isShow: true,
    width: "500px",
    filter: false,
  },
  {
    id: "category.name",
    name: "Category",
    isShow: true,
    width: "160px",
    filter: false,
  },
  {
    id: "publish_on",
    name: "Scheduled /Published On",
    isShow: true,
    width: "160px",
    filter: false,
    format: "date",
  },
  {
    id: "publish_end",
    name: "Scheduled /Published End",
    isShow: true,
    width: "160px",
    filter: false,
    format: "date",
  },
  {
    id: "created_on",
    name: "Created On",
    isShow: true,
    width: "160px",
    filter: false,
    format: "date-time",
  },
  {
    id: "created_by.name",
    name: "Created By",
    isShow: true,
    width: "160px",
    filter: false,
  },
  {
    id: "status",
    name: "Status",
    isShow: true,
    width: "160px",
    filter: false,
  },
  {
    id: "button",
    name: "Action",
    type: "dropdown",
    isShow: true,
    width: "117px",
    action: ["View Details", "Duplicate", "Publish", "Unpublish", "Delete"],
    permission: {
      Publish: {
        id: "Publish",
        permission: "happening.update_happening_article_status",
      },
      Duplicate: {
        id: "Duplicate",
        permission: "happening.create_happening_article",
      },
      ["View Details"]: {
        id: "View Details",
        permission: "happening.access_happening_article_list",
      },
      Unpublish: {
        id: "Unpublish",
        permission: "happening.update_happening_article_status",
      },
      Delete: {
        id: "Delete",
        permission: "happening.delete_happening_article",
      },
    },
    isshowAction: {
      item: "status",
      children: [
        {
          id: "Publish",
          value: "Published",
          // notShowCondition: "Submitted",
        },
        {
          id: "Unpublish",
          value: "Unpublished",
          notShowCondition: "Submitted",
        },
      ],
    },
  },
];
