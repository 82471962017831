import React, { useState, useEffect, useRef } from "react";
import SoundIcon from "../../../../assets/admintools/sound.svg";
import { TextBox, RadioButton, InputPrefix } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Icon } from "@progress/kendo-react-common";
import api from "../../../../config/api";
import config from "../../../../config/config";
import { DepotListDialog } from "../../../../components/dialog/depotListDialog";
import { ADMIN_TOOLS_DIVISION_LIST } from "../../../../routes";
import { useNavigate } from "react-router";

export const DivisionForm = ({
    data,
    isEdit,
    isCreate,
    setIsEdit,
    submitForm
}) => {
    const navigate = useNavigate();
    const [name, setName] = useState(null);
    const [code, setCode] = useState(null);
    const [depots, setDepots] = useState([]);
    const [showDepotList, setShowDepotList] = useState(false);

    useEffect(() => {
        if (data) {
            setName(data.name);
            setDepots(data.depot);
            setCode(data.code);
        }
    }, [])

    const submitDivision = () => {
        let data = {
            name,
            code,
            depot: depots
        }
        submitForm(data)
    }
    const depotSelectedCallback = (data) => {
        setDepots(data);
        setShowDepotList(false);
    }
    const cancelForm = ()=>{
        if(data && isEdit){
            setIsEdit(false);
        }else{
            navigate(ADMIN_TOOLS_DIVISION_LIST);
        }
    }
    return (
        <>
            {showDepotList && (
                <DepotListDialog
                    callback={depotSelectedCallback}
                    defaultSelected={depots}
                    onClickNo={() => setShowDepotList(false)}
                />
            )}
            <table>
                <tr>
                    <td>Division</td>
                    {isEdit ? (
                        <td><TextBox value={name} onChange={(e) => setName(e.target.value)} /></td>
                    ) : (
                        <td>{data.name}</td>
                    )
                    }
                </tr>
                <tr>
                    <td>Code</td>
                    {!isEdit ?(
                        <td>{data.code}</td>
                    ):(
                        <>
                        {isCreate ?(
                             <td><TextBox value={code} onChange={(e)=> setCode(e.target.value)}/></td>
                        ):(
                            <td>{data.code}</td>
                        )}
                        </>
                    )}
                </tr>
                {!isEdit && (
                    <>
                        <tr>
                            <td>Created By</td>
                            <td>{data.created_by ? data.created_by.name : "M/A"}</td>
                        </tr>
                        <tr>
                            <td>Created On</td>
                            <td>{data.created_on ? data.created_on : "N/A"}</td>
                        </tr>
                        <tr>
                            <td>Updated By</td>
                            <td>{data.updated_by ? data.updated_by.name : "N/A"}</td>
                        </tr>
                        <tr>
                            <td>Updated On</td>
                            <td>{data.updated_on ? data.updated_on : "N/A"}</td>
                        </tr>

                    </>
                )}
                <tr>
                    <td>List of depot</td>
                    <td>
                        {isEdit ? (
                            <div className="edit-depot-layout">
                                <Button onClick={() => setShowDepotList(true)}>Click here to update the depot(s)</Button>
                                {depots ? depots.map((v) => (
                                    <div className="division-depot-item">{v.name}</div>
                                )) : null}
                            </div>
                        ) : (
                            <div className="edit-depot-layout">
                                {data.depot ? data.depot.map((v) => (
                                    <div className="division-depot-item">{v.name}</div>
                                )) : null}

                            </div>
                        )}
                    </td>
                </tr>
                {isEdit && (
                    <tr>
                        <td colSpan={2}>
                            <div className="detail-button-div">
                                <Button className="detail-button detail-cancel" onClick={cancelForm}>Cancel</Button>
                                <Button className="detail-button detail-submit" onClick={submitDivision}>Submit</Button>
                            </div>
                        </td>
                    </tr>
                )}
            </table>
        </>
    );
};