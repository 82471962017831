import React, { useState, useEffect } from "react";
import { Navigate, useNavigate, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Dialog } from "@progress/kendo-react-dialogs";
import { TableHeader } from "./Tableheader";
import { IFeedbackTableBody } from "../../../../components/table/iFeedbackTable";

import { Loader } from "@progress/kendo-react-indicators";
import {
  fetchAlliFeedback,
  changeiFeedbackStatus,
} from "../../../../store/ifeedback";
import { IFEEDBACK_LIST } from "../../../../routes";
import { IFeedbackListSearchDialog } from "./ifeedback_list_search_dialog";
import { IFeedbackListFilterDialog } from "./ifeedback_list_filter_dialog";
import moment from "moment";
import {
  getUserPermission,
  checkMultiPermission,
  IFEEDBACK_PERMISSIONS_FOR_EXPORT,
} from "../../../../config/permission_helper";
import IFeedbackDetailsStatusDialog from "../details/ifeedback_details_status_dialog";
import "./ifeedback.css";
import Cross_icon from "../../../../assets/mic/cross_icon.svg";
import api from "../../../../config/api";
import { getCurrentLoggedInSessionToken } from "../../../../config/userPool";

export const IFeedbackList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const [ifeedback_ids, setifeedback_ids] = useState([]);
  const [all_ifeedbacks, setAlliFeedbacks] = useState([]);

  // pagination
  const [alliFeedback_next, setAlliFeedbackNext] = useState({});
  const [pagenumber, setPageNumber] = useState(0);
  const [is_previous, setIsPrevious] = useState(false);
  const [is_next, setIsNext] = useState(false);
  const { loading, error } = useSelector((state) => state.ifeedback);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [showSearchDialog, setShowSearchDialog] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [showChangeStatusDialog, setShowChangeStatusDialog] = useState(false);
  const [currentiFeedbackStatus, setCurrentiFeedbackStatus] = useState("");
  const [currentiFeedbackID, setCurrentiFeedbackID] = useState("");
  const [actionMessage, setActionMessage] = useState("");
  const [feedbackDelete, setFeedbackDelete] = useState({
    isDelete: false,
    deleteId: "",
  });
  const [showAlert, setshowAlert] = useState(false);
  const ifeedbackStatuses = ["Submitted", "Investigating", "Closed"];

  useEffect(() => {
    query.delete("next_key");
    query.delete("pagination");
    query.delete("filter");
    query.delete("created_on_start");
    query.delete("created_on_end");
    query.delete("updated_on_start");
    query.delete("updated_on_end");
    query.delete("matter");
    query.delete("status");
    query.delete("search");
    query.delete("ifeedback_no");
    query.delete("created_by");

    dispatch(fetchAlliFeedback(query)).then((res) => {
      let { data, next_key, total_count } = res.payload;
      setAlliFeedbackNext(next_key);
      setifeedback_ids(data);
      setAlliFeedbacks([{ data, next_key }]);

      query.set("next_key", JSON.stringify(next_key));
      navigate(IFEEDBACK_LIST + "?" + query.toString());
    });
  }, []);

  //   const handleDeleteAction = ((id)=>{
  //     dispatch(deleteDivision(id)).then(()=>{
  //       dispatch(fetchAllDivisions()).then((res)=>{
  //         setAllDivisions(res.payload);
  //       });
  //     })
  //   })
  const handleFilterCallback = (data) => {
    const {
      created_on_start,
      created_on_end,
      updated_on_start,
      updated_on_end,
      matter,
      status,
    } = data;
    console.log("Filter Call Back- ", created_on_start, created_on_end);
    query.delete("filter");
    if (created_on_start) {
      query.set(
        "created_on_start",
        new Date(created_on_start)
          ? moment(new Date(created_on_start)).format("YYYY-MM-DD")
          : ""
      );
    } else {
      query.set("created_on_start", "");
    }

    if (created_on_end) {
      query.set(
        "created_on_end",
        new Date(created_on_end)
          ? moment(new Date(created_on_end)).format("YYYY-MM-DD")
          : ""
      );
    } else {
      query.set("created_on_end", "");
    }

    if (updated_on_start) {
      query.set(
        "updated_on_start",
        new Date(updated_on_start)
          ? moment(new Date(updated_on_start)).format("YYYY-MM-DD")
          : ""
      );
    } else {
      query.set("updated_on_start", "");
    }

    if (updated_on_start) {
      query.set(
        "updated_on_end",
        new Date(updated_on_end)
          ? moment(new Date(updated_on_end)).format("YYYY-MM-DD")
          : ""
      );
    } else {
      query.set("updated_on_end", "");
    }

    query.set("matter", matter);
    query.set("status", status);
    if (
      created_on_start ||
      created_on_end ||
      updated_on_start ||
      updated_on_end ||
      matter ||
      status
    ) {
      query.set("filter", true);
    }
    navigate(IFEEDBACK_LIST + "?" + query.toString());
    setShowFilterDialog(false);
    handleFilterAction();
  };
  const handleFilterAction = () => {
    query.delete("next_key");
    query.delete("pagination");

    dispatch(fetchAlliFeedback(query)).then((res) => {
      let { data, next_key, total_count } = res.payload;
      setAlliFeedbacks([{ data, next_key, total_count }]);
      setAlliFeedbackNext(next_key);
      setifeedback_ids(data);
      setPageNumber(0);
      setIsPrevious(false);
      setIsNext(false);

      query.set("next_key", JSON.stringify(next_key));
      navigate(IFEEDBACK_LIST + "?" + query.toString());
    });

    /*
    // This is written for client-side filters
    let results = all_ifeedbacks[0].data;
    if (query.toString()) {
      if (query.get("created_on_start")) {
        const formatted_created_on = new Date(
          moment(query.get("created_on_start"))
        );
        results = results.filter(
          (v) =>
            new Date(moment(v.created_on, "DD MMM YYYY,hh:mma")) >=
            formatted_created_on
        );
      }
      if (query.get("created_on_end")) {
        const formatted_created_end = new Date(
          moment(query.get("created_on_end")).hours(23).minutes(59).seconds(59)
        );
        results = results.filter(
          (v) =>
            new Date(moment(v.created_on, "DD MMM YYYY,hh:mma")) <=
            formatted_created_end
        );
      }
      if (query.get("updated_on_start")) {
        const formatted_updated_start = new Date(
          moment(query.get("updated_on_start"))
        );
        results = results.filter(
          (v) =>
            new Date(moment(v.updated_on, "DD MMM YYYY,hh:mma")) >=
            formatted_updated_start
        );
      }
      if (query.get("updated_on_end")) {
        const formatted_updated_end = new Date(
          moment(query.get("updated_on_end")).hours(23).minutes(59).seconds(59)
        );
        results = results.filter(
          (v) =>
            new Date(moment(v.updated_on, "DD MMM YYYY,hh:mma")) <=
            formatted_updated_end
        );
      }
      if (query.get("ifeedback_no")) {
        results = results.filter(
          (v) =>
            v.no
              .toLowerCase()
              .indexOf(query.get("ifeedback_no").toLowerCase()) > -1
        );
      }
      if (query.get("created_by")) {
        results = results.filter(
          (v) =>
            v.created_by.name
              .toLowerCase()
              .indexOf(query.get("created_by").toLowerCase()) > -1
        );
      }
      if (query.get("matter")) {
        let chosen = query.get("matter").split(",");
        results = results.filter((v) => chosen.indexOf(v.matter) > -1);
      }
      if (query.get("status")) {
        let chosen = query.get("status").split(",");
        results = results.filter((v) => chosen.indexOf(v.status) > -1);
      }
    }
    console.log("All iFeedbacks after Filter- ", results);
    setifeedback_ids(results);
    */
  };

  const handleSearchCallback = (data) => {
    const { ifeedback_no, created_by_name } = data;
    query.delete("search");
    query.set("ifeedback_no", ifeedback_no);
    query.set("created_by", created_by_name);
    if (ifeedback_no || created_by_name) {
      query.set("search", true);
    }
    navigate(IFEEDBACK_LIST + "?" + query.toString());
    setShowSearchDialog(false);
    handleFilterAction();
  };
  const handleClearFilterOptions = () => {
    query.delete("filter");
    query.delete("created_on_start");
    query.delete("created_on_end");
    query.delete("updated_on_start");
    query.delete("updated_on_end");
    query.delete("matter");
    query.delete("status");

    navigate(IFEEDBACK_LIST + "?" + query.toString());
    handleFilterAction();
  };
  const handleClearSearchOptions = () => {
    query.delete("search");
    query.delete("ifeedback_no");
    query.delete("created_by");
    navigate(IFEEDBACK_LIST + "?" + query.toString());
    handleFilterAction();
  };

  const handlePreviouscallback = () => {
    if (pagenumber - 1 === 0) {
      setIsPrevious(false);
    }

    let result = all_ifeedbacks[pagenumber - 1];
    setifeedback_ids(result.data);
    setAlliFeedbackNext(result.next_key);
    setPageNumber(pagenumber - 1);
  };

  const handlenextpagecallback = () => {
    query.delete("pagination");

    if (pagenumber + 1 < all_ifeedbacks.length) {
      console.log("Pag < length", pagenumber);
      let result = all_ifeedbacks[pagenumber + 1];
      setifeedback_ids(result.data);
      setAlliFeedbackNext(result.next_key);
      setPageNumber(pagenumber + 1);
      console.log(
        "setifeedback_ids-- ",
        ifeedback_ids,
        "setPageNumber",
        pagenumber
      );
    } else {
      query.set("next_key", JSON.stringify(alliFeedback_next));
      query.set("pagination", true);
      dispatch(fetchAlliFeedback(query)).then((res) => {
        let { data, next_key } = res.payload;
        if (data.length > 0) {
          setPageNumber(pagenumber + 1);
          setIsPrevious(true);
          setAlliFeedbacks([...all_ifeedbacks, { data, next_key }]);
          console.log("NEXT KEY_____", next_key);
          if (Object.keys(next_key).length == 0) {
            console.log("next key is empty");
            setAlliFeedbackNext({});
            setIsNext(true);
          }
          setifeedback_ids(data);
          setAlliFeedbackNext(next_key);
          query.set("next_key", JSON.stringify(next_key));
        } else {
          console.log("data.length====0");
          if (pagenumber === 0) {
            setIsPrevious(false);
          } else {
            setIsPrevious(true);
          }
          setAlliFeedbackNext({});
        }
        query.set("pagination", true);
        navigate(IFEEDBACK_LIST + "?" + query.toString());
      });
    }
  };
  const handlePageChange = (number) => {
    console.log("handlePageChange", number);
    if (number === 0) {
      setIsPrevious(false);
    } else {
      setIsPrevious(true);
    }
    let result = all_ifeedbacks[number];
    setifeedback_ids(result.data);
    setAlliFeedbackNext(result.next_key);
    setPageNumber(number);
  };

  const shouldShowExport = () => {
    const userPermissions = getUserPermission();
    if (
      checkMultiPermission(userPermissions, IFEEDBACK_PERMISSIONS_FOR_EXPORT)
    ) {
      return true;
    } else {
      return false;
    }
  };
  const handleOpenStatusDialog = (no, status) => {
    console.log("click status-", no, status);
    setCurrentiFeedbackID(no);
    setCurrentiFeedbackStatus(status);
    setShowChangeStatusDialog(true);
  };

  const handleSaveiFeedbackStatus = () => {
    console.log("selectedID----", currentiFeedbackID);
    dispatch(
      changeiFeedbackStatus({
        ifeedback_id: currentiFeedbackID,
        ifeedbackStatus: currentiFeedbackStatus.toLowerCase(),
      })
    )
      .then((result) => {
        console.log("handleSaveiFeedbackStatus RES-", result);
        if (result.payload.status === "success") {
          setActionMessage("Updated Status");
          setshowAlert(true);
        } else {
          setActionMessage(result.payload.message);
          setshowAlert(true);
        }
      })
      .then(() => {
        console.log("calling FetchAlliFeedback");
        dispatch(fetchAlliFeedback(query)).then((res) => {
          let { data, next_key } = res.payload;
          setAlliFeedbacks([{ data, next_key }]);
          setAlliFeedbackNext(next_key);
          setifeedback_ids(data);
          navigate(IFEEDBACK_LIST + "?" + query.toString());
        });
      });
    console.log("props.loading----", loading);
    setShowChangeStatusDialog(false);
  };

  const DeleteFeedback = (e, val) => {
    console.log(e, val);
    setFeedbackDelete({
      ...feedbackDelete,
      isDelete: true,
      deleteId: val.no,
    });
  };

  const Delete = async () => {
    const token = getCurrentLoggedInSessionToken();
    setLoading(true);
    setFeedbackDelete({
      ...feedbackDelete,
      isDelete: false,
    });
    const results = await api.remove(
      `/api-ifeedback/ifeedback/${feedbackDelete.deleteId}`,
      JSON.stringify({ id: feedbackDelete.deleteId }),
      token
    );
    if (results.status === "success") {
      setLoading(false);
      setFeedbackDelete({
        ...feedbackDelete,
        deleteId: "",
        isDelete: false,
      });
      dispatch(fetchAlliFeedback(query)).then((res) => {
        let { data, next_key } = res.payload;
        setAlliFeedbacks([{ data, next_key }]);
        setAlliFeedbackNext(next_key);
        setifeedback_ids(data);
        navigate(IFEEDBACK_LIST + "?" + query.toString());
      });
    }
    console.log(results);
  };

  return (
    <div className="body">
      {Loading && (
        <Loader
          type="converging-spinner"
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#ffffff4d",
            position: "absolute",
            zIndex: "999",
          }}
        />
      )}
      {showAlert && (
        <Dialog
          className="alert-form"
          title={"Alert"}
          width={400}
          // height={280}
          onClose={() => setshowAlert(false)}
        >
          <p>{actionMessage}</p>
        </Dialog>
      )}
      {showFilterDialog && (
        <IFeedbackListFilterDialog
          setShowFilterDialog={setShowFilterDialog}
          onFiltercallback={handleFilterCallback}
        />
      )}
      {showSearchDialog && (
        <IFeedbackListSearchDialog
          setShowSearchDialog={setShowSearchDialog}
          onSearchcallback={handleSearchCallback}
        />
      )}
      <TableHeader
        title="iFeedback"
        loading={loading}
        loadData={() => {
          dispatch(fetchAlliFeedback(query)).then((res) => {
            console.log("Refresh data- ", res);
            let { data, next_key } = res.payload;
            setAlliFeedbacks([{ data, next_key }]);
            console.log("Ifeedback List Next Keyyyyyyyyyyy", next_key);
            setAlliFeedbackNext(next_key);
            setifeedback_ids(data);
            navigate(IFEEDBACK_LIST + "?" + query.toString());
          });
        }}
        showiFeedbackExport={shouldShowExport()}
        setShowFilterDialog={setShowFilterDialog}
        setShowSearchDialog={setShowSearchDialog}
      />
      {(query.get("filter") || query.get("search")) && (
        <div className="table-filter-div">
          {query.get("filter") && (
            <div className="table-filter-item">
              <div className="table-filter-title">Filter applied</div>
              <span
                className="table-filter-clear"
                onClick={handleClearFilterOptions}
              >
                Clear filter
              </span>
            </div>
          )}
          {query.get("search") && (
            <div className="table-filter-item">
              <div className="table-filter-title">Keyword search applied</div>
              <span
                className="table-filter-clear"
                onClick={handleClearSearchOptions}
              >
                Clear search
              </span>
            </div>
          )}
        </div>
      )}
      {loading ? (
        <Loader
          type="infinite-spinner"
          style={{
            display: "flex",
            alignItems: "center",
            width: "40px",
            margin: "auto",
            justifyContent: "center",
            height: "100vh",
          }}
        />
      ) : (
        <IFeedbackTableBody
          loading={loading}
          data={ifeedback_ids}
          alldata={all_ifeedbacks}
          handlenextpagecallback={handlenextpagecallback}
          handlePreviouscallback={handlePreviouscallback}
          is_previous={is_previous}
          is_next={is_next}
          pagenumber={pagenumber}
          DeleteFeedback={DeleteFeedback}
          handlePageChange={handlePageChange}
          openStatusDialog={handleOpenStatusDialog}
        />
      )}
      {showChangeStatusDialog && (
        <IFeedbackDetailsStatusDialog
          onToggleDialog={() => setShowChangeStatusDialog(false)}
          ifeedbackStatuses={ifeedbackStatuses}
          currentiFeedbackStatus={currentiFeedbackStatus}
          onChangeiFeedbackStatus={(e) =>
            setCurrentiFeedbackStatus(e.target.value)
          }
          onSaveiFeedbackStatus={handleSaveiFeedbackStatus}
        />
      )}
      {feedbackDelete.isDelete && (
        <Dialog>
          <div style={{ position: "relative" }}>
            <div
              style={{
                position: "absolute",
                right: "10px",
                top: "15px",
                cursor: "pointer",
              }}
              onClick={() =>
                setFeedbackDelete({
                  ...feedbackDelete,
                  isDelete: false,
                  deleteId: "",
                })
              }
            >
              <img src={Cross_icon} />
            </div>
          </div>
          <div className="Feedback_delete_popup_box">
            <div className="Feedback_delete_title">
              Are you sure, you want to delete {feedbackDelete.deleteId}
            </div>
            <div className="Feedback_delete_footer_box">
              <div
                className="Feedback_delete_footer_cancel"
                onClick={() =>
                  setFeedbackDelete({
                    ...feedbackDelete,
                    isDelete: false,
                    deleteId: "",
                  })
                }
              >
                Cancel
              </div>
              <div className="Feedback_delete_footer_delete" onClick={Delete}>
                Delete
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
};
