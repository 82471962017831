import { Button } from "@progress/kendo-react-buttons";

import RefreshIcon from "../../../assets/header/refresh.svg";
import ExportIcon from "../../../assets/header/export.svg";
import SelectOption from "../bus-checker-periods/components/select-options";
import ButtonExport from "../../../components/button-export";

import "./approved-assignment.css";
import { useApprovedAssignment } from "./actions";
import { Loader } from "@progress/kendo-react-indicators";
import {
  checkPermission,
  getUserPermission,
} from "../../../config/permission_helper";

const Page = () => {
  const {
    isFetching,
    isAbleShowDetail,
    exportData,
    list,
    period,
    selectPeriodPublish,
    maxAllocation,
    onSelectPeriod,
    refetch,
    onExport,
  } = useApprovedAssignment();
  return (
    <div className="approved-assignment">
      <div className="container">
        <div className="approved-assignment-container">
          <div className="approved-assignment-headers">
            <h2>Feedback on Bus Service</h2>
            {isAbleShowDetail && (
              <div className="filter-group">
                <Button onClick={refetch} className="custom-button">
                  <img
                    style={{ width: 20, height: 20 }}
                    src={RefreshIcon}
                    alt="refresh-icon"
                  />
                </Button>
                {checkPermission(
                  getUserPermission(),
                  "fob.bsqc_export_approved_assignment"
                ) && (
                  <ButtonExport
                    onClick={onExport}
                    data={exportData}
                    filename="approved-assignment"
                  />
                )}
                {/* <Button disabled={isFetching} onClick={onExport} className="custom-button">
                  <img style={{ width: 20, height: 20 }} src={ExportIcon} alt="export-icon" />
                </Button> */}
              </div>
            )}
          </div>

          <div style={{ width: 450 }}>
            <SelectOption
              className="approved-assignment-options"
              title={selectPeriodPublish?.label ?? "Select BSQC Check Period"}
              select={selectPeriodPublish}
              options={period}
              onSelect={onSelectPeriod}
              inputStyle={{ minHeight: 30, margin: "unset" }}
            />
          </div>

          <div style={{ marginTop: 40 }}>
            {isFetching ? (
              <div style={{ width: "100%" }}>
                <Loader
                  type="infinite-spinner"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "40px",
                    margin: "auto",
                    justifyContent: "center",
                    height: "100%",
                  }}
                />
              </div>
            ) : (
              isAbleShowDetail && (
                <table>
                  <thead>
                    <tr>
                      <th rowSpan={2} colSpan={2}>
                        {selectPeriodPublish?.data?.name || ""}
                      </th>
                      <th colSpan={maxAllocation * 7}>
                        BSQC Check Preference Selection
                      </th>
                    </tr>
                    <tr>
                      {Array.from(
                        { length: maxAllocation ?? 1 },
                        () => null
                      )?.map((_, idx) => (
                        <th colSpan={7}>Assignment {idx + 1}</th>
                      ))}
                    </tr>
                    <tr style={{ fontSize: 14 }}>
                      <th>S.No.</th>
                      <th>Checkers</th>
                      {Array.from(
                        { length: maxAllocation ?? 1 },
                        () => null
                      ).map(() => (
                        <>
                          <th>Service No.</th>
                          <th>Bus Stop</th>
                          <th>Bus Stop Description</th>
                          <th>Road Name</th>
                          <th>AM/PM</th>
                          <th>Direction</th>
                          <th>Time</th>
                        </>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {!!list?.length &&
                      list?.map(({ data, name }, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{idx + 1}</td>
                            <td>{name}</td>

                            {new Array(maxAllocation)
                              .fill("")
                              ?.map((_, key) => (
                                <>
                                  <td>{data?.[key]?.service_no ?? ""}</td>
                                  <td>{data[key]?.bus_stop_code ?? ""}</td>
                                  <td>{data[key]?.bus_stop_name ?? ""}</td>
                                  <td>{data[key]?.road_name ?? ""}</td>
                                  <td>
                                    {typeof data[key]?.is_am !== "undefined" ||
                                    data[key]?.is_am === null
                                      ? data[key]?.is_am
                                        ? "AM"
                                        : "PM"
                                      : ""}
                                  </td>
                                  <td>{data[key]?.direction ?? ""}</td>
                                  <td>{data[key]?.time ?? ""}</td>
                                </>
                              ))}
                          </tr>
                        );
                      })}
                    {!list?.length && (
                      <tr>
                        <td colSpan={3} style={{ textAlign: "center" }}>
                          No Record found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page;
