import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionToken } from "../config/userPool";

const { FOB_ASSIGNMENT_GET_ENDPOINT } = config.api_endpoint;

const initialState = {
  loading: true,
  assignmentList: [],
  error: null,
  next_key: "",
};

export const fetchAssignment = createAsyncThunk(
  "assignment/fetchAssignment",
  async (_, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(FOB_ASSIGNMENT_GET_ENDPOINT, {}, token);
      let data = results.data;
      console.log(results);
      const Action_Included_data = [];
      results.data.map((i) => {
        Action_Included_data.push({
          ...i,
          action: [
            {
              id: "delete",
              name: "Delete",
            },
          ],
        });
      });
      return {
        data: Action_Included_data,
        next_key: results.next_key,
      };
    } catch (e) {
      return rejectWithValue(e.details);
    }
  }
);

export const fetchAssignmentFilter = createAsyncThunk(
  "assignment/fetchAssignmentFilter",
  async (_, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(FOB_ASSIGNMENT_GET_ENDPOINT, {}, token);
      let data = results.data;
      console.log(results);
      const Action_Included_data = [];
      results.data.map((i) => {
        Action_Included_data.push({
          ...i,
          action: [
            {
              id: "delete",
              name: "Delete",
            },
          ],
        });
      });
      return {
        data: Action_Included_data,
        next_key: results.next_key,
      };
    } catch (e) {
      return rejectWithValue(e.details);
    }
  }
);

const AssignmentSlice = createSlice({
  name: "assignment",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAssignment.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAssignment.fulfilled, (state, action) => {
        state.loading = false;
        console.log(action.payload.data);
        state.assignmentList = action.payload.data;
        state.next_key = action.payload.next_key;
      })
      .addCase(fetchAssignment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchAssignmentFilter.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAssignmentFilter.fulfilled, (state, action) => {
        state.loading = false;
        console.log(action.payload.data);
        state.assignmentList = action.payload.data;
        state.next_key = action.payload.next_key;
      })
      .addCase(fetchAssignmentFilter.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

const AssignmentReducer = AssignmentSlice.reducer;
export default AssignmentReducer;
