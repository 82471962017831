import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// kendo
import { Loader } from "@progress/kendo-react-indicators";
import { TextBox, TextArea } from "@progress/kendo-react-inputs";
// image
import LeftArrowIcon from "../../../../assets/dr_safe/left-arrow.png";
// routes
import { DR_SAFE_CONTACT_LIST } from "../../../../routes";
// css
import "./contact_details.css";
import {
  fetchAllDRSafeContactDetails,
  saveDRSafeNewContactDetails,
  updateDRSafeContactDetails,
} from "../../../../store/drSafeContact";

const DrSafeContactDetails = () => {
  let { search } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleBackBtnClicked = () => {
    navigate(DR_SAFE_CONTACT_LIST);
  };

  const {
    drSafeContactDetails,
    details_loading: loading,
    details_error: error,
    drSafeContactNewDetails,
    drSafeContactUpdateDetails,
  } = useSelector((state) => state.drSafeContact);

  const [contact, setContact] = useState({});
  const [contactName, setContactName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [saveBtnClicked, setSaveBtnClicked] = useState(false);

  const handleContactName = (e) => {
    setContactName(e.target.value);
  };

  const handleContactNo = (e) => {
    setContactNo(e.target.value);
  };

  const handleSaveBtnClicked = () => {
    const query = new URLSearchParams(search);
    if (query.get("id")) {
      dispatch(
        updateDRSafeContactDetails({
          id: query.get("id"),
          contact_name: contactName,
          contact_number: contactNo,
          occ: contact["occ"],
        })
      );
    } else {
      dispatch(
        saveDRSafeNewContactDetails({
          contact_name: contactName,
          contact_number: contactNo,
          occ: true,
        })
      );
    }
    setSaveBtnClicked(true);
  };

  useEffect(() => {
    console.log("drSafeContactNewDetails:", drSafeContactNewDetails);
    if (drSafeContactNewDetails.id && saveBtnClicked) {
      navigate(DR_SAFE_CONTACT_LIST);
    }
  }, [drSafeContactNewDetails]);

  useEffect(() => {
    console.log("drSafeContactUpdateDetails:", drSafeContactUpdateDetails);
    if (drSafeContactUpdateDetails.id && saveBtnClicked) {
      navigate(DR_SAFE_CONTACT_LIST);
    }
  }, [drSafeContactUpdateDetails]);

  useEffect(() => {
    console.log("drSafeContactDetails:", drSafeContactDetails);
    if (drSafeContactDetails.length > 0) {
      setContact(drSafeContactDetails[0]);
      setContactName(drSafeContactDetails[0]["contact_name"]);
      setContactNo(drSafeContactDetails[0]["contact_number"]);
    }
  }, [drSafeContactDetails]);

  useEffect(() => {
    const query = new URLSearchParams(search);
    console.log("Contact Details query:", query.get("id"));

    if (query.get("id")) {
      dispatch(fetchAllDRSafeContactDetails(query.get("id")));
    } else {
      setContactName("");
      setContactNo("");
    }
  }, []);

  return (
    <div className="dr-safe-content-details-wrapper">
      <div className="dr-safe-contact-content-header">
        <span
          className="dr-safe-contact-content-header-text"
          onClick={handleBackBtnClicked}
        >
          <img src={LeftArrowIcon} /> Back
        </span>
      </div>

      {loading ? (
        <Loader
          className="dr-safe-contact-details-loader"
          type="infinite-spinner"
        />
      ) : (
        <React.Fragment>
          <div className="dr-safe-contact-content-details">
            <div className="dr-safe-contact-content-details-left">
              <div className="dr-safe-contact-content-details-left-label normal">
                Contact Name
              </div>
              <div className="dr-safe-contact-content-details-left-label normal">
                Contact No.
              </div>
            </div>
            <div className="dr-safe-contact-content-details-right">
              <div className="dr-safe-contact-content-details-right-label normal">
                <TextBox
                  value={contactName}
                  onChange={handleContactName}
                  height={"48px"}
                />
              </div>
              <div className="dr-safe-contact-content-details-right-label normal">
                <TextBox
                  value={contactNo}
                  onChange={handleContactNo}
                  height={"48px"}
                />
              </div>
            </div>
          </div>
          <div
            className="dr-safe-contact-content-header-btn-save"
            onClick={handleSaveBtnClicked}
          >
            Save
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default DrSafeContactDetails;
