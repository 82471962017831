import React, { useEffect, useState } from "react";
import { CustomStep } from "../../../components/common/custom_steps";
import { Stepper } from "@progress/kendo-react-layout";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchApreAudit,
  fetchApreDelete,
  fetchApreDetail,
  fetchApreUpdateStatus,
} from "../../../store/aprewall/aprewall";
import { Loader } from "@progress/kendo-react-indicators";
import moment from "moment";
import {
  CARES_APPRECIATION_WALLS_CREATE,
  CARES_APPRECIATION_WALLS_EDIT,
  CARES_APPRECIATION_WALLS_LISTING,
} from "../../../routes";
import { Confirmation } from "../../../components/dialog/confirmation";
import {
  checkPermission,
  getUserPermission,
} from "../../../config/permission_helper";
import { PermissionDeninedPage } from "../../../components/reusableItems/permission_denined";
import { ShowDialog } from "../../../components/reusableItems/dialog";

export const Aprewall_Detail = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const id = query.get("id");
  const [showDelete, setShowDelete] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [tempStatus, setTempStatus] = useState("");
  const { loading, detail_data, audit_data } = useSelector(
    (state) => state.aprewall
  );
  const userPermissions = getUserPermission();
  const viewPermssion = checkPermission(
    userPermissions,
    "cares.access_cares_appwall_list"
  );
  const DeletePermssion = checkPermission(
    userPermissions,
    "cares.delete_cares_appwall"
  );
  const AddPermssion = checkPermission(
    userPermissions,
    "cares.add_cares_appwall"
  );

  useEffect(() => {
    dispatch(fetchApreDetail(id)).then((res) => {
      dispatch(fetchApreAudit(id));
    });
  }, [id]);

  const dateFormatChanger = (input) => {
    return `${input.split("-")[2]}-${input.split("-")[1]}-${
      input.split("-")[0]
    }`;
  };

  const handleDeleteAprewall = () => {
    setShowDelete(false);
    dispatch(fetchApreDelete(id)).then((res) => {
      navigate(CARES_APPRECIATION_WALLS_LISTING);
    });
  };

  const getDropdownData = (value) => {
    let permission = {
      Publish: {
        id: "Published",
        permission: "cares.update_cares_appwall_status",
      },
      Unpublish: {
        id: "Unpublished",
        permission: "cares.update_cares_appwall_status",
      },
    };
    if (value.length && value.length > 0) {
      let updateValue = [];
      value.map((i) => {
        const userPermission = getUserPermission();
        const checkStatusPermission = checkPermission(
          userPermission,
          permission?.[i].permission
        );
        if (checkStatusPermission) {
          console.log(detail_data);
          if (detail_data.status !== permission?.[i].id) updateValue.push(i);
        }
      });
      return updateValue;
    }
    return [];
  };

  const updateStatus = () => {
    setStatusLoading(true);
    setShowStatus(false);
    console.log("working", {
      id: id,
      status: tempStatus === "Publish" ? "published" : "unpublished",
    });
    dispatch(
      fetchApreUpdateStatus({
        id: id,
        status: tempStatus === "Publish" ? "Published" : "Unpublished",
      })
    ).then((res) => {
      if (res.payload.status === "success") {
        setStatusLoading(false);
        setTempStatus("");
        dispatch(fetchApreDetail(id)).then((res) => {
          dispatch(fetchApreAudit(id));
        });
      } else {
        alert(res.payload.message);
      }
    });
  };

  return (
    <>
      {showDelete && (
        <Confirmation
          title={"Please Confirm"}
          onClickNo={() => setShowDelete(false)}
          onClickYes={handleDeleteAprewall}
        />
      )}
      {showStatus && (
        <ShowDialog
          cancelbtn={() => setShowStatus(false)}
          title={""}
          dropdown={true}
          cancelContent={"Cancel"}
          confrimContent={"Update"}
          confrinbtn={updateStatus}
          dropdownData={getDropdownData(["Publish", "Unpublish"])}
          dropdownOnChange={(e) => setTempStatus(e)}
          dropdownValue={tempStatus}
        />
      )}
      {viewPermssion ? (
        <>
          {loading || statusLoading ? (
            <Loader
              type="converging-spinner"
              style={{
                width: "100%",
                height: "calc(100% - 75px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#ffffff4d",
                position: "absolute",
                zIndex: "999",
              }}
            />
          ) : (
            <div className="Aprewall_detail_main_box">
              <div className="Aprewall_detail_child_box">
                <div className="Aprewall_detail_left_box">
                  <div
                    className="Aprewall_detail_back"
                    onClick={() => navigate(CARES_APPRECIATION_WALLS_LISTING)}
                  >
                    <span class="k-icon k-font-icon k-i-arrow-chevron-left" />
                    Back
                  </div>
                  <div className="Aprewall_detail_child_box_main_detail_box">
                    <div className="Aprewall_detail_child_box_main_detail_items">
                      <div className="Aprewall_detail_child_box_main_detail_items_title Aprewall_detail_center_item detail_padding_bottom">
                        Title in English
                      </div>
                      <div className="Aprewall_detail_child_box_main_detail_items_splitter" />
                      <div className="Aprewall_detail_center_item Aprewall_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detail_data && detail_data.title_en
                          ? detail_data.title_en
                          : ""}
                      </div>
                    </div>
                    <div className="Aprewall_detail_child_box_main_detail_items">
                      <div className="Aprewall_detail_child_box_main_detail_items_title Aprewall_detail_center_item detail_padding_bottom">
                        Title in Chinese
                      </div>
                      <div className="Aprewall_detail_child_box_main_detail_items_splitter" />
                      <div className="Aprewall_detail_center_item Aprewall_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detail_data && detail_data.title_zh
                          ? detail_data.title_zh
                          : ""}
                      </div>
                    </div>
                    <div className="Aprewall_detail_child_box_main_detail_items">
                      <div className="Aprewall_detail_child_box_main_detail_items_title Aprewall_detail_center_item detail_padding_bottom">
                        Message in English
                      </div>
                      <div className="Aprewall_detail_child_box_main_detail_items_splitter" />
                      <div
                        className="Aprewall_detail_child_box_main_detail_items_subdetail detail_padding_bottom"
                        dangerouslySetInnerHTML={{
                          __html: `${
                            detail_data && detail_data.message_en
                              ? detail_data.message_en
                              : ""
                          }`,
                        }}
                      />
                    </div>
                    <div className="Aprewall_detail_child_box_main_detail_items">
                      <div className="Aprewall_detail_child_box_main_detail_items_title Aprewall_detail_center_item detail_padding_bottom">
                        Message in Chinese
                      </div>
                      <div className="Aprewall_detail_child_box_main_detail_items_splitter" />
                      <div
                        className="Aprewall_detail_child_box_main_detail_items_subdetail detail_padding_bottom"
                        dangerouslySetInnerHTML={{
                          __html: `${
                            detail_data && detail_data.message_zh
                              ? detail_data.message_zh
                              : ""
                          }`,
                        }}
                      />
                    </div>
                    <div className="Aprewall_detail_child_box_main_detail_items">
                      <div className="Aprewall_detail_child_box_main_detail_items_title Aprewall_detail_center_item detail_padding_bottom">
                        Appreciation Wall Date
                      </div>
                      <div className="Aprewall_detail_child_box_main_detail_items_splitter" />
                      <div className="Aprewall_detail_center_item Aprewall_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        From{" "}
                        {detail_data && detail_data.from_date
                          ? moment(
                              new Date(dateFormatChanger(detail_data.from_date))
                            ).format("DD MMM YYYY")
                          : "-"}
                        ,
                        {detail_data && detail_data.from_time
                          ? detail_data.from_time
                          : "-"}{" "}
                        to{" "}
                        {detail_data && detail_data.to_date
                          ? moment(
                              new Date(dateFormatChanger(detail_data.to_date))
                            ).format("DD MMM YYYY")
                          : "-"}
                        ,
                        {detail_data && detail_data.to_time
                          ? detail_data.to_time
                          : "-"}
                      </div>
                    </div>
                    <div className="Aprewall_detail_child_box_main_detail_items">
                      <div className="Aprewall_detail_child_box_main_detail_items_title Aprewall_detail_center_item detail_padding_bottom">
                        Message Image
                      </div>
                      <div className="Aprewall_detail_child_box_main_detail_items_splitter" />
                      <div className="Aprewall_detail_center_item Aprewall_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        <img
                          style={{ maxWidth: "170px", height: "120px" }}
                          src={
                            detail_data &&
                            detail_data.image_url !== "" &&
                            detail_data.image_url
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Aprewall_detail_right_box">
                  {/* {detail_data && detail_data.status !== "published" && ( */}
                  <div className="Aprewall_detail_right_status_box">
                    <div className="Aprewall_detail_right_box_status_title">
                      Status
                    </div>
                    <div className="Aprewall_detail_right_box_status_subdetail">
                      {detail_data && detail_data.status
                        ? detail_data.status
                        : "-"}
                    </div>
                  </div>
                  {/* )} */}
                  <div className="Aprewall_detail_right_box_action_main_box">
                    <div className="Aprewall_detail_right_box_action_title">
                      Actions
                    </div>
                    {detail_data &&
                    detail_data.status !== "Published" &&
                    AddPermssion ? (
                      <div
                        className="Aprewall_detail_right_box_action_edit"
                        onClick={() =>
                          navigate(`${CARES_APPRECIATION_WALLS_EDIT}?id=${id}`)
                        }
                      >
                        Edit Appreciation Wall &#x2192;
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      className="Aprewall_detail_right_box_action_edit"
                      onClick={() => setShowStatus(true)}
                    >
                      Edit Visibility &#x2192;
                    </div>
                    {DeletePermssion && (
                      <div
                        className="Aprewall_detail_right_box_action_delete"
                        onClick={() => setShowDelete(true)}
                      >
                        Delete Appreciation Wall &#x2192;
                      </div>
                    )}
                  </div>
                  <div className="Aprewall_detail_right_box_action_main_box">
                    <div className="Aprewall_detail_right_box_action_title">
                      Activity Trail
                    </div>
                    <div className={audit_data.length <= 1 && "audit_bar_hide"}>
                      {loading ? (
                        <Loader
                          type="converging-spinner"
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            background: "#ffffff4d",
                          }}
                        />
                      ) : (
                        <Stepper
                          orientation={"vertical"}
                          items={audit_data}
                          item={CustomStep}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <PermissionDeninedPage
          height={"calc(100% - 76px)"}
          title={"Sorry, You don't have permission to access this page!"}
        />
      )}
    </>
  );
};
