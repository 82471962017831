import { combineReducers } from "redux";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { createBrowserHistory } from "history";
import { createReduxHistoryContext } from "redux-first-history";
import ChannelReducer from "./channels";
import UsersList from "./usersList";
import CategoryReducer from "./category";
import LinkToTypeReducer from "./link_to";
import DivisionReducer from "./division";
import DepotReducer from "./depot";
import NotificationReducer from "./notification";
import drSafeIncidentReducer from "./drSafeIncident";
import drSafeContactReducer from "./drSafeContact";
import drSecureIncidentReducer from "./drSecureIncident";
import drSecureContactReducer from "./drSecureContact";
import DrInfraLocationReducer from "./drInfraLocation";
import DrInfraSubSystemReducer from "./drInfraSubSystem";
import DrInfraSystemReducer from "./drInfraSystem";
import drInfraIncidentReducer from "./drInfraIncidient";
import MatterReducer from "./matter";
import iFeedbackReducer from "./ifeedback";
import FeedbackOnBus from "./feedback-on-bus";
import InterchangeReducer from "./interchange";
import DesignationReducer from "./designation";
import CommUserListReducer from "./commUserList";
import CategoryUserListReducer from "./categoryUserList";
import GroupMgmtReducer from "./groupMgmt";
import UserMgmtReducer from "./userMgmt";
import telephoneDirectoryReducer from "./telephoneDirectory";
import CommRoleReducer from "./commRole";
import iSafeReducer from "./isafe";
import EmailGroupReducer from "./emailGroup";
import EmailTemplateReducer from "./emailTemplate";
import FeedbackOnBusSummaryCheckSliceReducer from "./feedbackonbusSummaryChecks";
import FOBEmailAddressReducer from "./fobemailAddress";
import AssignmentReducer from "./assignment";
import FeedbackOnReportSliceReducer from "./feedbackonbusReport";
import careECardReducer from "./ecards";
import aprewallReducer from "./aprewall/aprewall";
import categoryReducer from "./admin_articles/manage_Category";
import promotionReducer from "./happenings/promotion";
import TutorialReducer from "./manage_tutorial";
import clinicReducer from "./clinic";
import articlesReducer from "./happenings/articles";

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({ history: createBrowserHistory() });

export const store = configureStore({
  reducer: combineReducers({
    router: routerReducer,
    channel: ChannelReducer,
    usersList: UsersList,
    category: CategoryReducer,
    link_to_type: LinkToTypeReducer,
    division: DivisionReducer,
    depot: DepotReducer,
    notification: NotificationReducer,
    drSafeIncident: drSafeIncidentReducer,
    drSafeContact: drSafeContactReducer,
    drSecureIncident: drSecureIncidentReducer,
    drSecureContact: drSecureContactReducer,
    drInfraIncident: drInfraIncidentReducer,
    location: DrInfraLocationReducer,
    subsystem: DrInfraSubSystemReducer,
    system: DrInfraSystemReducer,
    matter: MatterReducer,
    ifeedback: iFeedbackReducer,
    feedbackOnBus: FeedbackOnBus,
    interchange: InterchangeReducer,
    designation: DesignationReducer,
    commUser: CommUserListReducer,
    categoryUserList: CategoryUserListReducer,
    groupMgmt: GroupMgmtReducer,
    userMgmt: UserMgmtReducer,
    telephone_directory: telephoneDirectoryReducer,
    commRole: CommRoleReducer,
    isafe: iSafeReducer,
    emailGroup: EmailGroupReducer,
    emailTemplate: EmailTemplateReducer,
    assignmentList: AssignmentReducer,
    SummaryCheckfeedbackOnBus: FeedbackOnBusSummaryCheckSliceReducer,
    FOBEmailAddress: FOBEmailAddressReducer,
    FOBReport: FeedbackOnReportSliceReducer,
    ecard: careECardReducer,
    aprewall: aprewallReducer,
    categoryAdmin: categoryReducer,
    promotion: promotionReducer,
    manageTutorial: TutorialReducer,
    clinic: clinicReducer,
    articles: articlesReducer,
  }),
  middleware: [...getDefaultMiddleware(), routerMiddleware],
});

export const history = createReduxHistory(store);
