// redux
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionTokenWithPromise } from "../config/userPool";

const { ALL_USERS_USER_MGMT_ENDPOINT } = config.api_endpoint;

const initialState = {
  users_loading: false,
  users: [],
  users_error: null,
  user_details_loading: false,
  user_details: [],
  user_details_error: null,
};

export const fetchAllUserList = createAsyncThunk(
  "userMgmt/fetchAllUserList",
  async (_, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(ALL_USERS_USER_MGMT_ENDPOINT, {}, token);
      console.log("fetchAllUserList Results:", results);

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetchUserById = createAsyncThunk(
  "userMgmt/fetchUserById",
  async (id, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(
        ALL_USERS_USER_MGMT_ENDPOINT + "/" + id,
        {},
        token
      );
      console.log("fetchUserById Results:", results);

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

const UserMgmtSlice = createSlice({
  name: "groupMgmt",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUserList.pending, (state) => {
        state.users_loading = true;
      })
      .addCase(fetchAllUserList.fulfilled, (state, action) => {
        state.users_loading = false;
        state.users = action.payload;
      })
      .addCase(fetchAllUserList.rejected, (state, action) => {
        state.users_loading = false;
        state.users_error = action.error.message;
      })
      .addCase(fetchUserById.pending, (state) => {
        state.user_details_loading = true;
      })
      .addCase(fetchUserById.fulfilled, (state, action) => {
        state.user_details_loading = false;
        state.user_details = action.payload;
      })
      .addCase(fetchUserById.rejected, (state, action) => {
        state.user_details_loading = false;
        state.user_details_error = action.error.message;
      });
  },
});

const UserMgmtReducer = UserMgmtSlice.reducer;
export default UserMgmtReducer;
