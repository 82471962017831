import React, { useState, useEffect, useRef } from "react";
import {useDispatch} from "react-redux";
import { TextBox, RadioButton, InputPrefix } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Icon } from "@progress/kendo-react-common";
import api from "../../../../config/api";
import config from "../../../../config/config";
import { UserListDialog } from "../../../../components/dialog/userListDialog";
import { fetchAllDepartments, fetchAllTelephoneDepots, fetchAllSubDepots } from '../../../../store/telephoneDirectory';
import { TELEPHONE_DIRECTORY_DEPARTMENT_LIST } from "../../../../routes";
import { useNavigate } from "react-router";
import "../../telephone_directory.css";

export const DepartmentForm = ({
    data,
    isEdit,
    isCreate,
    setIsEdit,
    submitForm
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const [parentDepot, setParentDepot] = useState(null);
    // const [childDepot, setChildDepot] = useState(null);
    const [department, setDepartment] = useState(null);
    // const [phoneNumber, setPhoneNumber] = useState(null);
    // const [departmentsList, setDepartmentsList] = useState([]);
    // const [parentDepotsList, setparentDepotsList] = useState([]);
    // const [childDepotsList, setchildDepotsList] = useState([]);
    console.log('Contact Data--',data);
    useEffect(() => {
        if (data) {
            // setParentDepot(data.parent_depot);
            // setChildDepot(data.child_depot);
            setDepartment(data.department);
            // setPhoneNumber(data.contact_number);
        }
        // dispatch(fetchAllDepartments()).then ((res) => {setDepartmentsList(res.payload)}).then
        // (dispatch(fetchAllTelephoneDepots()).then((res) =>{setparentDepotsList(res.payload)})).then
        // (dispatch(fetchAllSubDepots()).then((res) => {setchildDepotsList(res.payload)}));
    }, [])

    const submitContact = () => {
        let data = { department }
        submitForm(data)
    }
    const cancelForm = ()=>{
        if(data && isCreate){
            setIsEdit(false);
        }else{
            navigate(TELEPHONE_DIRECTORY_DEPARTMENT_LIST);
        }
    }
    console.log('Data----',data);
    console.log('Selected User--',department)
    return (
        <div>
        <div className="details-info">
            <div className="details-info-left">
                <div>
                    <div>Department Name <span style={{color:"red"}}>*</span></div>
                    <div>
                    {isCreate ? (<div><TextBox value={department} placeholder="Enter Department Name"  onChange={(e)=> setDepartment(e.target.value)}/>  </div>
                    ):(<div><TextBox value={department} onChange={(e)=> setDepartment(e.target.value)}/> </div>)}
                   
                    </div>
                </div>
            </div>
            
        </div>
        <div>
                {isEdit && (
                    <div>
                        <div colSpan={2}>
                            <div className="detail-button-div">
                                <Button className="detail-button detail-cancel" onClick={cancelForm}>Cancel</Button>
                                <Button className="detail-button detail-submit" onClick={submitContact}>Submit</Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};