import React from "react";
import SearchIcon from "../../assets/common/search.svg";
import ResetIcon from "../../assets/common/reset_cross.svg";
import "./reusable.css";

export const Search = (props) => {
  const keyDownHandler = (e) => {
    if (e.key === "Enter") {
      props.applySearch && props.applySearch();
    }
  };
  return (
    <div className="search-div">
      <img
        className="Search_icon"
        src={SearchIcon}
        onClick={(e) => props.applySearch() && props.applySearch()}
      />
      <input
        className="textbox"
        type={props.type ? props.type : "text"}
        value={props.searchValue}
        placeholder={props.placeholder ? props.placeholder : "Search"}
        onKeyDown={keyDownHandler}
        onChange={(e) => {
          props.handleSearch(e.target.value);
        }}
      />
      {props.searchValue.length > 0 && (
        <img
          className="Search_icon Search-reset-icon"
          src={ResetIcon}
          onClick={() => props.handleClear() && props.handleClear()}
        />
      )}
    </div>
  );
};
