import { useCallback, useEffect, useState } from "react"
import { CheckerAssigned, GetDetailCheckerAssigned, RetrieveBusStop,GetRoadNameByAM } from "../../../../../services/feedbackOnBus";

export const useCheckerPref = props => {
  const { isOpen, data, onClose, onSubmit: onSuccessUpdate } = props;

  const [isFetching, setIsFetching] = useState(false);
  const [recipient, setRecipient] = useState([{}]);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [errorsValidate, setErrorsValidate] = useState({});
  const [busStopList,setBusStopList] = useState([]);
  const [allBusStopList,setAllBusStopList] = useState([]);
  useEffect(() => {
    if (isOpen) {
      const getDetailCheckerPreference = async () => {
        const payload = { id: data?.id, cognito_username: data?.cognito_username };

        const [err, response] = await GetDetailCheckerAssigned(payload);
        if (!err && response) {
          const newRecipient = response?.data?.length ? response?.data?.map(
            ({ svc, bus_stop_code, bus_stop_name, is_am, time, ...rest }) => ({
              ...rest,
              service_no: svc,
              bus_stop: bus_stop_code,
              bus_stop_desc: bus_stop_name,
              time_am_pm: time,
              time: {
                label: is_am ? 'AM' : 'PM',
                id: is_am ? 'am' : 'pm'
              },
              am: is_am ? "am": "pm"
            })
          ) : []
          console.log(newRecipient);
          setRecipient(newRecipient);
        }
      }
      const getBusStopById = async () => {
        const payload = { pk: data?.id };

        const [err, response] = await GetRoadNameByAM(payload);
        if (!err && response) {
          console.log(response);
          const resBusStopList = response?.data?.length? response.data: [];
          setBusStopList(resBusStopList);
          setAllBusStopList(resBusStopList);
        }
      }
      getDetailCheckerPreference();
      getBusStopById();
    }
  }, [isOpen, data])

  const onCleanUpForm = () => {
    setRecipient([{}]);
    setMessage('');
  }

  const onChange = useCallback(
    (e) => {
      e.preventDefault()
      const name = e?.target?.name ?? "";
      const value = e?.target?.value ?? "";
      const id = name?.split(':')?.[1]
      const nameField = name?.split(':')?.[0]
      const selectInput = recipient?.[id] ?? {}
      
      const inputData = {
        ...selectInput,
        [nameField]: value
      }
      console.log('inputData: ', inputData, id);
  
      const newRecipient = recipient.map(
        (d, idx) => {
          if (idx == id) return inputData;
  
          return d
        }
      )
  
      setRecipient(newRecipient); 
    },
    [recipient]
  )
  const onFetchDetail = useCallback(
    async (id) => {
      const idDetail = data?.id;
      const selectInput = recipient?.[id] ?? {}
  
      const [err, response] = await RetrieveBusStop({
        id: idDetail,
        svc: selectInput?.service_no,
        bus_stop: selectInput?.bus_stop,
        amPm: selectInput?.time?.label
      })

      if (err) {
        setMessage(err?.message);

        // Reset form input
        const updated = {
          bus_stop_desc: "",
          road_name: "",
          time_am_pm: "",
          direction: ""
        };
  
        const newRecipient = recipient?.map(
          (prev, idx) => {
            if (idx === id) return {
              ...prev,
              ...updated
            }
  
            return prev;
          }
        );
  
        setErrorsValidate(prev => ({
          ...prev,
          [id]: true
        }))
        setRecipient(newRecipient);

        return;
      }

      setErrorsValidate(prev => ({
        ...prev,
        [id]: false
      }))

      const newData = response?.data ?? {}

      const updated = {
        bus_stop_desc: newData?.bus_stop_name,
        road_name: newData?.road_name ?? "",
        time_am_pm: newData?.time,
        direction: newData?.direction
      }

      const newRecipient = recipient?.map(
        (prev, idx) => {
          if (idx === id) return {
            ...prev,
            ...updated
          }

          return prev;
        }
      );

      setRecipient(newRecipient);
    },
    [data?.id, recipient]
  )

  const onCloseMessageModal = () => setMessage("");
  const onCloseSuccessModal = () => {
    onClose();
    setSuccess(false);
    onSuccessUpdate();
  }

  const onSelectTime = (id, data) => {
    const selectInput = recipient?.[id] ?? {}  
    const inputData = {
      ...selectInput,
      time: data,
      service_no: "",
      bus_stop: "",
      bus_stop_desc: "",
      road_name: "",
      time_am_pm: "",
      direction: "",
      am: data.id === "am" ? "am": "pm"
    }
    
    const newRecipient = recipient.map(
      (d, idx) => {
        if (idx == id) return inputData;

        return d
      }
    )
    setRecipient(newRecipient);
  }
  const onSelectSVC = (id, data) => {
    console.log(data,id);
    const selectInput = recipient?.[id] ?? {}  
    const inputData = {
      ...selectInput,
      'service_no': data.id,
      bus_stop: "",
      bus_stop_desc: "",
      road_name: "",
      time_am_pm: "",
      direction: ""
    }
    
    const newRecipient = recipient.map(
      (d, idx) => {
        if (idx == id) return inputData;

        return d
      }
    )

    setRecipient(newRecipient);
  }
  const onSelectBusStop = (id, data) => {
    console.log(data,id);

    const selectInput = recipient?.[id] ?? {}  
    const selectRoadName = busStopList.filter((x=> x.bus_stop_code == data))
    console.log(selectRoadName);
    const inputData = {
      ...selectInput,
      'bus_stop': data,
      'bus_stop_desc': selectRoadName.length>0?selectRoadName[0].bus_stop_name: "",
      'road_name': selectRoadName.length>0?selectRoadName[0].road_name: "",
      'time_am_pm': selectRoadName.length>0?selectRoadName[0].am_pm_text: "",
      'direction': selectRoadName.length>0?selectRoadName[0].direction: "",
    }
    
    const newRecipient = recipient.map(
      (d, idx) => {
        if (idx == id) return inputData;

        return d
      }
    )

    setRecipient(newRecipient);
  }  
  const getValue = useCallback(
    (id, target = '') => {
      if (!target) return recipient?.[id];
  
      return recipient?.[id]?.[target];
    },
    [recipient]
  )

  const onAddNewRow = () => {
    setRecipient(prev => ([
      ...prev,
      {
        service_no: "",
        bus_stop: "",
        time: "",
        bus_stop_desc: "",
        road_name: "",
        time_am_pm: "",
        direction: ""
      }
    ]));
  }

  const onDeleteRow = (id) => {
    if (recipient?.length === 1) {
      setRecipient([]);
      setErrorsValidate({});

      return;
    };

    const newData = recipient?.filter(
      (_, idx) => {
        return idx !== id
      }
    )

    setRecipient(newData);
  }

  const onValidate = (cb) => {
    let errorValidate = {}

    recipient.forEach(
      (dataFields, idx) => {
        if (!Object.values(dataFields).length === 8) {
          errorValidate[idx] = true
        } else {
          const objectList = {
            service_no: "",
            bus_stop: "",
            time: "",
            bus_stop_desc: "",
            road_name: "",
            time_am_pm: "",
            direction: ""
          };

          console.log('dataFields: ', dataFields);

          Object.values(dataFields)?.forEach(
            (itm) => {
              if (!itm && !errorValidate[idx]) {
                errorValidate[idx] = true
              }
            }
          )
        }
      }
    )

    if (Object.keys(errorValidate).length) {

      setErrorsValidate(errorValidate)
      return;
    }

    setErrorsValidate({})
    if (typeof cb === 'function') cb();
  }

  const hasErrors = (id) => errorsValidate?.[id]
  const isAbleToSubmit = () => Object.keys(errorsValidate)?.filter(
    (key) => errorsValidate[key] === true
  )?.length;

  const onSubmit = () => onValidate(
    async () => {
      setIsFetching(true);
      const assigned = recipient?.map(
        ({ service_no, bus_stop, direction, time }) => {
          const template = "busstop#{{id}}#{{service_no}}#{{bus_stop}}#{{direction}}#{{amPm}}"

          return template
            ?.replace('{{id}}', data?.id)
            ?.replace('{{service_no}}', service_no)
            ?.replace('{{bus_stop}}', bus_stop)
            ?.replace('{{direction}}', direction)
            ?.replace('{{amPm}}', time?.id === 'am' ? 'True' : 'False')
        }
      )

      const payload = {
        id: data?.id,
        cognito_username: data?.cognito_username,
        name: data?.name ?? "",
        assigned
      }

      const [err, response] = await CheckerAssigned(payload);
      
      if (err) {
        setMessage(err?.message);
        setIsFetching(false);
        return;
      }

      setIsFetching(false);
      setSuccess(response?.message);
    }
  )

  return {
    success,
    message,
    isFetching,
    onSubmit,
    onCloseMessageModal,
    onCloseSuccessModal,
    getValue,
    onChange,
    onAddNewRow,
    onDeleteRow,
    onSelectTime,
    onFetchDetail,
    onCleanUpForm,
    hasErrors,
    isAbleToSubmit,
    recipient,
    busStopList,
    onSelectSVC,
    onSelectBusStop
  }
}