import NavigateIcon from "../../../../assets/dr_safe/navigate.png";
// components
import DrSecureDetailsStatusDialog from "./dr_secure_details_status_dialog";
import DrSecureDetailsMarkAsDuplicateDialog from "./dr_secure_details_mark_as_duplicate_dialog";
import DrSecureDetailsShareDialog from "./dr_secure_details_share_dialog";
import DrSecureDetailsRouteToDepDialog from "./dr_secure_details_route_dialog";

const DrSecureDetailsActions = ({
  onToggleDialog,
  incidentStatuses,
  currentIncidentStatus,
  onChangeIncidentStatus,
  onSaveIncidentStatus,
  showChangeStatusDialog,
  onUpdateStatusBtnClicked,
  showChangeMarkAsDuplicateDialog,
  onMarkAsDuplicateBtnClicked,
  onSubmitMarkAsDuplicate,
  parentCasesList,
  currentParentCase,
  onChangeParentCaseList,
  showChangeShareDialog,
  onShareBtnClicked,
  onSubmitShare,
  onRouteToDepClose,
  onRouteToDepOpen,
  onRouteToDepAction,
  showRouteToDepeDialog,
  case_ref_no,  

  shouldShowBtnUpdateStatus,
  shouldShowBtnRoute,
  shouldShowBtnShare,
  shouldShowBtnMarkDuplicate
}) => {
  return (
    <div className="dr-secure-details-actions-wrapper">
      <div className="dr-secure-details-actions-title">Actions</div>

      <div className="dr-secure-details-actions-button-list">
        {shouldShowBtnUpdateStatus && (
          <div
            className="dr-secure-details-actions-button"
            onClick={onUpdateStatusBtnClicked}
          >
            <span>Update status</span> <img src={NavigateIcon} />
          </div>
        )}
        
        {shouldShowBtnRoute && (
          <div className="dr-safe-details-actions-button" onClick={onRouteToDepOpen}>
            <span>Route to other department</span> <img src={NavigateIcon} />
          </div>    
        )}
             
        {/* <div className="dr-secure-details-actions-button">
          <span>Route to other department</span> <img src={NavigateIcon} />
        </div>
        <div className="dr-secure-details-actions-button">
          <span>Change incident type</span> <img src={NavigateIcon} />
        </div> */}
        {shouldShowBtnMarkDuplicate && (
          <div className="dr-secure-details-actions-button" onClick={onMarkAsDuplicateBtnClicked}>
            <span>Mark as duplicate</span> <img src={NavigateIcon} />
          </div>
        )}
        
        {shouldShowBtnShare && (
          <div className="dr-secure-details-actions-button" onClick={onShareBtnClicked}>
            <span>Share</span> <img src={NavigateIcon} />
          </div>
        )}
        
        {showChangeStatusDialog && (
          <DrSecureDetailsStatusDialog
            onToggleDialog={onToggleDialog}
            incidentStatuses={incidentStatuses}
            currentIncidentStatus={currentIncidentStatus}
            onChangeIncidentStatus={onChangeIncidentStatus}
            onSaveIncidentStatus={onSaveIncidentStatus}
          />
        )}

        {showChangeMarkAsDuplicateDialog && (
          <DrSecureDetailsMarkAsDuplicateDialog
            onToggleDialog={onToggleDialog}
            onSubmitMarkAsDuplicate={onSubmitMarkAsDuplicate}
            parentCasesList={parentCasesList}
            currentParentCase={currentParentCase}
            onChangeParentCaseList={onChangeParentCaseList}
          />
        )}

        {showChangeShareDialog && (
          <DrSecureDetailsShareDialog
            onToggleDialog={onToggleDialog}
            onSubmitShare={onSubmitShare}
          />
        )}
      {showRouteToDepeDialog && (
        <DrSecureDetailsRouteToDepDialog
        case_ref_no={case_ref_no}
        onRouteToDepClose={onRouteToDepClose}
        onRouteToDepAction={onRouteToDepAction}
        />
      )}
      </div> 
    </div>
  );
};

export default DrSecureDetailsActions;
