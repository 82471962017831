import React, { useState } from "react";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { orderBy } from "@progress/kendo-data-query";
import moment from "moment";
// css
import "./dr_table.css";
import {
  checkMultiPermission,
  getUserPermission,
} from "../../config/permission_helper";

const DrTable = ({
  data,
  actionDropdownClicked,
  onSortBy,
  showUpdateStatusOption,
}) => {
  const initialDataState = {
    skip: 0,
    take: 10,
  };
  const initialSort = [
    {
      field: "updated_on",
      dir: "asc",
    },
  ];
  const [page, setPage] = useState(initialDataState);
  const [sort, setSort] = useState(initialSort);
  const [orderBy, setOrderBy] = useState(true);
  const [sortBy, setSortBy] = useState("updated_on");
  const [pageSizeValue, setPageSizeValue] = React.useState();
  // const options = ["View Details", "Delete"];
  const userPermissions = getUserPermission();
  const check = checkMultiPermission(userPermissions, [
    "dr_safe.delete_incident",
  ]);
  const options = check ? ["View Details", "Delete"] : ["View Details"];
  if (showUpdateStatusOption) {
    options.push("Update Status");
  }
  const pageChange = (event) => {
    const targetEvent = event.targetEvent;
    const take = targetEvent.value === "All" ? 77 : event.page.take;
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };
  const sortChange = (event) => {
    console.log("sort change- ", event.sort);
    setSort(event.sort);
    console.log("sort- ", sort);
    for (let i in event.sort) {
      if (event.sort[i].dir == "desc") {
        setOrderBy(false);
      } else if (event.sort[i].dir == "asc") {
        setOrderBy(true);
      }
      if (event.sort[i].field == "created_on") {
        setSortBy("created_on");
      } else if (event.sort[i].field == "updated_on") {
        setSortBy("updated_on");
      }
      console.log("sortby-", sortBy, "orderby-", orderBy);
      onSortBy(sortBy, orderBy);
    }
  };
  const customDateCell = (props) => {
    if (props.dataItem[props.field] !== "") {
      return (
        <td>
          {moment(props.dataItem[props.field]).format("DD MMM YYYY, hh:mm A")}
        </td>
      );
    }
    return <td>{props.dataItem[props.field]}</td>;
  };

  return (
    <div className="tbody-content">
      {data && (
        <Grid
          style={
            {
              // height: "420px",
            }
          }
          data={data.slice(page.skip, page.take + page.skip)}
          skip={page.skip}
          take={page.take}
          total={data.length}
          pageable={{
            buttonCount: 4,
            pageSizes: [10, 20, 30, "All"],
            pageSizeValue: pageSizeValue,
          }}
          onPageChange={pageChange}
          sortable={{ allowUnsort: false }}
          sort={sort}
          onSortChange={sortChange}
          className="drTable"
        >
          <GridColumn
            title={"Case Ref No."}
            field={"case_ref_no"}
            width={170}
          />
          <GridColumn title={"Bus Reg No."} field={"bus_reg_no"} width={150} />
          <GridColumn title={"Bus Service"} field={"bus_service"} width={150} />
          <GridColumn
            title={"Incident Location"}
            field={"incident_location"}
            width={250}
          />
          <GridColumn
            key={5}
            title={"Updated By"}
            field={"updated_by.name"}
            width={220}
          />
          <GridColumn
            title={"Updated On"}
            field={"updated_on"}
            cell={customDateCell}
            sortable={true}
            width={220}
          />
          <GridColumn title={"Status"} field={"status"} width={150} />
          <GridColumn
            width={150}
            key={8}
            title={"Action"}
            cell={(props) => (
              <td>
                <DropDownList
                  data={options}
                  defaultValue={"Action"}
                  onChange={(e) => actionDropdownClicked(e, props)}
                  style={{
                    fontSize: "15px",
                    width: "120px",
                    height: "48px",
                    backgroundColor: "#EB6E1F",
                    color: "white",
                    textAlign: "center",
                    borderRadius: "8px",
                  }}
                />
              </td>
            )}
          />
        </Grid>
      )}
    </div>
  );
};

export default DrTable;
