import React, { useState, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
// redux
import { useDispatch, useSelector } from "react-redux";
import { fetchCommRoleById, updateRole } from "../../../../../store/commRole";
// kendo
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
// routes
import { ADMIN_TOOLS_ROLE_LIST } from "../../../../../routes";
// component
import { CommRoleForm } from "../form";
// permissions
import {
  getUserPermission,
  checkMultiPermission,
} from "../../../../../config/permission_helper";
import { ADMIN_TOOLS_ADD_EDIT_ROLE } from "../../../../../config/permission_helper";
// image
import ArrorRightIcon from "../../../../../assets/common/arrao-right.svg";
import BackIcon from "../../../../../assets/communication/Back.svg";
import EditIcon from "../../../../../assets/common/edit_icon.svg";
// css
import "../comm_role.css";

export const CommRoleDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const role_id = query.get("id");

  // state
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  // redux
  const { role_details_loading, role_details, role_details_error } =
    useSelector((state) => state.commRole);

  // method
  const shouldShowAddButton = () => {
    const userPermissions = getUserPermission();
    return checkMultiPermission(userPermissions, ADMIN_TOOLS_ADD_EDIT_ROLE)
      ? true
      : false;
  };

  const handleSaveRole = (data) => {
    console.log("handleSaveRole: ", data);
    dispatch(updateRole({ id: role_id, data: data })).then(() => {
      setLoading(false);
      navigate(ADMIN_TOOLS_ROLE_LIST);
    });
  };

  // useEffect
  useEffect(() => {
    if (role_id) {
      dispatch(fetchCommRoleById(role_id));
    }
  }, []);

  return (
    <>
      <div className="role-detail-body">
        {loading || role_details_loading ? (
          <Loader
            type="infinite-spinner"
            style={{
              display: "flex",
              alignItems: "center",
              width: "40px",
              margin: "auto",
              justifyContent: "center",
              height: "100vh",
            }}
          />
        ) : (
          <>
            <div className="role-detail-header">
              <div
                className="role-detail-group-div"
                onClick={() => navigate(ADMIN_TOOLS_ROLE_LIST)}
              >
                <img src={BackIcon} className="role-detail-icon" />
                <span className="role-detail-label">Back</span>
              </div>
              {!isEdit && shouldShowAddButton() && (
                <div>
                  <Button
                    className="role-detail-edit-btn"
                    onClick={() => setIsEdit(true)}
                  >
                    <img src={EditIcon} style={{ verticalAlign: "middle" }} />
                    <span className="role-detail-edit-btn-label">Edit</span>
                    <img
                      src={ArrorRightIcon}
                      style={{ verticalAlign: "middle" }}
                    />
                  </Button>
                </div>
              )}
            </div>
            <div className="role-detail-content">
              <CommRoleForm
                data={role_details}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                setLoading={setLoading}
                submitForm={handleSaveRole}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
