import React, { useState,useEffect } from "react";
import { Navigate, useNavigate } from "react-router";
import { useDispatch,useSelector } from "react-redux";

// import DrTableHeader from "../../../components/drTable/dr_table_header";
import DrInfraLocationTable from "../../../components/drTable/dr_location_table";
import DrInfraLocationListHeader from "./list/dr_infra_location_list_header";
import "../dr_infra.css";
import { fetchAllDrInfraLocations, syncDrInfraLocations } from "../../../store/drInfraLocation";
import { Loader } from "@progress/kendo-react-indicators";

import {
  getUserPermission,
  checkMultiPermission,
  DR_INFRA_PERMISSIONS_SYNC_LOCATIONS
} from "../../../config/permission_helper";

export const DrInfraLocationList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDrSearchDialog,setShowDrSearchDialog] = useState(false);
  const [showSyncBtnLoading,setSyncBtnLoading] = useState(false);
  const [searchResult,setSearchResult] = useState([]);
  const [isSearch,setIsSearch] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { location_data ,loading, error } = useSelector((state) => state.location);

  useEffect(() => {
    dispatch(fetchAllDrInfraLocations({}));
  }, []);

  const shouldShowSyncBtn = () => {
    const userPermissions = getUserPermission();
    if (checkMultiPermission(userPermissions, DR_INFRA_PERMISSIONS_SYNC_LOCATIONS)) {
      return true;
    } else {
      return false;
    }
  };

  const handleSearchAction= async (locationCategory, locationName, locationRailLineId) => {
    const res= await dispatch(fetchAllDrInfraLocations({ locationCategory, locationName, locationRailLineId }));
    if (res.payload.length > 0){
      setSearchResult(res.payload);
      setIsSearch("search");
      setErrorMessage("");
    }
    else{
      setSearchResult([]);
      setErrorMessage("");
    }   
    setShowDrSearchDialog(false);
  };
  
  const handleSyncAction = async() => {
    setSyncBtnLoading(true);
    const res= await dispatch(syncDrInfraLocations({}));
    setSyncBtnLoading(false);
    if (res.payload) {
      if (res.payload.status == "success") {
        alert("Locations saved successfully.");
        handleSearchAction("", "", "")
      } else {
        alert("Error saving Location.");
      }
    } else {
      alert("Error saving Location.");
    }
  }

  return (
    <div className="dr-infra-list-wrapper">
      <DrInfraLocationListHeader 
        title={"Location"} 
        showDrSearchDialog={showDrSearchDialog}
        onSearchBtnClicked={() => setShowDrSearchDialog(true)}
        onSearchDialogClose={() => setShowDrSearchDialog(false)}
        onSearchAction = {(locationCategory, locationName, locationRailLineId)=> handleSearchAction(locationCategory, locationName, locationRailLineId)}
        showSyncBtnLoading={showSyncBtnLoading}
        onShowSyncLoading={() => setSyncBtnLoading(false)}
        onSyncAction = {() => handleSyncAction()}
        shouldShowSyncButton={shouldShowSyncBtn()}
      />
      {loading? (
        <Loader
        type="infinite-spinner"
        style={{
            display: "flex",
            alignItems: "center",
            width: "40px",
            margin: "auto",
            justifyContent: "center",
            height: "100vh",
        }}
        />
      ):(
        <DrInfraLocationTable 
        data={isSearch=="search" ? searchResult: location_data}/>
      )}
    </div>
  );
};