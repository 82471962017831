import { Stepper, Step } from "@progress/kendo-react-layout";
//css
import "./dr_stepper.css";

const DrStepper = ({ data }) => {
  const customStep = (props) => (
    <Step>
      <div className="dr-step-circle"></div>
      <div className="dr-step-info">
        <div className="dr-step-title">{props.title}</div>
        <div className="dr-step-description">{props.description}</div>
        <div className="dr-step-creator">{props.creator}</div>
        <div className="dr-step-submitted-on">{props.submittedOn}</div>
      </div>
    </Step>
  );

  return (
    <Stepper
      className="dr-stepper"
      //   value={value}
      //   onChange={handleChange}
      items={data}
      item={customStep}
      orientation={"vertical"}
    />
  );
};

export default DrStepper;
