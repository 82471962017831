// kendo
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
// icons
import ExportIcon from "../../../../assets/dr_safe/export.png";
import FilterIcon from "../../../../assets/dr_safe/filter.png";
import SearchIcon from "../../../../assets/dr_safe/search.png";
// components
import PageTitle from "../../../../components/pageTitle/page_title";
import DrSafeListSearchDialog from "./dr_safe_list_search_dialog";
import DrSafeListFilterDialog from "./dr_safe_list_filter_dialog";

const DrSafeListHeader = ({
  showDrSearchDialog,
  onSearchBtnClicked,
  onSearchDialogClose,
  onSearchAction,
  showDrFilterDialog,
  onFilterBtnClicked,
  onFilterDialogClose,
  onFilterAction
}) => {
  return (
    <div className="dr-safe-list-header">
      <PageTitle title="DR Safe Reported Incident" />
      <div className="dr-safe-list-header-right">
        <div className="dr-infra-list-header-icon">
          <img src={ExportIcon} />
        </div>
        <Button className="dr-safe-list-header-icon" title="Filter" onClick={onFilterBtnClicked}>
          <img src={FilterIcon} />
        </Button>
        <Button className="dr-safe-list-header-icon" title="Search" onClick={onSearchBtnClicked} >
          <img src={SearchIcon} />
        </Button>
      </div>
      {showDrSearchDialog && (
        <DrSafeListSearchDialog
          onToggleDialog={onSearchDialogClose}
          onSubmitShare={onSearchAction}
          
        />
      )}

      {showDrFilterDialog && (
        <DrSafeListFilterDialog
        onToggleDialog={onFilterDialogClose}
        onSubmitFilter={onFilterAction}
        />
      )}
    </div>
  );
};

export default DrSafeListHeader;
