import React, { useEffect } from 'react';
import { TextBox } from '@progress/kendo-react-inputs';
import "./manage_tutorial.css";

const TextFieldBox = React.memo(({ title, value, onChange, errorMessage }) => {
    return (
        <div className="tutorial_create_item_main_box margin-bottom">
            <div className="tutorial_create_item_main_box_title">{title}</div>
            <div>
                <TextBox
                    className="tutorial_create_item_main_box_field"
                    value={value}
                    onChange={onChange}
                />
                {errorMessage && (
                    <div className="tutorial_create_error_text">{errorMessage}</div>
                )}
            </div>
        </div>
    );
});

export default TextFieldBox;
