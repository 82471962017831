import React, { useState, useEffect, useRef } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import "../../admin_tools.css";
import { Button } from "@progress/kendo-react-buttons";
import BackIcon from "../../../../assets/communication/Back.svg";
import { ADMIN_TOOLS_CATEGORY_LIST, ADMIN_TOOLS_DIVISION_LIST } from "../../../../routes";
import EditIcon from "../../../../assets/common/edit_icon.svg";
import ArrorRightIcon from "../../../../assets/common/arrao-right.svg";
import { Loader } from "@progress/kendo-react-indicators";
import { fetchDivisionById, updateDivision } from "../../../../store/division";
import { DivisionForm } from "./division_form";

export const DivisionDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const division_id = query.get("id");
    const [isEdit, setIsEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const {
        detail_data,
        detail_loading,
        detail_error,
    } = useSelector((state) => state.division);

    useEffect(() => {
        if (division_id) {
            dispatch(fetchDivisionById(division_id));
        }
    }, [])
    const handleSaveDivision = (data) => {
        data.id = division_id
        dispatch(updateDivision({ data, division_id }))
            .then(() => {
                setIsEdit(false);
                dispatch(fetchDivisionById(division_id));
            });
    }
    return (
        <>
            <div className="detail-body">
                {detail_loading ? (
                    <Loader
                        type="infinite-spinner"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            width: "40px",
                            margin: "auto",
                            justifyContent: "center",
                            height: "100vh",
                        }}
                    />
                ) : (
                    <>
                        <div className="detail-header">
                            <div className="categoryback-group-div" onClick={() => navigate(ADMIN_TOOLS_DIVISION_LIST)}>
                                <img src={BackIcon} className="categoryback-icon" />
                                <span className="categoryback-label">Back</span>
                            </div>
                            {!isEdit && (
                                <div>
                                    <Button className="editBtn" onClick={() => setIsEdit(true)}>
                                        <img src={EditIcon} style={{ verticalAlign: "middle" }} />
                                        <span className="editBtn-label">Edit</span>
                                        <img src={ArrorRightIcon} style={{ verticalAlign: "middle" }} />
                                    </Button>
                                </div>

                            )}
                        </div>
                        <div className="detail-content">
                            <DivisionForm
                                data={detail_data}
                                setIsEdit={setIsEdit}
                                isEdit={isEdit}
                                submitForm={handleSaveDivision}
                            />
                        </div>
                    </>
                )}

            </div>
        </>

    );
};