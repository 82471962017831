// redux
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionToken } from "../config/userPool";

const {
  CLINIC_LISTING_ENDPOINT,
  CLINIC_GET_PRESIGNED_ENDPOINT,
  CLINIC_DELETE_ENDPOINT,
  CLINIC_UPLOAD_ENDPOINT
} = config.api_endpoint;

const initialState = {
  loading: false,
  clinic_list: [],
  error: null,
};

export const fetchAllClinics = createAsyncThunk(
  "tutorials/fetchAllClinics",
  async (query, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const url = `${CLINIC_LISTING_ENDPOINT}`;
      const results = await api.get(url, query, token);

      return results;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);


export const uploadClinic = createAsyncThunk(
  "tutorials/uploadClinic",
  async (data, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.post(
        CLINIC_UPLOAD_ENDPOINT,
        JSON.stringify(data),
        token
      );

      return results;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);


export const deleteClinicById = createAsyncThunk(
  "tutorials/deleteClinicById",
  async (data, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.remove(
        `${CLINIC_DELETE_ENDPOINT}/${data}`,
        {},
        token
      );

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);



export const getPresignedURL = createAsyncThunk(
  "tutorials/getPresignedURL",
  async (data, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.post(
        CLINIC_GET_PRESIGNED_ENDPOINT,
        JSON.stringify(data),
        token
      );

      return results;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

const clinicSlice = createSlice({
  name: "clinicList",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllClinics.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllClinics.fulfilled, (state, action) => {
        state.loading = false;
        state.clinic_list = action.payload;
      })
      .addCase(fetchAllClinics.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

  },
});

const clinicReducer = clinicSlice.reducer;
export default clinicReducer;
