import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
// redux
import {
  fetchAllEmailTemplateList,
  deleteEmailTemplate,
} from "../../../../../store/emailTemplate";
// kendo
import { Loader } from "@progress/kendo-react-indicators";
// component
import { TableHeader } from "../../../../../components/table/Tableheader";
import { EmailTemplateTableBody } from "../../../../../components/table/emailTemplateTable";
import { SearchEmailTemplate } from "../../../../../components/dialog/email_template_search";
import { FilterEmailTemplate } from "../../../../../components/dialog/email_template_filter";
// routes
import {
  ADMIN_TOOLS_EMAIL_TEMPLATE_LIST,
  ADMIN_TOOLS_EMAIL_TEMPLATE_ADD,
} from "../../../../../routes";
// permissions
import {
  getUserPermission,
  checkMultiPermission,
} from "../../../../../config/permission_helper";
import {
  ADMIN_TOOLS_FOB_ADD_EDIT_EMAIL_TEMPLATE,
  ADMIN_TOOLS_FOB_DELETE_EMAIL_TEMPLATE,
} from "../../../../../config/permission_helper";

export const EmailTemplateList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  // redux
  const {
    email_templates_loading,
    email_templates,
    delete_email_template_loading,
  } = useSelector((state) => state.emailTemplate);
  // states
  const [loading, setLoading] = useState(false);
  const [allEmailTemplates, setAllEmailTemplates] = useState([]);
  const [filteredEmailTemplates, setFilteredEmailTemplates] = useState([]);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [showSearchDialog, setShowSearchDialog] = useState(false);

  // methods
  const shouldHideAddButton = () => {
    const userPermissions = getUserPermission();
    return checkMultiPermission(
      userPermissions,
      ADMIN_TOOLS_FOB_ADD_EDIT_EMAIL_TEMPLATE
    )
      ? false
      : true;
  };

  const shouldShowDeleteButton = () => {
    const userPermissions = getUserPermission();
    return checkMultiPermission(
      userPermissions,
      ADMIN_TOOLS_FOB_DELETE_EMAIL_TEMPLATE
    )
      ? true
      : false;
  };

  const handleFilteringData = () => {
    let results = allEmailTemplates;
    if (query.get("type")) {
      let all_types = query.get("type").split(",");
      if (all_types.indexOf("All") === -1) {
        results = results.filter((v) => all_types.indexOf(v.type) > -1);
      }
    }
    if (query.get("subject")) {
      results = results.filter(
        (v) =>
          v.subject.toLowerCase().indexOf(query.get("subject").toLowerCase()) >
          -1
      );
    }
    setFilteredEmailTemplates(results);
  };

  const handleSearchCallback = (data) => {
    console.log(data);
    const { subject } = data;
    query.set("subject", subject);
    if (subject) {
      query.set("search", true);
    }
    navigate(ADMIN_TOOLS_EMAIL_TEMPLATE_LIST + "?" + query.toString());
    setShowSearchDialog(false);
    handleFilteringData();
  };

  const handleFilterCallback = (data) => {
    console.log("handleFilterCallback:", data);
    const { type } = data;
    query.set("type", type.toString());

    if (type.length > 0) {
      query.set("filter", true);
    }
    navigate(ADMIN_TOOLS_EMAIL_TEMPLATE_LIST + "?" + query.toString());
    setShowFilterDialog(false);
    handleFilteringData();
  };

  const handleDeleteAction = (id) => {
    setLoading(true);
    dispatch(deleteEmailTemplate(id)).then((r) => {
      dispatch(fetchAllEmailTemplateList()).then((res) => {
        let results = res.payload;
        setAllEmailTemplates(results);
        setLoading(false);
      });
    });
  };

  // useEffect
  useEffect(() => {
    let results = allEmailTemplates;
    if (query.toString()) {
      if (query.get("name")) {
        results = results.filter(
          (v) =>
            v.name.toLowerCase().indexOf(query.get("name").toLowerCase()) > -1
        );
      }
      if (query.get("type")) {
        let all_types = query.get("type").split(",");
        if (all_types.indexOf("All") === -1) {
          results = results.filter((v) => all_types.indexOf(v.type) > -1);
        }
      }
    }
    setFilteredEmailTemplates(results);
    setLoading(false);
  }, [allEmailTemplates]);

  useEffect(() => {
    setLoading(true);
    dispatch(fetchAllEmailTemplateList()).then((res) => {
      let results = res.payload;
      setAllEmailTemplates(results);
      console.log("useEffect results:", results);
    });
  }, []);

  return (
    <div className="admin-tool-email-group-list-content">
      {showFilterDialog && (
        <FilterEmailTemplate
          setShowFilterDialog={setShowFilterDialog}
          onFiltercallback={handleFilterCallback}
        />
      )}
      {showSearchDialog && (
        <SearchEmailTemplate
          setShowSearchDialog={setShowSearchDialog}
          onSearchcallback={handleSearchCallback}
        />
      )}
      <TableHeader
        title="Manage Email Templates"
        onRefresh={() => {
          dispatch(fetchAllEmailTemplateList()).then((res) => {
            let results = res.payload;
            setAllEmailTemplates(results);
          });
        }}
        hideaddbtn={shouldHideAddButton()}
        loading={loading}
        onFilter={() => setShowFilterDialog(true)}
        onSearch={() => setShowSearchDialog(true)}
        addurl={ADMIN_TOOLS_EMAIL_TEMPLATE_ADD}
      />
      {email_templates_loading || loading || delete_email_template_loading ? (
        <Loader
          type="infinite-spinner"
          style={{
            display: "flex",
            alignItems: "center",
            width: "40px",
            margin: "auto",
            justifyContent: "center",
            height: "100vh",
          }}
        />
      ) : (
        <EmailTemplateTableBody
          data={filteredEmailTemplates}
          handleDeleteAction={handleDeleteAction}
          showDeleteOption={shouldShowDeleteButton()}
        />
      )}
    </div>
  );
};
