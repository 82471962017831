import React from "react";
import { useNavigate } from "react-router-dom";
import ReactPlayer from 'react-player';
// kendo
import { Loader } from "@progress/kendo-react-indicators";
import moment from "moment";
// image
import LeftArrowIcon from "../../../../assets/dr_safe/left-arrow.png";
import BackIcon from "../../../../assets/communication/Back.svg";
// routes
import { DR_SAFE_INCIDENT_LIST } from "../../../../routes";

const DrSafeDetailsContent = ({ loading, incident, locations,createdUser,updatedUser }) => {
  const navigate = useNavigate();
  console.log("details loading:", loading);
  console.log("details incident:", incident);

  return (
    <div>
      <div className="detail-header">
        <div className="categoryback-group-div" onClick={() => navigate(DR_SAFE_INCIDENT_LIST)}>
          <img src={BackIcon} className="categoryback-icon" />
          <span className="categoryback-label">Back</span>
          </div>  
      </div>
      {/* <div className="dr-safe-details-content-details"> */}
        {loading || !incident ? (
          <Loader type="infinite-spinner" />
        ) : (
          // <React.Fragment>
          //   <table className="recall-form-table" width={700}>
          //     <tr>
          //         <td className="font-weight-600 font-size-16">Case Ref No.</td>
          //         <td className="font-weight-500">
          //             <span>{incident.case_ref_no}</span>
          //         </td>
          //     </tr>
          //     <tr>
          //         <td className="font-weight-600 font-size-16">Status</td>
          //         <td className="font-weight-500">{incident.status}</td>
          //     </tr>
          //     <tr>
          //         <td className="font-weight-600 font-size-16">Defect Location</td>
          //         <td className="font-weight-500">{incident.incident_location}</td>
          //     </tr>
          //     <tr>
          //         <td className="font-weight-600 font-size-16">Bus Reg No.</td>
          //         <td className="font-weight-500">{incident.bus_reg_no}</td>
          //     </tr>
          //     <tr>
          //         <td className="font-weight-600 font-size-16">Description</td>
          //         <td className="font-weight-500" style={{ textAlign: "justify" }}>{incident.description}</td>

          //     </tr>
          //     <tr>
          //         <td className="font-weight-600 font-size-16">Attachment</td>
          //         <td>
          //           {incident.images &&(
          //               <>
          //               {incident.images.map((image, ind) =>
          //               <p>
          //                   <img src={image.url} key={ind} width={174}/>
          //               </p>                                            
          //               )}
          //               </>
          //           )}

          //           {incident.video && incident.video.url && (
          //             <ReactPlayer width={174} height={106} url={incident.video.url} controls={true} />
          //           )}
          //           {incident.images &&
          //             incident.images.length === 0 &&
          //             incident.video &&
          //             !incident.video.url && (
          //               <p style={{ marginTop: "0px" }}>
          //                 No available attachments.
          //               </p>
          //           )}
          //          </td>
          //     </tr>
          //     <tr>
          //         <td className="font-weight-600 font-size-16">Created By</td>
          //         <td className="font-weight-500">{incident.created_by && incident.created_by.name}</td>
          //     </tr>
          //     <tr>
          //     <td className="font-weight-600 font-size-16">Created On</td>
          //         <td className="font-weight-500">{moment(incident.created_on).format("DD MMM YYYY, hh:mm A")}</td>
          //     </tr>
          //     <tr>
          //     <td className="font-weight-600 font-size-16">Updated By</td>
          //         <td className="font-weight-500">{incident.updated_by && incident.updated_by.name}</td>
          //     </tr>
          //     <tr>
          //     <td className="font-weight-600 font-size-16">Updated On</td>
          //         <td className="font-weight-500">{moment(incident.updated_on).format("DD MMM YYYY, hh:mm A")}</td>
          //     </tr>
          //     <tr></tr>
          //   </table>
          // </React.Fragment>

          <div className='dr-details__body'>
              <>
                    <div className='dr-details-info'>
                    <div className='dr-details-info__item'>
                        <div className='dr-details-info__desc'>Case Ref No.</div>
                        <div className='dr-details-info__value'>{incident.case_ref_no}</div>
                    </div>
                    <div className='dr-details-info__item'>
                        <div className='dr-details-info__desc'>Status</div>
                        <div className='dr-details-info__value'>{incident.status_str}</div>
                    </div>
                    <div className='dr-details-info__item'>
                        <div className='dr-details-info__desc'>Defect Location</div>
                        <div className='dr-details-info__value'>{incident.incident_location? incident.incident_location.location_name : null}</div>
                    </div>

                    <div className='dr-details-info__item'>
                        <div className='dr-details-info__desc'>Bus Reg No.</div>
                        <div className='dr-details-info__value'>{incident.bus_reg_no}</div>
                    </div>

                    <div className='dr-details-info__item'>
                        <div className='dr-details-info__desc'>Description</div>
                        <div className='dr-details-info__value'>{incident.description}</div>
                    </div>
                    
                    <div className='dr-details-info__item'>
                        <div className='dr-details-info__desc'>Attachment</div>
                        <div className='dr-details-info__value'>
                        {incident.images &&(
                        <>
                        {incident.images.map((image, ind) =>
                        <p>
                            <img src={image.url} key={ind} width={174}/>
                        </p>                                            
                        )}
                        </>
                    )}

                    {incident.video && incident.video.url && (
                      <ReactPlayer width={174} height={106} url={incident.video.url} controls={true} />
                    )}
                    {incident.images &&
                      incident.images.length === 0 &&
                      incident.video &&
                      !incident.video.url && (
                        <p style={{ marginTop: "0px" }}>
                          No available attachments.
                        </p>
                    )}</div>
                    </div>

                    <div className='dr-details-info__item'>
                        <div className='dr-details-info__desc'>Created By</div>
                        <div className='dr-details-info__value'>{incident.created_by && incident.created_by.name}</div>
                    </div>
                    <div className='dr-details-info__item'>
                        <div className='dr-details-info__desc'>&nbsp;&nbsp;&nbsp;&nbsp;SAP No</div>
                        <div className='dr-details-info__value'>{createdUser.username}</div>
                    </div>                    
                    <div className='dr-details-info__item'>
                        <div className='dr-details-info__desc'>&nbsp;&nbsp;&nbsp;&nbsp;Department</div>
                        <div className='dr-details-info__value'>{createdUser.dept_desc}</div>
                    </div>
                    <div className='dr-details-info__item'>
                        <div className='dr-details-info__desc'>&nbsp;&nbsp;&nbsp;&nbsp;Section</div>
                        <div className='dr-details-info__value'>{createdUser.sect_desc}</div>
                    </div>                  
                    <div className='dr-details-info__item'>
                        <div className='dr-details-info__desc'>&nbsp;&nbsp;&nbsp;&nbsp;Created On</div>
                        <div className='dr-details-info__value'>{moment(incident.created_on).format("DD MMM YYYY, hh:mm A")}</div>
                    </div>

                    <div className='dr-details-info__item'>
                        <div className='dr-details-info__desc'>Updated By</div>
                        <div className='dr-details-info__value'>{incident.updated_by && incident.updated_by.name}</div>
                    </div>
                    <div className='dr-details-info__item'>
                        <div className='dr-details-info__desc'>&nbsp;&nbsp;&nbsp;&nbsp;SAP No</div>
                        <div className='dr-details-info__value'>{updatedUser.username}</div>
                    </div>                    
                    <div className='dr-details-info__item'>
                        <div className='dr-details-info__desc'>&nbsp;&nbsp;&nbsp;&nbsp;Department</div>
                        <div className='dr-details-info__value'>{updatedUser.dept_desc}</div>
                    </div>
                    <div className='dr-details-info__item'>
                        <div className='dr-details-info__desc'>&nbsp;&nbsp;&nbsp;&nbsp;Section</div>
                        <div className='dr-details-info__value'>{updatedUser.sect_desc}</div>
                    </div>                    
                    <div className='dr-details-info__item'>
                        <div className='dr-details-info__desc'>&nbsp;&nbsp;&nbsp;&nbsp;Updated On</div>
                        <div className='dr-details-info__value'>{moment(incident.updated_on).format("DD MMM YYYY, hh:mm A")}</div>
                    </div>

                    
                    </div>
                    
                </>
                {/* )} */}
          </div>
        )}
      {/* </div> */}
    </div>
  );
};

export default DrSafeDetailsContent;