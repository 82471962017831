import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCurrentLoggedInSessionTokenWithPromise } from "../../config/userPool";
import api from "../../config/api";
import config from "../../config/config";

const {
  HAPPENING_PROMOTION_ENDPOINT,
  AUDIT_ENDPOINT,
  HAPPENING_PROMOTION_EXPORT_ENDPOINT,
} = config.api_endpoint;

const initialState = {
  loading: false,
  error: null,
  detail_data: {},
  listing_data: [],
  create_data: {},
  update_data: {},
  delete_data: {},
  audit_data: [],
  filter_data: [],
  export_data: [],
};

export const fetchPromotionListing = createAsyncThunk(
  "promotion/fetchPromotionListing",
  async (_, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(HAPPENING_PROMOTION_ENDPOINT, {}, token);
      return results;
    } catch (err) {
      return rejectWithValue(err.details);
    }
  }
);

export const fetchPromotionListingFilter = createAsyncThunk(
  "promotion/fetchPromotionListingFilter",
  async (string, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(
        `${HAPPENING_PROMOTION_ENDPOINT}?${string}`,
        {},
        token
      );
      return results;
    } catch (err) {
      return rejectWithValue(err.details);
    }
  }
);

export const fetchPromotionDetail = createAsyncThunk(
  "promotion/fetchPromotionDetail",
  async (id, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(
        `${HAPPENING_PROMOTION_ENDPOINT}/${id}`,
        {},
        token
      );
      return results;
    } catch (err) {
      return rejectWithValue(err.details);
    }
  }
);

export const fetchPromotionCreate = createAsyncThunk(
  "promotion/fetchPromotionCreate",
  async (data, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = api.post(
        HAPPENING_PROMOTION_ENDPOINT,
        JSON.stringify(data),
        token
      );
      return results;
    } catch (err) {
      return rejectWithValue(err.details);
    }
  }
);

export const fetchPromotionUpdate = createAsyncThunk(
  "promotion/fetchPromotionUpdate",
  async (data, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.put(
        `${HAPPENING_PROMOTION_ENDPOINT}/${data.id}`,
        JSON.stringify(data),
        token
      );
      return results;
    } catch (err) {
      return rejectWithValue(err.details);
    }
  }
);

export const fetchPromotionDelete = createAsyncThunk(
  "promotion/fetchPromotionDelete",
  async (id, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.remove(
        `${HAPPENING_PROMOTION_ENDPOINT}/${id}`,
        {},
        token
      );
      return results;
    } catch (err) {
      return rejectWithValue(err.details);
    }
  }
);

export const fetchPromotionAudit = createAsyncThunk(
  "promotion/fetchPromotionAudit",
  async (id, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(
        `${AUDIT_ENDPOINT}?table_name=happenings-promotion&source_id=${id}`,
        {},
        token
      );
      return results;
    } catch (err) {
      return rejectWithValue(err.details);
    }
  }
);

export const fetchPromotionExport = createAsyncThunk(
  "promotion/fetchPromotionExport",
  async (_, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(
        HAPPENING_PROMOTION_EXPORT_ENDPOINT,
        {},
        token
      );
      return results;
    } catch (err) {
      return rejectWithValue(err.details);
    }
  }
);

const promotionSlice = createSlice({
  name: "promotion",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPromotionListing.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPromotionListing.fulfilled, (state, action) => {
        state.loading = false;
        state.listing_data = action.payload.data;
      })
      .addCase(fetchPromotionListing.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      })
      .addCase(fetchPromotionListingFilter.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPromotionListingFilter.fulfilled, (state, action) => {
        state.loading = false;
        state.filter_data = action.payload.data;
      })
      .addCase(fetchPromotionListingFilter.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      })
      .addCase(fetchPromotionDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPromotionDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.detail_data = action.payload.data;
      })
      .addCase(fetchPromotionDetail.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      })
      .addCase(fetchPromotionCreate.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPromotionCreate.fulfilled, (state, action) => {
        state.loading = false;
        state.create_data = action.payload.data;
      })
      .addCase(fetchPromotionCreate.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      })
      .addCase(fetchPromotionUpdate.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPromotionUpdate.fulfilled, (state, action) => {
        state.loading = false;
        state.update_data = action.payload.data;
      })
      .addCase(fetchPromotionUpdate.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      })
      .addCase(fetchPromotionDelete.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPromotionDelete.fulfilled, (state, action) => {
        state.loading = false;
        state.delete_data = action.payload.data;
      })
      .addCase(fetchPromotionDelete.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      })
      .addCase(fetchPromotionAudit.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPromotionAudit.fulfilled, (state, action) => {
        state.loading = false;
        state.audit_data = action.payload.data;
      })
      .addCase(fetchPromotionAudit.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      })
      .addCase(fetchPromotionExport.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPromotionExport.fulfilled, (state, action) => {
        state.loading = false;
        state.export_data = action.payload.data;
      })
      .addCase(fetchPromotionExport.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      });
  },
});

const promotionReducer = promotionSlice.reducer;
export default promotionReducer;
