// redux
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionToken } from "../config/userPool";

const {
  MANAGE_TUTORIAL_LISTING_ENDPOINT,
  MANAGE_TUTORIAL_ENDPOINT,
  MANAGE_TUTORIAL_DELETE_ENDPOINT,
  MANAGE_TUTORIAL_GET_FILE_ENDPOINT,
  APP_MODULES_LISTING_ENDPOINT,
  AUDIT_ENDPOINT,

} = config.api_endpoint;

const initialState = {
  loading: false,
  tutorial_list: [],
  error: null,
  next_key: {},
  detail_loading: false,
  detail_data: {},
  detail_error: null,
  audit_data: [],
  audit_loading: false,
  audit_error: null,
  app_modules: [],
  app_modules_loading: false,
  app_modules_error: null,
};

export const fetchAllTutorials = createAsyncThunk(
  "tutorials/fetchAllTutorials",
  async (query, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const url = `${MANAGE_TUTORIAL_LISTING_ENDPOINT}`;
      const results = await api.get(url, {}, token);

      return results;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetchAppModules = createAsyncThunk(
  "tutorials/fetchAppModules",
  async (query, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const url = `${APP_MODULES_LISTING_ENDPOINT}`;
      const results = await api.get(url, {}, `Bearer ${token}`);

      return results;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetcheTutorialDetailById = createAsyncThunk(
  "tutorials/fetcheTutorialDetailById",
  async (id, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(
        MANAGE_TUTORIAL_ENDPOINT + "?id=" + id,
        {},
        `Bearer ${token}`
      );

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);


export const createAndUpdateTutorial = createAsyncThunk(
  "tutorials/createAndUpdateTutorial",
  async (data, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.post(
        MANAGE_TUTORIAL_ENDPOINT,
        JSON.stringify(data),
        `Bearer ${token}`
      );

      return results;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);


export const deleteTutorialById = createAsyncThunk(
  "tutorials/deleteTutorialById",
  async (data, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.post(
        MANAGE_TUTORIAL_DELETE_ENDPOINT,
        JSON.stringify(data),
        `Bearer ${token}`
      );

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);



export const getPresignedURL = createAsyncThunk(
  "tutorials/getPresignedURL",
  async (data, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.post(
        MANAGE_TUTORIAL_GET_FILE_ENDPOINT,
        JSON.stringify(data),
        `Bearer ${token}`
      );

      return results;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetchTutorialAudit = createAsyncThunk(
  "cares/fetchTutorialAudit",
  async (id, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const url = `${AUDIT_ENDPOINT}?table_name=tutorial&source_id=${id}`;
      const results = await api.get(url, {}, token);
      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

const tutorialSlice = createSlice({
  name: "tutorialList",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetcheTutorialDetailById.pending, (state) => {
        state.detail_loading = true;
      })
      .addCase(fetcheTutorialDetailById.fulfilled, (state, action) => {
        state.detail_loading = false;
        state.detail_data = action.payload;        
      })
      .addCase(fetcheTutorialDetailById.rejected, (state, action) => {
        state.detail_loading = false;
        state.detail_error = action.error.message;
      })
      .addCase(fetchAllTutorials.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllTutorials.fulfilled, (state, action) => {
        state.loading = false;
        state.tutorial_list = action.payload;
      })
      .addCase(fetchAllTutorials.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(fetchAppModules.pending, (state) => {
        state.app_modules_loading = true;
      })
      .addCase(fetchAppModules.fulfilled, (state, action) => {
        state.app_modules_loading = false;
        state.app_modules = action.payload.data;
      })
      .addCase(fetchAppModules.rejected, (state, action) => {
        state.app_modules_loading = false;
        state.app_modules_error = action.error.message;
      })
      .addCase(fetchTutorialAudit.pending, (state) => {
        state.audit_loading = true;
      })
      .addCase(fetchTutorialAudit.fulfilled, (state, action) => {
        state.audit_loading = false;
        state.audit_data = action.payload;
      })
      .addCase(fetchTutorialAudit.rejected, (state, action) => {
        state.audit_loading = false;
        state.audit_error = action.error.message;
      })
  },
});

const TutorialReducer = tutorialSlice.reducer;
export default TutorialReducer;
