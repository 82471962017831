import { Button } from "@progress/kendo-react-buttons";
import RefreshIcon from "../../../assets/header/refresh.svg";
import ExportIcon from "../../../assets/header/export.svg";

import './assignment-listing.css';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useAction } from "./actions";

const Component = () => {
  const { data } = useAction();
  return (
    <div className='page-wrapper'>
      <div className='container'>
        <div className='assignment-container'>
          <div className='assignment-container-headers'>
            <h2 style={{ fontSize: 32 }}>Assignment Listing</h2>
            <div className="filter-group">
              <div className='wrapper-button'>
                <Button style={{ height: '100%'}} className="button-add cursor-pointer">
                  Add
                </Button>
              </div>
              <Button className="custom-button">
                <img style={{ width: 20, height: 20 }} src={RefreshIcon} alt="refresh-icon" />
              </Button>
              <Button className="custom-button">
                <img style={{ width: 20, height: 20 }} src={ExportIcon} alt="export-icon" />
              </Button>
            </div>
          </div>
        </div>
        <div style={{ width: '100%', overflow: 'scroll' }}>
          {/* <table style={{ fontSize: 14 }}>
            <thead>
              <tr>
                <th style={{ minWidth: 60 }}>S. No</th>
                <th>
                  <div>
                    <div style={{ minHeight: 35}}>Assigned To</div>
                    <input style={{ marginTop: 10, padding: '2px 8px'}} placeholder="Assigned To" />
                  </div>
                </th>
                <th>
                  <div>
                    <div style={{ minHeight: 35}}>Feedback Scheme</div>
                    <input style={{ marginTop: 10, padding: '2px 8px'}} placeholder="Assigned To" />
                  </div>
                </th>
                <th>
                  <div>
                    <div style={{ minHeight: 35}}>Assigned BC</div>
                    <input style={{ marginTop: 10, padding: '2px 8px'}} placeholder="Assigned To" />
                  </div>
                </th>
                <th>
                  <div>
                    <div style={{ minHeight: 35}}>Service No.</div>
                    <input style={{ marginTop: 10, padding: '2px 8px'}} placeholder="Assigned To" />
                  </div>
                </th>
                <th>
                  <div>
                    <div style={{ minHeight: 35}}>Direction</div>
                    <input style={{ marginTop: 10, padding: '2px 8px'}} placeholder="Assigned To" />
                  </div>
                </th>
                <th>
                  <div>
                    <div style={{ minHeight: 35}}>Direction</div>
                    <input style={{ marginTop: 10, padding: '2px 8px'}} placeholder="Assigned To" />
                  </div>
                </th>
                <th>
                  <div>
                    <div style={{ minHeight: 35}}>Boarding Bus Stop</div>
                    <input style={{ marginTop: 10, padding: '2px 8px'}} placeholder="Assigned To" />
                  </div>
                </th>
                <th>
                  <div>
                    <div style={{ minHeight: 35}}>Type</div>
                    <input style={{ marginTop: 10, padding: '2px 8px'}} placeholder="Assigned To" />
                  </div>
                </th>
                <th>
                  <div>
                    <div style={{ minHeight: 35}}>Checker To Note</div>
                    <input style={{ marginTop: 10, padding: '2px 8px'}} placeholder="Assigned To" />
                  </div>
                </th>
                <th>
                  <div>
                    <div style={{ minHeight: 35}}>Commuters Feedback</div>
                    <input style={{ marginTop: 10, padding: '2px 8px'}} placeholder="Assigned To" />
                  </div>
                </th>
                <th style={{ minWidth: 60 }}>
                  <div>
                    <div>Action</div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.map(
                ({ id, assignedTo, feedbackScheme, assignedBC, serviceNo, direction, boardingBusStop, type, checkerNote, commuterFeedback }) => (
                  <tr>
                    <td>{id}</td>
                    <td>{assignedTo}</td>
                    <td>{feedbackScheme}</td>
                    <td>{assignedBC}</td>
                    <td>{serviceNo}</td>
                    <td>{direction}</td>
                    <td>{boardingBusStop}</td>
                    <td>{type}</td>
                    <td>{checkerNote}</td>
                    <td>{commuterFeedback}</td>
                    <td>
                      <DropDownList
                        style={{
                          width: "120px",
                          height: "48px",
                          backgroundColor: "#EB6E1F",
                          color: "white",
                          textAlign: "center",
                          borderRadius: "8px",
                        }}
                        defaultValue={"Action"}
                        data={["Delete"]}
                        onChange={(e) => {
                          const targetEvent = e?.target?.value ?? "";
                          
                          if (targetEvent === "Delete") {
                          }
                        }}
                      />
                    </td>
                  </tr>
                )
              )}
              
            </tbody>
          </table> */}
          
        </div>
      </div>
    </div>
  )
}

export default Component;
