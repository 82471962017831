import { useMemo, useState } from "react";
import { ExpansionPanel, ExpansionPanelContent } from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import "./expansion_panel.css";
import { CustomItemDetail } from "../../../../../components/common/itemdetail";
import { FobSummaryChecksRouteHistory } from "./route_history";
import { TextArea, TextBox } from "@progress/kendo-react-inputs";
import { FobSummaryChecksUpdateHistory } from "./update_history";
import { Loader } from "@progress/kendo-react-indicators";

export const SummaryFeedbackExtensionPanel = (props) => {
    const formdata = props.data;
    const [openGeneral, setOpenGeneral] = useState(true);
    const [openJourney, setOpenJourney] = useState(false);
    const [openService, setOpenService] = useState(false);
    const [openRouteHistory, setOpenRouteHistory] = useState(false);
    const [openUpdateHistory, setOpenUpdateHistory] = useState(false);
    const [updateRemarks, setUpdateRemarks] = useState('');
    const onhandleUpdate = () => {
        setUpdateRemarks('');
        props.onUpdate(updateRemarks);
    }
    return (
        <div className="wrapper">
            <ExpansionPanel
                title={"Geneal Feedback"}
                expanded={openGeneral}
                tabIndex={0}
                key={"general"}
                onAction={(event) => setOpenGeneral(!openGeneral)}
                className="scheck-panel"
            >
                <Reveal>
                    {openGeneral && (
                        <ExpansionPanelContent>
                            <div className="content">
                                <div className="custom-detail-item-body">
                                    <div className="custom-detail-item-with-button">
                                        <CustomItemDetail label={"Reference No"} value={formdata.reference_no} />
                                        <div className="summary-check-detail-status">{formdata.status_str}</div>
                                    </div>
                                </div>
                                {/* <CustomItemDetail label={"Reference No"} value={formdata.reference_no}/>     */}
                                <CustomItemDetail label={"Check Date"} value={formdata.checked_date} />
                                <CustomItemDetail label={"Submitted On"} value={formdata.submitted_date} />
                                <CustomItemDetail label={"Feedback Scheme"} value={formdata.scheme_name} />
                                <CustomItemDetail label={"Time Arriving"} value={formdata.arriving_time} />
                                <CustomItemDetail label={"Service No."} value={formdata.svc} />
                                <CustomItemDetail label={"Direction"} value={formdata.direction} />
                                <CustomItemDetail label={"Boarding street or Bus Stop No."} value={formdata.boarding_bus_stop} />
                                <CustomItemDetail label={"BC No."} value={formdata.bus_stop_code} />
                            </div>
                        </ExpansionPanelContent>
                    )}
                </Reveal>
            </ExpansionPanel>
            <ExpansionPanel
                title={"Journey Attributes"}
                // subtitle={"General"}
                expanded={openJourney}
                tabIndex={0}
                key={"journey"}
                onAction={(event) => setOpenJourney(!openJourney)}
                className="scheck-panel"
            >
                <Reveal>
                    {openJourney && (
                        <ExpansionPanelContent>
                            <div className="content">
                                <span>Bus1</span>
                                <CustomItemDetail label={"Prefix"} value={formdata.prefix} />
                                <CustomItemDetail label={"Bus No."} value={formdata.bus_stop_name} />
                                <CustomItemDetail label={"Bus arrive time"} value={formdata.bus_arrival_time} />
                                <CustomItemDetail label={"Passenger Loading"} value={formdata.passenger_loading} />
                                <CustomItemDetail label={"Time Board Buss"} value={formdata.time_board_bus} />
                            </div>
                        </ExpansionPanelContent>
                    )}
                </Reveal>
            </ExpansionPanel>
            <ExpansionPanel
                title={"Service Attributes"}
                // subtitle={"General"}
                expanded={openService}
                tabIndex={0}
                key={"service"}
                onAction={(event) => setOpenService(!openService)}
                className="scheck-panel"
            >
                <Reveal>
                    {openService && (
                        <ExpansionPanelContent>
                            <div className="content">
                                <CustomItemDetail label={"Aircon"} value={formdata.aircon} />
                                <CustomItemDetail label={"Aircon Remarks"} value={formdata.aircon_remarks? formdata.aircon_remarks: "N/A"} />
                                <CustomItemDetail label={"Cleanliness"} value={formdata.cleanliness? formdata.cleanliness: "N/A"} />
                                <CustomItemDetail label={"Cleanliness Remarks"} value={formdata.cleanliness_remarks? formdata.cleanliness_remarks: "N/A"} />
                                <CustomItemDetail label={"Bus Condition"} value={formdata.bus_conditions_remarks? formdata.bus_conditions_remarks: "N/A"} />
                                <CustomItemDetail label={"Category"} value={formdata.category_names? formdata.category_names: "N/A"} />
                                <CustomItemDetail label={"Bus Service Remarks"} value={"N/A"} />
                                <CustomItemDetail label={"BC Service"} value={formdata.bc_service? formdata.bc_service: "N/A"} />
                                <CustomItemDetail label={"BC Service Remarks"} value={formdata.bc_service_remarks? formdata.bc_service_remarks: "N/A"} />
                                <CustomItemDetail label={"English Literacy"} value={formdata.eng_literacy? formdata.eng_literacy: "N/A"} />
                                <CustomItemDetail label={"English Literacy Remarks"} value={formdata.eng_literacy_remarks? formdata.eng_literacy_remarks: "N/A"} />
                                <CustomItemDetail label={"BC Driving"} value={formdata.describe_bc_driving_str? formdata.describe_bc_driving_str: "N/A"} />
                                <CustomItemDetail label={"BC Driving Remarks"} value={formdata.describe_bc_driving_remark? formdata.describe_bc_driving_remark: "N/A"} />
                                <CustomItemDetail label={"Overall Trip Experience"} value={formdata.overall_exp? formdata.overall_exp: "N/A"} />
                                <CustomItemDetail label={"Overall Trip Experience Remarks"} value={formdata.overall_exp_remarks? formdata.overall_exp_remarks : "N/A"} />
                                <CustomItemDetail label={"Complients"} value={formdata.compliments? formdata.compliments: "N/A"} />
                                <CustomItemDetail label={"Other Remarks"} value={formdata.other_feedback? formdata.other_feedback: "N/A"} />
                                <CustomItemDetail label={"Alighting street or Bus Stop No."} value={formdata.alighting_bus_stop? formdata.alighting_bus_stop: "N/A"} />
                                <CustomItemDetail label={"Time Alight Bus"} value={formdata.time_alight_bus? formdata.time_alight_bus: "N/A"} />
                            </div>
                        </ExpansionPanelContent>
                    )}
                </Reveal>
            </ExpansionPanel>
            <ExpansionPanel
                title={"Route History"}
                expanded={openRouteHistory}
                tabIndex={0}
                key={"history"}
                onAction={(event) => setOpenRouteHistory(!openRouteHistory)}
                className="scheck-panel"
            >
                <Reveal>
                    {openRouteHistory && (
                        <ExpansionPanelContent>
                            <div className="content">
                                <FobSummaryChecksRouteHistory routehistory={props.routehistory} />
                            </div>
                        </ExpansionPanelContent>
                    )}
                </Reveal>
            </ExpansionPanel>
            <ExpansionPanel
                title={"Update History"}
                expanded={openUpdateHistory}
                tabIndex={0}
                key={"updates"}
                onAction={(event) => setOpenUpdateHistory(!openUpdateHistory)}
                className="scheck-panel"
            >
                <Reveal>
                    {openUpdateHistory && (
                        <ExpansionPanelContent>
                            {props.feedback_updates_loading || props.feedback_updates_list_loading ? (
                                <Loader
                                    type="infinite-spinner"
                                    style={{
                                        height: "80vh",
                                        display: "flex",
                                        alignItems: "center",
                                        width: "40px",
                                        margin: "auto",
                                        justifyContent: "center",
                                        minHeight: "100%",
                                    }}
                                />
                            ) : (
                                <div className="content feedback-update-content">
                                    <div className="feedback-update-history">
                                        {props.updateHistory.map((v) => (
                                            <div>
                                                <div className="update-history-username">{v.created_by.name}</div>
                                                <div>{v.updated_on}</div>
                                                <div>{v.description}</div>
                                            </div>
                                        ))}
                                    </div>
                                    <form onSubmit={(e) => e.preventDefault()} className="feedback-onupdateform">
                                        <div style={{fontWeight:700,fontSize:"medium"}}>Add Update</div>
                                        <TextBox
                                            value={updateRemarks}
                                            onChange={(e) => setUpdateRemarks(e.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 32) {
                                                    e.stopPropagation();
                                                }
                                            }}
                                        />
                                        <div className="feedback-update-button-div">
                                            <button
                                                onClick={onhandleUpdate}
                                                disabled={updateRemarks === null || updateRemarks === ''}
                                                style={updateRemarks === null || updateRemarks === '' ? { background: "gray" } : {}}
                                            >Send</button>
                                        </div>
                                    </form>
                                </div>
                            )}
                        </ExpansionPanelContent>
                    )}
                </Reveal>
            </ExpansionPanel>

        </div>
    );
};