import React, { useState, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import "./notification.css";
import { fetchAllCategories } from "../../store/category";
import { Loader } from "@progress/kendo-react-indicators";
import { RadioButton } from "@progress/kendo-react-inputs";
// import { DatePicker } from "@progress/kendo-react-dateinputs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CustomDatePicker } from "../custom/datepicker";

import moment from "moment/moment";

const all_or_date_range = [{ value: "all", label: "All dates" }, { value: "date", label: "Date range" }]
const filter_status = [
    { value: "published", label: "Published" },
    { value: "scheduled", label: "Scheduled" },
    { value: "draft", label: "Draft" },
    { value: "pendingapproval", label: "Pending Approval" }
]
const acknowledge_status = [{ value: "required", label: "Yes" }, { value: "not_required", label: "No" }]

export const FilterDivision = (props) => {
    const dispatch = useDispatch();
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const [selectedUpdatedOnDateOption, setSelectedUpdatedOnDateOption] = useState("all");
    const [selectedCreatedDateOption, setSelectedCreatedDateOption] = useState("all");

    const [CreatedDateFrom, setCreatedDateFrom] = useState("");
    const [CreatedDateTo, setCreatedDateTo] = useState("");
    const [UpdatedDateFrom, setUpdatedDateFrom] = useState("");
    const [UpdatedDateTo, setUpdatedDateTo] = useState("");

    useEffect(() => {
        if (query.toString()) {
            if (query.get("created_on_start")) {
                setCreatedDateFrom(new Date(query.get("created_on_start")));
            }
            if (query.get("created_on_end")) {
                setCreatedDateTo(new Date(query.get("created_on_end")));
            }
            if (query.get("created_on_start") || query.get("created_on_end")) {
                setSelectedCreatedDateOption("date");
            }
            if (query.get("updated_on_start")) {
                setUpdatedDateFrom(new Date(query.get("updated_on_start")));
            }
            if (query.get("updated_on_end")) {
                setUpdatedDateTo(new Date(query.get("updated_on_end")));
            }
            if (query.get("updated_on_start") || query.get("updated_on_end")) {
                setSelectedUpdatedOnDateOption("date");
            }            
        }
    }, []);
    const handleUpdatedDateOptionChange = (e) => {
        setSelectedUpdatedOnDateOption(e.value);
    }
    const handleCreatedDateOptionChange = (e) => {
        setSelectedCreatedDateOption(e.value);
    }
    const handleclearfilter = () => {
        setUpdatedDateFrom("");
        setUpdatedDateTo("");
        setCreatedDateFrom("");
        setCreatedDateTo("");
    }
    const onSubmitFilter = () => {
        let data = {
            created_on_start: selectedCreatedDateOption === "all" ? "" : CreatedDateFrom,
            created_on_end: selectedCreatedDateOption === "all" ? "" : CreatedDateTo,
            updated_on_start: selectedUpdatedOnDateOption === "all" ? "" : UpdatedDateFrom,
            updated_on_end: selectedUpdatedOnDateOption === "all" ? "" : UpdatedDateTo,
        }
        console.log(data);
        props.onFiltercallback(data)
    }

    return (
        <>
            <Dialog
                className="search-notification"
                title={" "}
                width={395}
                height={"100%"}
                onClose={() => props.setShowFilterDialog(false)}
            >
                <div className="search-body">
                    <div className="filter-title">
                        <div className="title">Filter</div>
                        <div className="clear-filter" onClick={handleclearfilter}><u>Clear Filter</u></div>
                    </div>
                    <div className="filter-body">
                        <p className="filter-sub-title">Created On</p>
                        <div className="category-flex-row">
                            {all_or_date_range.map((v) => (
                                <div className="date-option-div">
                                    <RadioButton
                                        className="filter-option-input"
                                        value={v.value}
                                        key={v.value}
                                        label={v.label}
                                        onChange={(e) => handleCreatedDateOptionChange(e)}
                                        checked={selectedCreatedDateOption === v.value}
                                    />
                                </div>
                            ))}
                        </div>
                        {selectedCreatedDateOption !== "all" && (
                            <div className="category-flex-row">
                                <div className="category-date-div">
                                    <div>Date From</div>
                                    <DatePicker
                                        className="filter-date-range"
                                        format={"dd MMM yyyy"}
                                        selected={CreatedDateFrom}
                                        onChange={(date) => setCreatedDateFrom(date)}
                                        customInput={<CustomDatePicker iconColor={"#781D7E"} />}
                                    />
                                </div>
                                <div className="category-date-div">
                                    <div>Date To</div>
                                    <DatePicker
                                        selected={CreatedDateTo}
                                        onChange={(date) => setCreatedDateTo(date)}
                                        format={"dd MMM yyyy"}
                                        customInput={<CustomDatePicker iconColor={"#781D7E"} />}
                                    />
                                </div>
                            </div>
                        )}
                        <p className="filter-sub-title">Updated On</p>
                        <div className="category-flex-row">
                            {all_or_date_range.map((v) => (
                                <div className="date-option-div">
                                    <RadioButton
                                        className="filter-option-input"
                                        value={v.value}
                                        key={v.value}
                                        label={v.label}
                                        onChange={(e) => handleUpdatedDateOptionChange(e)}
                                        checked={selectedUpdatedOnDateOption === v.value}
                                    />
                                </div>
                            ))}
                        </div>
                        {selectedUpdatedOnDateOption !== "all" && (
                            <div className="category-flex-row">
                                <div className="category-date-div">
                                    <div>Date From</div>
                                    <DatePicker
                                        className="filter-date-range"
                                        format={"dd MMM yyyy"}
                                        selected={UpdatedDateFrom}
                                        onChange={(date) => setUpdatedDateFrom(date)}
                                        customInput={<CustomDatePicker iconColor={"#781D7E"} />}
                                    />
                                </div>
                                <div className="category-date-div">
                                    <div>Date To</div>
                                    <DatePicker
                                        className="filter-date-range"
                                        format={"dd MMM yyyy"}
                                        selected={UpdatedDateTo}
                                        onChange={(date) => setUpdatedDateTo(date)}
                                        customInput={<CustomDatePicker iconColor={"#781D7E"} />}
                                    />
                                </div>
                            </div>
                        )}                        
                    </div>

                </div>
                <DialogActionsBar>
                    <Button className="search-button cancel-btn" onClick={() => props.setShowFilterDialog(false)}>Cancel</Button>
                    <Button className="search-button search-btn" onClick={() => onSubmitFilter()}>Submit</Button>
                </DialogActionsBar>
            </Dialog>
        </>

    );
};