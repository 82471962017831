import './bus-checker.styles.css';

import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onChangeStep, onSelectCheckPeriod } from '../../../store/feedback-on-bus';

// Screens
import SelectFormPeriod from './partials/select-check-period';
import FormAddBSQCPeriod from './partials/form-add-period';
import ListOfCheckPeriod from './partials/list-of-check-period'
import { useLocation, useNavigate, useParams } from 'react-router';
import { STEP_BUS_CHECKER_PERIODS } from '../../../store/feedback-on-bus/constant';
import { useMasterData } from './actions';

const STEP = {
  SELECT_PERIOD: 'select-period',
  FORM_UPLOADER: 'form-uploader',
  LIST_DATA_UPLOADER: 'list-of-data-uploader'
}

const Page = props => {
  const navigate = useNavigate();
  const params = useLocation();
  useMasterData();

  const { id } = useParams();
  const dispatch = useDispatch();
  const { step, selectedCheckPeriod } = useSelector((state) => state.feedbackOnBus);

  // Navigate to details page
  useEffect(() => {
    const onSelectPeriod = (id) => {
      if (id) {
        dispatch(onChangeStep(STEP_BUS_CHECKER_PERIODS.LIST_DATA_UPLOADER));
        dispatch(onSelectCheckPeriod(id));

        navigate(`/feedback-on-bus/bsqc-checker/${id}`);
        return;
      }

      dispatch(onChangeStep(STEP_BUS_CHECKER_PERIODS.SELECT_PERIOD));
    }

    onSelectPeriod(id);
  }, [id]);

  const isSelectPeriod = useMemo(() => {
    if (
      (params?.pathname.includes('bsqc-checker') || params?.pathname === '/feedback-on-bus' )
      && step === STEP.SELECT_PERIOD
    ) {
      return true
    }

    return false
  }, [params?.pathname, step]);

  return (
    <div className='bus-checker-periods'>
      <div className='container'>
        {isSelectPeriod && (
          <SelectFormPeriod />
        )}
        {step === STEP.FORM_UPLOADER && (
          <FormAddBSQCPeriod />
        )}
        {step === STEP.LIST_DATA_UPLOADER && selectedCheckPeriod && <ListOfCheckPeriod />}
      </div>
    </div>
  )
}

export default Page;