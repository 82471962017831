import { Dialog } from "@progress/kendo-react-dialogs"
import Button from "../../../components/button";
import { X } from "@phosphor-icons/react";

import '../mic/mic.css';
import { useState } from "react";
import { ChangeGroup } from "../../../services/ideas";

const Component = props => {
  const [groupId, setGroupId] = useState('');
  const [groupName, setGroupName] = useState('');
  const [isFetch, setIsFetch] = useState(false);

  const onChangeGroup = (e) => {
    let index = e.nativeEvent.target.selectedIndex;
    let label = e.nativeEvent.target[index].text;

    const value = e?.target?.value;

    setGroupName(label)
    setGroupId(value);
  }

  const onSubmit = async () => {
    if (!groupId) return;
    
    try {
      setIsFetch(true);
      const payload = {
        id: props?.micId,
        sk: props?.sk,
        group_id: groupId
      }

      const [err, response] = await ChangeGroup(payload);
      setIsFetch(false);

      if (err) props?.onFailed(err);
      else props?.onSuccess({ ...response, groupId, groupName });
    } catch(err) {
      setIsFetch(true);

      props?.onFailed(err);
    }
  }

  const listOfDropdown = props?.list ?? [];

  return (
    <Dialog onClose={props?.onToggle}>
      <div className="mic-popup__wrapper">
        <div className="button-close-popup" onClick={props?.onToggle}>
          <X size={16} />
        </div>
        <div>
          <h3>{props?.title}</h3>

          <div className='dropdown'>
            <div className='dropdown-label'>
              <strong>Change Department to</strong>
            </div>
            <select onChange={onChangeGroup} className='dropdown-select' name={props?.section} id="lang">
              <option key={'default'} value={''}>Select</option>
              {listOfDropdown.map(
                ({ id, label }) => (
                  <option key={id} value={id}>{label}</option>
                )
              )}
            </select>

            <div className="footer-section"> 
              <Button disabled={isFetch} onClick={onSubmit}>
                {isFetch ? "Loading ..." : "Submit"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default Component;
