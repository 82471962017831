import React, { useEffect, useState } from "react";
import "./articles_admin.css";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCategoryDelete,
  fetchCategoryListing,
} from "../../../store/admin_articles/manage_Category";
import { TableHeader } from "../../../components/reusableItems/header";
import SearchIcon from "../../../assets/header/search.svg";
import RefreshIcon from "../../../assets/header/refresh.svg";
import { Reusable_table } from "../../../components/reusableItems/table";
import { ConfigData } from "./ConfigData";
import { Loader } from "@progress/kendo-react-indicators";
import { Search } from "../../../components/reusableItems/search";
import { searchFilter } from "../../../components/reusableItems/filter/filter_helper";
import { useNavigate } from "react-router";
import {
  ADMIN_TOOLS_ARTICLES_CATEGORY_CREATE_ROUTE,
  ADMIN_TOOLS_ARTICLES_CATEGORY_EDIT_ROUTE,
} from "../../../routes";
import { ShowDialog } from "../../../components/reusableItems/dialog";

export const Admin_Articles = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [tempData, setTempData] = useState([]);
  const [filterLoading, setFilterLoading] = useState(false);
  const [popupDelete, setPopupDelete] = useState(false);
  const [tempId, setTempID] = useState("");
  const { loading, error, listing_data } = useSelector(
    (state) => state.categoryAdmin
  );

  useEffect(() => {
    setFilterLoading(true);
    dispatch(fetchCategoryListing()).then((res) => {
      setTempData(res.payload.data);
      setFilterLoading(false);
    });
  }, []);

  const handleRefreshAction = () => {
    setFilterLoading(true);
    dispatch(fetchCategoryListing()).then((res) => {
      setTempData(res.payload.data);
      setFilterLoading(false);
    });
  };

  const handleCreateAction = () => {
    navigate(ADMIN_TOOLS_ARTICLES_CATEGORY_CREATE_ROUTE);
  };

  const handleSearchAction = () => {
    setSearch(!search);
  };

  const actionTrigger = () => {
    console.log(tempId);
    setFilterLoading(true);
    setPopupDelete(false);
    dispatch(fetchCategoryDelete(tempId)).then((res) => {
      if (res.payload.status_code === 200) {
        dispatch(fetchCategoryListing()).then((res) => {
          if (res.payload.status_code === 200) {
            setTempData(res.payload.data);
            setFilterLoading(false);
          } else {
            setFilterLoading(false);
            alert(res.payload.message);
          }
        });
      } else {
        setFilterLoading(false);
        alert(res.payload.message);
      }
    });
  };

  const handleTableClick = (type, id) => {
    console.log(type, id);
    if (type === "Edit") {
      navigate(`${ADMIN_TOOLS_ARTICLES_CATEGORY_EDIT_ROUTE}?id=${id}`);
    } else {
      setTempID(id);
      setPopupDelete(true);
    }
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value === "") applySearch("");
  };

  const handleClear = async () => {
    setFilterLoading(true);
    setSearchValue("");
    await setTempData(listing_data);
    setFilterLoading(false);
  };

  const applySearch = async (value) => {
    setFilterLoading(true);
    let searchColumn = [{ id: "category", caseSensitive: true }];
    const filtered = await searchFilter(
      listing_data,
      searchColumn,
      value === "" ? "" : searchValue
    );
    setTempData(filtered);
    setFilterLoading(false);
  };

  console.log(listing_data);

  const icons = [
    {
      buttonName: "+ Add new",
      action: handleCreateAction,
    },
    {
      icon: RefreshIcon,
      action: handleRefreshAction,
    },
    {
      icon: SearchIcon,
      action: handleSearchAction,
    },
  ];

  return (
    <>
      {
        <div className="container">
          <TableHeader title={"Manage Category"} icons={icons} />
          {popupDelete && (
            <ShowDialog
              title={"Please Confirm!"}
              content={"Are you sure you want to delete?"}
              cancelContent={"Cancel"}
              confrimContent={"Delete"}
              cancelbtn={() => setPopupDelete(false)}
              confrinbtn={actionTrigger}
            />
          )}
          {search && (
            <Search
              handleSearch={handleSearch}
              type={"text"}
              searchValue={searchValue}
              placeholder={"Search"}
              handleClear={handleClear}
              applySearch={applySearch}
            />
          )}
          {loading || filterLoading ? (
            <Loader
              type="converging-spinner"
              style={{
                width: "100%",
                height: "calc(100vh - 165px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#ffffff4d",
                position: "absolute",
                zIndex: "999",
              }}
            />
          ) : (
            <Reusable_table
              header={ConfigData}
              data={tempData}
              pagination={true}
              calculatePagi={true}
              pageChangeLoading={false}
              showRow={true}
              handleAction={handleTableClick}
              maxHeight={
                search ? "calc(-282px + 100vh)" : "calc(100vh - 220px)"
              }
              maxWidth={"calc(100vw - 240px)"}
            />
          )}
        </div>
      }
    </>
  );
};
