// redux

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionToken } from "../config/userPool";

const {
  NOTIFICATION_ENDPOINT,
  NOTIFICATION_RECIPIENT_ENDPOINT,
  NOTIFICATION_OVERVIEW_ENDPOINT,
  EXPORT_NOTIFICATION_ENDPOINT,
  AUDIT_NOTIFICATION_ENDPOINT,
  EXPORT_RECIPIENT_ENDPOINT,
  COMM_RECIPIENT_GET_FILE_ENDPOINT
} = config.api_endpoint;

const initialState = {
  loading: false,
  recipient_loading: false,
  ndata_loading: false,
  notification_ids: [],
  notification_next_key: {},
  notification_data: [],
  recipients: [],
  error: null,
  recipient_error: null,
  ndata_error: null,
  overview_loading: true,
  overview_data: [],
  overview_error: null,
  export_data: [],
  export_loading: false,
  export_error: [],
  audit_loading: false,
  audit_data: [],
  audit_error: false,
  file_url: [],
  file_loading: false,
  file_error: null
};
const status_values = {
  draft: "Draft",
  published: "Published",
  scheduled: "Scheduled",
  pendingapproval: "Pending Approval",
  rejected: "Rejected",
  archived: "Archived",
  recall: "Recall",
  approved: "Approved",
  expired: "Expired",
};
export const fetchNotificationById = createAsyncThunk(
  "notification/fetchNotificationById",
  async (notification_id, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(
        `${NOTIFICATION_ENDPOINT}/${notification_id}`,
        {},
        token
      );
      let data = results.data;
      let channel_names = data.channel.map((v) => v.name);
      data.channel_names = channel_names.join(",");
      data.status = status_values[data.status];
      console.log(data);
      return data;
    } catch (e) {
      return rejectWithValue(e.details);
    }
  }
);

export const fetchNotificationOverviewById = createAsyncThunk(
  "notification/fetchNotificationOverviewById",
  async (notification_id, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(
        `${NOTIFICATION_OVERVIEW_ENDPOINT}/${notification_id}`,
        {},
        token
      );
      let data = results.data;
      return data;
    } catch (e) {
      return rejectWithValue(e.details);
    }
  }
);

export const fetchNotificationRecipients = createAsyncThunk(
  "notification/fetchNotificationRecipients",
  async (notification_id, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(
        `${NOTIFICATION_RECIPIENT_ENDPOINT}/${notification_id}`,
        {},
        token
      );
      let result_data = results.data;
      result_data = result_data.map((v) => ({
        ...v,
        is_open: v.open ? "Yes" : "No",
        is_ack:
          v.acknowledgement === true
            ? "Yes"
            : v.acknowledgement === false
            ? "No"
            : v.acknowledgement,
      }));
      return result_data;
    } catch (e) {
      return rejectWithValue(e.details);
    }
  }
);

export const fetchAllNotifications = createAsyncThunk(
  "notification/fetchAllNotifications",
  async (query, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const query_string = query.toString().replace("?", "");
      const results = await api.get(
        NOTIFICATION_ENDPOINT + "?" + query_string,
        {},
        token
      );
      console.log(results);
      let result_data = results.data;
      result_data = result_data.map((v) => ({
        ...v,
        total_read_count: `${v.total_read}/${v.total_recipient}`,
        ack_label: v.ack_required ? "Yes" : "No",
        status_label: status_values[v.status],
        channel_label: v.channel.map((v) => v.name).join(","),
      }));

      return {
        data: result_data,
        next_key: results.next_key,
      };
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const updateNotification = createAsyncThunk(
  "notification/updateNotification",
  async (data, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.post(
        NOTIFICATION_ENDPOINT,
        JSON.stringify(data),
        token
      );
      return results;
    } catch (e) {
      return rejectWithValue(e.details);
    }
  }
);

export const exportToCSVNotifications = createAsyncThunk(
  "notification/exportToCSVNotifications",
  async (data, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.post(
        EXPORT_NOTIFICATION_ENDPOINT,
        JSON.stringify(data),
        token
      );
      return results.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.details);
    }
  }
);

export const fetchNotificationAudit = createAsyncThunk(
  "notification/fetchNotificationAudit",
  async (notification_id, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const url = `${AUDIT_NOTIFICATION_ENDPOINT}?table_name=comm&source_id=${notification_id}&search=True`;
      const results = await api.get(url, {}, token);
      return results.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.details);
    }
  }
);

export const fetchAllNotificationsSortBy = createAsyncThunk(
  "notification/fetchAllNotificationsSortBy",
  async (query, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const query_string = query.toString().replace("?", "");
      console.log(query_string);
      const results = await api.get(
        NOTIFICATION_ENDPOINT + "?" + query_string,
        {},
        token
      );
      console.log(results);
      let result_data = results.data;
      result_data = result_data.map((v) => ({
        ...v,
        total_read_count: `${v.total_read}/${v.total_recipient}`,
        ack_label: v.ack_required ? "Yes" : "No",
        status_label: status_values[v.status],
        channel_label: v.channel.map((v) => v.name).join(","),
      }));
      return result_data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const exportToCSVRecipients = createAsyncThunk(
  "notification/exportToCSVRecipients",
  async (id, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      console.log("id-", id);
      const results = await api.get(
        `${EXPORT_RECIPIENT_ENDPOINT}/${id}`,
        {},
        token
      );
      console.log("Recipient CSV api result-", results);
      return results.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.details);
    }
  }
);

export const getCommRecipientsFile = createAsyncThunk(
  "notification/getCommRecipientsFile",
  async (data, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.post(
        COMM_RECIPIENT_GET_FILE_ENDPOINT,
        JSON.stringify(data),
        token
      );
      return results;
    } catch (e) {
      return rejectWithValue(e.details);
    }
  }
);

const NotificationSlice = createSlice({
  name: "notification",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotificationById.pending, (state) => {
        state.ndata_loading = true;
      })
      .addCase(fetchNotificationById.fulfilled, (state, action) => {
        state.ndata_loading = false;
        state.notification_data = action.payload;
      })
      .addCase(fetchNotificationById.rejected, (state, action) => {
        state.ndata_loading = false;
        state.ndata_error = action.error.message;
      })
      .addCase(updateNotification.pending, (state) => {
        state.ndata_loading = true;
      })
      .addCase(updateNotification.fulfilled, (state, action) => {
        state.ndata_loading = false;
        state.notification_data = action.payload;
      })
      .addCase(updateNotification.rejected, (state, action) => {
        state.ndata_loading = false;
        state.ndata_error = action.error.message;
      })
      .addCase(fetchNotificationOverviewById.pending, (state) => {
        state.overview_loading = true;
      })
      .addCase(fetchNotificationOverviewById.fulfilled, (state, action) => {
        state.overview_loading = false;
        state.overview_data = action.payload;
      })
      .addCase(fetchNotificationOverviewById.rejected, (state, action) => {
        state.overview_loading = false;
        state.overview_error = action.error.message;
      })
      .addCase(fetchNotificationRecipients.pending, (state) => {
        state.recipient_loading = true;
      })
      .addCase(fetchNotificationRecipients.fulfilled, (state, action) => {
        state.recipient_loading = false;
        state.recipients = action.payload;
      })
      .addCase(fetchNotificationRecipients.rejected, (state, action) => {
        state.recipient_loading = false;
        state.recipient_error = action.error.message;
      })
      .addCase(exportToCSVNotifications.pending, (state) => {
        state.export_loading = true;
      })
      .addCase(exportToCSVNotifications.fulfilled, (state, action) => {
        state.export_loading = false;
        state.export_data = action.payload;
      })
      .addCase(exportToCSVNotifications.rejected, (state, action) => {
        state.export_loading = false;
        state.export_error = action.error.message;
      })
      .addCase(fetchNotificationAudit.pending, (state) => {
        state.audit_loading = true;
      })
      .addCase(fetchNotificationAudit.fulfilled, (state, action) => {
        state.audit_loading = false;
        state.audit_data = action.payload;
      })
      .addCase(fetchNotificationAudit.rejected, (state, action) => {
        state.audit_loading = false;
        state.audit_error = action.error.message;
      })
      .addCase(fetchAllNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.notification_ids = action.payload.data;
        state.notification_next_key = action.payload.next_key;
      })
      .addCase(fetchAllNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getCommRecipientsFile.pending, (state) => {
        state.file_loading = true;
      })
      .addCase(getCommRecipientsFile.fulfilled, (state, action) => {
        state.file_loading = false;
        state.file_url = action.payload.data;
      })
      .addCase(getCommRecipientsFile.rejected, (state, action) => {
        state.file_loading = false;
        state.file_error = action.error.message;
      })
      ;
  },
});

const NotificationReducer = NotificationSlice.reducer;
export default NotificationReducer;
