import React, { useState, useEffect } from "react";
// redux
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCategories } from "../../store/category";
// kendo
import { Checkbox } from "@progress/kendo-react-inputs";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Loader } from "@progress/kendo-react-indicators";
// css
import "./dialog.css";

export const CategoryListDialog = (props) => {
  const dispatch = useDispatch();
  const [selectedCategories, setSelectedCategories] = useState(
    props.defaultSelected
  );
  const { loading, category_ids, error } = useSelector(
    (state) => state.category
  );
  useEffect(() => {
    dispatch(fetchAllCategories());
  }, []);
  const handleSelected = (e, val) => {
    var updatedList = [...selectedCategories];
    if (e.target.value) {
      updatedList = [...selectedCategories, val];
    } else {
      var selected_index = selectedCategories.map((v) => v.id).indexOf(val.id);
      updatedList.splice(selected_index, 1);
    }
    setSelectedCategories(updatedList);
  };
  const handleSubmitApprovers = () => {
    props.callback(selectedCategories);
  };
  const isChecked = (item) =>
    selectedCategories.map((v) => v.name).includes(item) ? true : false;
  return (
    <Dialog
      title={`Please select category(s): ${selectedCategories.length} selected`}
      width={500}
      height={500}
      onClose={props.onClickNo}
      className="category-dialog"
    >
      <div className="category-list-div">
        {loading ? (
          <Loader
            type="infinite-spinner"
            style={{
              display: "flex",
              alignItems: "center",
              width: "40px",
              margin: "auto",
              justifyContent: "center",
              height: "350px",
            }}
          />
        ) : (
          category_ids.map((v) => (
            <Checkbox
              label={v.name}
              onChange={(e) => handleSelected(e, v)}
              checked={isChecked(v.name)}
            />
          ))
        )}
      </div>
      <DialogActionsBar>
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base category-cancel-button"
          onClick={props.onClickNo}
        >
          No
        </button>
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base category-submit-button"
          onClick={handleSubmitApprovers}
        >
          Yes
        </button>
      </DialogActionsBar>
    </Dialog>
  );
};
