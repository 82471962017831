import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// kendo
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
// icon
import CrossIcon from "../../../../assets/dr_safe/cross_gray_icon.png";
// css
import "./ifeedback_details.css";
import { fetchiFeedbackMatters } from "../../../../store/ifeedback";
import { Loader } from "@progress/kendo-react-indicators";

const IFeedbackDetailsMatterDialog = ({
    currentiFeedbackMatter,
    onMatterDialogClose,
    onMatterAction
}) => {
    const dispatch = useDispatch();
    const [selectedMatter, setSelectedMatter] = useState(null);
    const { matters, matter_loading, matters_error } = useSelector((state) => state.ifeedback);
    console.log('Matter Dia- matter:',matters,'Matter_loadinge ',matter_loading,'currentiFeedbackMatter-',currentiFeedbackMatter);
    useEffect(() => {
        dispatch(fetchiFeedbackMatters())
    }, []);
    
    return (
        <Dialog title={" "} width={400} onClose={onMatterDialogClose} className="duplicate-dialog">
            {matter_loading ? (
                <Loader
                    type="infinite-spinner"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        width: "40px",
                        margin: "auto",
                        justifyContent: "center",
                    }}
                />
            ) : (
                <div className="duplicate-dialog-div">
                    <span className="dialog-duplicate-title">Change Matter Type</span>
                    <p>
                        {/* <DropDownList
                            className="ifeedback-details-status-dialog-dropdown-value"
                            data={matters}
                            textField="description"
                            dataItemKey="sk"
                            value={currentiFeedbackMatter}                        
                            onChange={(e)=> setSelectedMatter(e.target.value.sk)}
                            style={{
                                width: "100%",
                              }}
                        /> */}
                        <select onChange={(e)=> setSelectedMatter(e.target.value)} className='ifeedback-details-status-dialog-dropdown-value select-case-dropdown'
                        style={{
                            width: "100%",
                          }}>
                            <option key={'default'} value={''}>Select</option>
                            {matters.map(
                                ({ sk, description }) => (
                                    <option key={sk} value={sk}>{description}</option>
                                )
                            )}
                        </select>
                    </p>
                    <p>
                        <Button 
                            className="ifeedback-details-status-dialog-button" 
                            style={{ width: "100%" }} 
                            onClick={() => onMatterAction(selectedMatter)}
                            disabled={!selectedMatter}
                        >Submit</Button>
                    </p>
                </div>
            )}

        </Dialog>
    );
};

export default IFeedbackDetailsMatterDialog;