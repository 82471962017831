import React, { useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";

export const FobSummaryChecksRouteHistory = ({
    routehistory: data
}) => {
    const initialDataState = {
        skip: 0,
        take: 10,
    };
    const initialSort = [
        {
            field: "created_on",
            dir: "asc",
        },
    ];
    const [page, setPage] = useState(initialDataState);
    const [sort, setSort] = useState(initialSort);
    const [pageSizeValue, setPageSizeValue] = React.useState();
    const pageChange = (event) => {
        const targetEvent = event.targetEvent;
        const take = targetEvent.value === "All" ? 77 : event.page.take;
        if (targetEvent.value) {
            setPageSizeValue(targetEvent.value);
        }
        setPage({
            ...event.page,
            take,
        });
    };
    console.log(data);
    return (
        <Grid
            style={{
                // height: "420px",
                maxWidth: "1200px"
            }}
            data={data.slice(page.skip, page.take + page.skip)}
            skip={page.skip}
            take={page.take}
            total={data.length}
            pageable={{
                buttonCount: 4,
                pageSizes: [5, 10, 15, "All"],
                pageSizeValue: pageSizeValue,
            }}
            onPageChange={pageChange}
            sortable={true}
            sort={sort}
            onSortChange={(e) => {
                setSort(e.sort);
            }}
        >
            <GridColumn
                width={50}
                field="email_groups"
                title="Sr.No"
                cell={(props) => (
                    <td className="k-table-td">
                        {props.dataIndex+1}
                    </td>
                )}
                />
            <GridColumn title="Reference No" field={"reference_no"} width={200}/>
            <GridColumn
                width={200}
                field="email_groups"
                title="To"
                cell={(props) => (
                    <td className="k-table-td">
                    {props.dataItem.email_groups.map((v)=> v.name)}
                    </td>
                )}
                />
            <GridColumn title="TimeStamp" field={"formatted_created_on"} width={200}/>
            <GridColumn title="Comments" field={"remark"} width={400}/>            
        </Grid>
    );
};