import { Dialog } from "@progress/kendo-react-dialogs";
import React from "react";
import "./articles.css";
import { Checkbox, RadioButton } from "@progress/kendo-react-inputs";
import DatePicker from "react-datepicker";
import { CustomDatePicker } from "../../../components/custom/datepicker";
import moment from "moment";

export const Filter = (props) => {
  const checkDateVali = (type) => {
    if (type === "startDate") {
      if (
        props.filterValue.startDate_from === "" ||
        props.filterValue.startDate_to === ""
      ) {
        return (
          <div className="Articles_create_error_text">
            In date range, From date, To date should not be empty!
          </div>
        );
      } else if (
        new Date(props.filterValue.startDate_from) >
        new Date(props.filterValue.startDate_to)
      ) {
        return (
          <div className="Articles_create_error_text">
            In date range, From date should not be later than To date!
          </div>
        );
      } else {
        return "";
      }
    }
    // if (type === "endDate") {
    //   if (
    //     props.filterValue.endDate_from === "" ||
    //     props.filterValue.endDate_to === ""
    //   ) {
    //     return (
    //       <div className="Articles_create_error_text">
    //         In date range, From date, To date should not be empty!;
    //       </div>
    //     );
    //   } else if (
    //     new Date(props.filterValue.endDate_from) >
    //     new Date(props.filterValue.endDate_to)
    //   ) {
    //     return (
    //       <div className="Articles_create_error_text">
    //         In date range, From date should not be later than To date!;
    //       </div>
    //     );
    //   } else {
    //     return "";
    //   }
    // }
    if (type === "createdDate") {
      if (
        props.filterValue.createdDate_from === "" ||
        props.filterValue.createdDate_to === ""
      ) {
        return (
          <div className="Articles_create_error_text">
            In date range, From date, To date should not be empty!;
          </div>
        );
      } else if (
        new Date(props.filterValue.createdDate_from) >
        new Date(props.filterValue.createdDate_to)
      ) {
        return (
          <div className="Articles_create_error_text">
            In date range, From date should not be later than To date!;
          </div>
        );
      } else {
        return "";
      }
    }
  };

  return (
    <Dialog width={395} height={"100%"} className="Articles_filter_dialog">
      <div className="Articles_filter_main_box">
        <div style={{ position: "relative" }}>
          <div
            className="Articles_filter_cross_icon"
            onClick={() => props.setShowFilter(false)}
          >
            <span class="k-icon k-font-icon k-i-close k-icon-xl" />
          </div>
        </div>
        <div className="Articles_filter_header_box">
          <div className="Articles_filter_header_title">Filter</div>
          <div
            className="Articles_filter_header_clear_filter"
            onClick={props.filterClearAction}
          >
            Clear Filter
          </div>
        </div>
        <div className="Articles_filter_detail_main_box">
          <div className="Articles_filter_detail_date_box">
            <div className="Articles_filter_detail_title">
              Scheduled / Published Date
            </div>
            <div className="Articles_filter_radio_button_main_box">
              <div className="Articles_filter_radio_main_box">
                <RadioButton
                  className="Articles_filter_Radio"
                  name="startDate"
                  value="all"
                  key="all"
                  checked={props.filterValue.startDate === "all"}
                  onChange={(e) => {
                    props.setFilterValue({
                      ...props.filterValue,
                      startDate: "all",
                    });
                  }}
                />
                All dates
              </div>
              <div className="Articles_filter_radio_main_box">
                <RadioButton
                  className="Articles_filter_Radio"
                  name="startDate"
                  value="date"
                  key="date"
                  checked={props.filterValue.startDate === "date"}
                  onChange={(e) => {
                    props.setFilterValue({
                      ...props.filterValue,
                      startDate: "date",
                    });
                  }}
                />
                Date Range
              </div>
            </div>
            {props.filterValue.startDate === "date" && (
              <div className="Articles_filter_detail_date">
                <div className="Articles_filter_detail_date_child">
                  <div>From</div>
                  <DatePicker
                    dateFormatCalendar="MMMM"
                    customInput={<CustomDatePicker />}
                    // minDate={new Date()}
                    selected={
                      props.filterValue.startDate_from !== ""
                        ? new Date(props.filterValue.startDate_from)
                        : ""
                    }
                    onChange={(e) =>
                      props.setFilterValue({
                        ...props.filterValue,
                        startDate_from: moment(e).format("YYYY-MM-DD"),
                      })
                    }
                  />
                </div>
                <div className="Articles_filter_detail_date_child">
                  <div>To</div>
                  <DatePicker
                    dateFormatCalendar="MMMM"
                    customInput={<CustomDatePicker />}
                    // minDate={new Date()}
                    selected={
                      props.filterValue.startDate_to !== ""
                        ? new Date(props.filterValue.startDate_to)
                        : ""
                    }
                    onChange={(e) =>
                      props.setFilterValue({
                        ...props.filterValue,
                        startDate_to: moment(e).format("YYYY-MM-DD"),
                      })
                    }
                  />
                </div>
              </div>
            )}
            {props.filterValue.startDate === "date"
              ? checkDateVali("startDate")
              : ""}
          </div>
          {/* <div className="Articles_filter_detail_date_box">
            <div className="Articles_filter_detail_title">End Date</div>
            <div className="Articles_filter_radio_button_main_box">
              <div className="Articles_filter_radio_main_box">
                <RadioButton
                  className="Articles_filter_Radio"
                  name="endDate"
                  value="all"
                  key="all"
                  checked={props.filterValue.endDate === "all"}
                  onChange={(e) => {
                    props.setFilterValue({
                      ...props.filterValue,
                      endDate: "all",
                    });
                  }}
                />
                All dates
              </div>
              <div className="Articles_filter_radio_main_box">
                <RadioButton
                  className="Articles_filter_Radio"
                  name="endDate"
                  value="date"
                  key="date"
                  checked={props.filterValue.endDate === "date"}
                  onChange={(e) => {
                    props.setFilterValue({
                      ...props.filterValue,
                      endDate: "date",
                    });
                  }}
                />
                Date Range
              </div>
            </div>
            {props.filterValue.endDate === "date" && (
              <div className="Articles_filter_detail_date">
                <div className="Articles_filter_detail_date_child">
                  <div>From</div>
                  <DatePicker
                    dateFormatCalendar="MMMM"
                    customInput={<CustomDatePicker />}
                    // minDate={new Date()}
                    selected={
                      props.filterValue.endDate_from !== ""
                        ? new Date(props.filterValue.endDate_from)
                        : ""
                    }
                    onChange={(e) =>
                      props.setFilterValue({
                        ...props.filterValue,
                        endDate_from: moment(e).format("YYYY-MM-DD"),
                      })
                    }
                  />
                </div>
                <div className="Articles_filter_detail_date_child">
                  <div>To</div>
                  <DatePicker
                    dateFormatCalendar="MMMM"
                    customInput={<CustomDatePicker />}
                    // minDate={new Date()}
                    selected={
                      props.filterValue.endDate_to !== ""
                        ? new Date(props.filterValue.endDate_to)
                        : ""
                    }
                    onChange={(e) =>
                      props.setFilterValue({
                        ...props.filterValue,
                        endDate_to: moment(e).format("YYYY-MM-DD"),
                      })
                    }
                  />
                </div>
              </div>
            )}
            {props.filterValue.endDate === "date"
              ? checkDateVali("endDate")
              : ""}
          </div> */}
          <div className="Articles_filter_detail_date_box">
            <div className="Articles_filter_detail_title">Created On</div>
            <div className="Articles_filter_radio_button_main_box">
              <div className="Articles_filter_radio_main_box">
                <RadioButton
                  className="Articles_filter_Radio"
                  name="createdOn"
                  value="all"
                  key="all"
                  checked={props.filterValue.createdDate === "all"}
                  onChange={(e) => {
                    props.setFilterValue({
                      ...props.filterValue,
                      createdDate: "all",
                    });
                  }}
                />
                All dates
              </div>
              <div className="Articles_filter_radio_main_box">
                <RadioButton
                  className="Articles_filter_Radio"
                  name="createdOn"
                  value="date"
                  key="date"
                  checked={props.filterValue.createdDate === "date"}
                  onChange={(e) => {
                    props.setFilterValue({
                      ...props.filterValue,
                      createdDate: "date",
                    });
                  }}
                />
                Date Range
              </div>
            </div>
            {props.filterValue.createdDate === "date" && (
              <div className="Articles_filter_detail_date">
                <div className="Articles_filter_detail_date_child">
                  <div>From</div>
                  <DatePicker
                    dateFormatCalendar="MMMM"
                    customInput={<CustomDatePicker />}
                    // minDate={new Date()}
                    selected={
                      props.filterValue.createdDate_from !== ""
                        ? new Date(props.filterValue.createdDate_from)
                        : ""
                    }
                    onChange={(e) =>
                      props.setFilterValue({
                        ...props.filterValue,
                        createdDate_from: moment(e).format("YYYY-MM-DD"),
                      })
                    }
                  />
                </div>
                <div className="Articles_filter_detail_date_child">
                  <div>To</div>
                  <DatePicker
                    dateFormatCalendar="MMMM"
                    customInput={<CustomDatePicker />}
                    // minDate={new Date()}
                    selected={
                      props.filterValue.createdDate_to !== ""
                        ? new Date(props.filterValue.createdDate_to)
                        : ""
                    }
                    onChange={(e) =>
                      props.setFilterValue({
                        ...props.filterValue,
                        createdDate_to: moment(e).format("YYYY-MM-DD"),
                      })
                    }
                  />
                </div>
              </div>
            )}
            {props.filterValue.createdDate === "date"
              ? checkDateVali("createdDate")
              : ""}
          </div>
          <div className="Pormotions_filter_check_box_main_box">
            <div className="Articles_filter_detail_title">Status</div>
            {["Submitted", "Published", "Unpublished"].map((i) => (
              <div key={i} className="Pormotions_filter_check_box_item">
                <Checkbox
                  style={{ border: "1px solid #CCC" }}
                  checked={
                    props.filterValue.status.find((l) => l === i) ? true : false
                  }
                  onClick={(e) => {
                    const find = props.filterValue.status.find((l) => l === i);
                    if (find) {
                      const filter = props.filterValue.status.filter(
                        (l) => l !== i
                      );
                      props.setFilterValue({
                        ...props.filterValue,
                        status: filter,
                      });
                    } else {
                      props.setFilterValue({
                        ...props.filterValue,
                        status: [...props.filterValue.status, i],
                      });
                    }
                  }}
                />
                {i}
              </div>
            ))}
          </div>
          <div className="Pormotions_filter_check_box_main_box">
            <div className="Articles_filter_detail_title">Category</div>
            {props.category.map((i) => (
              <div key={i} className="Pormotions_filter_check_box_item">
                <Checkbox
                  style={{ border: "1px solid #CCC" }}
                  checked={
                    props.filterValue.category.find((l) => l === i.id)
                      ? true
                      : false
                  }
                  onClick={(e) => {
                    const find = props.filterValue.category.find(
                      (l) => l === i.id
                    );
                    if (find) {
                      const filter = props.filterValue.category.filter(
                        (l) => l !== i.id
                      );
                      props.setFilterValue({
                        ...props.filterValue,
                        category: filter,
                      });
                    } else {
                      props.setFilterValue({
                        ...props.filterValue,
                        category: [...props.filterValue.category, i.id],
                      });
                    }
                  }}
                />
                {i.category}
              </div>
            ))}
          </div>
        </div>
        <div className="Articles_filter_footer_main_box">
          <div
            className="Articles_filter_footer_cancel"
            onClick={() => props.setShowFilter(false)}
          >
            Cancel
          </div>
          <div
            className="Articles_filter_footer_submit"
            onClick={props.FilterAction}
          >
            Submit
          </div>
        </div>
      </div>
    </Dialog>
  );
};
