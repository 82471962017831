import React, { useEffect, useState } from "react";
import left from "../../../assets/common/left_pagi.svg";
import leftHover from "../../../assets/common/left_pagi_hover.svg";
import right from "../../../assets/common/right_pagi.svg";
import rightHover from "../../../assets/common/right_pagi_hover.svg";
import "./pagination.css";
import { DropDownList } from "@progress/kendo-react-dropdowns";

export const Pagination = (props) => {
  const [left_hover, setLeft_hover] = useState(false);
  const [right_hover, setRight_hover] = useState(false);
  const [page_number, setPage_number] = useState(1);
  const [pages, setPages] = useState(1);
  const [pagesCount, setPageCount] = useState(
    props.pageCount ? props.pageCount : 1
  );
  const [actviePage, setActivePage] = useState(
    props.activePages ? props.activePages : 1
  );
  const paginationCount = 8;

  const pageChange = (Identifier) => {
    if (Identifier === "Increase" || Identifier === "firstPage") {
      if (Identifier === "firstPage") {
        props.handlePageChange(1);
        setActivePage(1);
        setPages(1);
      } else {
        props.handlePageChange(actviePage + 1);
        setActivePage(actviePage + 1);
        if (actviePage - 7 === pages) {
          console.log(actviePage);
          setPages(pages + paginationCount);
        }
      }
    } else {
      if (Identifier === "lastPage") {
        props.handlePageChange(pagesCount);
        setActivePage(pagesCount);
        let tempPage =
          Math.floor(pagesCount / paginationCount) * paginationCount;
        setPages(tempPage + 1);
      } else {
        props.handlePageChange(actviePage - 1);
        setActivePage(actviePage - 1);
        if (actviePage === pages) {
          setPages(pages - paginationCount);
        }
      }
    }
  };
  useEffect(() => {
    setPageCount(props.pageCount);
    setActivePage(props.activePages);
  }, [props.pageCount]);
  useEffect(() => {
    if (props.calculatePagi) {
      let tempRowCount =
        props.rowCount === "All" ? props.listItem.length : props.rowCount;
      let totalPage = Math.floor(props.listItem.length / tempRowCount);
      totalPage =
        props.listItem.length / tempRowCount > totalPage
          ? totalPage + 1
          : totalPage;
      setPageCount(totalPage);
      setActivePage(1);
    }
  }, [props.calculatePagi, props.rowCount]);
  return (
    <div className="pagination_main_box">
      <div
        className="pagination_item_box_unactive"
        onMouseEnter={() => setLeft_hover(true)}
        onMouseLeave={() => setLeft_hover(false)}
        onClick={() => pageChange("firstPage")}
        style={{
          pointerEvents: actviePage === 1 ? "none" : "",
          opacity: actviePage === 1 ? 0.5 : 1,
        }}
      >
        <span class="k-icon k-font-icon k-i-arrow-end-left" />
        {/* <img src={left_hover === false ? left : leftHover} /> */}
      </div>
      <div
        className="pagination_item_box_unactive"
        onMouseEnter={() => setLeft_hover(true)}
        onMouseLeave={() => setLeft_hover(false)}
        onClick={() => pageChange("Decrease")}
        style={{
          pointerEvents: actviePage === 1 ? "none" : "",
          opacity: actviePage === 1 ? 0.5 : 1,
        }}
      >
        <span class="k-icon k-font-icon k-i-arrow-60-left" />
        {/* <img src={left_hover === false ? left : leftHover} /> */}
      </div>
      {pages > 1 && (
        <div
          className="pagination_item_box_unactive"
          onClick={() => {
            setPages(pages - paginationCount);
            setActivePage(pages - 1);
            props.handlePageChange(pages - 1);
          }}
        >
          ...
        </div>
      )}
      {[0, 1, 2, 3, 4, 5, 6, 7].map((data, id) => {
        if (pagesCount - pages >= id)
          return (
            <div
              className={
                actviePage === pages + data
                  ? "pagination_item_box_active"
                  : "pagination_item_box_unactive"
              }
              key={data}
              onClick={() => {
                setActivePage(pages + data);
                props.handlePageChange(pages + data);
              }}
            >
              {pages + data}
            </div>
          );
      })}
      {pages < pagesCount - paginationCount && (
        <div
          className="pagination_item_box_unactive"
          onClick={() => {
            setPages(pages + paginationCount);
            setActivePage(pages + paginationCount);
            props.handlePageChange(pages + paginationCount);
          }}
        >
          ...
        </div>
      )}
      <div
        className="pagination_item_box_unactive"
        onMouseEnter={() => setRight_hover(true)}
        onMouseLeave={() => setRight_hover(false)}
        onClick={() => pageChange("Increase")}
        style={{
          pointerEvents: actviePage === pagesCount ? "none" : "",
          opacity: actviePage === pagesCount ? 0.5 : 1,
        }}
      >
        <span class="k-icon k-font-icon k-i-arrow-60-right" />
        {/* <img src={right_hover === false ? right : rightHover} /> */}
      </div>
      <div
        className="pagination_item_box_unactive"
        onMouseEnter={() => setRight_hover(true)}
        onMouseLeave={() => setRight_hover(false)}
        onClick={() => pageChange("lastPage")}
        style={{
          pointerEvents: actviePage === pagesCount ? "none" : "",
          opacity: actviePage === pagesCount ? 0.5 : 1,
        }}
      >
        <span class="k-icon k-font-icon k-i-arrow-end-right" />
        {/* <img src={right_hover === false ? right : rightHover} /> */}
      </div>
      {props.showRow && (
        <div className="pagination_item_row_count_main_box">
          <DropDownList
            placeholder="Select"
            data={props.rowPerPage}
            className="pagination_item_row_count_drop_down"
            value={props.rowCount}
            onChange={(e) => props.handleRowChange(e.target.value)}
          />
          <div className="pagination_item_row_count_title">items per page</div>
        </div>
      )}
    </div>
  );
};
