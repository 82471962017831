import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";

import "./table.css";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { USER_MANAGEMENT_USERS_DETAILS } from "../../routes";
import { Confirmation } from "../../components/dialog/confirmation";
import { getCurrentLoggedInSessionTokenWithPromise } from "../../config/userPool";
import api from "../../config/api";
import config from "../../config/config";
import {
  checkPermission,
  getUserPermission,
} from "../../config/permission_helper";

const initialDataState = {
  skip: 0,
  take: 10,
  dir: "asc",
};

const initialSort = [
  {
    field: "user_name",
    dir: "asc",
  },
];

const itemRender = (li, itemProps) => {
  const index = itemProps.index;
  const itemChildren = (
    <span
      style={{
        display: "flex",
      }}
    >
      {li.props.children}
    </span>
  );
  return React.cloneElement(li, li.props, itemChildren);
};

const valueRender = (element, value) => {
  if (!value) {
    return element;
  }
  const children = [
    <span>&nbsp;&nbsp;&nbsp;&nbsp; {element.props.children}</span>,
  ];
  return React.cloneElement(
    element,
    {
      ...element.props,
    },
    children
  );
};

const rowRender = (trElement, props) => {
  const enabled = props.dataItem.is_active;
  const black = {
    color: "#424242",
  };
  const red = {
    color: "#ff3333",
  };
  const trProps = {
    style: enabled ? black : red,
  };
  return React.cloneElement(
    trElement,
    {
      ...trProps,
    },
    trElement.props.children
  );
};

export const UserTableBody = (props) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(initialDataState);
  const [pageSizeValue, setPageSizeValue] = useState();
  const [sort, setSort] = useState(initialSort);
  const { USER_DEACTIVE_ACTIVATE_ENDPOINT } = config.api_endpoint;

  const pageChange = (event) => {
    const targetEvent = event.targetEvent;
    const take = targetEvent.value === "All" ? 77 : event.page.take;
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const userStatus = async (is_active, value) => {
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    await api
      .post(
        USER_DEACTIVE_ACTIVATE_ENDPOINT,
        JSON.stringify({
          username: value.username,
          is_active: is_active,
          // username: "107102",
        }),
        token
      )
      .then((res) => {
        console.log(res);
        if (res.status_code === 200) {
          props.reload();
        } else {
          alert(res.message);
        }
      });
  };

  const onItemActionChange = (e, val) => {
    console.log(e, val);
    const selected = e.target.value;
    const { username } = val;
    if (selected === "View Details") {
      navigate(`${USER_MANAGEMENT_USERS_DETAILS}?id=${username}`);
    }
    if (selected === "Reactivate") {
      userStatus(true, val);
    }
    if (selected === "Deactivate") {
      userStatus(false, val);
    }
  };

  const permissionChecker = (permssion_required) => {
    const permisison = getUserPermission();
    return checkPermission(permisison, permssion_required);
  };

  return (
    <div className="tbody-content">
      <Grid
        data={
          props.data
            ? orderBy(props.data.slice(page.skip, page.take + page.skip), sort)
            : []
        }
        skip={page.skip}
        take={page.take}
        total={props.data ? props.data.length : 0}
        sortable={true}
        sort={sort}
        onSortChange={(e) => {
          setSort(e.sort);
        }}
        pageable={{
          buttonCount: 4,
          pageSizes: [5, 10, 15, "All"],
          pageSizeValue: pageSizeValue,
          info: false,
          // pageSizes: false,
        }}
        pageSize={false}
        info={false}
        pageSizes={false}
        onPageChange={pageChange}
        rowRender={rowRender}
      >
        {/* <GridColumn field="sap_no" title="SAP No" width={100} /> */}
        <GridColumn field="user_name" title="Name" width={200} />
        {/* <GridColumn field="username" title="SAP/BC No" width={100}/> */}
        <GridColumn field="email" title="Email" width={200} />
        <GridColumn field="dept_desc" title="Department/Depot" />
        <GridColumn field="sect_desc" title="Section/Interchange Group" />
        <GridColumn field="emp_sgrp_desc" title="Corp Rank" />
        <GridColumn field="designation" title="Designation" />
        <GridColumn
          width={150}
          sortable={false}
          field="Action"
          cell={(props) => (
            <td className="k-table-td">
              {console.log(props.dataItem)}
              <DropDownList
                size={"large"}
                fillMode={"solid"}
                rounded={"large"}
                className="action-btn-dropdown"
                // data={["View Details"]}
                data={
                  props.dataItem.is_active
                    ? permissionChecker("user_management.deactivate_user")
                      ? ["View Details", "Deactivate"]
                      : ["View Details"]
                    : permissionChecker("user_management.reactivate_user")
                    ? ["View Details", "Reactivate"]
                    : ["View Details"]
                }
                valueRender={valueRender}
                itemRender={itemRender}
                defaultValue={"Action"}
                onChange={(e) => onItemActionChange(e, props.dataItem)}
                style={{
                  fontSize: "15px",
                }}
              />
            </td>
          )}
        />
      </Grid>
    </div>
  );
};
