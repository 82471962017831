import React, { useState, useRef, useEffect } from "react";

import { Button } from "@progress/kendo-react-buttons";
import { useLocation, useNavigate } from "react-router";

import "./reports.css";
import ExportIcon from "../../../assets/header/export.svg";
import RefreshIcon from "../../../assets/header/refresh.svg";
import FilterIcon from "../../../assets/header/filter.svg";
import SearchIcon from "../../../assets/header/search.svg";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CustomDatePicker } from "../../../components/custom/datepicker";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import moment from "moment";

const columns = [
    "Select All", "Date", "FeedbackScheme", "Type", "ServiceNumber", "AssignedBC", "SubmittedBy", "BusRegistrationNumber", "BusArrivalTime",
    "PassengerLoading", "TimeBoardBus", "Aircon", "Cleanliness", "BusConditions", "Category", "BCService", "EnglishLiteracy", "BCDrivingPositive",
    "BCDrivingNegative", "TripExperience", "Compliments", "OtherFeedback", "TimeAlightBus", "BusWaitingTime", "BusRiderComfortable", "SafeBusRide",
    "BusCaptainPolite", "BusExperience", "Suggestion", "CommuterName", "CommuterPhone", "ActionTaken", "MeetTrainingObjects", "Remarks"
]
export const FOBReportTableHeader = (props) => {
    const {search} = useLocation();
    const query = new URLSearchParams(search);
    const [state, setState] = React.useState({ value: [], allSelected: true });
    const exportLink = useRef(null);
    const handleChange = (event) => {
        const currentSelectAll = state.value.some((i) => i === "Select All");
        const nextSelectAll = event.value.some((i) => i === "Select All");
        let value = event.value;
        const currentCount = state.value.length;
        const nextCount = value.length;

        if (
            nextCount > currentCount &&
            !currentSelectAll &&
            !nextSelectAll &&
            columns.length - 1 === nextCount
        ) {
            value = columns;
        } else if (
            nextCount < currentCount &&
            currentCount === columns.length &&
            currentSelectAll &&
            nextSelectAll
        ) {
            value = value.filter((v) => v !== "Select All");
        } else if (!currentSelectAll && nextSelectAll) {
            value = columns;
        } else if (currentSelectAll && !nextSelectAll) {
            value = [];
        }
        console.log(value);
        setState({ value });
        props.onhandlePageColumnChange(value);
    };
    const itemRender = (li, itemProps) => {
        const itemChildren = (
            <span>
                <input
                    type="checkbox"
                    name={itemProps.dataItem}
                    checked={itemProps.selected}
                    onChange={(e) => itemProps.onClick(itemProps.index, e)}
                />
                &nbsp;{li.props.children}
            </span>
        );
        return React.cloneElement(li, li.props, itemChildren);
    };
    const value = state.value;
    const selected = value.length;
    const onClickExportButton = ()=>{
        exportLink.current.link.click();
    }
    return (
        <div className="report-table-header">
            <div className="report-table-filter">
                <div className="report-table-filter-item">
                    <div>Date Submitted From</div>
                    <DatePicker
                        value={query.get("start_date") || ""}
                        onChange={(date)=> props.onhandleFilterChange({"startdate": moment(date).format("YYYY-MM-DD")})}
                        dateFormatCalendar="MMMM"
                        customInput={<CustomDatePicker />}
                    />
                </div>
                <div className="report-table-filter-item">
                    <div>Date Submitted To</div>
                    <DatePicker
                        value={query.get("end_date") || ""}
                        onChange={(date)=> props.onhandleFilterChange({"enddate": moment(date).format("YYYY-MM-DD")})}
                        dateFormatCalendar="MMMM"
                        customInput={<CustomDatePicker />}
                    />
                </div>
                {/* <div className="report-table-filter-item">
                    <div>Report Column</div>
                    <MultiSelect
                        style={{
                            width: "240px"
                        }}
                        data={columns}
                        itemRender={itemRender}
                        autoClose={false}
                        value={value}
                        // opened={true}
                        onChange={handleChange}
                        tags={
                            selected > 0
                                ? [{ text: `${selected} items selected`, data: [...value] }]
                                : []
                        }
                    />
                </div> */}
                <div className="report-table-filter-item">
                    <div>Feedback Scheme</div>
                    <select 
                        className="report-table-filter-select"
                        value={query.get("type")}
                        onChange={(e)=> props.onhandleFilterChange({"type":e.target.value})}
                    >
                        <option value={"default"}>Select Feedback Scheme</option>
                        <option value={"BSQC"}>Bus Service Quality Check</option>
                        <option value={"MRCBC"}>Mystery Rider Check(BC)</option>    
                    </select>
                </div>
            </div>
            <div className="report-table-functions">
                {!props.hiderefreshbtn && (
                    <Button
                        onClick={props?.onRefresh}
                        title="Refresh"
                        className="button2"
                    >
                        <img src={RefreshIcon} alt="refresh-icon" />
                    </Button>
                )}
                {props.showNotificationExport && (
                    <>
                        {typeof props?.onExport !== "function" && (
                            <CSVLink
                                uFEFF={false}
                                asyncOnClick={true}
                                data={props.data}
                                filename="export_notification"
                                ref={exportLink}
                                hidden
                            >
                                Download
                            </CSVLink>
                        )}
                        <Button
                            // disabled={loading}
                            onClick={onClickExportButton}
                            className="button2"
                        >
                            <img src={ExportIcon} alt="copy-icon" />
                        </Button>
                    </>
                )}
                {/* {!props.hideFilterBtn && (
                    <Button onClick={props?.onFilter} className="button2">
                        <img src={FilterIcon} alt="filter" />
                    </Button>
                )} */}
                {/* {!props.hideSearchBtn && (
                    <Button onClick={props?.onSearch} className="button2">
                        <img src={SearchIcon} alt="search-icon" />
                    </Button>
                )} */}
            </div>
        </div>
    );
};
