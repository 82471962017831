import { Dialog } from "@progress/kendo-react-dialogs";
import React from "react";
import "./clinic.css";
import { Checkbox, RadioButton } from "@progress/kendo-react-inputs";
import DatePicker from "react-datepicker";
import { CustomDatePicker } from "../../../components/custom/datepicker";
import moment from "moment";

export const Filter = (props) => {

  return (
    <Dialog width={395} height={"100%"} className="clinic_filter_dialog">
      <div className="clinic_filter_main_box">
        <div style={{ position: "relative" }}>
          <div
            className="clinic_filter_cross_icon"
            onClick={() => props.setShowFilter(false)}
          >
            <span class="k-icon k-font-icon k-i-close k-icon-xl" />
          </div>
        </div>
        <div className="clinic_filter_header_box">
          <div className="clinic_filter_header_title">Filter</div>
          <div
            className="clinic_filter_header_clear_filter"
            onClick={props.filterClearAction}
          >
            Clear Filter
          </div>
        </div>
        <div className="clinic_filter_detail_main_box">
          <div className="clinic_filter_detail_title">Healthier SG Clinic</div>
          <div className="clinic_filter_radio_button_main_box">
            <div className="clinic_filter_radio_main_box">
              <RadioButton
                className="clinic_filter_Radio"
                name="hsc"
                value="yes"
                key="yes"
                checked={props.filterValue.hsc === "Yes"}
                onChange={(e) => {
                  props.setFilterValue({
                    ...props.filterValue,
                    hsc: "Yes",
                  });
                }}
              />
              Yes
            </div>
            <div className="clinic_filter_radio_main_box">
              <RadioButton
                className="clinic_filter_Radio"
                name="hsc"
                value="no"
                key="no"
                checked={props.filterValue.hsc === "No"}
                onChange={(e) => {
                  props.setFilterValue({
                    ...props.filterValue,
                    hsc: "No",
                  });
                }}
              />
              No
            </div>
            <div className="clinic_filter_radio_main_box">
              <RadioButton
                className="clinic_filter_Radio"
                name="healthier_sg_clinic_yes"
                value="no_applicable"
                key="no_applicable"
                checked={props.filterValue.hsc === "Not Applicable"}
                onChange={(e) => {
                  props.setFilterValue({
                    ...props.filterValue,
                    hsc: "Not Applicable",
                  });
                }}
              />
              Not Applicable
            </div>
          </div>

          <div className="clinic_filter_detail_title">Claimable</div>
          <div className="clinic_filter_radio_button_main_box">
            <div className="clinic_filter_radio_main_box">
              <RadioButton
                className="clinic_filter_Radio"
                name="claimable"
                value="yes"
                key="yes"
                checked={props.filterValue.claimable === "Yes"}
                onChange={(e) => {
                  props.setFilterValue({
                    ...props.filterValue,
                    claimable: "Yes",
                  });
                }}
              />
              Yes
            </div>
            <div className="clinic_filter_radio_main_box">
              <RadioButton
                className="clinic_filter_Radio"
                name="claimable"
                value="no"
                key="no"
                checked={props.filterValue.claimable === "No"}
                onChange={(e) => {
                  props.setFilterValue({
                    ...props.filterValue,
                    claimable: "No",
                  });
                }}
              />
              No
            </div>
          </div>
        </div>
        <div className="clinic_filter_footer_main_box">
          <div
            className="clinic_filter_footer_cancel"
            onClick={() => props.setShowFilter(false)}
          >
            Cancel
          </div>
          <div
            className="clinic_filter_footer_submit"
            onClick={props.FilterAction}
          >
            Submit
          </div>
        </div>
      </div>
    </Dialog>
  );
};
