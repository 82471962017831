import React, { useState, useRef, useEffect } from "react";

import { Navigate, useLocation, useNavigate } from "react-router";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";

import "../../../../components/table/table.css";
import RefreshIcon from "../../../../assets/header/refresh.svg";
import SearchIcon from "../../../../assets/header/search.svg";

import { useDispatch, useSelector } from "react-redux";

export const TableHeader = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [loading, setLoading] = useState(false);

  const onClickAddButton = () => {
    const { addurl } = props;
    navigate(addurl);
  };
  return (
    <div className="table-header">
      <div className="table-header-div">
        <p className="table-name">{props.title}</p>
        <div className="buttons_group">
          {props.showCreateNew && (
            <div>
              {!props.hideaddbtn && (
                <Button
                  className="addnew_btn"
                  onClick={onClickAddButton}
                  style={{ borderRadius: 8 }}
                >
                  + Add New{" "}
                </Button>
              )}
            </div>
          )}

          {/* {!props.hideaddbtn && (
                  <Button className="addnew_btn" onClick={onClickAddButton} style={{borderRadius:8}} >+ Create New </Button>
                )} */}
          {!props.hiderefreshbtn && (
            <Button
              className="button2"
              title="Refresh"
              onClick={() => props.onRefresh()}
              disabled={props.loading}
            >
              <img src={RefreshIcon} />
            </Button>
          )}
          {!props.hideSearchBtn && (
            <Button
              className="button2"
              title="Search"
              onClick={() => props.onSearch(true)}
            >
              <img src={SearchIcon} />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
