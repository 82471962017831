import React from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CustomDatePicker } from "../../../components/custom/datepicker";

export const EcardsFilter = (props) => {
  console.log(props.shouldDisableApprove());
  return (
    <div className="filter_main_box">
      {props.dropdownConfig &&
        props.dropdownConfig.length > 0 &&
        props.dropdownConfig.map((i) => (
          <div
            className="filter_child_box"
            key={i.id}
            style={{ maxWidth: i.type === "button" && "130px" }}
          >
            {i.type !== "button" && (
              <div className="filter_title">{i.title}</div>
            )}
            {i.type === "button" && (
              <div
                className="filter_title"
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  color: "#0000ff9c",
                  fontSize: "14px",
                }}
                onClick={() => props.clearFilter && props.clearFilter()}
              >
                Clear Filter
              </div>
            )}
            {i.type === "dropdown" ? (
              <DropDownList
                data={
                  props?.[i.dropdownIdentifier]
                    ? props?.[i.dropdownIdentifier]
                    : []
                }
                value={props.filterValue && props.filterValue[i.id]}
                dataItemKey={`${i.dataKeyItem}`}
                textField={`${i.textField}`}
                size={"large"}
                onChange={(e) =>
                  props.handleFilter &&
                  props.handleFilter(e.target.value, i.id, i.dataKeyItem)
                }
                iconColor="#73007D"
                iconClassName="k-icon k-font-icon k-i-arrow-chevron-down"
              />
            ) : i.type === "date" ? (
              <DatePicker
                style={{ width: "100%" }}
                dateFormat="MMM yyyy"
                selected={props.filterValue && props.filterValue[i.id]}
                showMonthYearPicker
                customInput={<CustomDatePicker iconColor={"#781D7E"} />}
                onChange={(e) =>
                  props.handleFilter && props.handleFilter(e, i.id)
                }
              />
            ) : (
              <div
                className="filter_button"
                style={{
                  pointerEvents: props.shouldDisableApprove() ? "none" : "",
                  opacity: props.shouldDisableApprove() ? "0.5" : "1",
                }}
                onClick={() => props.handleApprove && props.handleApprove()}
              >
                Approve
              </div>
            )}
          </div>
        ))}
    </div>
  );
};
