import { Dialog,DialogActionsBar } from "@progress/kendo-react-dialogs";

import Input from "../../../../components/input";
import Drawer from "../../../../components/drawer";
import Button from "../../../../components/button";
import { useState } from "react";

const DrSubSystemListSearchDialog = ({onToggleDialog, onSubmitShare}) => {
  const [subSystemName, setSubSystemName] = useState('');
  const [subSystemRailLineId, setSubSystemRailLineId] = useState('');

  const onSubSystemNameChange = e => {
    setSubSystemName(e.target.value);
  };
  const onSubSystemRailLineIdChange = e => {
    setSubSystemRailLineId(e.target.value);
  };
  const onSubmit = () => {
    onSubmitShare(subSystemName, subSystemRailLineId)
  }

  return (
    <Dialog
        className="search-notification"
        title={" "}
        // width={500}
        height={"100%"}
        onClose={onToggleDialog}
        >
        <div className="search-body">
            <div className="noti-dialog-title dialog-row">Search</div>
            <div className="dialog-row">
                <span className="search-row-title">Sub System</span>
                <Input className="search-input" onChange={onSubSystemNameChange}/>
            </div>

            <div className="dialog-row" style={{height:"80%"}}>
                <span className="search-row-title">Rail Line ID</span>
                <Input className="search-input" onChange={onSubSystemRailLineIdChange}/>
            </div>
        </div>
        <DialogActionsBar>
            <Button className="search-button cancel-btn" onClick={onToggleDialog}>Cancel</Button>
            <Button className="search-button search-btn" onClick={onSubmit}>Submit</Button>
        </DialogActionsBar>
    </Dialog>          
  );
};

export default DrSubSystemListSearchDialog;
