import { CaretLeft } from "@phosphor-icons/react";
import DatePicker from "react-datepicker";
import { Dialog } from "@progress/kendo-react-dialogs";

import { STEP_BUS_CHECKER_PERIODS } from "../../../../../store/feedback-on-bus/constant";
import { CustomDatePicker } from "../../../../../components/custom/datepicker";
import Icons, { IconSize } from "../../../../../components/icons";
import Button from "../../../../../components/button";
import { useDispatch } from "react-redux";
import { onChangeStep } from "../../../../../store/feedback-on-bus";
import { useAction } from "./action";
import { FIELDS } from "./constant";
import { useState } from "react";
import { Loader } from "@progress/kendo-react-indicators";
import {
  checkPermission,
  getUserPermission,
} from "../../../../../config/permission_helper";

const Component = () => {
  const [dialogMessage, setDialogMesasge] = useState({
    isOpen: false,
    message: "",
  });

  const [idUploadPartial, setUploadPartial] = useState(false);

  const onToggleDialog = () =>
    setDialogMesasge((prev) => ({
      isOpen: !prev.isOpen,
      msg: "",
    }));

  const hooksCallback = {
    onError: (msg) => setDialogMesasge({ isOpen: true, message: msg }),
    onSuccessUploadPartial: (msg, id) => {
      setUploadPartial(id);
      setDialogMesasge({ isOpen: true, message: msg });
    },
  };

  const {
    errors,
    isFetching,
    bsqcNamePeriod,
    file,
    filename,
    isInvalidPeriod,
    createdDateFrom,
    createdDateTo,
    onAddFile,
    onDeleteFile,
    onChangeDate,
    onChangeNamePeriod,
    onUpload,
    onNext,
    getErrorField,
  } = useAction(hooksCallback);

  const dispatch = useDispatch();

  const onBack = () => {
    dispatch(onChangeStep(STEP_BUS_CHECKER_PERIODS.SELECT_PERIOD));
  };

  return (
    <>
      {isFetching && (
        <Loader
          type="converging-spinner"
          style={{
            width: "100%",
            height: "calc(100vh - 110px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#ffffff4d",
            position: "absolute",
            zIndex: "999",
          }}
        />
      )}
      <div className="header">
        <div
          className="button-back cursor-pointer width-fit-content"
          onClick={onBack}
        >
          <CaretLeft weight="bold" size={20} color="#781d7e" />
          <div className="font-weight-600">Back</div>
        </div>
      </div>
      <div className="content-page">
        <div className="form-bsqc">
          <div className="form-date-select">
            <div className="input-group">
              <div className="input-date-label">
                <span>
                  <span style={{ fontWeight: "bold" }}>BSQC Check Period</span>
                  <span style={{ color: "red" }}>*</span>
                  {getErrorField(FIELDS.NAME) && (
                    <span
                      style={{ marginLeft: 20, color: "red", fontSize: 14 }}
                    >
                      {getErrorField(FIELDS.NAME)}
                    </span>
                  )}
                </span>
              </div>
              <input
                value={bsqcNamePeriod}
                onChange={onChangeNamePeriod}
                className="textfield"
                placeholder="Input BSQC check period"
              />
            </div>

            <div>
              <div className="input-date-label">
                <span>
                  <span style={{ fontWeight: "bold" }}>
                    Check Validity Period
                  </span>
                  <span style={{ color: "red" }}>*</span>
                  {isInvalidPeriod && (
                    <span
                      style={{ marginLeft: 20, color: "red", fontSize: 14 }}
                    >
                      This date range already added.
                    </span>
                  )}
                  {getErrorField(FIELDS.START_DATE) && (
                    <span
                      style={{ marginLeft: 20, color: "red", fontSize: 14 }}
                    >
                      {getErrorField(FIELDS.START_DATE)}
                    </span>
                  )}
                </span>
              </div>
              <div className="form-group-datepicker">
                <div className="input-group">
                  <DatePicker
                    className="filter-date-range"
                    format={"dd MMM yyyy"}
                    selected={createdDateFrom}
                    onChange={(date) => onChangeDate(date, "start")}
                    customInput={<CustomDatePicker iconColor={"#781D7E"} />}
                  />
                </div>

                <div className="input-group">
                  {/* <div className='input-date-label' style={{ color: 'transparent'}}>to</div> */}
                  <DatePicker
                    className="filter-date-range"
                    format={"dd MMM yyyy"}
                    selected={createdDateTo}
                    onChange={(date) => onChangeDate(date, "end")}
                    customInput={<CustomDatePicker iconColor={"#781D7E"} />}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="form-uploader">
            <div>
              <span>
                <span style={{ fontWeight: "bold" }}>Upload Excel File</span>
                <span style={{ color: "red" }}>*</span>
              </span>
              <a
                className="download_template_link"
                href="/BSQC_Template.xlsx"
                download
              >
                (Download BSQC Template)
              </a>
            </div>
            {getErrorField(FIELDS.FILE) && (
              <span style={{ color: "red", fontSize: 14 }}>
                {getErrorField(FIELDS.FILE)}
              </span>
            )}
            <div className="lisf-of-file">
              {file && filename && (
                <div className="card-item">
                  <div className="card-file-upload cursor-pointer">
                    <Icons
                      className="fa fa-file-lines"
                      style={{ color: "#73007D" }}
                    />
                    <span>{filename}</span>
                  </div>
                  <div className="card-action">
                    <Icons
                      onClick={onDeleteFile}
                      className="fa fa-trash fa-lg cursor-pointer"
                      size={IconSize.large}
                      style={{ color: "#73007D" }}
                    />
                  </div>
                </div>
              )}
              {!file && !filename && (
                <div className="card-item cursor-pointer position-relative">
                  <input
                    onChange={onAddFile}
                    className="input-file-csv"
                    type="file"
                    accept=".xlsx"
                  />
                  <Icons
                    className="fa fa-file-lines"
                    style={{ color: "#73007D" }}
                  />
                  <span>Choose File</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {checkPermission(getUserPermission(), "fob.bsqc_add_check_period") && (
        <div className="footer-page">
          <div className="wrapper">
            <Button
              onClick={onUpload}
              secondary
              disabled={isFetching}
              style={{ maxWidth: 140, paddingLeft: 20, paddingRight: 20 }}
            >
              {isFetching ? "Loading ..." : "Upload"}
            </Button>
          </div>
        </div>
      )}
      {dialogMessage.isOpen && (
        <Dialog
          className="alert-form"
          title={"Alert"}
          isOpen={dialogMessage.isOpen}
          width={400}
          onClose={onToggleDialog}
        >
          <p>{dialogMessage?.message}</p>
          {idUploadPartial && (
            <button
              className="button-add cursor-pointer"
              style={{ float: "right", minWidth: 100 }}
              onClick={() => onNext(idUploadPartial)}
            >
              Continue
            </button>
          )}
        </Dialog>
      )}
    </>
  );
};

export default Component;
