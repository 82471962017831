// redux
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionToken } from "../config/userPool";

const { USER_BY_PERMISSION_ENDPOINT } = config.api_endpoint;

const initialState = {
  loading: false,
  users_list: [],
  error: null,
};

export const fetchAllUsersList = createAsyncThunk(
    "usersList/fetchAllUsersList",
    async (_, { rejectWithValue }) => {
      try {
        const token = getCurrentLoggedInSessionToken();
        const data = {"permission": "ifeedback.limited_access_ifeedback_list"}
        const results = await api.post(USER_BY_PERMISSION_ENDPOINT,JSON.stringify(data), token);

        return results.data;
      } catch (error) {
        return rejectWithValue(error.details);
      }
    }
  );

const UsersListSlice = createSlice({
  name: "usersList",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUsersList.pending, (state) => {
        console.log("fetch all users list pending: ", state);
        state.loading = true;
      })
      .addCase(fetchAllUsersList.fulfilled, (state, action) => {
        state.users_list.push(action.payload);
        console.log('fetchAllUsersList fulfilled Action--',action);
        state.loading = false;
        state.users_list = action.payload;
        
      })
      .addCase(fetchAllUsersList.rejected, (state, action) => {
        console.log("fetch all users list rejected: ", state);
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

const usersListReducer = UsersListSlice.reducer;
export default usersListReducer;