import React, { useEffect, useRef, useState } from "react";
import "./articles.css";
import { TextArea, TextBox } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import DatePicker from "react-datepicker";
import { CustomDatePicker } from "../../../components/custom/datepicker";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "@progress/kendo-react-indicators";
import { Stepper } from "@progress/kendo-react-layout";
import { fetchCategoryListing } from "../../../store/admin_articles/manage_Category";
import { getCurrentLoggedInSessionTokenWithPromise } from "../../../config/userPool";
import config from "../../../config/config";
import { HAPPENINGS_ARTICLES_ROUTE } from "../../../routes";
import api from "../../../config/api";
import moment from "moment";
import {
  fetchArticlesCreateUpdate,
  fetchArticlesDetail,
  fetchArticlesUpdateStatus,
} from "../../../store/happenings/articles";
import { PermissionDeninedPage } from "../../../components/reusableItems/permission_denined";
import {
  checkPermission,
  getUserPermission,
} from "../../../config/permission_helper";
const steps = [
  {
    label: "Information",
    value: 0,
  },
  {
    label: "Review",
    value: 1,
  },
];
export const Article_Form = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const id = query.get("id");
  const duplicateID = query.get("duplicateID");
  const FileRef = useRef();
  const dispatch = useDispatch();
  const [mainLoading, setMainLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const [file, setFile] = useState("");
  const [imageLoading, setImageLoading] = useState(false);
  const [editData, setEditData] = useState({
    title_en: "",
    title_zh: "",
    category: {},
    blog_url: "",
    description_en: "",
    description_zh: "",
    image_url: "",
    publish_on: "",
    publish_end: "",
  });
  const [error, setError] = useState({
    title_en: "",
    category: "",
    publish_on: "",
    publish_end: "",
  });
  const { loading, listing_data } = useSelector((state) => state.categoryAdmin);
  const { HAPPENING_PROMOTION_UPLOAD_ENDPOINT } = config.api_endpoint;
  const userPermissions = getUserPermission();
  const AddPermssion = checkPermission(
    userPermissions,
    "happening.create_happening_article"
  );

  useEffect(() => {
    dispatch(fetchCategoryListing());
    if (id || duplicateID) {
      let findID = id ? id : duplicateID;
      setMainLoading(true);
      dispatch(fetchArticlesDetail(findID)).then((res) => {
        console.log(res);
        if (res.payload.status_code === 200) {
          setEditData({
            ...editData,
            title_en:
              res.payload.data && res.payload.data.title_en
                ? res.payload.data.title_en
                : "",
            title_zh:
              res.payload.data && res.payload.data.title_zh
                ? res.payload.data.title_zh
                : "",
            description_en:
              res.payload.data && res.payload.data.description_en
                ? res.payload.data.description_en
                : "",
            description_zh:
              res.payload.data && res.payload.data.description_zh
                ? res.payload.data.description_zh
                : "",
            category:
              res.payload.data && res.payload.data.category
                ? res.payload.data.category
                : {},
            blog_url:
              res.payload.data && res.payload.data.blog_url
                ? res.payload.data.blog_url
                : "",
            image_url:
              res.payload.data && res.payload.data.image_url
                ? res.payload.data.image_url
                : "",
            publish_on:
              res.payload.data && res.payload.data.publish_on
                ? moment(res.payload.data.publish_on.split("T")[0]).format(
                    "YYYY-MM-DD"
                  )
                : "",
            publish_end:
              res.payload.data && res.payload.data.publish_end
                ? moment(res.payload.data.publish_end.split("T")[0]).format(
                    "YYYY-MM-DD"
                  )
                : "",
          });
          setMainLoading(false);
        } else {
          setMainLoading(false);
          alert(res.payload.message);
        }
      });
    }
  }, []);

  const uploadFile = () => {
    FileRef.current.click();
  };

  const onUploadFile = async (e) => {
    setImageLoading(true);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    const extension_ =
      e.type === "" ? e.name.split(".")[1] : e.type.split("/")[1];
    api
      .post(
        HAPPENING_PROMOTION_UPLOAD_ENDPOINT,
        JSON.stringify({
          extensions: [extension_],
        }),
        token
      )
      .then((res) => {
        console.log(res);
        api.postDiff(res.data[0].presigned_url, e).then((res1) => {
          console.log(res1);
          if (res1 === 200) {
            setFile(e);
            setEditData({
              ...editData,
              image_url: res.data[0].key,
            });
            setImageLoading(false);
          } else {
            alert("An error occured while uploading file! Please try again!");
          }
        });
      });
  };

  const firstTabSubmit = () => {
    let error = null;
    if (
      new Date().setHours(0, 0, 0, 0) > new Date(editData.publish_on) ||
      new Date().setHours(0, 0, 0, 0) > new Date(editData.publish_end)
    ) {
      error = {
        ...error,
        publish_on: "Publish on date cannot be earlier than today date!",
        publish_end: "Publish End date cannot be earlier than today date!",
      };
    }
    if (editData.publish_on === "" || editData.publish_end === "") {
      error = {
        ...error,
        publish_on: "Publish on date field is required!",
        publish_end: "Publish End date field is required!",
      };
    } else if (new Date(editData.publish_on) > new Date(editData.publish_end)) {
      error = {
        ...error,
        publish_on:
          "Publish on date should not be later than Publish End date!",
        publish_end:
          "Publish End date should not be earlier than Publish on date!",
      };
    } else if (editData.publish_on === editData.publish_end) {
      error = {
        ...error,
        publish_on:
          "Publish on date and Publish End date should not be the same!",
        publish_end:
          "Publish on date and Publish End date should not be the same!",
      };
    }
    if (editData.title_en === "") {
      error = {
        ...error,
        title_en: "Title in English field is required!",
      };
    }
    if (editData.category.id === undefined) {
      error = {
        ...error,
        category: "Category field is required!",
      };
    }
    if (error !== null) {
      setError(error);
      return;
    }
    setError({
      ...error,
      title_en: "",
      category: "",
      publish_on: "",
      publish_end: "",
    });
    setTab(tab + 1);
  };

  const handleSubmit = (status) => {
    setMainLoading(true);
    let params = id
      ? {
          ...editData,
          id: id,
          image_url:
            file === ""
              ? editData.image_url.includes("http")
                ? `${editData.image_url.split("/")[3]}/${
                    editData.image_url.split("/")[4].split("?")[0]
                  }`
                : ""
              : editData.image_url,
        }
      : duplicateID
      ? {
          ...editData,
          image_url:
            file === ""
              ? editData.image_url.includes("http")
                ? `${editData.image_url.split("/")[3]}/${
                    editData.image_url.split("/")[4].split("?")[0]
                  }`
                : ""
              : editData.image_url,
        }
      : {
          ...editData,
        };
    dispatch(fetchArticlesCreateUpdate(params)).then((res) => {
      if (res.payload.status_code === 200) {
        if (id) {
          navigate(HAPPENINGS_ARTICLES_ROUTE);
        } else {
          if (status === "Published") {
            dispatch(
              fetchArticlesUpdateStatus({
                id: res.payload.data.id,
                status: status,
              })
            ).then((res1) => {
              if (res1.payload.status_code === 200) {
                navigate(HAPPENINGS_ARTICLES_ROUTE);
              } else {
                setMainLoading(false);
                alert(res.payload.message);
              }
            });
          } else {
            navigate(HAPPENINGS_ARTICLES_ROUTE);
          }
        }
      } else {
        setMainLoading(false);
        alert(res.payload.message);
      }
    });
  };

  const ImageHandler = () => {
    let url =
      id || duplicateID
        ? file === ""
          ? editData.image_url !== "" && editData.image_url
          : URL.createObjectURL(file)
        : file !== "" && URL.createObjectURL(file);
    let name =
      id || duplicateID
        ? file === ""
          ? editData.image_url !== "" &&
            editData.image_url.split("/")[4].split("?")[0]
          : file.name
        : file !== "" && file.name;
    return (
      <div className="Articles_create_file_main_box">
        <div className="Articles_create_file_child_box">
          <div>
            <img src={url} className="Articles_create_file_image" />
          </div>
          <div className="Articles_create_file_child_box_text">{name}</div>
        </div>
        <div>
          <span
            class="k-icon k-font-icon k-i-delete Articles_create_file_child_box_delete_icon"
            onClick={() => {
              setFile("");
              setEditData({
                ...editData,
                image_url: "",
              });
            }}
          />
        </div>
      </div>
    );
  };

  console.log(editData);

  return (
    <>
      {AddPermssion ? (
        <>
          {imageLoading && (
            <Loader
              type="converging-spinner"
              style={{
                width: "100%",
                height: "calc(100% - 76px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#ffffff4d",
                position: "absolute",
                zIndex: "999",
              }}
            />
          )}
          {loading || mainLoading ? (
            <Loader
              type="converging-spinner"
              style={{
                width: "100%",
                height: "calc(100% - 96px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#ffffff4d",
                position: "absolute",
                zIndex: "999",
              }}
            />
          ) : (
            <>
              <div
                className="Articles_create_header"
                //remove style if autosave needs to show
                style={{ minHeight: "49px" }}
              >
                <div
                  className="Articles_create_back"
                  onClick={() => navigate(HAPPENINGS_ARTICLES_ROUTE)}
                >
                  <span class="k-icon k-font-icon k-i-arrow-chevron-left" />
                  Back
                </div>
              </div>
              <div>
                <Stepper items={steps} value={tab} className="Articles_steps" />
              </div>
              {tab === 0 ? (
                <div className="Articles_create_scroll_box">
                  <div>
                    <div className="Articles_create_item_main_box">
                      <div className="Articles_create_item_main_box_title">
                        Title in English
                      </div>
                      <div>
                        <TextBox
                          className="Articles_create_item_main_box_field"
                          value={editData.title_en}
                          onChange={(e) =>
                            setEditData({
                              ...editData,
                              title_en: e.target.value,
                            })
                          }
                        />
                        {error.title_en !== "" && (
                          <div className="Articles_create_error_text">
                            {error.title_en}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="Articles_create_item_main_box">
                      <div className="Articles_create_item_main_box_title">
                        Title in Chinese
                      </div>
                      <div>
                        <TextBox
                          className="Articles_create_item_main_box_field"
                          value={editData.title_zh}
                          onChange={(e) =>
                            setEditData({
                              ...editData,
                              title_zh: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="Articles_create_item_main_box">
                      <div className="Articles_create_item_main_box_title">
                        Description in English
                      </div>
                      <div>
                        <TextArea
                          className="Articles_create_item_main_box_field"
                          style={{ minHeight: "150px" }}
                          value={editData.description_en}
                          onChange={(e) =>
                            setEditData({
                              ...editData,
                              description_en: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="Articles_create_item_main_box">
                      <div className="Articles_create_item_main_box_title">
                        Description in Chinese
                      </div>
                      <div>
                        <TextArea
                          className="Articles_create_item_main_box_field"
                          style={{ minHeight: "150px" }}
                          value={editData.description_zh}
                          onChange={(e) =>
                            setEditData({
                              ...editData,
                              description_zh: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="Articles_create_item_main_box">
                      <div className="Articles_create_item_main_box_title">
                        Category
                      </div>
                      <div className="Articles_create_dropdown">
                        <DropDownList
                          data={listing_data.length > 0 ? listing_data : []}
                          value={
                            listing_data.length > 0 &&
                            listing_data.find(
                              (i) => i.id === editData.category.id
                            )
                          }
                          dataItemKey="category"
                          textField="category"
                          size={"large"}
                          onChange={(e) =>
                            setEditData({
                              ...editData,
                              category: {
                                id: e.target.value.id,
                                name: e.target.value.category,
                              },
                            })
                          }
                          iconColor="#73007D"
                          iconClassName="k-icon k-font-icon k-i-arrow-chevron-down"
                        />
                        {error.category !== "" && (
                          <div className="Articles_create_error_text">
                            {error.category}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="Articles_create_item_main_box">
                      <div className="Articles_create_item_main_box_title">
                        Blog URL
                      </div>
                      <div>
                        <TextBox
                          className="Articles_create_item_main_box_field"
                          value={editData.blog_url}
                          placeholder="http://"
                          onChange={(e) =>
                            setEditData({
                              ...editData,
                              blog_url: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="Articles_create_item_main_box">
                      <div className="Articles_create_item_main_box_title">
                        Publish Rules
                      </div>
                      <div className="Articles_create_date_main_box">
                        <div className="Articles_create_date_child_box">
                          <div className="Articles_create_date_child_box_title">
                            Scheduled/Published On
                          </div>
                          <div className="Articles_create_date_child_box_field_box">
                            <div className="Articles_create_date_child_box_field_box_item">
                              <DatePicker
                                dateFormatCalendar="MMMM"
                                customInput={<CustomDatePicker />}
                                minDate={new Date()}
                                selected={
                                  editData.publish_on !== ""
                                    ? new Date(editData.publish_on)
                                    : ""
                                }
                                onChange={(e) =>
                                  setEditData({
                                    ...editData,
                                    publish_on: moment(e).format("YYYY-MM-DD"),
                                  })
                                }
                              />
                            </div>
                          </div>
                          {error.publish_on !== "" && (
                            <div className="Articles_create_error_text">
                              {error.publish_on}
                            </div>
                          )}
                        </div>
                        <div className="Articles_create_date_child_box">
                          <div className="Articles_create_date_child_box_title">
                            Scheduled/Published End
                            {/* <span
                      className="Apprewall_create_item_main_box_title_span Articles_clear_date"
                      onClick={() => {
                        setError({
                          ...error,
                          published_end: "",
                          published_on: "",
                        });
                        setEditData({
                          ...editData,
                          published_end: "",
                          published_on: "",
                        });
                      }}
                    >
                      Clear date
                    </span> */}
                          </div>
                          <div className="Articles_create_date_child_box_field_box">
                            <div className="Articles_create_date_child_box_field_box_item">
                              <DatePicker
                                dateFormatCalendar="MMMM"
                                customInput={<CustomDatePicker />}
                                minDate={new Date()}
                                selected={
                                  editData.publish_end !== ""
                                    ? new Date(editData.publish_end)
                                    : ""
                                }
                                onChange={(e) =>
                                  setEditData({
                                    ...editData,
                                    publish_end: moment(e).format("YYYY-MM-DD"),
                                  })
                                }
                              />
                            </div>
                          </div>
                          {error.publish_end !== "" && (
                            <div className="Articles_create_error_text">
                              {error.publish_end}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="Articles_create_item_main_box">
                      <div className="Articles_create_upload_main_box">
                        <div className="Articles_create_item_main_box_title">
                          Image Upload
                          <span className="Articles_create_item_main_box_title_span">
                            {/* (Max 1 file, no more than 5MB each) */}
                          </span>
                        </div>
                        <div className="Articles_create_upload_button_box">
                          <div onClick={uploadFile}>
                            <div>+ Upload Image</div>
                            <input
                              accept={[
                                "image/jpeg",
                                "image/png",
                                "image/jpg",
                                //   "application/pdf",
                                //   "application/msword",
                              ]}
                              ref={FileRef}
                              type="file"
                              hidden
                              onClick={(e) => (e.target.value = "")}
                              onChange={(e) => onUploadFile(e.target.files[0])}
                            />
                          </div>
                        </div>
                      </div>
                      {id || duplicateID
                        ? editData.image_url !== "" && <ImageHandler />
                        : file !== "" && <ImageHandler />}
                    </div>
                    {/* added */}
                  </div>
                </div>
              ) : (
                <div className="Articles_create_scroll_box_review">
                  <div
                    className="Articles_create_review_main_box"
                    style={{ minHeight: "55px" }}
                  >
                    <div className="Articles_create_review_left">
                      Title in English
                    </div>
                    <div className="Articles_create_review_middle" />
                    <div className="Articles_create_review_right Articles_create_review_flex">
                      {editData.title_en}
                    </div>
                  </div>
                  <div
                    className="Articles_create_review_main_box"
                    style={{ minHeight: "55px" }}
                  >
                    <div className="Articles_create_review_left">
                      Title in Chinese
                    </div>
                    <div className="Articles_create_review_middle" />
                    <div className="Articles_create_review_right Articles_create_review_flex">
                      {editData.title_zh}
                    </div>
                  </div>
                  <div
                    className="Articles_create_review_main_box"
                    style={{ minHeight: "55px" }}
                  >
                    <div className="Articles_create_review_left">
                      Description in English
                    </div>
                    <div className="Articles_create_review_middle" />
                    <div className="Articles_create_review_right Articles_create_review_flex">
                      {editData.description_en}
                    </div>
                  </div>
                  <div
                    className="Articles_create_review_main_box"
                    style={{ minHeight: "55px" }}
                  >
                    <div className="Articles_create_review_left">
                      Description in Chinese
                    </div>
                    <div className="Articles_create_review_middle" />
                    <div className="Articles_create_review_right Articles_create_review_flex">
                      {editData.description_zh}
                    </div>
                  </div>
                  <div
                    className="Articles_create_review_main_box"
                    style={{ minHeight: "55px" }}
                  >
                    <div className="Articles_create_review_left">Category</div>
                    <div className="Articles_create_review_middle" />
                    <div className="Articles_create_review_right Articles_create_review_flex">
                      {editData.category.name}
                    </div>
                  </div>
                  <div
                    className="Articles_create_review_main_box"
                    style={{ minHeight: "55px" }}
                  >
                    <div className="Articles_create_review_left">
                      Scheduled/Published On
                    </div>
                    <div className="Articles_create_review_middle" />
                    <div className="Articles_create_review_right Articles_create_review_flex">
                      {editData.publish_on !== ""
                        ? moment(editData.publish_on).format("DD MMM YYYY")
                        : "-"}
                    </div>
                  </div>
                  <div
                    className="Articles_create_review_main_box"
                    style={{ minHeight: "55px" }}
                  >
                    <div className="Articles_create_review_left">
                      Scheduled/Published End
                    </div>
                    <div className="Articles_create_review_middle" />
                    <div className="Articles_create_review_right Articles_create_review_flex">
                      {editData.publish_end !== ""
                        ? moment(editData.publish_end).format("DD MMM YYYY")
                        : "-"}
                    </div>
                  </div>
                  <div
                    className="Articles_create_review_main_box"
                    style={{ minHeight: "55px" }}
                  >
                    <div className="Articles_create_review_left">
                      Image Upload
                    </div>
                    <div className="Articles_create_review_middle" />
                    <div className="Articles_create_review_right Articles_create_review_flex">
                      <img
                        style={{ maxWidth: "170px", height: "120px" }}
                        src={
                          id
                            ? file === ""
                              ? editData.image_url !== "" && editData.image_url
                              : URL.createObjectURL(file)
                            : file !== "" && URL.createObjectURL(file)
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
              {tab === 0 ? (
                <div className="Articles_create_footer_main_box">
                  {/* <div className="Articles_create_footer_button">Save</div> */}
                  <div
                    className="Articles_create_footer_button"
                    style={{ marginLeft: "auto" }}
                    onClick={(e) => firstTabSubmit()}
                  >
                    Next &#x2192;
                  </div>
                </div>
              ) : (
                <div className="Articles_create_footer_main_box">
                  <div className="Articles_create_review_main_box">
                    <div
                      className="Articles_create_footer_button_previous"
                      onClick={() => setTab(tab - 1)}
                    >
                      Previous
                    </div>
                    <div
                      className="Articles_create_footer_button"
                      onClick={
                        () => handleSubmit("Submitted")
                        // handleSubmit(id ? detail_data.status : "Submitted")
                      }
                    >
                      Save
                    </div>
                  </div>
                  {id ? (
                    ""
                  ) : (
                    <div
                      className="Articles_create_footer_button_publish"
                      onClick={() => handleSubmit("Published")}
                    >
                      Publish
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <PermissionDeninedPage
          height={"calc(100% - 76px)"}
          title={"Sorry, You don't have permission to access this page!"}
        />
      )}
    </>
  );
};
