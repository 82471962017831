import React, { useState, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import BusRouteIcon from "../../assets/communication/bus_route.svg";
import RosterIcon from "../../assets/communication/roster.svg";
import OperationIcon from "../../assets/communication/operation.svg";
import "./notification.css";

import { Loader } from "@progress/kendo-react-indicators";
import { RadioButton } from "@progress/kendo-react-inputs";
// import { DatePicker } from "@progress/kendo-react-dateinputs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CustomDatePicker } from "../custom/datepicker";

import moment from "moment/moment";

const all_or_date_range = [{ value: "all", label: "All dates" }, { value: "date", label: "Date range" }]
const filter_status = [
    { value: "published", label: "Published" },
    { value: "scheduled", label: "Scheduled" },
    { value: "draft", label: "Draft" },
    { value: "pendingapproval", label: "Pending Approval" }
]
const acknowledge_status = [{ value: "required", label: "Yes" }, { value: "not_required", label: "No" }]

export const FilterCategory = (props) => {
    const dispatch = useDispatch();
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const [selectedack, setSelectedAck] = useState([]);
    const [selectedPushSilently, setSelectedPushSilently] = useState([]);

    useEffect(() => {
        if (query.toString()) {
            if (query.get("ack_required")) {
                const ack_required = query.get("ack_required");
                console.log(ack_required);
                setSelectedAck(ack_required === "True" ? ["required"] : ["not_required"])
            }
            else if (query.get("is_all_ack")) {
                setSelectedAck(acknowledge_status.map((v)=> v.value))
            }
            if (query.get("push_silently")) {
                const push_silently = query.get("push_silently");
                console.log(push_silently);
                setSelectedPushSilently(push_silently === "True" ? ["required"] : ["not_required"])
            }
            else if (query.get("is_all_pushsilently")) {
                setSelectedPushSilently(acknowledge_status.map((v)=> v.value))
            }            
        }
    }, []);
    const handleclearfilter = () => {
        setSelectedAck([]);
        setSelectedPushSilently([]);
    }
    const onSubmitFilter = () => {
        let data = {
            ack_required: selectedack.length === acknowledge_status.length || selectedack.length === 0 ? "": selectedack.includes("required")? "True": "False", 
            is_all_ack: selectedack.length === acknowledge_status.length? true: "",
            push_silently: selectedPushSilently.length === acknowledge_status.length || selectedPushSilently.length === 0 ? "": selectedPushSilently.includes("required")? "True": "False", 
            is_all_pushsilently: selectedPushSilently.length === acknowledge_status.length? true: "",
        }
        console.log(data);
        props.onFiltercallback(data)
    }
    const handleAckCheck = (e, value) => {
        var updatedList = [...selectedack];
        if (e.target.value) {
            updatedList = [...selectedack, value];
        } else {
            var selected_index = selectedack.indexOf(value);
            updatedList.splice(selected_index, 1);
        }
        setSelectedAck(updatedList);
    };
    const isAckChecked = (item) =>
        selectedack.includes(item) ? true : false;
    const handlePushSilentlyChecked = (e, value) => {
        var updatedList = [...selectedPushSilently];
        if (e.target.value) {
            updatedList = [...selectedPushSilently, value];
        } else {
            var selected_index = selectedPushSilently.indexOf(value);
            updatedList.splice(selected_index, 1);
        }
        setSelectedPushSilently(updatedList);
    };
    const isPushSilentlyChecked = (item) =>
        selectedPushSilently.includes(item) ? true : false;  

    return (
        <>
            <Dialog
                className="search-notification"
                title={" "}
                width={395}
                height={"100%"}
                onClose={() => props.setShowFilterDialog(false)}
            >
                <div className="search-body">
                    <div className="filter-title">
                        <div className="title">Filter</div>
                        <div className="clear-filter" onClick={handleclearfilter}><u>Clear Filter</u></div>
                    </div>
                    <div className="filter-body">
                        <p className="filter-sub-title">Acknowledgement Required</p>
                        <div className="category-flex-row">
                            {acknowledge_status.map((v) => (
                                <div className="date-option-div">
                                    <Checkbox
                                        style={{ border: "1px solid #CCC" }}
                                        key={v.value}
                                        value={v.value}
                                        onChange={(e) => handleAckCheck(e, v.value)}
                                        checked={isAckChecked(v.value)}
                                    />
                                    <label>{v.label}</label>
                                </div>
                            ))}
                        </div>
                        <p className="filter-sub-title">Push Silently</p>
                        <div className="category-flex-row">
                            {acknowledge_status.map((v) => (
                                <div className="date-option-div">
                                    <Checkbox
                                        style={{ border: "1px solid #CCC" }}
                                        key={v.value}
                                        value={v.value}
                                        onChange={(e) => handlePushSilentlyChecked(e, v.value)}
                                        checked={isPushSilentlyChecked(v.value)}
                                    />
                                    <label>{v.label}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <DialogActionsBar>
                    <Button className="search-button cancel-btn" onClick={() => props.setShowFilterDialog(false)}>Cancel</Button>
                    <Button className="search-button search-btn" onClick={() => onSubmitFilter()}>Submit</Button>
                </DialogActionsBar>
            </Dialog>
        </>

    );
};