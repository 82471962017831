import DatePicker from "react-datepicker";
import cn from "classnames";
import { Button } from "@progress/kendo-react-buttons";
import SelectOption from "../../components/select-options";
import { Loader } from "@progress/kendo-react-indicators";
import RefreshIcon from "../../../../../assets/header/refresh.svg";
import ExportIcon from "../../../../../assets/header/export.svg";
import { CustomDatePicker } from "../../../../../components/custom/datepicker";
import Table from "./table";
import { useAction, useDeleteItem } from "./action";
import { useMemo, useState } from "react";

import "./list-of-checker.css";
import { getColumn } from "./constant";
import { Confirmation } from "../../../../../components/dialog/confirmation";
import { useModal } from "../../../../../libs/hooks/use-modal";

import AddEditBSQCDetails from "./partials/add-edit-bsqc-details";
import Publisher from "./partials/publisher";
import DeleteConfirm from "./partials/delete-confirm";
import ReuploadFile from "./partials/reupload-bsqc";
import { Dialog } from "@progress/kendo-react-dialogs";
import {
  checkPermission,
  getUserPermission,
} from "../../../../../config/permission_helper";

const Component = () => {
  const { visible, onToggle } = useModal();
  const { visible: visibleConfirmDelete, onToggle: onToggleConfirmDelete } =
    useModal();
  const { visible: visiblePublish, onToggle: onTogglePublish } = useModal();
  const { visible: isSuccessPublish, onToggle: onToggleSuccessPublish } =
    useModal();
  const { visible: visibleReupload, onToggle: onToggleReupload } = useModal();
  const { visible: visibleUnpublish, onToggle: onToggleUnpublish } = useModal();
  const [messageModal, setMessageModal] = useState({
    msg: "",
    status: "",
  });

  const {
    isOpen,
    errors,
    isSuccess: isSuccessDelete,
    onToggle: onToggleDeleteItem,
    onToggleSuccess,
    onToggleErrorPopup,
    onSubmit,
    onOpen,
  } = useDeleteItem();

  const {
    hasPublish,
    hasApproved,
    textForm,
    bsqcPeriod,
    selectedPeriod,
    formMode,
    createdDateFrom,
    createdDateTo,
    list,
    initForm,
    isFetching,
    isFetchDelete,
    isFetchUnpublish,
    onChangeDate,
    onSetMode,
    onAdd,
    onUpdatePartial,
    onClearMode,
    onSelectPeriod,
    onDeleteBSQCPeriod,
    onRefresh,
    onExport,
    onUnpublish,
    refetchDetailChecker,
  } = useAction();

  const columns = useMemo(() => {
    const newColumn = getColumn(textForm?.am_text, textForm?.pm_text) ?? [];

    if (!(hasApproved || hasPublish))
      newColumn.push({
        Header: " ",
        columns: [
          {
            Header: "Action",
            accessor: "action",
          },
        ],
      });

    return newColumn;
  }, [textForm, hasApproved, hasPublish]);

  const onAddNew = () => {
    onSetMode("add", {});
    onToggle();
  };

  const buttonDeleteProps = useMemo(
    () => {
      const buttonClass = cn("check-period-options", {
        no_margin: hasApproved || hasPublish,
      });

      return {
        className: buttonClass,
        title: selectedPeriod?.label,
        select: selectedPeriod,
        options: bsqcPeriod,
        onSelect: ({ pk }) => onSelectPeriod(pk),
        ...(hasApproved || hasPublish
          ? {}
          : checkPermission(getUserPermission(), "fob.bsqc_delete_check_period")
          ? {
              actionName: "Delete",
              onAction: () => onToggleConfirmDelete(),
            }
          : {}),
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bsqcPeriod, hasApproved, selectedPeriod, hasPublish]
  );

  return (
    <>
      {isFetchDelete && (
        <Loader
          type="converging-spinner"
          style={{
            width: "100%",
            height: "calc(100vh - 110px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#ffffff4d",
            position: "absolute",
            zIndex: "999",
          }}
        />
      )}
      <div className="list-of-checker-container">
        <div className="list-of-checker-headers">
          <h2>Feedback on Bus Service</h2>
          <div className="filter-group">
            {checkPermission(
              getUserPermission(),
              "fob.bsqc_publish_check_period"
            ) && (
              <Button
                onClick={() =>
                  !(hasApproved || hasPublish)
                    ? onTogglePublish()
                    : onToggleUnpublish()
                }
                className="custom-button"
              >
                {hasApproved || hasPublish ? "Unpublish" : "Publish"}
              </Button>
            )}
            {!(hasApproved || hasPublish) && (
              <>
                {checkPermission(
                  getUserPermission(),
                  "fob.bsqc_reupload_check_period"
                ) && (
                  <Button onClick={onToggleReupload} className="custom-button">
                    Re-Upload
                  </Button>
                )}
                {checkPermission(
                  getUserPermission(),
                  "fob.bsqc_add_edit_services"
                ) && (
                  <Button onClick={onAddNew} className="custom-button">
                    + Add
                  </Button>
                )}
              </>
            )}
            <Button onClick={refetchDetailChecker} className="custom-button">
              <img
                style={{ width: 20, height: 20 }}
                src={RefreshIcon}
                alt="refresh-icon"
              />
            </Button>
            {checkPermission(
              getUserPermission(),
              "fob.bsqc_export_check_period"
            ) && (
              <Button onClick={onExport} className="custom-button">
                <img
                  style={{ width: 20, height: 20 }}
                  src={ExportIcon}
                  alt="export-icon"
                />
              </Button>
            )}
          </div>
        </div>
        <div className="form-date-select" style={{ marginTop: 40 }}>
          <div className="input-group">
            <div className="input-date-label">
              <span>
                <span style={{ fontWeight: "bold" }}>BSQC Check Period</span>
                <span style={{ color: "red" }}>*</span>
              </span>
            </div>
            <SelectOption {...buttonDeleteProps} />
          </div>
          <div>
            <div className="input-date-label">
              <span>
                <span style={{ fontWeight: "bold" }}>
                  Check Validity Period
                </span>
                <span style={{ color: "red" }}>*</span>
              </span>
            </div>
            <div className="form-group-datepicker">
              <div className="input-group">
                <DatePicker
                  disabled={isFetching}
                  className="filter-date-range"
                  dateFormat={"dd/MM/yyyy"}
                  selected={createdDateFrom}
                  onChange={(date) => onChangeDate(date, "start")}
                  customInput={<CustomDatePicker iconColor={"#781D7E"} />}
                />
              </div>

              <div className="input-group">
                <DatePicker
                  disabled={isFetching}
                  className="filter-date-range"
                  dateFormat={"dd/MM/yyyy"}
                  selected={createdDateTo}
                  onChange={(date) => onChangeDate(date, "end")}
                  customInput={<CustomDatePicker iconColor={"#781D7E"} />}
                />
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginTop: 20 }}>
          {isFetching ? (
            <div style={{ width: "100%" }}>
              <Loader
                type="infinite-spinner"
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "40px",
                  margin: "auto",
                  justifyContent: "center",
                  height: "100vh",
                }}
              />
            </div>
          ) : (
            <Table
              columns={columns}
              data={list}
              actions={[
                {
                  permission: "fob.bsqc_add_edit_services",
                  label: "Edit",
                  id: "edit",
                },
                {
                  permission: "fob.bsqc_delete_services",
                  label: "Delete",
                  id: "delete",
                },
              ]}
              onAction={(data, mode) => {
                if (mode === "edit") {
                  onSetMode("edit", data);
                  onToggle();
                }

                if (mode === "delete") {
                  onOpen(data);
                  onToggleDeleteItem();
                }
              }}
            />
          )}
        </div>

        <AddEditBSQCDetails
          id={selectedPeriod?.id}
          initForm={initForm}
          formMode={formMode}
          isOpen={visible}
          textForm={textForm}
          refresh={onRefresh}
          onClose={() => {
            onToggle();
            onClearMode();
          }}
          onSubmit={(data) => {
            const handler = formMode === "edit" ? onUpdatePartial : onAdd;

            handler(data);
            onToggle();
            onClearMode();
          }}
          onError={(msg) => {
            setMessageModal({ msg, status: "errors" });
            onToggleSuccessPublish();
          }}
        />

        <Publisher
          listCheckPeriod={bsqcPeriod}
          selectedPeriod={selectedPeriod}
          isOpen={visiblePublish}
          onClose={onTogglePublish}
          onSubmit={() => {
            onTogglePublish();

            setMessageModal({
              msg: "Check period successfully published.",
              status: "success",
            });
            onToggleSuccessPublish();
          }}
          onSuccess={() => {
            onRefresh();
          }}
          onError={(msg) => {
            setMessageModal({ msg, status: "errors" });
            onToggleSuccessPublish();
          }}
        />

        <DeleteConfirm
          loading={isFetchDelete}
          isOpen={visibleConfirmDelete}
          onConfirm={() => {
            onDeleteBSQCPeriod(() => {
              onToggleConfirmDelete();
            });
          }}
          onClose={onToggleConfirmDelete}
        />

        <DeleteConfirm
          loading={isFetchDelete}
          isOpen={isOpen}
          message="Want to delete this Item?"
          onConfirm={() => {
            onSubmit(() => {
              onToggleDeleteItem();
              refetchDetailChecker();
            });
          }}
          onClose={onToggleDeleteItem}
        />

        {visibleUnpublish && (
          <Confirmation
            title={"Unpublish Check Period"}
            message="Are you sure you want to Unpublish this check period?"
            onClickNo={onToggleUnpublish}
            onClickYes={() => {
              onUnpublish(() => {
                onToggleUnpublish();
              });
            }}
          />
        )}

        <ReuploadFile
          isOpen={visibleReupload}
          onClose={onToggleReupload}
          onSuccess={() => {
            onToggleReupload();

            setMessageModal({
              msg: "Re-upload Successfully.",
              status: "success",
            });
            onToggleSuccessPublish();

            onRefresh();
          }}
          onError={(msg) => {
            setMessageModal({ msg, status: "errors" });
            onToggleSuccessPublish();
          }}
        />

        {isSuccessPublish && (
          <Dialog
            className="alert-form"
            title={"Alert"}
            isOpen={isSuccessPublish}
            width={400}
            // height={280}
            onClose={() => {
              onToggleSuccessPublish();
              setMessageModal({ msg: "", status: "" });
            }}
          >
            <p>{messageModal?.msg}</p>
          </Dialog>
        )}

        {isSuccessDelete && (
          <Dialog
            className="alert-form"
            title={"Alert"}
            isOpen={isSuccessDelete}
            width={400}
            onClose={onToggleSuccess}
          >
            <p>Success Delete Item</p>
          </Dialog>
        )}

        {errors && (
          <Dialog
            className="alert-form"
            title={"Alert"}
            isOpen={!!errors}
            width={400}
            onClose={onToggleErrorPopup}
          >
            <p>{errors}</p>
          </Dialog>
        )}
      </div>
    </>
  );
};

export default Component;
