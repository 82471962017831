import React, { createRef, useEffect, useRef, useState } from "react";
import { Stepper } from "@progress/kendo-react-layout";
import { TextBox } from "@progress/kendo-react-inputs";
import { Editor, EditorTools, EditorUtils } from "@progress/kendo-react-editor";
import "./aprewall.css";
import DatePicker from "react-datepicker";
import { TimePicker } from "@progress/kendo-react-dateinputs";
import { CustomDatePicker } from "../../../components/custom/datepicker";
import { useLocation, useNavigate } from "react-router";
import { CARES_APPRECIATION_WALLS_LISTING } from "../../../routes";
import moment from "moment";
import { getCurrentLoggedInSessionTokenWithPromise } from "../../../config/userPool";
import api from "../../../config/api";
import { Loader } from "@progress/kendo-react-indicators";
import config from "../../../config/config";
import { useDispatch, useSelector } from "react-redux";
import { fetchApreDetail } from "../../../store/aprewall/aprewall";
import { PermissionDeninedPage } from "../../../components/reusableItems/permission_denined";
import {
  checkPermission,
  getUserPermission,
} from "../../../config/permission_helper";

const steps = [
  {
    label: "Information",
    value: 0,
  },
  {
    label: "Review",
    value: 1,
  },
];

export const Aprewall_create = () => {
  const dispatch = useDispatch();
  const { loading, detail_data } = useSelector((state) => state.aprewall);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const id = query.get("id");
  const minMinute = new Date(new Date().getTime() + 15 * 60000);
  const navigate = useNavigate();
  const { CARES_APREWALL_CREATE_UPDATE, CARES_APREWALL_UPDATE_STATUS } =
    config.api_endpoint;
  const userPermissions = getUserPermission();
  const AddPermssion = checkPermission(
    userPermissions,
    "cares.add_cares_appwall"
  );
  const chinese_editor = createRef();
  const FileRef = useRef();
  const {
    Bold,
    Italic,
    Underline,
    AlignCenter,
    AlignRight,
    AlignJustify,
    OrderedList,
    UnorderedList,
  } = EditorTools;
  const [file, setFile] = useState("");
  const [tab, setTab] = useState(0);
  const [loading1, setLoading] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [editData, setEditData] = useState({
    title_en: "",
    title_zh: "",
    message_en: "",
    message_zh: "",
    from_date: "",
    from_time: "",
    to_date: "",
    to_time: "",
    image_url: "",
  });
  const [showError, setShowError] = useState({
    title_en: "",
    title_zh: "",
    from_date: "",
    to_date: "",
  });

  const setData = (data) => {
    setEditData({
      ...editData,
      title_en: data.title_en,
      title_zh: data.title_zh,
      message_en: data.message_en,
      message_zh: data.message_zh,
      from_date: data.from_date,
      from_time: data.from_time,
      to_date: data.to_date,
      to_time: data.to_time,
      image_url: data.image_url,
    });
    setLoadingDetail(false);
  };

  useEffect(() => {
    if (id) {
      setLoadingDetail(true);
      dispatch(fetchApreDetail(id)).then(async (res) => {
        if (res.payload.status_code === 200) {
          await setData(res.payload.data);
        } else {
          alert(res.payload.message);
        }
      });
    }
  }, [id]);

  const uploadFile = () => {
    FileRef.current.click();
  };
  const onUploadFile = async (e) => {
    // if (e.size / 1024 > 5120) {
    //   alert("File size too large");
    // } else {
    setLoading(true);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    console.log(e);
    const extension_ =
      e.type === "" ? e.name.split(".")[1] : e.type.split("/")[1];
    api
      .post(
        "/api-cares/get-presigned-url",
        JSON.stringify({
          extension: extension_,
        }),
        token
      )
      .then((res) => {
        console.log(res);
        api.postDiff(res.data.presigned_url, e).then((res1) => {
          console.log(res1);
          if (res1 === 200) {
            setFile(e);
            setEditData({
              ...editData,
              image_url: res.data.key,
            });
            setLoading(false);
          } else {
            alert("An error occured while uploading file! Please try again!");
          }
        });
      });
    // }
  };
  const getHtml = () => {
    if (chinese_editor) {
      const view = chinese_editor.current.view;
      if (view) {
        return EditorUtils.getHtml(view.state);
      }
    }
  };
  const dateFormatChanger = (input) => {
    return `${input.split("-")[2]}-${input.split("-")[1]}-${
      input.split("-")[0]
    }`;
  };
  const timeFormatChanger = (input, bol) => {
    console.log(input);
    if (input === "") return "";
    let matches = input.toLowerCase().match(/(\d{1,2}):(\d{2}) ([ap]m)/);
    let decide24hr =
      matches[3] === "pm"
        ? parseInt(matches[1]) === 12
          ? "12"
          : `${parseInt(matches[1]) + 12}`
        : parseInt(matches[1]) === 12
        ? "00"
        : matches[1];
    let output = decide24hr + ":" + matches[2] + ":00";
    console.log(output);
    if (bol) return output;
    else return new Date(`01/01/1970 ${output}`);
  };
  const firstTabSubmit = () => {
    let StartDateVali = new Date(
      `${dateFormatChanger(editData.from_date)} ${timeFormatChanger(
        editData.from_time,
        true
      )}`
    );
    let endDateVali = new Date(
      `${dateFormatChanger(editData.to_date)} ${timeFormatChanger(
        editData.to_time,
        true
      )}`
    );
    let error = null;

    if (isNaN(StartDateVali) || isNaN(endDateVali)) {
      error = {
        ...error,
        from_date: "Start date/time fields are required!",
        to_date: "Start date/time fields are required!",
      };
    } else if (StartDateVali > endDateVali) {
      error = {
        ...error,
        from_date: "Start date/time should not be later than End date/time!",
        to_date: "End date/time should not be earlier than Start date/time!",
      };
    } else if (StartDateVali.getTime() === endDateVali.getTime()) {
      error = {
        ...error,
        from_date: "Start date/time and End date/time should not be the same!",
        to_date: "Start date/time and End date/time should not be the same!",
      };
    }
    if (editData.title_en === "") {
      error = {
        ...error,
        title_en: "Title in English field is required!",
      };
    }
    if (editData.title_zh === "") {
      error = {
        ...error,
        title_zh: "Title in Chinese field is required!",
      };
    }

    if (error !== null) {
      setShowError(error);
      return;
    }
    setTab(tab + 1);
    setEditData({
      ...editData,
      message_zh: getHtml(),
    });
  };

  const handleSubmit = async (type) => {
    console.log(editData);
    const params = id
      ? {
          ...editData,
          id: id,
          image_url:
            file === ""
              ? editData.image_url.includes("http")
                ? `${editData.image_url.split("/")[3]}/${
                    editData.image_url.split("/")[4].split("?")[0]
                  }`
                : ""
              : editData.image_url,
        }
      : {
          ...editData,
        };
    setLoading(true);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    api
      .post(CARES_APREWALL_CREATE_UPDATE, JSON.stringify(params), token)
      .then((res) => {
        if (res.status === "success") {
          if (type === "Published") {
            api
              .post(
                CARES_APREWALL_UPDATE_STATUS,
                JSON.stringify({ id: res.data.id, status: type }),
                token
              )
              .then((res1) => {
                if (res1.status === "success") {
                  setLoading(false);
                  navigate(CARES_APPRECIATION_WALLS_LISTING);
                } else {
                  alert(res1.message);
                }
              });
          } else {
            setLoading(false);
            navigate(CARES_APPRECIATION_WALLS_LISTING);
          }
        } else {
          alert(res.message);
        }
      });
  };

  const ImageHandler = () => {
    let url = id
      ? file === ""
        ? editData.image_url !== "" && editData.image_url
        : URL.createObjectURL(file)
      : file !== "" && URL.createObjectURL(file);
    let name = id
      ? file === ""
        ? editData.image_url !== "" &&
          editData.image_url.split("/")[4].split("?")[0]
        : file.name
      : file !== "" && file.name;
    return (
      <div className="Aprewall_create_file_main_box">
        <div className="Aprewall_create_file_child_box">
          <div>
            <img src={url} className="Aprewall_create_file_image" />
          </div>
          <div className="Aprewall_create_file_child_box_text">{name}</div>
        </div>
        <div>
          <span
            class="k-icon k-font-icon k-i-delete Aprewall_create_file_child_box_delete_icon"
            onClick={() => {
              setFile("");
              setEditData({
                ...editData,
                image_url: "",
              });
            }}
          />
        </div>
      </div>
    );
  };
  console.log(editData);
  return (
    <>
      {AddPermssion ? (
        <div>
          {loading1 && (
            <Loader
              type="converging-spinner"
              style={{
                width: "100%",
                height: "calc(100% - 96px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#ffffff4d",
                position: "absolute",
                zIndex: "999",
              }}
            />
          )}
          {loadingDetail ? (
            <Loader
              type="converging-spinner"
              style={{
                width: "100%",
                height: "calc(100% - 96px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#ffffff4d",
                position: "absolute",
                zIndex: "999",
              }}
            />
          ) : (
            <>
              <div
                className="Aprewall_create_header"
                //remove style if autosave needs to show
                style={{ minHeight: "50px" }}
              >
                <div
                  className="Apprewall_create_back"
                  onClick={() => navigate(CARES_APPRECIATION_WALLS_LISTING)}
                >
                  <span class="k-icon k-font-icon k-i-arrow-chevron-left" />
                  Back
                </div>
                {/* <div className="Apprewall_create_autosaved_icon">
          <span class="k-icon k-font-icon k-i-check" />
          Auto Saved
        </div> */}
              </div>
              <div>
                <Stepper
                  items={steps}
                  value={tab}
                  className="Apprewall_steps"
                  // onChange={(e) => setTab(e.value)}
                />
              </div>
              {tab === 0 ? (
                <div className="Aprewall_create_scroll_box">
                  <div>
                    <div className="Apprewall_create_item_main_box">
                      <div className="Apprewall_create_item_main_box_title">
                        Title in English
                      </div>
                      <div>
                        <TextBox
                          className="Apprewall_create_item_main_box_field"
                          value={editData.title_en}
                          onChange={(e) =>
                            setEditData({
                              ...editData,
                              title_en: e.target.value,
                            })
                          }
                        />
                        {showError.title_en !== "" && (
                          <div className="Aprewall_create_error_text">
                            {showError.title_en}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="Apprewall_create_item_main_box">
                      <div className="Apprewall_create_item_main_box_title">
                        Title in Chinese
                      </div>
                      <div>
                        <TextBox
                          className="Apprewall_create_item_main_box_field"
                          value={editData.title_zh}
                          onChange={(e) =>
                            setEditData({
                              ...editData,
                              title_zh: e.target.value,
                            })
                          }
                        />
                        {showError.title_zh !== "" && (
                          <div className="Aprewall_create_error_text">
                            {showError.title_zh}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="Apprewall_create_item_main_box">
                      <div className="Apprewall_create_item_main_box_title">
                        Message in English
                      </div>
                      <div>
                        <Editor
                          tools={[
                            [
                              Bold,
                              Italic,
                              Underline,
                              AlignCenter,
                              AlignJustify,
                              AlignRight,
                              OrderedList,
                              UnorderedList,
                            ],
                          ]}
                          className="Apprewall_create_item_main_box_field"
                          //   defaultContent={message_en}
                          value={editData.message_en}
                          onChange={(e) =>
                            setEditData({
                              ...editData,
                              message_en: e.html,
                            })
                          }
                          //   onChange={(e) => {
                          //     setMessage_en(e.html);
                          //     setDataToRef("message_en", e.html);
                          //   }}
                        />
                      </div>
                    </div>
                    <div className="Apprewall_create_item_main_box">
                      <div className="Apprewall_create_item_main_box_title">
                        Message in Chinese
                      </div>
                      <div>
                        <Editor
                          tools={[
                            [
                              Bold,
                              Italic,
                              Underline,
                              AlignCenter,
                              AlignJustify,
                              AlignRight,
                              OrderedList,
                              UnorderedList,
                            ],
                          ]}
                          id="Chinese_textbox"
                          className="Apprewall_create_item_main_box_field"
                          defaultContent={editData.message_zh}
                          ref={chinese_editor}
                          // value={editData.message_zh}
                          // onChange={(e) => {
                          //   console.log(getHtml());
                          //   // setMessage_zh(getHtml());
                          //   // setDataToRef("message_zh", getHtml());
                          // }}
                        />
                      </div>
                    </div>
                    <div className="Apprewall_create_item_main_box">
                      <div className="Apprewall_create_item_main_box_title">
                        Publish Rules{" "}
                        <span className="Apprewall_create_item_main_box_title_span">
                          (applies to messages in all channels except Inbox)
                        </span>
                      </div>
                      <div className="Aprewall_create_date_main_box">
                        <div className="Aprewall_create_date_child_box">
                          <div className="Aprewall_create_date_child_box_title">
                            Start
                          </div>
                          <div className="Aprewall_create_date_child_box_field_box">
                            <div className="Aprewall_create_date_child_box_field_box_item">
                              <DatePicker
                                dateFormatCalendar="MMMM"
                                customInput={<CustomDatePicker />}
                                minDate={new Date()}
                                selected={
                                  editData.from_date !== ""
                                    ? new Date(
                                        dateFormatChanger(editData.from_date)
                                      )
                                    : ""
                                }
                                onChange={(e) =>
                                  setEditData({
                                    ...editData,
                                    from_date: moment(e).format("DD-MM-YYYY"),
                                  })
                                }
                              />
                            </div>
                            <div className="Aprewall_create_date_child_box_field_box_item">
                              <TimePicker
                                className="info-datetime-picker keyboardInputDisable"
                                // defaultValue={new Date()}
                                placeholder={""}
                                onChange={(e) =>
                                  setEditData({
                                    ...editData,
                                    from_time: moment(e.target.value).format(
                                      "hh:mm A"
                                    ),
                                  })
                                }
                                min={
                                  editData.from_date === ""
                                    ? minMinute
                                    : moment(
                                        new Date(
                                          dateFormatChanger(editData.from_date)
                                        )
                                      ).format("DD-MM-YYYY") ===
                                      moment(new Date()).format("DD-MM-YYYY")
                                    ? minMinute
                                    : new Date("11-11-2023")
                                  // : new Date(new Date().getTime() + 3 * 24 * 3600000)
                                }
                                value={timeFormatChanger(editData.from_time)}
                                steps={{
                                  hour: 1,
                                  minute: 15,
                                  // second: 30,
                                }}
                              />
                            </div>
                          </div>
                          {showError.from_date !== "" && (
                            <div className="Aprewall_create_error_text">
                              {showError.from_date}
                            </div>
                          )}
                        </div>
                        <div className="Aprewall_create_date_child_box">
                          <div className="Aprewall_create_date_child_box_title">
                            End
                          </div>
                          <div className="Aprewall_create_date_child_box_field_box">
                            <div className="Aprewall_create_date_child_box_field_box_item">
                              <DatePicker
                                dateFormatCalendar="MMMM"
                                customInput={<CustomDatePicker />}
                                minDate={new Date()}
                                selected={
                                  editData.to_date !== ""
                                    ? new Date(
                                        dateFormatChanger(editData.to_date)
                                      )
                                    : ""
                                }
                                onChange={(e) =>
                                  setEditData({
                                    ...editData,
                                    to_date: moment(e).format("DD-MM-YYYY"),
                                  })
                                }
                              />
                            </div>
                            <div className="Aprewall_create_date_child_box_field_box_item">
                              <TimePicker
                                className="info-datetime-picker keyboardInputDisable"
                                // defaultValue={new Date()}
                                placeholder={""}
                                onChange={(e) =>
                                  setEditData({
                                    ...editData,
                                    to_time: moment(e.target.value).format(
                                      "hh:mm A"
                                    ),
                                  })
                                }
                                min={
                                  editData.to_date === ""
                                    ? minMinute
                                    : moment(
                                        new Date(
                                          dateFormatChanger(editData.to_date)
                                        )
                                      ).format("DD-MM-YYYY") ===
                                      moment(new Date()).format("DD-MM-YYYY")
                                    ? minMinute
                                    : new Date("11-11-2023")
                                }
                                value={timeFormatChanger(editData.to_time)}
                                steps={{
                                  hour: 1,
                                  minute: 15,
                                  // second: 30,
                                }}
                              />
                            </div>
                          </div>
                          {showError.to_date !== "" && (
                            <div className="Aprewall_create_error_text">
                              {showError.to_date}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="Apprewall_create_item_main_box">
                      <div className="Aprewall_create_upload_main_box">
                        <div className="Apprewall_create_item_main_box_title">
                          Message Image{" "}
                          <span className="Apprewall_create_item_main_box_title_span">
                            {/* (Max 1 file, no more than 5MB each) */}
                          </span>
                        </div>
                        <div className="Aprewall_create_upload_button_box">
                          <div onClick={uploadFile}>
                            <div>+ Upload Image</div>
                            <input
                              accept={[
                                "image/jpeg",
                                "image/png",
                                "image/jpg",
                                //   "application/pdf",
                                //   "application/msword",
                              ]}
                              ref={FileRef}
                              type="file"
                              hidden
                              onClick={(e) => (e.target.value = "")}
                              onChange={(e) => onUploadFile(e.target.files[0])}
                            />
                          </div>
                        </div>
                      </div>
                      {id
                        ? editData.image_url !== "" && <ImageHandler />
                        : file !== "" && <ImageHandler />}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="Aprewall_create_scroll_box_review">
                  <div
                    className="Aprewall_create_review_main_box"
                    style={{ minHeight: "55px" }}
                  >
                    <div className="Aprewall_create_review_left">
                      Title in English
                    </div>
                    <div className="Aprewall_create_review_middle" />
                    <div className="Aprewall_create_review_right Aprewall_create_review_flex">
                      {editData.title_en}
                    </div>
                  </div>
                  <div
                    className="Aprewall_create_review_main_box"
                    style={{ minHeight: "55px" }}
                  >
                    <div className="Aprewall_create_review_left">
                      Title in Chinese
                    </div>
                    <div className="Aprewall_create_review_middle" />
                    <div className="Aprewall_create_review_right Aprewall_create_review_flex">
                      {editData.title_zh}
                    </div>
                  </div>
                  <div
                    className="Aprewall_create_review_main_box"
                    style={{ minHeight: "55px" }}
                  >
                    <div className="Aprewall_create_review_left">
                      Message in English
                    </div>
                    <div className="Aprewall_create_review_middle" />
                    <div
                      className="Aprewall_create_review_right"
                      dangerouslySetInnerHTML={{
                        __html: `${editData.message_en}`,
                      }}
                    ></div>
                  </div>
                  <div
                    className="Aprewall_create_review_main_box"
                    style={{ minHeight: "55px" }}
                  >
                    <div className="Aprewall_create_review_left">
                      Message in Chinese
                    </div>
                    <div className="Aprewall_create_review_middle" />
                    <div
                      className="Aprewall_create_review_right"
                      dangerouslySetInnerHTML={{
                        __html: `${editData.message_zh}`,
                      }}
                    ></div>
                  </div>
                  <div
                    className="Aprewall_create_review_main_box"
                    style={{ minHeight: "55px" }}
                  >
                    <div className="Aprewall_create_review_left">
                      Publish Rules
                    </div>
                    <div className="Aprewall_create_review_middle" />
                    <div className="Aprewall_create_review_right Aprewall_create_review_flex">
                      From{" "}
                      {moment(
                        new Date(dateFormatChanger(editData.from_date))
                      ).format("DD MMM YYYY")}
                      ,{editData.from_time} to{" "}
                      {moment(
                        new Date(dateFormatChanger(editData.to_date))
                      ).format("DD MMM YYYY")}
                      ,{editData.to_time}
                    </div>
                  </div>
                  <div
                    className="Aprewall_create_review_main_box"
                    style={{ minHeight: "55px" }}
                  >
                    <div className="Aprewall_create_review_left">
                      Message Image
                    </div>
                    <div className="Aprewall_create_review_middle" />
                    <div className="Aprewall_create_review_right Aprewall_create_review_flex">
                      <img
                        style={{ maxWidth: "170px", height: "120px" }}
                        src={
                          id
                            ? file === ""
                              ? editData.image_url !== "" && editData.image_url
                              : URL.createObjectURL(file)
                            : file !== "" && URL.createObjectURL(file)
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
              {tab === 0 ? (
                <div className="Aprewall_create_footer_main_box">
                  {/* <div className="Aprewall_create_footer_button">Save</div> */}
                  <div
                    className="Aprewall_create_footer_button"
                    style={{ marginLeft: "auto" }}
                    onClick={(e) => firstTabSubmit()}
                  >
                    Next &#x2192;
                  </div>
                </div>
              ) : (
                <div className="Aprewall_create_footer_main_box">
                  <div className="Aprewall_create_review_main_box">
                    <div
                      className="Aprewall_create_footer_button_previous"
                      onClick={() => setTab(tab - 1)}
                    >
                      Previous
                    </div>
                    <div
                      className="Aprewall_create_footer_button"
                      onClick={() =>
                        handleSubmit(id ? detail_data.status : "Submitted")
                      }
                    >
                      Save
                    </div>
                  </div>
                  {id ? (
                    ""
                  ) : (
                    <div
                      className="Aprewall_create_footer_button_publish"
                      onClick={() => handleSubmit("Published")}
                    >
                      Publish
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      ) : (
        <PermissionDeninedPage
          height={"calc(100% - 76px)"}
          title={"Sorry, You don't have permission to access this page!"}
        />
      )}
    </>
  );
};
