import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
// redux
import { useDispatch } from "react-redux";
// kendo
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Loader } from "@progress/kendo-react-indicators";
import { MultiSelect } from "@progress/kendo-react-dropdowns";

import "./notification.css";

export const FilterEmailTemplate = (props) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  // state
  const [loading, setLoading] = useState(true);
  const allTypes = [
    {
      id: "All",
      text: "All",
    },
    {
      id: "BSQC",
      text: "Bus Service Quality Check",
    },
  ];

  const [selectedType, setSelectedType] = useState([]);

  // methods
  const handleClearFilter = () => {
    setSelectedType([]);
  };

  const onSubmitFilter = () => {
    let data = {
      type: selectedType.map((x) => x.id),
    };
    console.log("onSubmitFilter:", data);
    props.onFiltercallback(data);
  };

  const handleTypeDropdownChanged = (e) => {
    setSelectedType(e.target.value.slice(0, 3));
  };

  // useEffect
  useEffect(() => {
    if (query.toString()) {
      if (query.get("type")) {
        const type = query.get("type");
        console.log("filter type:", type);
        if (type) {
          let types = type.split(",");
          types = allTypes.filter((x) => types.includes(x.id));
          setSelectedType(types);
        }
      }
    }
    setLoading(false);
  }, []);

  return (
    <>
      <Dialog
        className="search-notification"
        title={" "}
        width={395}
        height={"100%"}
        onClose={() => props.setShowFilterDialog(false)}
      >
        {loading ? (
          <Loader
            type="infinite-spinner"
            style={{
              display: "flex",
              alignItems: "center",
              width: "40px",
              margin: "auto",
              justifyContent: "center",
              height: "100vh",
            }}
          />
        ) : (
          <div className="search-body">
            <div className="filter-title">
              <div className="title">Filter</div>
              <div className="clear-filter" onClick={handleClearFilter}>
                <u>Clear Filter</u>
              </div>
            </div>
            <div className="filter-body">
              <p className="filter-sub-title">Type</p>
              <div>
                <div className="user-filter-select-multi">
                  <MultiSelect
                    textField="text"
                    dataItemKey="id"
                    className="user-filter-dropdown-multi"
                    style={{ width: "100%" }}
                    data={allTypes}
                    onChange={handleTypeDropdownChanged}
                    value={selectedType}
                    placeholder="Select (max 3 items)"
                    disabled={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <DialogActionsBar>
          <Button
            className="search-button cancel-btn"
            onClick={() => props.setShowFilterDialog(false)}
          >
            Cancel
          </Button>
          <Button
            className="search-button search-btn"
            onClick={() => onSubmitFilter()}
            disabled={loading}
          >
            Submit
          </Button>
        </DialogActionsBar>
      </Dialog>
    </>
  );
};
