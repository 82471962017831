import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
// redux
import { fetchAllGroupList, deleteGroup } from "../../../store/groupMgmt";
// kendo
import { Loader } from "@progress/kendo-react-indicators";
// components
import { TableHeader } from "../../../components/table/Tableheader";
import { GroupTableBody } from "../../../components/table/groupTable";
import { SearchGroup } from "../../../components/dialog/group_search";
// routes
import {
  USER_MANAGEMENT_GROUPS,
  USER_MANAGEMENT_GROUPS_ADD,
} from "../../../routes";
// permissions
import {
  getUserPermission,
  checkMultiPermission,
} from "../../../config/permission_helper";
import {
  USER_MGMT_EDIT_GROUP,
  USER_MGMT_DELETE_GROUP,
} from "../../../config/permission_helper";

export const GroupManagementList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  // redux
  const { groups_loading, groups, delete_group_loading } = useSelector(
    (state) => state.groupMgmt
  );
  // states
  const [loading, setLoading] = useState(false);
  const [allGroups, setAllGroups] = useState([]);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [showSearchDialog, setShowSearchDialog] = useState(false);

  // methods
  const shouldHideAddButton = () => {
    const userPermissions = getUserPermission();
    return checkMultiPermission(userPermissions, USER_MGMT_EDIT_GROUP)
      ? false
      : true;
  };

  const shouldShowDeleteButton = () => {
    const userPermissions = getUserPermission();
    return checkMultiPermission(userPermissions, USER_MGMT_DELETE_GROUP)
      ? true
      : false;
  };

  const handleFilteringData = () => {
    let results = allGroups;
    if (query.get("name")) {
      results = results.filter(
        (v) =>
          v.name.toLowerCase().indexOf(query.get("name").toLowerCase()) > -1
      );
    }
    setFilteredGroups(results);
  };

  const handleSearchCallback = (data) => {
    console.log(data);
    const { name } = data;
    query.set("name", name);
    if (name) {
      query.set("search", true);
    }
    navigate(USER_MANAGEMENT_GROUPS + "?" + query.toString());
    setShowSearchDialog(false);
    handleFilteringData();
  };

  const handleDeleteAction = (id) => {
    setLoading(true);
    dispatch(deleteGroup(id)).then((r) => {
      dispatch(fetchAllGroupList()).then((res) => {
        let results = res.payload;
        setAllGroups(results);
        setLoading(false);
      });
    });
  };

  // useEffect
  useEffect(() => {
    let results = allGroups;
    if (query.toString()) {
      if (query.get("name")) {
        results = results.filter(
          (v) =>
            v.name.toLowerCase().indexOf(query.get("name").toLowerCase()) > -1
        );
      }
    }
    setFilteredGroups(results);
    setLoading(false);
  }, [allGroups]);

  useEffect(() => {
    setLoading(true);
    dispatch(fetchAllGroupList()).then((res) => {
      let results = res.payload;
      setAllGroups(results);
    });
  }, []);

  return (
    <div className="group-mgmt-list-content">
      {showSearchDialog && (
        <SearchGroup
          setShowSearchDialog={setShowSearchDialog}
          onSearchcallback={handleSearchCallback}
        />
      )}
      <TableHeader
        title="Manage Group"
        onRefresh={() => {
          dispatch(fetchAllGroupList()).then((res) => {
            let results = res.payload;
            setAllGroups(results);
          });
        }}
        hideaddbtn={shouldHideAddButton()}
        hideFilterBtn={true}
        loading={loading}
        // onFilter={() => setShowFilterDialog(true)}
        onSearch={() => setShowSearchDialog(true)}
        addurl={USER_MANAGEMENT_GROUPS_ADD}
      />
      {groups_loading || loading || delete_group_loading ? (
        <Loader
          type="infinite-spinner"
          style={{
            display: "flex",
            alignItems: "center",
            width: "40px",
            margin: "auto",
            justifyContent: "center",
            height: "100vh",
          }}
        />
      ) : (
        <GroupTableBody
          data={filteredGroups}
          handleDeleteAction={handleDeleteAction}
          showDeleteOption={shouldShowDeleteButton()}
        />
      )}
    </div>
  );
};
