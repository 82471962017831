import React, { useEffect, useState } from "react";
import "./promotions.css";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import {
  HAPPENINGS_PROMOTIONS_DUPLICATE_ROUTE,
  HAPPENINGS_PROMOTIONS_EDIT_ROUTE,
  HAPPENINGS_PROMOTIONS_ROUTE,
} from "../../../routes";
import {
  fetchPromotionAudit,
  fetchPromotionDelete,
  fetchPromotionDetail,
  fetchPromotionUpdate,
} from "../../../store/happenings/promotion";
import { Loader } from "@progress/kendo-react-indicators";
import moment from "moment";
import EditIcon from "../../../assets/common/EditIcon.svg";
import { Stepper } from "@progress/kendo-react-layout";
import { CustomStep } from "../../../components/common/custom_steps";
import { Dialog } from "@progress/kendo-react-dialogs";
import FileViewer from "react-file-viewer";
import { Confirmation } from "../../../components/dialog/confirmation";
import { ShowDialog } from "../../../components/reusableItems/dialog";
import {
  checkPermission,
  getUserPermission,
} from "../../../config/permission_helper";
import { PermissionDeninedPage } from "../../../components/reusableItems/permission_denined";

export const Promotion_Detail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const id = query.get("id");
  const [detailLoading, setDetailLoading] = useState(false);
  const [showPublishValidatoin, setShowPublishValidation] = useState(false);
  const [auditLoading, setAuditLoading] = useState(false);
  const [showDoc, setShowDoc] = useState(false);
  const [showDocIndex, setShowDocIndex] = useState(-1);
  const [detailData, setDetailData] = useState({});
  const [auditData, setAuditData] = useState([]);
  const [tempStatus, setTempStatus] = useState("");
  const [showStatus, setShowStatus] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const userPermissions = getUserPermission();
  const viewPermssion = checkPermission(
    userPermissions,
    "happening.access_happening_promotion_list"
  );
  const DeletePermssion = checkPermission(
    userPermissions,
    "happening.delete_happening_promotion"
  );
  const EditPermssion = checkPermission(
    userPermissions,
    "happening.edit_happening_promotion"
  );
  const AddPermssion = checkPermission(
    userPermissions,
    "happening.create_happening_promotion"
  );

  const getData = () => {
    setDetailLoading(true);
    setAuditLoading(true);
    dispatch(fetchPromotionAudit(id)).then((res) => {
      if (res.payload.status_code === 200) {
        setAuditData(res.payload.data);
        setAuditLoading(false);
      } else {
        setAuditLoading(false);
        alert(res.payload.message);
      }
    });
    dispatch(fetchPromotionDetail(id)).then((res) => {
      if (res.payload.status_code === 200) {
        // dispatch(fetAud)
        setDetailData(res.payload.data);
        setDetailLoading(false);
      } else {
        setDetailLoading(false);
        alert(res.payload.message);
      }
    });
  };
  useEffect(() => {
    getData();
  }, [id]);

  const getDropdownData = (value) => {
    let permission = {
      Publish: {
        id: "published",
        permission: "cares.update_cares_ecard_status",
      },
      Unpublish: {
        id: "unpublished",
        permission: "cares.update_cares_ecard_status",
      },
    };
    if (value.length && value.length > 0) {
      let updateValue = [];
      value.map((i) => {
        const userPermission = getUserPermission();
        const checkStatusPermission = checkPermission(
          userPermission,
          permission?.[i].permission
        );
        if (checkStatusPermission) {
          if (
            detailData.status.toLowerCase() === "submitted" &&
            detailData.status.toLowerCase() !== permission?.[i].id
          ) {
            if (i === "Publish") {
              updateValue.push(i);
            }
          } else if (
            detailData.status.toLowerCase() !== "submitted" &&
            detailData.status.toLowerCase() !== permission?.[i].id
          ) {
            updateValue.push(i);
          }
        }
      });
      return updateValue;
    }
    return [];
  };

  const updateStatus = () => {
    setShowStatus(false);
    if (detailData.status === "Submitted") {
      if (
        detailData.published_end === undefined ||
        detailData.published_end === ""
      ) {
        setShowPublishValidation(true);
        return;
      } else if (
        detailData.published_on === undefined ||
        detailData.published_on === ""
      ) {
        setShowPublishValidation(true);
        return;
      } else if (
        new Date().setHours(0, 0, 0, 0) >
        new Date(detailData.published_on.split("T")[0]).setHours(
          0,
          0,
          0,
          0
        ) ||
        new Date().setHours(0, 0, 0, 0) >
        new Date(detailData.published_end.split("T")[0]).setHours(0, 0, 0, 0)
      ) {
        setShowPublishValidation(true);
        return;
      } else if (
        new Date(detailData.published_on.split("T")[0]).setHours(0, 0, 0, 0) >
        new Date(detailData.published_end.split("T")[0]).setHours(0, 0, 0, 0)
      ) {
        setShowPublishValidation(true);
        return;
      } else if (detailData.published_on === detailData.published_end) {
        setShowPublishValidation(true);
        return;
      }
    }
    setDetailLoading(true);
    dispatch(
      fetchPromotionUpdate({
        id: id,
        status: tempStatus === "Publish" ? "published" : "unpublished",
      })
    ).then((res) => {
      if (res.payload.status_code === 200) {
        setTempStatus("");
        getData();
      } else {
        setDetailLoading(false);
        alert(res.payload.message);
      }
    });
  };

  const handleDeletePromotion = () => {
    setDetailLoading(true);
    setShowDelete(false);
    dispatch(fetchPromotionDelete(id)).then((res) => {
      if (res.payload.status_code === 200) {
        navigate(HAPPENINGS_PROMOTIONS_ROUTE);
      } else {
        setDetailLoading(false);
        alert(res.payload.message);
      }
    });
  };

  const editRedirect = () => {
    navigate(`${HAPPENINGS_PROMOTIONS_EDIT_ROUTE}?id=${id}`);
  };

  return (
    <>
      {viewPermssion ? (
        <>
          {showDelete && (
            <ShowDialog
              title={"Please Confirm!"}
              content={"Are you sure you want to delete?"}
              cancelContent={"Cancel"}
              confrimContent={"Delete"}
              cancelbtn={() => setShowDelete(false)}
              confrinbtn={handleDeletePromotion}
            />
          )}
          {showStatus && (
            <ShowDialog
              cancelbtn={() => setShowStatus(false)}
              title={""}
              dropdown={true}
              cancelContent={"Cancel"}
              confrimContent={"Update"}
              confrinbtn={updateStatus}
              dropdownData={getDropdownData(["Publish", "Unpublish"])}
              dropdownOnChange={(e) => setTempStatus(e)}
              dropdownValue={tempStatus}
            />
          )}
          {showPublishValidatoin && (
            <ShowDialog
              title={"Please Edit!"}
              content={
                "Update promotion data. Update Scheduled/Published On, Scheduled/Published End accordingly to publish the selected promotion!"
              }
              cancelContent={"Cancel"}
              confrimContent={"Edit"}
              cancelbtn={() => setShowPublishValidation(false)}
              confrinbtn={editRedirect}
            />
          )}
          {showDoc && (
            <Dialog>
              <div className="Promotions_doc_viewer_dialog">
                <div style={{ position: "relative" }}>
                  <div
                    className="Promotions_doc_viewer_dialog_close"
                    onClick={() => {
                      setShowDoc(false);
                      setShowDocIndex(-1);
                    }}
                  >
                    <span class="k-icon k-font-icon k-i-close k-icon-xl" />
                  </div>
                </div>
                <div className="Promotions_doc_viewer_dialog_item">
                  <FileViewer
                    fileType={
                      detailData.document_upload[showDocIndex]
                        .split("/").pop()
                        .split("?")[0]
                        .split(".").pop()
                    }
                    filePath={detailData.document_upload[showDocIndex]}
                  />
                </div>
              </div>
            </Dialog>
          )}
          {detailLoading ? (
            <Loader
              type="converging-spinner"
              style={{
                width: "100%",
                height: "calc(100% - 75px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#ffffff4d",
                position: "absolute",
                zIndex: "999",
              }}
            />
          ) : (
            <div className="Promotions_detail_main_box">
              <div className="Promotions_detail_child_box">
                <div className="Promotions_detail_left_box">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      className="Promotions_detail_back"
                      onClick={() => navigate(HAPPENINGS_PROMOTIONS_ROUTE)}
                    >
                      <span class="k-icon k-font-icon k-i-arrow-chevron-left" />
                      Back
                    </div>
                    <div
                      className="Promotions_detail_edit"
                      onClick={() =>
                        navigate(`${HAPPENINGS_PROMOTIONS_EDIT_ROUTE}?id=${id}`)
                      }
                    >
                      <img src={EditIcon} />
                      Edit
                    </div>
                  </div>
                  <div className="Promotions_detail_child_box_main_detail_box">
                    <div className="Promotions_detail_child_box_main_detail_items">
                      <div className="Promotions_detail_child_box_main_detail_items_title Promotions_detail_center_item detail_padding_bottom">
                        Status
                      </div>
                      <div className="Promotions_detail_child_box_main_detail_items_splitter" />
                      <div className="Promotions_detail_center_item Promotions_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detailData && detailData.status
                          ? detailData.status
                          : "-"}
                      </div>
                    </div>
                    <div className="Promotions_detail_child_box_main_detail_items">
                      <div className="Promotions_detail_child_box_main_detail_items_title Promotions_detail_center_item detail_padding_bottom">
                        Title in English
                      </div>
                      <div className="Promotions_detail_child_box_main_detail_items_splitter" />
                      <div className="Promotions_detail_center_item Promotions_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detailData && detailData.title
                          ? detailData.title
                          : "-"}
                      </div>
                    </div>
                    <div className="Promotions_detail_child_box_main_detail_items">
                      <div className="Promotions_detail_child_box_main_detail_items_title Promotions_detail_center_item detail_padding_bottom">
                        Title in Chinese
                      </div>
                      <div className="Promotions_detail_child_box_main_detail_items_splitter" />
                      <div className="Promotions_detail_center_item Promotions_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detailData && detailData.title_zh
                          ? detailData.title_zh
                          : "-"}
                      </div>
                    </div>
                    <div className="Promotions_detail_child_box_main_detail_items">
                      <div className="Promotions_detail_child_box_main_detail_items_title Promotions_detail_center_item detail_padding_bottom">
                        Category
                      </div>
                      <div className="Promotions_detail_child_box_main_detail_items_splitter" />
                      <div className="Promotions_detail_center_item Promotions_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detailData && detailData.category
                          ? detailData.category
                          : "-"}
                      </div>
                    </div>
                    <div className="Promotions_detail_child_box_main_detail_items">
                      <div className="Promotions_detail_child_box_main_detail_items_title Promotions_detail_center_item detail_padding_bottom">
                        Start Date
                      </div>
                      <div className="Promotions_detail_child_box_main_detail_items_splitter" />
                      <div className="Promotions_detail_center_item Promotions_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detailData && detailData.start_date
                          ? moment(
                            new Date(detailData.start_date.split("T")[0])
                          ).format("DD MMM YYYY")
                          : "-"}
                      </div>
                    </div>
                    <div className="Promotions_detail_child_box_main_detail_items">
                      <div className="Promotions_detail_child_box_main_detail_items_title Promotions_detail_center_item detail_padding_bottom">
                        End Date
                      </div>
                      <div className="Promotions_detail_child_box_main_detail_items_splitter" />
                      <div className="Promotions_detail_center_item Promotions_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detailData && detailData.end_date
                          ? moment(
                            new Date(detailData.end_date.split("T")[0])
                          ).format("DD MMM YYYY")
                          : "-"}
                      </div>
                    </div>
                    <div className="Promotions_detail_child_box_main_detail_items">
                      <div className="Promotions_detail_child_box_main_detail_items_title Promotions_detail_center_item detail_padding_bottom">
                        Scheduled/Published On
                      </div>
                      <div className="Promotions_detail_child_box_main_detail_items_splitter" />
                      <div className="Promotions_detail_center_item Promotions_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detailData && detailData.published_on
                          ? moment(
                            new Date(detailData.published_on.split("T")[0])
                          ).format("DD MMM YYYY")
                          : "-"}
                      </div>
                    </div>
                    <div className="Promotions_detail_child_box_main_detail_items">
                      <div className="Promotions_detail_child_box_main_detail_items_title Promotions_detail_center_item detail_padding_bottom">
                        Scheduled/Published End
                      </div>
                      <div className="Promotions_detail_child_box_main_detail_items_splitter" />
                      <div className="Promotions_detail_center_item Promotions_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detailData && detailData.published_end
                          ? moment(
                            new Date(detailData.published_end.split("T")[0])
                          ).format("DD MMM YYYY")
                          : "-"}
                      </div>
                    </div>
                    <div className="Promotions_detail_child_box_main_detail_items">
                      <div className="Promotions_detail_child_box_main_detail_items_title Promotions_detail_center_item detail_padding_bottom">
                        Created By
                      </div>
                      <div className="Promotions_detail_child_box_main_detail_items_splitter" />
                      <div className="Promotions_detail_center_item Promotions_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detailData && detailData.created_by?.name
                          ? detailData.created_by.name
                          : "-"}
                      </div>
                    </div>
                    <div className="Promotions_detail_child_box_main_detail_items">
                      <div className="Promotions_detail_child_box_main_detail_items_title Promotions_detail_center_item detail_padding_bottom">
                        Created On
                      </div>
                      <div className="Promotions_detail_child_box_main_detail_items_splitter" />
                      <div className="Promotions_detail_center_item Promotions_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detailData && detailData.created_on
                          ? moment(new Date(detailData.created_on)).format(
                            "DD MMM YYYY, hh:mm a"
                          )
                          : "-"}
                      </div>
                    </div>
                    <div className="Promotions_detail_child_box_main_detail_items">
                      <div className="Promotions_detail_child_box_main_detail_items_title Promotions_detail_center_item detail_padding_bottom">
                        Updated By
                      </div>
                      <div className="Promotions_detail_child_box_main_detail_items_splitter" />
                      <div className="Promotions_detail_center_item Promotions_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detailData && detailData.updated_by?.name
                          ? detailData.updated_by.name
                          : "-"}
                      </div>
                    </div>
                    <div className="Promotions_detail_child_box_main_detail_items">
                      <div className="Promotions_detail_child_box_main_detail_items_title Promotions_detail_center_item detail_padding_bottom">
                        Updated On
                      </div>
                      <div className="Promotions_detail_child_box_main_detail_items_splitter" />
                      <div className="Promotions_detail_center_item Promotions_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detailData && detailData.updated_on
                          ? moment(new Date(detailData.updated_on)).format(
                            "DD MMM YYYY, hh:mm a"
                          )
                          : "-"}
                      </div>
                    </div>
                    <div className="Promotions_detail_child_box_main_detail_items">
                      <div className="Promotions_detail_child_box_main_detail_items_title Promotions_detail_center_item detail_padding_bottom">
                        Image Upload
                      </div>
                      <div className="Promotions_detail_child_box_main_detail_items_splitter" />
                      <div className="Promotions_detail_center_item Promotions_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        <img
                          style={{ maxWidth: "170px", height: "120px" }}
                          src={
                            detailData &&
                            detailData.image !== "" &&
                            detailData.image
                          }
                        />
                      </div>
                    </div>
                    <div className="Promotions_detail_child_box_main_detail_items">
                      <div className="Promotions_detail_child_box_main_detail_items_title Promotions_detail_center_item detail_padding_bottom">
                        Document (s) Upload
                      </div>
                      <div className="Promotions_detail_child_box_main_detail_items_splitter" />
                      <div className="Promotions_detail_center_item Promotions_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detailData && detailData.document_upload && detailData.document_upload.length > 0 ? (
                          <div className="Promotions_create_file_main_container">
                            {detailData.document_upload.map((docUrl, index) => {
                              const docName = docUrl.split("/").pop().split("?")[0]; // Extract document name

                              return (
                                <div className="Promotions_doc_viewer">

                                  <div key={index} className="Promotions_doc_viewer_dialog_item">
                                    <div className="Promotions_doc_viewer_text">{docName}</div>
                                    <div className="Promotions_doc_viewer_dialog_item_action">
                                      <span onClick={() => {
                                        console.log(detailData.document_upload[index]);

                                        setShowDocIndex(index);
                                        setShowDoc(true);

                                      }}>View</span>
                                      <span onClick={() => window.open(docUrl)}>Download</span>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          "-"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Promotions_detail_right_box">
                  <div className="Promotions_detail_right_status_box">
                    <div className="Promotions_detail_right_box_status_title">
                      Status
                    </div>
                    <div className="Promotions_detail_right_box_status_subdetail">
                      {detailData && detailData.status
                        ? detailData.status
                        : "-"}
                    </div>
                  </div>
                  <div className="Promotions_detail_right_box_action_main_box">
                    <div className="Promotions_detail_right_box_action_title">
                      Actions
                    </div>
                    {AddPermssion && (
                      <div
                        className="Promotions_detail_right_box_action_edit"
                        onClick={() =>
                          navigate(
                            `${HAPPENINGS_PROMOTIONS_DUPLICATE_ROUTE}?duplicateID=${id}`
                          )
                        }
                      >
                        Duplicate post &#x2192;
                      </div>
                    )}
                    {EditPermssion ? (
                      <div
                        className="Promotions_detail_right_box_action_edit"
                        onClick={() => setShowStatus(true)}
                      >
                        Edit Visibility &#x2192;
                      </div>
                    ) : (
                      ""
                    )}
                    {DeletePermssion && (
                      <div
                        className="Promotions_detail_right_box_action_delete"
                        onClick={() => setShowDelete(true)}
                      >
                        Delete Promotion &#x2192;
                      </div>
                    )}
                  </div>
                  <div className="Promotions_detail_right_box_action_main_box">
                    <div className="Promotions_detail_right_box_action_title">
                      Activity Trail
                    </div>
                    <div className={auditData.length <= 1 && "audit_bar_hide"}>
                      {auditLoading ? (
                        <Loader
                          type="converging-spinner"
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            background: "#ffffff4d",
                          }}
                        />
                      ) : (
                        <Stepper
                          orientation={"vertical"}
                          items={auditData}
                          item={CustomStep}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <PermissionDeninedPage
          height={"calc(100% - 76px)"}
          title={"Sorry, You don't have permission to access this page!"}
        />
      )}
    </>
  );
};
