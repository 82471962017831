import React, { useState, useEffect} from "react";
import { useDispatch,useSelector } from "react-redux";
import { Dialog,DialogActionsBar } from "@progress/kendo-react-dialogs";
import { fetchAllUsersList } from "../../store/usersList";

import { Checkbox } from "@progress/kendo-react-inputs";
import "./dialog.css";

export const UserListDialog = (props) => {
    const dispatch = useDispatch();
    const [selectedUsers,setSelectedUsers] = useState(props.defaultSelected);
    const { users_list } = useSelector((state)=> state?.usersList);
    
    useEffect(()=>{
      dispatch(fetchAllUsersList());
    },[])
    
    const handleSelected = (e,val)=>{
        let updatedList = [...selectedUsers];
        if (e.target.value) {
          updatedList = [...selectedUsers, val];
        } else {
          const selected_index = selectedUsers.map((v)=>v.id).indexOf(val.id)
          updatedList.splice(selected_index, 1);
        }
        setSelectedUsers(updatedList);
    }
    const handleSubmitDepots = ()=>{
        props.callback(selectedUsers);
    }
    const isChecked = (item) =>
        selectedUsers.map((v)=>v.name).includes(item) ? true:false;
    return (
        <Dialog 
            title={`Please select user(s): ${selectedUsers.length} selected`} 
            width={500}
            height={500}
            onClose={props.onClickNo}
            className="depot-dialog"
            >
                <div className="depot-list-div">
                    {users_list.map((v)=>(
                        <Checkbox label={v.name} onChange={(e)=>handleSelected(e,v)} checked={isChecked(v.name)} />
                    ))}
                </div>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base depot-cancel-button"
              onClick={props.onClickNo}
            >
              No
            </button>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base depot-submit-button"
              onClick={handleSubmitDepots}
            >
              Yes
            </button>
          </DialogActionsBar>
        </Dialog>
    );
  };