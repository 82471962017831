export const ConfigData = [
  {
    id: "title_en",
    name: "Title in English",
    isShow: true,
    width: "160px",
    filter: false,
  },
  {
    id: "title_zh",
    name: "Title in Chinese",
    isShow: true,
    width: "160px",
    filter: false,
  },
  {
    id: "status",
    name: "Status",
    isShow: true,
    width: "117px",
    filter: false,
  },
  {
    id: "from_date,from_time",
    name: "Start Date",
    isShow: true,
    width: "170px",
    filter: true,
    format: "date-time",
  },
  // {
  //   id: "from_time",
  //   name: "Start Time",
  //   isShow: true,
  //   width: "117px",
  //   filter: false,
  //   // format: "time",
  // },
  {
    id: "to_date,to_time",
    name: "End Date",
    isShow: true,
    width: "170px",
    filter: false,
    format: "date-time",
  },
  // {
  //   id: "to_time",
  //   name: "End Time",
  //   isShow: true,
  //   width: "117px",
  //   filter: false,
  // },
  {
    id: "button",
    name: "Action",
    type: "dropdown",
    isShow: true,
    width: "117px",
    action: ["View Details", "Publish", "Unpublish", "Delete"],
    permission: {
      Publish: {
        id: "Publish",
        permission: "cares.update_cares_appwall_status",
      },
      ["View Details"]: {
        id: "View Details",
        permission: "cares.access_cares_appwall_list",
      },
      Unpublish: {
        id: "Unpublish",
        permission: "cares.update_cares_appwall_status",
      },
      Delete: {
        id: "Delete",
        permission: "cares.delete_cares_appwall",
      },
    },
    isshowAction: {
      item: "status",
      children: [
        {
          id: "Publish",
          value: "Published",
        },
        {
          id: "Unpublish",
          value: "Unpublished",
          notShowCondition: "Submitted",
        },
      ],
    },
  },
];

export const DefaulSorting = {
  id: "from_date,from_time",
  name: "Start Date",
  isShow: true,
  width: "170px",
  filter: true,
  format: "date-time",
};

export const data = [
  { title_en: "Test", title_en: "Test", title_en: "Test", title_en: "Test" },
  { title_en: "Test", title_en: "Test", title_en: "Test", title_en: "Test" },
  { title_en: "Test", title_en: "Test", title_en: "Test", title_en: "Test" },
  { title_en: "Test", title_en: "Test", title_en: "Test", title_en: "Test" },
  { title_en: "Test", title_en: "Test", title_en: "Test", title_en: "Test" },
  { title_en: "Test", title_en: "Test", title_en: "Test", title_en: "Test" },
  { title_en: "Test", title_en: "Test", title_en: "Test", title_en: "Test" },
  { title_en: "Test", title_en: "Test", title_en: "Test", title_en: "Test" },
  { title_en: "Test", title_en: "Test", title_en: "Test", title_en: "Test" },
  { title_en: "Test", title_en: "Test", title_en: "Test", title_en: "Test" },
  { title_en: "Test", title_en: "Test", title_en: "Test", title_en: "Test" },
  { title_en: "Test", title_en: "Test", title_en: "Test", title_en: "Test" },
  { title_en: "Test", title_en: "Test", title_en: "Test", title_en: "Test" },
  { title_en: "Test", title_en: "Test", title_en: "Test", title_en: "Test" },
  { title_en: "Test", title_en: "Test", title_en: "Test", title_en: "Test" },
  { title_en: "Test", title_en: "Test", title_en: "Test", title_en: "Test" },
  { title_en: "Test", title_en: "Test", title_en: "Test", title_en: "Test" },
  { title_en: "Test", title_en: "Test", title_en: "Test", title_en: "Test" },
  { title_en: "Test", title_en: "Test", title_en: "Test", title_en: "Test" },
];
