import React, { useState, useEffect, useRef } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import "./manage_tutorial.css";
import BackIcon from "../../../assets/communication/Back.svg";
import CloseIcon from "../../../assets/header/ic-close-white.svg";
import { ADMIN_TOOLS_TUTORIAL_DETAILS, ADMIN_TOOLS_TUTORIAL_LIST } from "../../../routes";
import { Loader } from "@progress/kendo-react-indicators";
import { TextBox } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { Editor, EditorTools, EditorUtils } from "@progress/kendo-react-editor";
import TextFieldBox from "./text_field_box";
import MessageBox from "./message_box";
import api from "../../../config/api";
import { PermissionDeninedPage } from "../../../components/reusableItems/permission_denined";
import {
  checkPermission,
  getUserPermission,
} from "../../../config/permission_helper";
import { ShowDialog } from "../../../components/reusableItems/dialog";

import {
  fetchAppModules,
  createAndUpdateTutorial,
  fetcheTutorialDetailById,
  getPresignedURL,
} from "../../../store/manage_tutorial";

export const TUTORIAL_TABS = {
  EN: "English",
  CH: "Chinese",
};
export const AddTutorial = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showDelete, setShowDelete] = useState(false);

  let id = query.get("id");
  let isDuplicate = query.get("isDuplicate") === "true" || false;

  const [currentIndex, setCurrentIndex] = useState(null);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const [tab, setTab] = useState(TUTORIAL_TABS.EN);

  const maxRows = 5;

  const [engRows, setEngRows] = useState([
    { image: '', message: '', image_file: null, image_error: '', message_error: '' }
  ]);

  const [editData, setEditData] = useState({
    title_en: "",
    title_zh: "",
    app_module: null,
  });
  const [showError, setShowError] = useState({
    title_en: "",
    title_zh: "",
    app_module: "",
  });
  const [lastData, setLastData] = useState({});
  const [lastDataEng, setLastDataEng] = useState([]);
  const [lastDataZh, setLastDataZh] = useState([]);



  const handleAddEngRow = () => {
    if (engRows.length < maxRows) {
      setEngRows([...engRows, { image: '', message: '', image_file: null, image_error: '', message_error: '' }]);
    }

    if (zhRows.length < maxRows) {
      setZhRows([...zhRows, { image: '', message: '', image_file: null, image_error: '', message_error: '' }]);
    }

    setIsButtonEnabled(false);

  };

  const [zhRows, setZhRows] = useState([
    { image: '', message: '', image_file: null, image_error: '', message_error: '' }
  ]);

  const handleAddZhRow = () => {
    if (zhRows.length < maxRows) {
      setZhRows([...zhRows, { image: '', message: '', image_file: null, image_error: '', message_error: '' }]);
    }
  };

  const { detail_loading, app_modules_loading, app_modules, loading } =
    useSelector((state) => state.manageTutorial);
  const userPermissions = getUserPermission();

  const AddPermssion = checkPermission(
    userPermissions,
    "tutorial.create_tutorial"
  );

  useEffect(() => {
    if (id) {
      setIsButtonEnabled(true);
      dispatch(fetcheTutorialDetailById(id)).then(async (res) => {
        setEditData({
          title_en: res.payload.title_en,
          title_zh: res.payload.title_zh,
          app_module: res.payload.app_module,
        });
        setLastData({
          title_en: res.payload.title_en,
          title_zh: res.payload.title_zh,
          app_module: res.payload.app_module,
        });
        setEngRows([
          { image: res.payload.image_1, message: res.payload.message_en_1, image_file: null, image_error: '', message_error: '' },
          { image: res.payload.image_2, message: res.payload.message_en_2, image_file: null, image_error: '', message_error: '' },
          { image: res.payload.image_3, message: res.payload.message_en_3, image_file: null, image_error: '', message_error: '' },
          { image: res.payload.image_4, message: res.payload.message_en_4, image_file: null, image_error: '', message_error: '' },
          { image: res.payload.image_5, message: res.payload.message_en_5, image_file: null, image_error: '', message_error: '' },
        ]);

        setZhRows([
          { image: res.payload.image_zh_1, message: res.payload.message_zh_1, image_file: null, image_error: '', message_error: '' },
          { image: res.payload.image_zh_2, message: res.payload.message_zh_2, image_file: null, image_error: '', message_error: '' },
          { image: res.payload.image_zh_3, message: res.payload.message_zh_3, image_file: null, image_error: '', message_error: '' },
          { image: res.payload.image_zh_4, message: res.payload.message_zh_4, image_file: null, image_error: '', message_error: '' },
          { image: res.payload.image_zh_5, message: res.payload.message_zh_5, image_file: null, image_error: '', message_error: '' },
        ]);

        setLastDataEng([
          { image: res.payload.image_1, message: res.payload.message_en_1, image_file: null, image_error: '', message_error: '' },
          { image: res.payload.image_2, message: res.payload.message_en_2, image_file: null, image_error: '', message_error: '' },
          { image: res.payload.image_3, message: res.payload.message_en_3, image_file: null, image_error: '', message_error: '' },
          { image: res.payload.image_4, message: res.payload.message_en_4, image_file: null, image_error: '', message_error: '' },
          { image: res.payload.image_5, message: res.payload.message_en_5, image_file: null, image_error: '', message_error: '' },
        ]);

        setLastDataZh([
          { image: res.payload.image_zh_1, message: res.payload.message_zh_1, image_file: null, image_error: '', message_error: '' },
          { image: res.payload.image_zh_2, message: res.payload.message_zh_2, image_file: null, image_error: '', message_error: '' },
          { image: res.payload.image_zh_3, message: res.payload.message_zh_3, image_file: null, image_error: '', message_error: '' },
          { image: res.payload.image_zh_4, message: res.payload.message_zh_4, image_file: null, image_error: '', message_error: '' },
          { image: res.payload.image_zh_5, message: res.payload.message_zh_5, image_file: null, image_error: '', message_error: '' },
        ]);

      });
    }
  }, [id]);

  const getData = async (callback) => {
    dispatch(fetchAppModules());
  };

  useEffect(() => {
    getData();
  }, []);

  const onTabChange = (tab) => {
    setTab(tab);
  };

  const getFileExtension = (filename) => {
    // Get the last part of the filename after the last dot
    const lastDotIndex = filename.lastIndexOf(".");

    // If there is no dot or it's the first character, return an empty string
    if (lastDotIndex === -1 || lastDotIndex === 0) {
      return "";
    }

    // Return the file extension
    return filename.substring(lastDotIndex + 1);
  };

  const uploadImage = async () => {
    if (image) {
      const extension_ = getFileExtension(image.name);
      const data = {
        extension: extension_,
      };
      setImageLoading(true);
      dispatch(getPresignedURL(data)).then(async (res) => {
        api.postDiff(res.payload.data.presigned_url, image).then((res1) => {
          if (res1 === 200) {

            if (currentIndex !== -1) {

              if (tab == TUTORIAL_TABS.EN) {
                const updatedRows = engRows.map((row, i) => (i === currentIndex ? { ...row, image: res.payload.data.presigned_url } : row));
                setEngRows(updatedRows);
              } else {
                const updatedRows = zhRows.map((row, i) => (i === currentIndex ? { ...row, image: res.payload.data.presigned_url } : row));
                setZhRows(updatedRows);
              }
            }


            setCurrentIndex(-1);
            setImage(null);

            setImageLoading(false);
            setIsDialogOpen(false);
            validateFields(true);

          } else {
            alert("An error occured while uploading file! Please try again!");
          }
        });
      });
    }
  };

  const cancelUploadImage = () => {
    setIsDialogOpen(false);
  };

  const validateFields = (isAutoSave) => {
    let isError = false;
    const tempErrors = { ...showError }; // Start with current errors

    if (!editData.app_module) {
      if (!isAutoSave) tempErrors.app_module = "App Module is required.";
      isError = true;
    }
    if (editData.title_en.trim() === "" && editData.title_zh.trim() === "") {
      if (!isAutoSave) tempErrors.title_en = "Topic/Title is required.";
      isError = true;
    }

    if (editData.title_en.trim() != "") {
      // Validate engRows (message validation)
      const updatedEngRows = engRows.map((row) => {
        if (row.message.trim() === "") {
          isError = true;
          return { ...row, message_error: "Message is required." };
        } else {
          return { ...row, message_error: "" };
        }
      });
      if (!isAutoSave) {
        setEngRows(updatedEngRows);
      }
    }
    if (editData.title_zh.trim() != "") {
      // Validate zhRows (message validation)
      const updatedZhRows = zhRows.map((row) => {
        if (row.message.trim() === "") {
          isError = true;
          return { ...row, message_error: "Message is required." };
        } else {
          return { ...row, message_error: "" };
        }
      });

      if (!isAutoSave) {
        setZhRows(updatedZhRows);
      }
    }

    if (!isAutoSave) {
      // Use functional setState to ensure proper updates
      setShowError((prevErrors) => ({ ...prevErrors, ...tempErrors }));
    } else {
      setIsButtonEnabled(!isError);
    }

    return isError;
  };

  const handleCancel = async () => {
    if (id) {
      navigate(`${ADMIN_TOOLS_TUTORIAL_DETAILS}?id=${id}`);
    } else {
      navigate(ADMIN_TOOLS_TUTORIAL_LIST);
    }
  };

  const actionTrigger = () => {
    if (tab == TUTORIAL_TABS.EN) {
      const en = engRows.filter((_, i) => i !== currentIndex);
      validateFieldsAndUpdateMessageData(en, zhRows);
    } else {
      const zh = zhRows.filter((_, i) => i !== currentIndex);
      validateFieldsAndUpdateMessageData(engRows, zh)
    }
    setShowDelete(false);
    setCurrentIndex(-1);
  };

  const validateFieldsAndUpdateMessageData = (en, zh) => {
    let isError = false;
    const tempErrors = { ...showError }; // Start with current errors
    let updatedEngRows = en;
    let updatedZhRows = zh;


    if (!editData.app_module) {
      tempErrors.app_module = "App Module is required.";
      isError = true;
    }
    if (editData.title_en.trim() === "" && editData.title_zh.trim() === "") {
      tempErrors.title_en = "Topic/Title is required.";
      isError = true;
    }

    if (editData.title_en.trim() != "") {
      // Validate engRows (message validation)
      updatedEngRows = en.map((row) => {
        if (row.message.trim() === "") {
          isError = true;
          return { ...row, message_error: "Message is required." };
        } else {
          return { ...row, message_error: "" };
        }
      });
    }
    if (editData.title_zh.trim() != "") {
      // Validate zhRows (message validation)
      updatedZhRows = zh.map((row) => {
        if (row.message.trim() === "") {
          isError = true;
          return { ...row, message_error: "Message is required." };
        } else {
          return { ...row, message_error: "" };
        }
      });

    }
    setEngRows(updatedEngRows);
    setZhRows(updatedZhRows);
    setIsButtonEnabled(!isError);

    return isError;
  };


  const autoSave = async () => {
    if (validateFields(false)) {
      return;
    }
    if (JSON.stringify(editData) !== JSON.stringify(lastData) || JSON.stringify(engRows) !== JSON.stringify(lastDataEng) || JSON.stringify(zhRows) !== JSON.stringify(lastDataZh)) {
      setIsSaving(true);
      setIsSave(false);
      uploadData(true);
    }
  };

  useEffect(() => {
    // Auto-save every 10 seconds
    const autoSaveInterval = setInterval(() => {
      if (editData && id && !isDuplicate) autoSave();
    }, 60000);

    return () => clearInterval(autoSaveInterval);
  }, [editData]);

  const handleSubmit = async () => {
    if (validateFields(false)) {
      return;
    }

    uploadData(false);
  };

  const uploadData = async (isAutoSave) => {
    let data = {
      title_en: editData.title_en,
      title_zh: editData.title_zh,
      app_module: editData.app_module,
      image_1: '',
      image_zh_1: '',
      message_en_1: '',
      message_zh_1: '',
      image_2: '',
      image_zh_2: '',
      message_en_2: '',
      message_zh_2: '',
      image_3: '',
      image_zh_3: '',
      message_en_3: '',
      message_zh_3: '',
      image_4: '',
      image_zh_4: '',
      message_en_4: '',
      message_zh_4: '',
      image_5: '',
      image_zh_5: '',
      message_en_5: '',
      message_zh_5: '',
    };

    engRows.forEach((row, index) => {
      data = {
        ...data,
        [`image_${index + 1}`]: getImagePath(row.image) || '',
        [`message_en_${index + 1}`]: row.message,
      }
    });

    zhRows.forEach((row, index) => {
      data = {
        ...data,
        [`image_zh_${index + 1}`]: getImagePath(row.image) || '',
        [`message_zh_${index + 1}`]: row.message,
      }
    });

    const params = id && !isDuplicate
      ? {
        ...data,
        id: id,
      }
      : {
        ...data,
      };


    if (!isAutoSave) {
      setSubmitLoading(true);
    }

    dispatch(createAndUpdateTutorial(params)).then(async (res) => {
      if (res.payload.status === "success") {
        setLastData(editData);
        setLastDataEng(engRows);
        setLastDataZh(zhRows);

        id = res.payload.data.id;
        if (!isAutoSave) {
          setSubmitLoading(false);
          navigate(ADMIN_TOOLS_TUTORIAL_LIST);
        } else {
          setIsSave(true);
          setIsSaving(false);
        }
      } else {
        setErrorMessage(res.payload.message);
        setSubmitLoading(false);
        setIsSaving(false);

      }
    });
  };

  function getImageName(url) {
    const urlParts = url.split("?");
    const imageUrl = urlParts[0];
    const imageName = imageUrl.substring(imageUrl.lastIndexOf("/") + 1);

    return imageName;
  }

  function getImagePath(url) {
    // Match everything between the domain and the query string
    const match = url.match(/https:\/\/[^/]+\/(.+?)\?/);
    return match ? match[1] : null;
  }

  const ImageView = ({ title, keyProp, image_url, file, errorMessage, index, isError }) => {
    const fileInputRef = useRef();

    const handleFileChange = (e) => {
      const selectedFile = e.target.files[0];
      setCurrentIndex(index);
      if (selectedFile && selectedFile.size <= 5 * 1024 * 1024) {
        const img = new Image();
        const reader = new FileReader();

        reader.onload = (e1) => {
          img.src = e1.target.result;
          img.onload = () => {
            // Check aspect ratio
            const width = img.naturalWidth;
            const height = img.naturalHeight;
            const aspectRatio = width / height;

            // 5:11 ratio (width:height)
            const expectedAspectRatio = 5 / 11;

            // Set error state if the aspect ratio is incorrect
            if (Math.abs(aspectRatio - expectedAspectRatio) > 0.01) {

              if (tab == TUTORIAL_TABS.EN) {
                const updatedRows = engRows.map((row, i) => (i === index ? { ...row, image_error: "Please select an image with a 5:11 aspect ratio." } : row));
                setEngRows(updatedRows);
              } else {
                const updatedRows = zhRows.map((row, i) => (i === index ? { ...row, image_error: "Please select an image with a 5:11 aspect ratio." } : row));
                setZhRows(updatedRows);
              }

              setImage(null);
            } else {
              if (tab == TUTORIAL_TABS.EN) {
                const updatedRows = engRows.map((row, i) => (i === index ? { ...row, image_file: selectedFile, image_error: "" } : row));
                setEngRows(updatedRows);
              } else {
                const updatedRows = zhRows.map((row, i) => (i === index ? { ...row, image_file: selectedFile, image_error: "" } : row));
                setZhRows(updatedRows);
              }

              setImage(selectedFile);
              setIsDialogOpen(true);
            }
          };
        };

        reader.readAsDataURL(selectedFile);
      } else {
        alert("File must be png/jpeg and under 5MB");
      }
    };

    return (
      <div className="tutorial_create_upload_main_box">
        <div className="tutorial_create_item_main_box_title">
          {title}
          <span className="tutorial_create_item_main_box_title_span">
            {`${" (image ratio 5:11, png or jpeg, Max size 5MB)"}`}
          </span>
        </div>
        <div className="tutorial_create_upload_image_box">
          {image_url ? (
            <ImageHandler
              key={keyProp}
              keyProp={keyProp}
              image_url={image_url}
              file={file}
              index={index}
            />
          ) : (
            <div className="tutorial_create_upload_button_box">
              <div
                onClick={() => {
                  fileInputRef.current.click();
                }}
              >
                <div>+ Add</div>
              </div>
              <input
                accept={["image/jpeg", "image/png", "image/jpg"]}
                ref={fileInputRef}
                key={keyProp}
                type="file"
                hidden
                onChange={(e) => handleFileChange(e, keyProp)} // Pass keyProp here
              />
            </div>
          )}
        </div>
        {(errorMessage || isError) && (
          <div className="tutorial_create_error_text">{errorMessage || ''}</div>
        )}
      </div>
    );
  };

  const ImageHandler = ({ keyProp, image_url, file, index }) => {
    return (
      <div className="tutorial_create_file_main_box">
        <div className="tutorial_create_file_child_box">
          {file ? (
            <>
              <img
                key={keyProp}
                src={URL.createObjectURL(file)}
                alt={getImageName(file.name)}
                className="tutorial_create_file_image"
              />

            </>
          ) : (
            <>
              <img
                key={keyProp}
                src={image_url}
                alt={getImageName(image_url)}
                className="tutorial_create_file_image"
              />
            </>
          )}
        </div>
        <img
          className="tutorial_create_file_child_box_delete_icon"
          width={20}
          height={20}
          src={CloseIcon}
          alt="icon-close"
          onClick={() => {

            if (tab == TUTORIAL_TABS.EN) {
              const updatedRows = engRows.map((row, i) => (i === index ? { ...row, image_error: "", image: "", image_file: null } : row));
              setEngRows(updatedRows);
            } else {
              const updatedRows = zhRows.map((row, i) => (i === index ? { ...row, image_error: "", image: "", image_file: null } : row));
              setZhRows(updatedRows);
            }

          }}
        />
      </div>
    );
  };

  const DropDownBox = () => {
    return (
      <div className="tutorial_create_item_main_box margin-bottom">
        <div className="tutorial_create_item_main_box_title">Module Names</div>
        <div>
          <DropDownList
            data={app_modules}
            textField="name"
            dataItemKey="id"
            value={editData.app_module}
            onChange={(e) => {
              const value = e.target.value;
              setEditData((prevData) => ({ ...prevData, app_module: value }));
              validateFields(true);
            }}
            placeholder="Select App Module"
            className="tutorial_create_item_main_box_field tutorial_dropdown"
            itemRender={itemRender}
          />
          {showError.app_module && (
            <div className="tutorial_create_error_text">
              {showError.app_module}
            </div>
          )}
        </div>
      </div>
    );
  };

  const itemRender = (li, itemProps) => {
    return React.cloneElement(li, {
      style: {
        height: "48px",
        lineHeight: "48px",
      },
    });
  };

  return (
    <>
      {AddPermssion ? (
        <div style={{ height: "calc(100% - 75px)" }}>
          {(detail_loading || loading) ? (
            <Loader
              type="infinite-spinner"
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                margin: "auto",
                justifyContent: "center",
                height: "100%",
              }}
            />
          ) : (
            <>
              {(submitLoading || app_modules_loading) && (
                <Loader
                  type="converging-spinner"
                  style={{
                    width: "100%",
                    height: "calc(100% - 75px)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "#ffffff4d",
                    position: "absolute",
                    zIndex: "9999",
                  }}
                />
              )}
              {showDelete && (
                <ShowDialog
                  title={"Please Confirm!"}
                  content={"Are you sure you want to delete?"}
                  cancelContent={"Cancel"}
                  confrimContent={"Delete"}
                  cancelbtn={() => setShowDelete(false)}
                  confrinbtn={actionTrigger}
                />
              )}
              {errorMessage && (
                <ShowDialog
                  title={"Error!"}
                  content={errorMessage}
                  cancelContent={"OK"}
                  cancelbtn={() => setErrorMessage('')}
                />
              )}
              {isDialogOpen && (
                <Dialog
                  title="Preview"
                  onClose={() => setIsDialogOpen(false)}
                  open={isDialogOpen}
                >
                  <div
                    style={{
                      width: "50vh",
                      height: "70vh",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    {image && (
                      <img
                        src={URL.createObjectURL(image)}
                        style={{
                          height: "100%",
                          objectFit: "cover",
                          aspectRatio: "5 / 11",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        alt="Preview"
                      />
                    )}

                    {imageLoading && (
                      <Loader
                        type="converging-spinner"
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          background: "#ffffff4d",
                          position: "absolute",
                        }}
                      />
                    )}
                  </div>

                  <DialogActionsBar>
                    <button
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base tutorial-cancel-button"
                      onClick={cancelUploadImage}
                    >
                      Cancel
                    </button>
                    <button
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base tutorial-submit-button"
                      onClick={uploadImage}
                    >
                      Upload
                    </button>
                  </DialogActionsBar>
                </Dialog>
              )}

              <div
                className="tutorial_create_header"
                //remove style if autosave needs to show
                style={{ minHeight: "49px" }}
              >
                <div
                  className="tutorial_create_back"
                  onClick={() => navigate(ADMIN_TOOLS_TUTORIAL_LIST)}
                >
                  <span className="k-icon k-font-icon k-i-arrow-chevron-left" />
                  Back
                </div>
                {isSave && (
                  <div className="tutorial_create_autosaved_icon">
                    <span className="k-icon k-font-icon k-i-check" />
                    Auto Saved
                  </div>
                )}
              </div>

              <div className="tutorial_create_scroll_box">
                <div className="tutorial_tab_content">
                  <div className="tabs">
                    <div
                      onClick={() => onTabChange(TUTORIAL_TABS.EN)}
                      className={`tab ${tab === TUTORIAL_TABS.EN ? "isactive" : ""
                        }`}
                    >
                      {TUTORIAL_TABS.EN}
                    </div>
                    <div style={{ borderLeft: "2px solid #CCCCCC" }}></div>
                    <div
                      onClick={() => onTabChange(TUTORIAL_TABS.CH)}
                      className={`tab ${tab === TUTORIAL_TABS.CH ? "isactive" : ""
                        }`}
                    >
                      {TUTORIAL_TABS.CH}
                    </div>
                  </div>
                </div>

                <DropDownBox />

                {tab == TUTORIAL_TABS.EN ? (
                  // English UI
                  <div>
                    <TextFieldBox
                      title={"Topic/Title"}
                      value={editData.title_en}
                      errorMessage={showError.title_en}
                      onChange={(e) => {
                        const value = e.target.value;
                        setEditData((prevData) => ({
                          ...prevData,
                          title_en: value,
                        }));
                        validateFields(true);

                      }}
                    />

                    <div className="row-container">
                      {engRows.map((row, index) => (
                        <div key={index} className="tutorial-row">
                          <MessageBox
                            key={`message_en_${index + 1}`}
                            title={`Message in English ${index + 1}`}
                            value={row.message}
                            errorMessage={row.message_error}
                            onChange={(e) => {
                              const value = e.html;
                              const updatedRows = engRows.map((row, i) => (i === index ? { ...row, message: value } : row));
                              setEngRows(updatedRows);
                              validateFields(true);
                            }}
                          />

                          <ImageView
                            title={`Upload Image ${index + 1}`}
                            keyProp={`image_${index + 1}`}
                            errorMessage={row.image_error}
                            isError={row.message_error}
                            file={row.image_file}
                            image_url={row.image}
                            index={index}
                          />

                          <div className="tutorial_delete_row_main_box">
                            <div className="tutorial_delete_row">
                              <span
                                className="k-icon k-font-icon k-i-delete tutorial_create_delete_row"
                                onClick={() => {
                                  setCurrentIndex(index);
                                  setShowDelete(true);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ))}

                    </div>

                  </div>
                ) : (
                  // Chinese UI
                  <div>
                    <TextFieldBox
                      title={"Topic/Title"}
                      value={editData.title_zh}
                      errorMessage={showError.title_zh}
                      onChange={(e) => {
                        const value = e.target.value;
                        setEditData((prevData) => ({
                          ...prevData,
                          title_zh: value,
                        }));
                        validateFields(true);

                      }}
                    />

                    <div className="row-container">
                      {zhRows.map((row, index) => (
                        <div key={index} className="tutorial-row">
                          <MessageBox
                            key={`message_zh_${index + 1}`}
                            title={`Message in Chinese ${index + 1}`}
                            value={row.message}
                            errorMessage={row.message_error}
                            onChange={(e) => {
                              const value = e.html;
                              const updatedRows = zhRows.map((row, i) => (i === index ? { ...row, message: value } : row));
                              setZhRows(updatedRows);
                              validateFields(true);
                            }}
                          />

                          <ImageView
                            title={`Upload Image ${index + 1}`}
                            keyProp={`image_zh_${index + 1}`}
                            errorMessage={row.image_error}
                            isError={row.message_error}
                            file={row.image_file}
                            image_url={row.image}
                            index={index}
                          />

                          <div className="tutorial_delete_row_main_box">
                            <div className="tutorial_delete_row">
                              <span
                                className="k-icon k-font-icon k-i-delete tutorial_create_delete_row"
                                onClick={() => {
                                  setCurrentIndex(index);
                                  setShowDelete(true);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ))}

                    </div>


                  </div>
                )}

                {tab == TUTORIAL_TABS.EN ? (
                  engRows.length < maxRows && (
                    <div className="tutorial_add_row_button" onClick={handleAddEngRow}> <div>+ Add Tutorial image and message</div></div>
                  )
                ) : (
                  zhRows.length < maxRows && (
                    <div className="tutorial_add_row_button" onClick={handleAddEngRow}> <div>+ Add Tutorial image and message</div></div>
                  )
                )
                }

              </div>
              <div className="tutorial_create_footer_main_box">

                <div className="tutorial_create_review_main_box">
                  <div
                    className="tutorial_create_footer_button_previous"
                    onClick={handleCancel}
                  >
                    Cancel
                  </div>
                  <div
                    className={`tutorial_create_footer_button ${!isButtonEnabled ? 'disabled' : ''}`}
                    onClick={isButtonEnabled ? handleSubmit : undefined} // Prevent click if disabled
                  >
                    Submit
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <PermissionDeninedPage
          height={"calc(100% - 76px)"}
          title={"Sorry, You don't have permission to access this page!"}
        />
      )}
    </>
  );
};
