import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { onChangeStep, onSelectCheckPeriod } from "../../../../../store/feedback-on-bus";
import { STEP_BUS_CHECKER_PERIODS } from "../../../../../store/feedback-on-bus/constant";
import { CheckPeriodValid, CreateNewFeedbackOnBus } from "../../../../../services/feedbackOnBus";
import moment from "moment";
import { useNavigate } from "react-router";
import { FIELDS } from "./constant";

export const useAction = props => {
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const [isFetching, setIsFetching] = useState(false);
  const [bsqcNamePeriod, setBSQCNamePeriod] = useState('');
  const [createdDateFrom,setCreatedDateFrom] = useState("");
  const [createdDateTo,setCreatedDateTo] = useState("");
  const [file, setFile] = useState('');
  const [filename, setFilename] = useState('');
  const [isInvalidPeriod, setInvalidPeriod] = useState(false);
  const [errors, setErrors] = useState({});

  const onChangeDate = (date, type = 'start') => {
    setErrorField(FIELDS.START_DATE, "Start and End time is required", date);
    
    if (type === 'start') {
      setCreatedDateFrom(date);

      return;
    }

    setCreatedDateTo(date);
  }

  useEffect(() => {
    const getValidPeriod = async () => {
      if (createdDateFrom && createdDateTo) {
        const [err, response] = await CheckPeriodValid(
          moment(createdDateFrom).format('YYYY-MM-DD'),
          moment(createdDateTo).format('YYYY-MM-DD')
        );

        if (!err && !response?.data?.valid) {
          setInvalidPeriod(true);
        } else {
          setInvalidPeriod(false);
        }
      }
    }

    getValidPeriod();
  }, [createdDateFrom, createdDateTo])

  const setErrorField = (field, message, value) => {
    setErrors(
      prev => ({
        ...prev,
        [field]: value ? "" : message
      })
    )

    return value ? "" : message
  }

  const onValidate = (cb) => {
    const ERRORS_FIELD = []
    const FIELDS_INPUT = [
      {
        key: FIELDS.NAME,
        data: bsqcNamePeriod,
        message: "BSQC Check period is required"
      },
      {
        key: FIELDS.FILE,
        data: file,
        message: "Upload excel is required"
      }, 
      {
        key: FIELDS.START_DATE,
        data: createdDateFrom || createdDateTo,
        message: "Start and End time is required"
      }
    ];

    FIELDS_INPUT.forEach(
      ({ key, data, message }) => {
        const isError = setErrorField(key, message, data)
        if (isError) ERRORS_FIELD.push(key)
      }
    );
  
    if (ERRORS_FIELD.length) {
      setIsFetching(false);

      return;
    }

    cb();
  }

  const onUpload = useCallback(
    async () => onValidate(
      async () => {
        setIsFetching(true);
        const form = new FormData();
  
        // Do upload csv
        form.append(FIELDS.NAME, bsqcNamePeriod);
        form.append(FIELDS.FILE, file, filename);
        form.append('filename', filename);
        form.append(FIELDS.START_DATE, moment(createdDateFrom).format('YYYY-MM-DD'));
        form.append('end_date', moment(createdDateTo).format('YYYY-MM-DD'));
    
        const [err, response] = await CreateNewFeedbackOnBus(form);
        
        console.log('response: ', response)
        if (!err && !response?.status.includes('error')) {
          onNext(response?.data?.id ?? '') 
        } else if (response?.status === 'success with error') {
          if (typeof props?.onSuccessUploadPartial === 'function') {
            props?.onSuccessUploadPartial(response?.message, response?.data?.id)
          }
        } else props?.onError(err?.message)
  
        setIsFetching(false);
      }
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bsqcNamePeriod, createdDateFrom, createdDateTo, file, filename]
  );

  const onAddFile = (e) =>{
    const file = e?.target?.files[0];

    setFile(file)
    setFilename(file?.name)
  };

  const onDeleteFile = () => {
    setFile(null);
    setFilename('');
  }

  const onChangeNamePeriod = e => setBSQCNamePeriod(e?.target?.value ?? '');

  const onNext = useCallback(
    (idUpload) => {
      dispatch(onChangeStep(STEP_BUS_CHECKER_PERIODS.LIST_DATA_UPLOADER));
      if (idUpload) {
        dispatch(onSelectCheckPeriod(idUpload));

        navigate(`/feedback-on-bus/bsqc-checker/${idUpload}`);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getErrorField = useCallback(
    (field) => errors?.[field],
    [errors]
  )
  
  return {
    errors,
    isFetching,
    bsqcNamePeriod,
    file,
    filename,
    isInvalidPeriod,
    createdDateFrom,
    createdDateTo,
    onChangeDate,
    onUpload,
    onAddFile,
    onNext,
    onDeleteFile,
    onChangeNamePeriod,
    getErrorField,
  }
}