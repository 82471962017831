import React, { useState, useEffect, useRef } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import "../../admin_tools.css";
import BackIcon from "../../../../assets/communication/Back.svg";
import { ADMIN_TOOLS_CARES_IMAGES } from "../../../../routes";
import { Loader } from "@progress/kendo-react-indicators";
import { EcardImageForm} from "./form";
import { saveEcardAccessoryImage } from "../../../../store/ecards";

export const AddEcardImage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, []);

  const handleSaveAccessory = ({name,url}) => {
    let payload = {
      name,
      url
    }
    console.log(payload);
    dispatch(saveEcardAccessoryImage(payload)).then((response) => {
      setLoading(false);
      console.log("handleSaveAccessory response:", response);
      navigate(ADMIN_TOOLS_CARES_IMAGES);
    });
  };
  return (
    <>
      <div className="detail-body">
        {loading ? (
          <Loader
            type="infinite-spinner"
            style={{
              display: "flex",
              alignItems: "center",
              width: "40px",
              margin: "auto",
              justifyContent: "center",
              height: "100vh",
            }}
          />
        ) : (
          <>
            <div className="detail-header">
              <div
                className="categoryback-group-div"
                onClick={() => navigate(ADMIN_TOOLS_CARES_IMAGES)}
              >
                <img src={BackIcon} className="categoryback-icon" />
                <span className="categoryback-label">Back</span>
              </div>
            </div>
            <div className="detail-content">
              <EcardImageForm
                data={null}
                isEdit={true}
                isCreate={true}
                submitForm={handleSaveAccessory}
                setLoading={setLoading}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
