// redux

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionToken } from "../config/userPool";

const { DR_INFRA_SYSTEM_ENDPOINT } = config.api_endpoint;

const initialState = {
  loading: false,
  system_data: [],
  error: null,
};

export const fetchAllDrInfraSystem = createAsyncThunk(
  "dr_infra/fetchAllDrInfraSystem",
  async (query, { rejectWithValue }) => {
    try {
      let q_params = {}
      if (query["systemName"]) {
        q_params["system_name"] = query["systemName"]
      }

      if (query["subSystemName"]) {
        q_params["subsystem_name"] = query["subSystemName"]
      }

      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(DR_INFRA_SYSTEM_ENDPOINT, q_params, token);
      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

const DrInfraSystem = createSlice({
  name: "system",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllDrInfraSystem.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllDrInfraSystem.fulfilled, (state, action) => {
        state.loading = false;
        state.system_data = action.payload;
      })
      .addCase(fetchAllDrInfraSystem.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

const DrInfraSystemReducer = DrInfraSystem.reducer;
export default DrInfraSystemReducer;
