import React, { useState } from "react";
import { useNavigate } from "react-router";
// redux
import { useDispatch, useSelector } from "react-redux";
import { createEmailGroup } from "../../../../../store/emailGroup";
// kendo
import { Loader } from "@progress/kendo-react-indicators";
// routes
import { ADMIN_TOOLS_EMAIL_GROUP_LIST } from "../../../../../routes";
// component
import { EmailGroupForm } from "../form";
// image
import BackIcon from "../../../../../assets/communication/Back.svg";
// css
import "../email_group.css";

export const EmailGroupAddNew = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // redux
  const {
    create_email_group,
    create_email_group_error,
    create_email_group_loading,
  } = useSelector((state) => state.emailGroup);

  // state
  const [loading, setLoading] = useState(false);

  // method
  const handleSaveGroup = (data) => {
    console.log("handleSaveGroup: ", data);
    dispatch(createEmailGroup(data)).then(() => {
      setLoading(false);
      navigate(ADMIN_TOOLS_EMAIL_GROUP_LIST);
    });
  };
  return (
    <>
      <div className="email-group-detail-body">
        {loading || create_email_group_loading ? (
          <Loader
            type="infinite-spinner"
            style={{
              display: "flex",
              alignItems: "center",
              width: "40px",
              margin: "auto",
              justifyContent: "center",
              height: "100vh",
            }}
          />
        ) : (
          <>
            <div className="email-group-detail-header">
              <div
                className="email-group-detail-group-div"
                onClick={() => navigate(ADMIN_TOOLS_EMAIL_GROUP_LIST)}
              >
                <img src={BackIcon} className="email-group-detail-icon" />
                <span className="email-group-detail-label">Back</span>
              </div>
            </div>
            <div className="email-group-detail-content">
              <EmailGroupForm
                id={null}
                data={null}
                isEdit={true}
                setIsEdit={true}
                setLoading={setLoading}
                submitForm={handleSaveGroup}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
