import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../config/api";
import config from "../../config/config";
import { getCurrentLoggedInSessionTokenWithPromise } from "../../config/userPool";

const {
  CARES_APREWALL_DETAIL_ENDPOINT,
  AUDIT_ENDPOINT,
  CARES_APREWALL_DELETE_ENDPOINT,
  CARES_APREWALL_LISTING_ENDPOINT,
  CARES_APREWALL_UPDATE_STATUS,
} = config.api_endpoint;

const initialState = {
  loading: false,
  error: null,
  detail_data: {},
  listing_data: [],
  audit_data: [],
};

export const fetchApreDetail = createAsyncThunk(
  "cares/fetchApreDetail",
  async (id, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(
        CARES_APREWALL_DETAIL_ENDPOINT + "/" + id,
        {},
        token
      );
      return results;
    } catch (err) {
      return rejectWithValue(err.details);
    }
  }
);

export const fetchApreAudit = createAsyncThunk(
  "cares/fetchApreAudit",
  async (id, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(
        `${AUDIT_ENDPOINT}?table_name=cares&source_id=${id}`,
        {},
        token
      );
      return results;
    } catch (err) {
      return rejectWithValue(err.details);
    }
  }
);

export const fetchApreListing = createAsyncThunk(
  "cares/fetchApreListing",
  async (_, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(CARES_APREWALL_LISTING_ENDPOINT, {}, token);
      return results;
    } catch (err) {
      return rejectWithValue(err.details);
    }
  }
);

export const fetchApreDelete = createAsyncThunk(
  "cares/fetchApreDelete",
  async (id, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.post(
        CARES_APREWALL_DELETE_ENDPOINT,
        JSON.stringify({ id: id }),
        token
      );
      console.log(results);
      return results;
    } catch (err) {
      return rejectWithValue(err.details);
    }
  }
);

export const fetchApreUpdateStatus = createAsyncThunk(
  "cares/fetchApreUpdateStatus",
  async (params, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.post(
        CARES_APREWALL_UPDATE_STATUS,
        JSON.stringify(params),
        token
      );
      console.log(results);
      return results;
    } catch (err) {
      return rejectWithValue(err.details);
    }
  }
);

const aprewallSlice = createSlice({
  name: "arepwall",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchApreDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchApreDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.detail_data = action.payload.data;
      })
      .addCase(fetchApreDetail.rejected, (state, action) => {
        state.loading = true;
        state.error = action.err.message;
      })
      .addCase(fetchApreAudit.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchApreAudit.fulfilled, (state, action) => {
        state.loading = false;
        state.audit_data = action.payload.data;
      })
      .addCase(fetchApreAudit.rejected, (state, action) => {
        state.loading = true;
        state.error = action.err.message;
      })
      .addCase(fetchApreDelete.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchApreDelete.fulfilled, (state, action) => {
        state.loading = false;
        state.audit_data = action.payload.message;
      })
      .addCase(fetchApreDelete.rejected, (state, action) => {
        state.loading = true;
        state.error = action.err.message;
      })
      .addCase(fetchApreListing.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchApreListing.fulfilled, (state, action) => {
        state.loading = false;
        state.listing_data = action.payload.data;
      })
      .addCase(fetchApreListing.rejected, (state, action) => {
        state.loading = true;
        state.error = action.err.message;
      })
      .addCase(fetchApreUpdateStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchApreUpdateStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.listing_data = action.payload;
      })
      .addCase(fetchApreUpdateStatus.rejected, (state, action) => {
        state.loading = true;
        state.error = action.err.message;
      });
  },
});

const aprewallReducer = aprewallSlice.reducer;
export default aprewallReducer;
