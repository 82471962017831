import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import config from "../../config/config";
import { getCurrentLoggedInSessionTokenWithPromise } from "../../config/userPool";
import api from "../../config/api";

const {
  HAPPENING_ARTICLES_ENDPOINT,
  HAPPENING_ARTICLES_CREATE_UPDATE_ENDPOINT,
  HAPPENING_ARTICLES_DETAIL_ENDPOINT,
  HAPPENING_ARTICLES_UPDATE_STATUS_ENDPOINT,
  HAPPENING_ARTICLES_DELETE_ENDPOINT,
  HAPPENING_ARTICLES_EXPORT_ENDPOINT,
  AUDIT_ENDPOINT,
} = config.api_endpoint;

const initialState = {
  loading: false,
  auditLoading: false,
  error: null,
  detail_data: {},
  listing_data: [],
  create_data: {},
  update_data: {},
  delete_data: {},
  audit_data: [],
  filter_data: [],
  export_data: [],
};

export const fetchArticlesListing = createAsyncThunk(
  "articles/fetchArticlesListing",
  async (_, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(HAPPENING_ARTICLES_ENDPOINT, {}, token);
      return results;
    } catch (err) {
      return rejectWithValue(err.details);
    }
  }
);

export const fetchArticlesFilter = createAsyncThunk(
  "articles/fetchArticlesFilter",
  async (params, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(
        `${HAPPENING_ARTICLES_ENDPOINT}?${params}`,
        {},
        token
      );
      return results;
    } catch (err) {
      return rejectWithValue(err.details);
    }
  }
);

export const fetchArticlesDetail = createAsyncThunk(
  "articles/fetchArticlesDetail",
  async (id, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(
        HAPPENING_ARTICLES_DETAIL_ENDPOINT,
        { id: id },
        token
      );
      return results;
    } catch (err) {
      return rejectWithValue(err.details);
    }
  }
);

export const fetchArticlesCreateUpdate = createAsyncThunk(
  "articles/fetchArticlesCreateUpdate",
  async (params, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.post(
        HAPPENING_ARTICLES_CREATE_UPDATE_ENDPOINT,
        JSON.stringify(params),
        token
      );
      return results;
    } catch (err) {
      return rejectWithValue(err.details);
    }
  }
);

export const fetchArticlesUpdateStatus = createAsyncThunk(
  "articles/fetchArticlesUpdateStatus",
  async (params, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.post(
        HAPPENING_ARTICLES_UPDATE_STATUS_ENDPOINT,
        JSON.stringify(params),
        token
      );
      return results;
    } catch (err) {
      return rejectWithValue(err.details);
    }
  }
);

export const fetchArticlesDelete = createAsyncThunk(
  "articles/fetchArticlesDelete",
  async (id, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.post(
        HAPPENING_ARTICLES_DELETE_ENDPOINT,
        JSON.stringify({ id: id }),
        token
      );
      return results;
    } catch (err) {
      return rejectWithValue(err.details);
    }
  }
);

export const fetchArticlesExport = createAsyncThunk(
  "articles/fetchArticlesExport",
  async (_, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.post(
        HAPPENING_ARTICLES_EXPORT_ENDPOINT,
        JSON.stringify({}),
        token
      );
      return results;
    } catch (err) {
      return rejectWithValue(err.details);
    }
  }
);

export const fetchArticlesAudit = createAsyncThunk(
  "articles/fetchArticlesAudit",
  async (id, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(
        `${AUDIT_ENDPOINT}?table_name=happenings-article&source_id=${id}`,
        {},
        token
      );
      return results;
    } catch (err) {
      return rejectWithValue(err.details);
    }
  }
);

const articlesSlice = createSlice({
  name: "articles",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchArticlesListing.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchArticlesListing.fulfilled, (state, action) => {
        state.loading = false;
        state.listing_data = action.payload.data;
      })
      .addCase(fetchArticlesListing.rejected, (state, action) => {
        state.loading = true;
        state.error = action.err.message;
      })
      .addCase(fetchArticlesFilter.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchArticlesFilter.fulfilled, (state, action) => {
        state.loading = false;
        state.filter_data = action.payload.data;
      })
      .addCase(fetchArticlesFilter.rejected, (state, action) => {
        state.loading = true;
        state.error = action.err.message;
      })
      .addCase(fetchArticlesDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchArticlesDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.detail_data = action.payload.data;
      })
      .addCase(fetchArticlesDetail.rejected, (state, action) => {
        state.loading = true;
        state.error = action.err.message;
      })
      .addCase(fetchArticlesCreateUpdate.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchArticlesCreateUpdate.fulfilled, (state, action) => {
        state.loading = false;
        state.create_data = action.payload.data;
      })
      .addCase(fetchArticlesCreateUpdate.rejected, (state, action) => {
        state.loading = true;
        state.error = action.err.message;
      })
      .addCase(fetchArticlesUpdateStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchArticlesUpdateStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.update_data = action.payload.data;
      })
      .addCase(fetchArticlesUpdateStatus.rejected, (state, action) => {
        state.loading = true;
        state.error = action.err.message;
      })
      .addCase(fetchArticlesDelete.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchArticlesDelete.fulfilled, (state, action) => {
        state.loading = false;
        state.delete_data = action.payload.data;
      })
      .addCase(fetchArticlesDelete.rejected, (state, action) => {
        state.loading = true;
        state.error = action.err.message;
      })
      .addCase(fetchArticlesExport.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchArticlesExport.fulfilled, (state, action) => {
        state.loading = false;
        state.export_data = action.payload.data;
      })
      .addCase(fetchArticlesExport.rejected, (state, action) => {
        state.loading = true;
        state.error = action.err.message;
      })
      .addCase(fetchArticlesAudit.pending, (state) => {
        state.auditLoading = true;
      })
      .addCase(fetchArticlesAudit.fulfilled, (state, action) => {
        state.auditLoading = false;
        state.audit_data = action.payload.data;
      })
      .addCase(fetchArticlesAudit.rejected, (state, action) => {
        state.auditLoading = true;
        state.error = action.err.message;
      });
  },
});

const articlesReducer = articlesSlice.reducer;
export default articlesReducer;
