import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import "../../communication.css";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { Icon } from "@progress/kendo-react-common";
import { ADD_NOTIFICATION_INFORMATION } from "../../../../routes";

import { ViewForm } from "./view";
import { shouldShowAddNew } from "../permission_checker";

export const Notification_Details = (props) => {
  const navigate = useNavigate();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const handleEditBtn = () => {
    query.set("state", 0);
    navigate(ADD_NOTIFICATION_INFORMATION + "?" + query.toString());
  };
  return (
    <>
      <div className="recall-tab-div">
        <div className="recall-tab-group">
          <a className="selected-tab">Notification</a>
          <a onClick={() => props.onChange(1)}>Recipients</a>
        </div>
        {props.data.status === "Draft" && shouldShowAddNew() && (
          <Button onClick={handleEditBtn}>
            <Icon name="edit" />
            Edit
          </Button>
        )}
      </div>
      <div className="recall-info">
        <ViewForm data={props.data} createdUser={props.createdUser} updatedUser={props.updatedUser} />
      </div>
    </>
  );
};
