import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { 
  Grid, 
  GridColumn,
  getSelectedState,
} from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";

import { orderBy } from "@progress/kendo-data-query";
import { useDispatch } from "react-redux";
import "./table.css";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { CARES_ECARDS_VIEW_DETAILS } from "../../routes";
import { Confirmation } from "../dialog/confirmation";

import { Dialog } from "@progress/kendo-react-dialogs";
import {
  checkMultiPermission,
  checkPermission,
  getUserPermission,
} from "../../config/permission_helper";

const initialDataState = {
  skip: 0,
  take: 10,
  dir: "asc",
};
const initialSort = [
  {
    field: "cares_date",
    dir: "desc",
  },
];

const itemRender = (li, itemProps) => {
  const index = itemProps.index;
  const itemChildren = (
    <span
      style={{
        display: "flex",
      }}
    >
      {li.props.children}
    </span>
  );
  return React.cloneElement(li, li.props, itemChildren);
};

const valueRender = (element, value) => {
  if (!value) {
    return element;
  }
  const children = [
    <span>&nbsp;&nbsp;&nbsp;&nbsp; {element.props.children}</span>,
  ];
  return React.cloneElement(
    element,
    {
      ...element.props,
    },
    children
  );
};

const DATA_ITEM_KEY = "id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

export const ECardTableBody = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(initialDataState);
  const [pageSizeValue, setPageSizeValue] = useState();
  const [sort, setSort] = useState(initialSort);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedID, setSelectedID] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [dataState, setDataState] = React.useState(props.data?.map((dataItem) =>
    Object.assign(
      {
        selected: false,
      },
      dataItem
    )
  ));
  const [selectedState, setSelectedState] = React.useState({});
  useEffect(()=>{
    if(props.data){
      setDataState(props.data.map((dataItem) =>
        Object.assign(
          {
            selected: false,
          },
          dataItem
        )
      ))
    }
  },[props.data]); 
  const onSelectionChange = React.useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      let selectedRowIDS = [];
      for (const [key, value] of Object.entries(newSelectedState)) {
        if(value){
          selectedRowIDS.push(key);
        }
      }
      console.log(selectedRowIDS);
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );
  const onHeaderSelectionChange = React.useCallback((event) => {
    console.log(event);
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};
    const selectedRowIDS = [];
    event.dataItems.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
      if(checked){
        selectedRowIDS.push(idGetter(item));
      }
    });
    console.log(selectedRowIDS);
    setSelectedState(newSelectedState);
  }, []);

  const userPermissions = getUserPermission();
  const checkDelete = checkPermission(userPermissions,"cares.delete_cares_ecard");
  const checkUpdateStatus = checkPermission(userPermissions,"cares.update_cares_ecard_status")

  const table_action = ((status)=>{
    let actions = ["View Details"]
    if(checkUpdateStatus){
      if (status === "published"){
        actions.push("Unpublish");
      }else{
        actions.push("Publish");
      }
    }
    if(checkDelete){
      actions.push("Delete");
    }
    return actions
  });
  const onItemActionChange = (e, val) => {
    const selected = e.target.value;
    console.log(selected);
    const { id } = val;

    if (selected === "View Details") {
      navigate(`${CARES_ECARDS_VIEW_DETAILS}?id=${id}`);
    } else if (selected === "Delete") {
      setSelectedID(val.id);
      setShowDeleteConfirmation(true);
    } else {
      console.log("Update Status", val.id);
      setSelectedID(val.id);
      setSelectedStatus(selected);
      setShowConfirm(true);
    }
  };
  const handleConfirmUpdateStatus = () => {
    props.updateStatus({
      id: selectedID,
      status: selectedStatus === "Unpublish" ? "unpublish" : "publish",
    });
    setShowConfirm(false);
  };
  const handlePreviousPage = () => {
    props.handlePreviouscallback();
  };

  const handleNextPage = () => {
    props.handlenextpagecallback();
  };
  const handleConfirmDelete = () => {
    console.log(selectedID);
    setShowDeleteConfirmation(false);
    props.onDeleteECard({ id: selectedID });
  };

  return (
    <div className="tbody-content">
      {showConfirm && (
        <Confirmation
          message={"Are you sure you want to update visibility?"}
          onClickYes={handleConfirmUpdateStatus}
          onClickNo={() => setShowConfirm(false)}
        />
      )}
      {showDeleteConfirmation && (
        <Confirmation
          message={"Are you sure want to delete this eCard?"}
          onClickNo={() => setShowDeleteConfirmation(false)}
          onClickYes={handleConfirmDelete}
        />
      )}
      <Grid
        data={dataState? orderBy(dataState?.map((item) => ({
          ...item,
          [SELECTED_FIELD]: selectedState[idGetter(item)],
        })).slice(page.skip, page.take + page.skip), sort):[]}
        skip={page.skip}
        take={page.take}
        total={props.data?.length}
        pageable={{
          buttonCount: 2,
          pageSizes: false,
          info: false,
        }}
        sortable={true}
        sort={sort}
        onSortChange={(e) => {
          setSort(e.sort);
        }}
        dataItemKey={DATA_ITEM_KEY}
        selectedField={SELECTED_FIELD}
        selectable={{
          enabled: true,
          drag: false,
          cell: false,
          mode: "multiple",
        }}
        onSelectionChange={onSelectionChange}
        onHeaderSelectionChange={onHeaderSelectionChange}
      >
        <GridColumn
          field={SELECTED_FIELD}
          width="65px"
          headerSelectionValue={
            dataState?.findIndex((item) => !selectedState[idGetter(item)]) === -1
          }
        />
        <GridColumn
          title={"Business Area"}
          field={"from_user_businessarea"}
          width={130}
          cell={(itemprops) => {
            if (itemprops.dataItem[props.field] !== "") {
              return (
                <td className="k-table-td">
                  {
                    props.business_areas?.filter(
                      ({ code }) => code === itemprops.dataItem[itemprops.field]
                    )[0]?.name
                  }
                </td>
              );
            }
            return <td>{itemprops.dataItem[itemprops.field]}</td>;
          }}
        />
        <GridColumn
          field="from_user_dept"
          title="Department"
          width={130}
          cell={(itemprops) => {
            if (itemprops.dataItem[props.field] !== "") {
              return (
                <td className="k-table-td">
                  {
                    props.depots?.filter(
                      ({ id }) => id === itemprops.dataItem[itemprops.field]
                    )[0]?.name
                  }
                </td>
              );
            }
            return <td>{itemprops.dataItem[itemprops.field]}</td>;
          }}
        />
        <GridColumn field="from_user.name" title="Name" width={150} />
        <GridColumn field="from_user.designation" title="Designation" width={130}/>
        <GridColumn field="cares_date" title="CARES date" width={120}/>
        <GridColumn
          field="service_standard_numbers"
          title="Service Standards"
          width={100}
        />
        <GridColumn field="recognised_by.name" title="Recognised By" width={130}/>
        <GridColumn field="status" title="Status" width={130}/>
        <GridColumn
          width={160}
          sortable={false}
          field="Action"
          cell={(props) => (
            <td className="k-table-td">
              <DropDownList
                style={{
                  fontSize: "15px",
                  width: "120px",
                  height: "48px",
                  backgroundColor: "#EB6E1F",
                  color: "white",
                  textAlign: "center",
                  borderRadius: "8px",
                }}
                defaultValue={"Action"}
                // data={
                //   props.dataItem.status === "published"
                //     ? ["View Details", "Unpublish", "Delete"]
                //     : ["View Details", "Publish", "Delete"]
                // }
                data={table_action(props.dataItem.status)}
                onChange={(e) => onItemActionChange(e, props.dataItem)}
                size={"large"}
                fillMode={"solid"}
                rounded={"large"}
                valueRender={valueRender}
                itemRender={itemRender}
              />
            </td>
          )}
        />
      </Grid>

      {/* <div className="table-custom-pagination">
        <div
          class="k-pager k-pager-md k-grid-pager"
          role="application"
          aria-roledescription="pager"
          style={{ border: "none" }}
        >
          <div class="k-pager-numbers-wrap">
            <button
              title="Go to the previous page"
              role="button"
              aria-disabled="true"
              class="k-button k-button-md k-button-flat k-button-flat-base k-icon-button k-pager-nav"
              disabled={!props.is_previous}
              onClick={handlePreviousPage}
            >
              <span
                class="k-icon k-i-caret-alt-left k-button-icon"
                role="presentation"
              ></span>
            </button>
            <div class="k-pager-numbers">
              {props.data.length > 0 && (
                <>
                  {props.length > 4 ? (
                    <></>
                  ) : (
                    <>
                      {props.alldata &&
                        props.alldata.map((v, i) => (
                          <button
                            role="button"
                            aria-label="undefined 1"
                            aria-current="true"
                            class={`k-button k-button-md k-button-flat k-button-flat-primary ${
                              props.pagenumber === i ? `k-selected` : ""
                            }`}
                            onClick={() => props.handlePageChange(i)}
                          >
                            <span class="k-button-text">{i + 1}</span>
                          </button>
                        ))}
                    </>
                  )}
                </>
              )}
            </div>
            <button
              title="Go to the next page"
              role="button"
              aria-disabled="true"
              class="k-button k-button-md k-button-flat k-button-flat-base k-icon-button k-pager-nav"
              disabled={props.is_next}
              onClick={handleNextPage}
            >
              <span
                class="k-icon k-i-caret-alt-right k-button-icon"
                role="presentation"
              ></span>
            </button>
          </div>
        </div>
      </div> */}
    </div>
  );
};
