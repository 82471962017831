import { useEffect, useMemo, useRef, useState } from "react"
import { Buffer } from 'buffer';
import { GetSummaryCount, GetFeedbackList, GetFeedbackDetail } from "../../../services/feedbackOnBus";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export const useGetSummaryCountQuery = () => {
  const [error, setError] = useState("");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const GetData = async () => {
    setLoading(true);
    const [err, response] = await GetSummaryCount();

    if (err) {
      setError(err)

      return;
    }

    if (error) setError("");
    setData(response?.data);

    setLoading(false);
  }

  useEffect(() => {
    GetData();
  }, []);

  const refetch = async () => await GetData();

  return { data, loading, error, refetch }
}

export const useLazyQuery = (Request) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [data, setData] = useState(null);

  const GetData = async (payload = {}) => {
    const [err, response] = await Request(payload);

    if (err) {
      setError(err)
      setLoading(false);

      return;
    }

    if (error) setError("");
    setData(response?.data);
    
    setLoading(false);
  }

  const refetch = async (payload = {}) => {
    setLoading(true);
    const [err, response] = await Request(payload);

    if (err) {
      setError(err)
      setLoading(false);

      return;
    }

    if (error) setError("");
    setData(response?.data);
    setLoading(false);

  }

  return [GetData, { data, error, loading, refetch }]
}

const useQueryString = () => {
  const location = useLocation();

  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const queryString = useMemo(
    () => {
      // Convert the params to an object
      const paramsObject = {};

      for (const [key, value] of queryParams) {
        paramsObject[key] = value;
      }

      return paramsObject
    },
    [queryParams]
  )

  return { queryParams, values: queryString }
}

export const useFeedbackDetails = () => {
  const [
    getDetailFeedback, 
    {
      data,
      error,
      loading,
      refetch
    }
  ] = useLazyQuery(GetFeedbackDetail);
  const { id, sk } = useParams();

  useEffect(() => {
    if (id && sk) {
      const idSk = Buffer.from(sk, 'base64').toString('ascii');

      console.log('{ id, sk: decodeURIComponent(idSk) }" ', { id, sk: decodeURIComponent(idSk) })
      getDetailFeedback({ id, sk: decodeURIComponent(idSk) })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, sk])

  console.log("data: ", data);

  return { data }
}

export const useFeedbackListQuery = () => {
  const _mounted = useRef(null);
  const [
    getDetail, 
    {
      data,
      error,
      loading,
      refetch
    }
  ] = useLazyQuery(GetFeedbackList);
  const navigate = useNavigate();
  const { values: queryString } = useQueryString();

  useEffect(() => {
    if (!_mounted.current) {
      console.log("CALLED")
      getDetail();

      _mounted.current = true;
    }
  }, [_mounted])

  useEffect(() => {
    if (queryString) {
      refetch(queryString)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString])

  const onFilter = (payload = {}) => {
    const queryParams = { ...queryString, ...payload };
    const params = new URLSearchParams();

    for (const key in queryParams) {
      if (queryParams.hasOwnProperty(key)) {
        params.append(key, queryParams[key]);
      }
    }
    // Use navigate with the query option to navigate with the specified query string
    navigate(`/feedback-on-bus/summary-of-check?${params.toString()}`);
  }

  const onRefresh = () => refetch(queryString)

  return {
    data,
    error,
    loading,
    queryString,
    onFilter,
    onRefresh
  }
} 
