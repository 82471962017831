import React, { useEffect, useRef, useState } from "react";
import {
  ConfigData,
  DefaulSorting,
  dropdownConfig,
  statusConfig,
} from "./configData";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "@progress/kendo-react-indicators";
import SearchIcon from "../../../assets/header/search.svg";
import FilterIcon from "../../../assets/header/filter.svg";
import RefreshIcon from "../../../assets/header/refresh.svg";
import UploadIcon from "../../../assets/header/ic-upload.svg";
import { TableHeader } from "../../../components/reusableItems/header";
import { Reusable_table } from "../../../components/reusableItems/table";
import { Search } from "../../../components/reusableItems/search";
import { searchFilter } from "../../../components/reusableItems/filter/filter_helper";

import {
  fetchAllClinics,
  deleteClinicById,
  getPresignedURL,
  uploadClinic
} from "../../../store/clinic";
import api from "../../../config/api";

import "./clinic.css";
import { useLocation, useNavigate } from "react-router";
import { PermissionDeninedPage } from "../../../components/reusableItems/permission_denined";
import {
  checkPermission,
  getUserPermission,
} from "../../../config/permission_helper";
import moment from "moment";
import { ShowDialog } from "../../../components/reusableItems/dialog";
import { fetchUserById } from "../../../store/userMgmt";
import { Button } from "@progress/kendo-react-buttons";
import { Filter } from "./filter";

export const ClinicListing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { searchQuery } = useLocation();
  const exportLink = useRef(null);
  const query = new URLSearchParams(searchQuery);
  const { loading, clinic_list } = useSelector(
    (state) => state.clinic
  );
  const [tempData, setTempData] = useState([]);
  const [mainLoading, setMainLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [actionID, setActionID] = useState("");
  const [actionType, setActionType] = useState("");
  const [search, setSearch] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(false);
  const [filterValue, setFilterValue] = useState({
    hsc: "",
    claimable: "",
  });
  const [file, setFile] = useState(null);
  const [showUpload, setShowUpload] = useState(false);

  const fileInputRef = useRef();

  const userPermissions = getUserPermission();
  const viewPermssion = checkPermission(
    userPermissions,
    "clinic.access_clinic"
  );
  const uploadPermssion = checkPermission(
    userPermissions,
    "clinic.upload_clinic"
  );


  const getData = async (callback) => {
    setMainLoading(true);
    dispatch(fetchUserById(localStorage.getItem("username"))).then(
      async (result) => {
        dispatch(fetchAllClinics(filterValue)).then((res) => {
          setTempData(
            res.payload.data.map((item, index) => ({
              ...item,
              no: index + 1
            }))
          );
          setMainLoading(false);
          setShouldFetch(false);
        });
      }
    );
  };

  useEffect(() => {
    getData();
  }, []);


  const handleCreateAction = () => {
  };

  const RefreshData = () => {
    getData();
  };

  const handleFilterAction = () => {
    setShowFilter(!showFilter);
  };

  const handleSearchAction = () => {
    setSearch(!search);
  };


  const handleFileChange = (e) => {

    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setShowUpload(true);
  };



  const icons = [
    {
      icon: FilterIcon,
      action: handleFilterAction,
    },
    {
      icon: RefreshIcon,
      action: RefreshData,
    },
    {
      icon: SearchIcon,
      action: handleSearchAction,
    },
  ];

  const actionTrigger = () => {
    setMainLoading(true);
    if (actionType === "Delete") {
      setShowDelete(false);
      dispatch(deleteClinicById(actionID)).then((res) => {
        getData();
      });
    }
  };


  const getFileExtension = (filename) => {
    // Get the last part of the filename after the last dot
    const lastDotIndex = filename.lastIndexOf(".");

    // If there is no dot or it's the first character, return an empty string
    if (lastDotIndex === -1 || lastDotIndex === 0) {
      return "";
    }

    // Return the file extension
    return filename.substring(lastDotIndex + 1);
  };

  const uploadActionTrigger = async () => {
    if (file) {
      const extension_ = getFileExtension(file.name);
      const data = {
        extension: extension_,
      };
      setMainLoading(true);
      setShowUpload(false);

      dispatch(getPresignedURL(data)).then(async (res) => {
        console.log(res.payload.data.presigned_url);

        api.postDiff(res.payload.data.presigned_url, file).then((res1) => {
          if (res1 === 200) {
            const params = {
              file: res.payload.data.key
            }
            dispatch(uploadClinic(params)).then(async (res) => {
              setFile(null);
              getData();
            });

          } else {
            alert("An error occured while uploading file! Please try again!");
          }
        });
      });
    }
    setShowUpload(false);
  };


  const handleTableClick = (type, id) => {
    console.log(type, id);
    if (type === "Delete") {
      setActionID(id);
      setActionType(type);
      if (type === "Delete") {
        setShowDelete(true);
      }
    }
  };



  const handleSearch = (value) => {
    setSearchValue(value);
    if (value != "") applySearch(value);
  };

  const handleClear = async () => {
    setSearchLoading(true);
    const data = clinic_list.data.map((item, index) => ({
      ...item,
      no: index + 1
    }));

    setTempData(data);
    setSearchValue("");
     // Add a 1-second delay before setting loading to false
     await new Promise(resolve => setTimeout(resolve, 1000));
    setSearchLoading(false);

  };

  const searchFilterByMultipleColumn = (data, columns, searchValue) => {
    if (!searchValue) return data; // Return all data if searchValue is empty

    const lowerSearchValue = searchValue.toLowerCase();

    const tempData = [];

    data.forEach((item) => {
      const matches = columns.some((col) => {
        const itemValue = item[col.id];

        if (typeof itemValue === 'string') {
          const valueToCheck = col.caseSensitive ? itemValue : itemValue.toLowerCase();
          return valueToCheck.includes(lowerSearchValue);
        }
        return false;
      });

      // Add only if it matches and is not already added
      if (matches && !tempData.find((i) => i.id === item.id)) {
        tempData.push(item);
      }
    });

    return tempData;
  };

  const applySearch = async (searchValue) => {
    // Check if searchValue is defined and not empty
    setSearchLoading(true);
    if (!searchValue) {
      const data = clinic_list.data.map((item, index) => ({
        ...item,
        no: index + 1
      }));

      setTempData(data);
      console.log(data);

      setSearchLoading(false);
      return;
    }


    let searchColumn = [
      { id: "tpa", caseSensitive: false },
      { id: "region", caseSensitive: false },
      { id: "area", caseSensitive: false },
      { id: "clinic_name", caseSensitive: false },
      { id: "address", caseSensitive: false },
      { id: "remarks", caseSensitive: false },
      { id: "phone", caseSensitive: false },
    ];

    // Add index number to each item in data
    const data = clinic_list.data.map((item, index) => ({
      ...item,
      no: index + 1
    }));

    // Call the search function with proper arguments
    const filtered = await searchFilterByMultipleColumn(data, searchColumn, searchValue);

    setTempData(filtered);
    setSearchLoading(false);
  };



  const FilterAction = () => {
    setShowFilter(false);

    getData();
  }


  const filterClearAction = async () => {
    setShowFilter(false);
    setFilterValue({
      ...filterValue,
      hsc: "",
      claimable: "",
    });
    setShouldFetch(true);
  };

  useEffect(() => {
    if (shouldFetch) {
      getData();
      setShouldFetch(false);
    }
  }, [filterValue, shouldFetch]);

  return (
    <>
      {viewPermssion ? (
        <div className="container">

          <div className="reusable_header_main_box">
            <div className="reusable_header_title">Manage Panel GP Clinics</div>
            <div className="reusable_header_icon_main_box">
              {
                uploadPermssion && (
                  <>

                    <div
                      key="upload_excel"
                      className="reusable_header_create_box upload-btn"
                      onClick={() => {
                        fileInputRef.current.click();
                      }}
                    >
                      <img src={UploadIcon} />
                      Upload Excel
                    </div>
                    <input
                      accept=".xlsx, .csv"
                      ref={fileInputRef}
                      key={'file'}
                      type="file"
                      hidden
                      onChange={(e) => handleFileChange(e)} // Pass keyProp here
                    />
                  </>
                )
              }
              {icons.map((i, id) => {
                if (i.buttonName) {
                  return (
                    <div
                      key={id}
                      className="reusable_header_create_box"
                      onClick={i.action}
                    >
                      {i.buttonName}
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={id}
                      className="reusable_header_icon_box"
                      onClick={i.action}
                    >
                      <img src={i.icon} />
                    </div>
                  );
                }
              })}
            </div>
          </div>

          {search && (
            <Search
              handleSearch={handleSearch}
              type={"text"}
              searchValue={searchValue}
              placeholder={"Search"}
              handleClear={handleClear}
              applySearch={applySearch}
            />
          )}
          {showDelete && (
            <ShowDialog
              title={"Please Confirm!"}
              content={"Are you sure you want to delete?"}
              cancelContent={"Cancel"}
              confrimContent={"Delete"}
              cancelbtn={() => setShowDelete(false)}
              confrinbtn={actionTrigger}
            />
          )}
          {showFilter && (
            <Filter
              setShowFilter={setShowFilter}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              FilterAction={FilterAction}
              filterClearAction={filterClearAction}
            />
          )}

          {showUpload && (
            <ShowDialog
              title={"Please Confirm!"}
              content={"Are you sure you want to upload excel?"}
              cancelContent={"Cancel"}
              confrimContent={"Upload"}
              cancelbtn={() => {
                setShowUpload(false);
                setFile(null);
              }}

              confrinbtn={() => {
                uploadActionTrigger();
              }}
            />
          )}

          {loading || mainLoading || searchLoading ? (
            <Loader
              type="converging-spinner"
              style={{
                width: "100%",
                height: "calc(100vh - 165px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#ffffff4d",
                position: "absolute",
                zIndex: "999",
              }}
            />
          ) : (
            <>

              <Reusable_table
                header={ConfigData}
                data={tempData}
                pagination={true}
                calculatePagi={true}
                pageChangeLoading={false}
                showRow={true}
                checkbox={false}
                defaultSorting={DefaulSorting}
                //   handleTableClick={handleTableClick}
                handleAction={handleTableClick}
                maxHeight={
                  search ? "calc(-282px + 100vh)" : "calc(100vh - 220px)"
                }
                maxWidth={"calc(100vw - 240px)"}
              />
            </>
          )}
        </div>
      ) : (
        <PermissionDeninedPage
          height={"calc(100% - 76px)"}
          title={"Sorry, You don't have permission to access this page!"}
        />
      )}
    </>
  );
};
