import React, { useEffect, useState } from "react";
import "./articles.css";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchArticlesAudit,
  fetchArticlesDelete,
  fetchArticlesDetail,
  fetchArticlesUpdateStatus,
} from "../../../store/happenings/articles";
import EditIcon from "../../../assets/common/EditIcon.svg";
import { useLocation, useNavigate } from "react-router";
import {
  HAPPENINGS_ARTICLES_DUPLICATE_ROUTE,
  HAPPENINGS_ARTICLES_EDIT_ROUTE,
  HAPPENINGS_ARTICLES_ROUTE,
} from "../../../routes";
import moment from "moment";
import { Loader } from "@progress/kendo-react-indicators";
import { CustomStep } from "../../../components/common/custom_steps";
import { Stepper } from "@progress/kendo-react-layout";
import {
  checkPermission,
  getUserPermission,
} from "../../../config/permission_helper";
import { ShowDialog } from "../../../components/reusableItems/dialog";
import { PermissionDeninedPage } from "../../../components/reusableItems/permission_denined";

export const Article_Detail = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const id = query.get("id");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showDelete, setShowDelete] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [tempStatus, setTempStatus] = useState("");
  const [detailLoading, setDetailLoading] = useState(false);
  const { loading, detail_data, audit_data, auditLoading } = useSelector(
    (state) => state.articles
  );
  const userPermissions = getUserPermission();
  const viewPermssion = checkPermission(
    userPermissions,
    "happening.access_happening_article_list"
  );
  const DeletePermssion = checkPermission(
    userPermissions,
    "happening.delete_happening_article"
  );
  const EditPermssion = checkPermission(
    userPermissions,
    "happening.edit_happening_article"
  );
  const AddPermssion = checkPermission(
    userPermissions,
    "happening.create_happening_article"
  );

  useEffect(() => {
    dispatch(fetchArticlesDetail(id)).then((res) => {
      dispatch(fetchArticlesAudit(id));
    });
  }, []);

  const getDropdownData = (value) => {
    let permission = {
      Publish: {
        id: "published",
        permission: "cares.update_cares_ecard_status",
      },
      Unpublish: {
        id: "unpublished",
        permission: "cares.update_cares_ecard_status",
      },
    };
    if (value.length && value.length > 0) {
      let updateValue = [];
      value.map((i) => {
        const userPermission = getUserPermission();
        const checkStatusPermission = checkPermission(
          userPermission,
          permission?.[i].permission
        );
        if (checkStatusPermission) {
          if (
            detail_data.status.toLowerCase() === "submitted" &&
            detail_data.status.toLowerCase() !== permission?.[i].id
          ) {
            if (i === "Publish") {
              updateValue.push(i);
            }
          } else if (
            detail_data.status.toLowerCase() !== "submitted" &&
            detail_data.status.toLowerCase() !== permission?.[i].id
          ) {
            updateValue.push(i);
          }
        }
      });
      return updateValue;
    }
    return [];
  };

  const updateStatus = () => {
    setShowStatus(false);
    setDetailLoading(true);
    dispatch(
      fetchArticlesUpdateStatus({
        id: id,
        status: tempStatus === "Publish" ? "published" : "unpublished",
      })
    ).then((res) => {
      if (res.payload.status_code === 200) {
        setDetailLoading(false);
        setTempStatus("");
        dispatch(fetchArticlesDetail(id)).then((res) => {
          dispatch(fetchArticlesAudit(id));
        });
      } else {
        setDetailLoading(false);
        alert(res.payload.message);
      }
    });
  };

  const handleDeleteArticles = () => {
    setDetailLoading(true);
    setShowDelete(false);
    dispatch(fetchArticlesDelete(id)).then((res) => {
      if (res.payload.status_code === 200) {
        navigate(HAPPENINGS_ARTICLES_ROUTE);
      } else {
        setDetailLoading(false);
        alert(res.payload.message);
      }
    });
  };

  console.log(detail_data);
  return (
    <>
      {viewPermssion ? (
        <>
          {showDelete && (
            <ShowDialog
              title={"Please Confirm!"}
              content={"Are you sure you want to delete?"}
              cancelContent={"Cancel"}
              confrimContent={"Delete"}
              cancelbtn={() => setShowDelete(false)}
              confrinbtn={handleDeleteArticles}
            />
          )}
          {showStatus && (
            <ShowDialog
              cancelbtn={() => setShowStatus(false)}
              title={""}
              dropdown={true}
              cancelContent={"Cancel"}
              confrimContent={"Update"}
              confrinbtn={updateStatus}
              dropdownData={getDropdownData(["Publish", "Unpublish"])}
              dropdownOnChange={(e) => setTempStatus(e)}
              dropdownValue={tempStatus}
            />
          )}
          {loading || detailLoading ? (
            <Loader
              type="converging-spinner"
              style={{
                width: "100%",
                height: "calc(100% - 75px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#ffffff4d",
                position: "absolute",
                zIndex: "999",
              }}
            />
          ) : (
            <div className="Articles_detail_main_box">
              <div className="Articles_detail_child_box">
                <div className="Articles_detail_left_box">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      className="Articles_detail_back"
                      onClick={() => navigate(HAPPENINGS_ARTICLES_ROUTE)}
                    >
                      <span class="k-icon k-font-icon k-i-arrow-chevron-left" />
                      Back
                    </div>
                    <div
                      className="Articles_detail_edit"
                      onClick={() =>
                        navigate(`${HAPPENINGS_ARTICLES_EDIT_ROUTE}?id=${id}`)
                      }
                    >
                      <img src={EditIcon} />
                      Edit
                    </div>
                  </div>
                  <div className="Articles_detail_child_box_main_detail_box">
                    <div className="Articles_detail_child_box_main_detail_items">
                      <div className="Articles_detail_child_box_main_detail_items_title Articles_detail_center_item detail_padding_bottom">
                        Title in English
                      </div>
                      <div className="Articles_detail_child_box_main_detail_items_splitter" />
                      <div className="Articles_detail_center_item Articles_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detail_data && detail_data.title_en
                          ? detail_data.title_en
                          : "-"}
                      </div>
                    </div>
                    <div className="Articles_detail_child_box_main_detail_items">
                      <div className="Articles_detail_child_box_main_detail_items_title Articles_detail_center_item detail_padding_bottom">
                        Title in Chinese
                      </div>
                      <div className="Articles_detail_child_box_main_detail_items_splitter" />
                      <div className="Articles_detail_center_item Articles_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detail_data && detail_data.title_zh
                          ? detail_data.title_zh
                          : "-"}
                      </div>
                    </div>
                    <div className="Articles_detail_child_box_main_detail_items">
                      <div className="Articles_detail_child_box_main_detail_items_title Articles_detail_center_item detail_padding_bottom">
                        Description in English
                      </div>
                      <div className="Articles_detail_child_box_main_detail_items_splitter" />
                      <div className="Articles_detail_center_item Articles_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detail_data && detail_data.description_en
                          ? detail_data.description_en
                          : "-"}
                      </div>
                    </div>
                    <div className="Articles_detail_child_box_main_detail_items">
                      <div className="Articles_detail_child_box_main_detail_items_title Articles_detail_center_item detail_padding_bottom">
                        Description in Chinese
                      </div>
                      <div className="Articles_detail_child_box_main_detail_items_splitter" />
                      <div className="Articles_detail_center_item Articles_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detail_data && detail_data.description_zh
                          ? detail_data.description_zh
                          : "-"}
                      </div>
                    </div>
                    <div className="Articles_detail_child_box_main_detail_items">
                      <div className="Articles_detail_child_box_main_detail_items_title Articles_detail_center_item detail_padding_bottom">
                        Category
                      </div>
                      <div className="Articles_detail_child_box_main_detail_items_splitter" />
                      <div className="Articles_detail_center_item Articles_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detail_data && detail_data.category?.name
                          ? detail_data.category.name
                          : "-"}
                      </div>
                    </div>
                    <div className="Articles_detail_child_box_main_detail_items">
                      <div className="Articles_detail_child_box_main_detail_items_title Articles_detail_center_item detail_padding_bottom">
                        Blog URL
                      </div>
                      <div className="Articles_detail_child_box_main_detail_items_splitter" />
                      <div className="Articles_detail_center_item Articles_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detail_data && detail_data.blog_url
                          ? detail_data.blog_url
                          : "-"}
                      </div>
                    </div>
                    <div className="Articles_detail_child_box_main_detail_items">
                      <div className="Articles_detail_child_box_main_detail_items_title Articles_detail_center_item detail_padding_bottom">
                        Image Upload
                      </div>
                      <div className="Articles_detail_child_box_main_detail_items_splitter" />
                      <div className="Articles_detail_center_item Articles_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        <img
                          style={{ maxWidth: "170px", height: "120px" }}
                          src={
                            detail_data &&
                            detail_data.image_url !== "" &&
                            detail_data.image_url
                          }
                        />
                      </div>
                    </div>
                    <div className="Articles_detail_child_box_main_detail_items">
                      <div className="Articles_detail_child_box_main_detail_items_title Articles_detail_center_item detail_padding_bottom">
                        Scheduled/Published On
                      </div>
                      <div className="Articles_detail_child_box_main_detail_items_splitter" />
                      <div className="Articles_detail_center_item Articles_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detail_data && detail_data.publish_on
                          ? moment(new Date(detail_data.publish_on)).format(
                              "DD MMM YYYY"
                            )
                          : "-"}
                      </div>
                    </div>
                    <div className="Articles_detail_child_box_main_detail_items">
                      <div className="Articles_detail_child_box_main_detail_items_title Articles_detail_center_item detail_padding_bottom">
                        Scheduled/Published End
                      </div>
                      <div className="Articles_detail_child_box_main_detail_items_splitter" />
                      <div className="Articles_detail_center_item Articles_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detail_data && detail_data.publish_end
                          ? moment(new Date(detail_data.publish_end)).format(
                              "DD MMM YYYY"
                            )
                          : "-"}
                      </div>
                    </div>
                    <div className="Articles_detail_child_box_main_detail_items">
                      <div className="Articles_detail_child_box_main_detail_items_title Articles_detail_center_item detail_padding_bottom">
                        Created By
                      </div>
                      <div className="Articles_detail_child_box_main_detail_items_splitter" />
                      <div className="Articles_detail_center_item Articles_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detail_data && detail_data.created_by?.name
                          ? detail_data.created_by.name
                          : "-"}
                      </div>
                    </div>
                    <div className="Articles_detail_child_box_main_detail_items">
                      <div className="Articles_detail_child_box_main_detail_items_title Articles_detail_center_item detail_padding_bottom">
                        Created On
                      </div>
                      <div className="Articles_detail_child_box_main_detail_items_splitter" />
                      <div className="Articles_detail_center_item Articles_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detail_data && detail_data.created_on
                          ? moment(new Date(detail_data.created_on)).format(
                              "DD MMM YYYY, hh:mm a"
                            )
                          : "-"}
                      </div>
                    </div>
                    <div className="Articles_detail_child_box_main_detail_items">
                      <div className="Articles_detail_child_box_main_detail_items_title Articles_detail_center_item detail_padding_bottom">
                        Status
                      </div>
                      <div className="Articles_detail_child_box_main_detail_items_splitter" />
                      <div className="Articles_detail_center_item Articles_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detail_data && detail_data.status
                          ? detail_data.status
                          : "-"}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Articles_detail_right_box">
                  {/* {detail_data && detail_data.status !== "published" && ( */}
                  <div className="Articles_detail_right_status_box">
                    <div className="Articles_detail_right_box_status_title">
                      Status
                    </div>
                    <div className="Articles_detail_right_box_status_subdetail">
                      {detail_data && detail_data.status
                        ? detail_data.status
                        : "-"}
                    </div>
                  </div>
                  {/* )} */}
                  <div className="Articles_detail_right_box_action_main_box">
                    <div className="Articles_detail_right_box_action_title">
                      Actions
                    </div>
                    {AddPermssion && (
                      <div
                        className="Articles_detail_right_box_action_edit"
                        onClick={() =>
                          navigate(
                            `${HAPPENINGS_ARTICLES_DUPLICATE_ROUTE}?duplicateID=${id}`
                          )
                        }
                      >
                        Duplicate post &#x2192;
                      </div>
                    )}
                    {EditPermssion && (
                      <div
                        className="Articles_detail_right_box_action_edit"
                        onClick={() => setShowStatus(true)}
                      >
                        Edit Visibility &#x2192;
                      </div>
                    )}
                    {DeletePermssion && (
                      <div
                        className="Articles_detail_right_box_action_delete"
                        onClick={() => setShowDelete(true)}
                      >
                        Delete Articles &#x2192;
                      </div>
                    )}
                  </div>
                  <div className="Articles_detail_right_box_action_main_box">
                    <div className="Articles_detail_right_box_action_title">
                      Activity Trail
                    </div>
                    <div className={audit_data.length <= 1 && "audit_bar_hide"}>
                      {auditLoading ? (
                        <Loader
                          type="converging-spinner"
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            background: "#ffffff4d",
                          }}
                        />
                      ) : (
                        <Stepper
                          orientation={"vertical"}
                          items={audit_data}
                          item={CustomStep}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <PermissionDeninedPage
          height={"calc(100% - 76px)"}
          title={"Sorry, You don't have permission to access this page!"}
        />
      )}
    </>
  );
};
