import React from "react";
import "./reusable.css";

export const TableHeader = (props) => {
  return (
    <div className="reusable_header_main_box">
      <div className="reusable_header_title">{props.title}</div>
      <div className="reusable_header_icon_main_box">
        {props.icons.map((i, id) => {
          if (i.buttonName) {
            return (
              <div
                key={id}
                className="reusable_header_create_box"
                onClick={i.action}
              >
                {i.buttonName}
              </div>
            );
          } else {
            return (
              <div
                key={id}
                className="reusable_header_icon_box"
                onClick={i.action}
              >
                <img src={i.icon} />
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};
