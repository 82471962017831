import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPlayer from "react-player";
import { Navigate, useLocation, useNavigate } from "react-router";
import "../../communication.css";
import { Button } from "@progress/kendo-react-buttons";
import Attachment_File from "../../../../assets/communication/attachment_file.svg";
import { Dialog } from "@progress/kendo-react-dialogs";
import { ReviewRecipientList } from "./recipientList";
import moment from "moment";
import { Loader } from "@progress/kendo-react-indicators";
import {
  fetchNotificationById,
  fetchNotificationRecipients,
} from "../../../../store/notification";
import { INBOX_ROUTE } from "../../../../routes";
import config from "../../../../config/config";
import api from "../../../../config/api";
import { getCurrentLoggedInSessionTokenWithPromise } from "../../../../config/userPool";

const { NOTIFICATION_ENDPOINT } = config.api_endpoint;

export const ReviewForm = (props) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const dispatch = useDispatch();
  const [view_recipients, setViewRecipients] = useState(false);
  const [showAlert, setshowAlert] = useState(false);
  const [disabled, setDisabled] = useState(false);
  // const { recipients, recipient_loading, recipient_error } = useSelector(
  //   (state) => state.notification
  // );
  const copyId = query.get("copyId");
  const notification__id = query.get("id");
  const toggleViewRecipients = () => {
    setViewRecipients(!view_recipients);
  };

  const [notification_data, setNotificationData] = useState({});
  const [ndata_loading, setNDataLoading] = useState(false);
  const [recipients, setRecipients] = useState([]);

  const fetchNotificationData = async (recId) => {
    setNDataLoading(true);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    await api
      .get(`${NOTIFICATION_ENDPOINT}/${recId}`, {}, token)
      .then((res) => {
        if (res.status === "success") {
          const data = res.data;
          if(data.channel.length == 1 && data.channel.some(e=>e.id =="SplashScreen")){
            props.setIsSplashOnly(true);
          }          
          setNotificationData(data);
          setNDataLoading(false);
          setRecipients(data.recipient);
        }
      });
  };

  useEffect(() => {
    if (!ndata_loading) {
      props.setLoading(false);
    }
  }, [ndata_loading]);

  useEffect(() => {
    if (!props.autoSaveInProgress) {
      if (copyId) {
        fetchNotificationData(copyId);
      } else if (notification__id) {
        fetchNotificationData(notification__id);
      }
    }
  }, [props.autoSaveInProgress]);

  useEffect(() => {
    console.log("<<< REVIEW FIRST LOAD >>>");
    setNDataLoading(true);
    if (copyId) {
      //   fetchNotificationData(copyId);
      dispatch(fetchNotificationRecipients(copyId));
    } else if (notification__id) {
      //   fetchNotificationData(notification__id);
      dispatch(fetchNotificationRecipients(notification__id));
    }
  }, []);

  const GetUpdate_body = () => {
    let recipients_list = recipients.map((v) => ({
      id: v["id"],
      name: v["name"],
      depot: v.depot || v.department,
      interchange: v.interchange,
      designation: v.designation,
      file1: v.file1,
      file2: v.file2,
      file3: v.file3
    }));
    return {
      title_en: notification_data.title_en,
      title_zh: notification_data.title_zh,
      message_en: notification_data.message_en,
      message_zh: notification_data.message_zh,
      start_date: notification_data.start_date,
      start_time: notification_data.start_time,
      end_date: notification_data.end_date,
      end_time: notification_data.end_time,
      channel: notification_data.channel,
      high_priority_flag: notification_data.high_priority_flag,
      status: notification_data.status,
      link_to: notification_data.link_to,
      link_to_type: notification_data.link_to_type,
      category_id: notification_data.category_id,
      depot: notification_data.depot,
      division: notification_data.division,
      interchange: notification_data.interchange,
      home_banner_image: notification_data.home_banner_image.map((v) => ({
        url: v.url,
        name: v.name,
      })),
      splash_image: notification_data.splash_image.map((v) => ({
        url: v.url,
        name: v.name,
      })),
      message_image: notification_data.message_image.map((v) => ({
        url: v.url,
        name: v.name,
      })),
      message_video: notification_data.message_video.map((v) => ({
        name: v.name,
        url: v.url,
      })),
      popup_image: notification_data.popup_image.map((v) => ({
        url: v.url,
        name: v.name,
      })),
      message_attachment: notification_data.message_attachment.map((v) => ({
        url: v.url,
        name: v.name,
      })),
      recipient: recipients_list,
    };
  };
  const onSubmitReview = (e, isSave) => {
    e.preventDefault();
    if (isSave) {
      navigate(INBOX_ROUTE);
    } else {
      if (
        !notification_data.category_id ||
        !notification_data.start_date ||
        !notification_data.start_time ||
        !notification_data.end_date ||
        !notification_data.end_time
      ) {
        setshowAlert(true);
      } else {
        props.setLoading(true);
        const update_body = GetUpdate_body();
        const update_info = { ...update_body, status: "pendingApproval" };
        if (notification__id) {
          update_info.id = notification__id;
        }
        props.submitcallback(update_info);
      }
    }
  };
  // console.log(notification_data);
  return (
    <>
      {ndata_loading || props.loading || props.autoSaveInProgress ? (
        <Loader
          type="infinite-spinner"
          style={{
            display: "flex",
            alignItems: "center",
            width: "40px",
            margin: "auto",
            justifyContent: "center",
            height: "100vh",
          }}
        />
      ) : (
        <>
          {view_recipients && (
            <Dialog
              title={"Recipient list"}
              onClose={toggleViewRecipients}
              width={1000}
            >
              <ReviewRecipientList data={recipients} />
            </Dialog>
          )}
          {showAlert && (
            <Dialog
              className="alert-form"
              title={"Alert"}
              width={400}
              // height={280}
              onClose={() => setshowAlert(false)}
            >
              <p>The following fields are required to submit the form.</p>
              <ul>
                {!notification_data.start_date && <li>Start Date</li>}
                {!notification_data.start_time && <li>Start Time</li>}
                {!notification_data.end_date && <li>End Date</li>}
                {!notification_data.end_time && <li>End Time</li>}
                {!notification_data.category_id && <li>Category</li>}
              </ul>
            </Dialog>
          )}
          <form className="k-form">
            <table className="review-form-table">
              {!query.get("skip") && (
                <tr>
                  <td className="font-weight-600 font-size-16">Distribution</td>
                  <td>
                    <span><div>Total Number of Recipients Count: {notification_data.total_recipient
                      ? notification_data.total_recipient
                      : "0"}</div></span>
                    <a
                      className="font-weight-600"
                      onClick={toggleViewRecipients}
                    >
                      <u style={{ color: "#781D7E" }}>View recipient list</u>
                    </a>
                  </td>
                </tr>
              )}
              <tr>
                <td className="font-weight-600 font-size-16">Channel</td>
                <td>
                  <span>
                    {notification_data.channel
                      ? notification_data.channel.map((x) => x.name).join(", ")
                      : "-"}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="font-weight-600 font-size-16">
                  Title in English
                </td>
                <td>
                  <span>
                    {notification_data.title_en
                      ? notification_data.title_en
                      : "-"}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="font-weight-600 font-size-16">
                  Title in Chinese
                </td>
                <td>
                  <span>
                    {notification_data.title_zh
                      ? notification_data.title_zh
                      : "-"}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="font-weight-600 font-size-16">
                  Message in English
                </td>
                <td>
                  {notification_data.message_en ? (
                    <div
                      className="message-body-div"
                      dangerouslySetInnerHTML={{
                        __html: `${notification_data.message_en}`,
                      }}
                    />
                  ) : (
                    "-"
                  )}
                </td>
              </tr>
              <tr>
                <td className="font-weight-600 font-size-16">
                  Message in Chinese
                </td>
                <td>
                  {notification_data.message_zh ? (
                    <div
                      className="message-body-div"
                      dangerouslySetInnerHTML={{
                        __html: `${notification_data.message_zh}`,
                      }}
                    />
                  ) : (
                    "-"
                  )}
                </td>
              </tr>
              <tr>
                <td className="font-weight-600 font-size-16">
                  High Priority Flag
                </td>
                <td>
                  <span>
                    {notification_data.high_priority_flag ? "Yes" : "No"}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="font-weight-600 font-size-16">Category</td>
                <td>
                  <span>
                    {notification_data.category_name
                      ? notification_data.category_name
                      : "-"}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="font-weight-600 font-size-16">Link to</td>
                <td>
                  <span>
                    {notification_data.link_to
                      ? notification_data.link_to
                      : "-"}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="font-weight-600 font-size-16">Publish Rules</td>
                <td>
                  <span>
                    {notification_data.publish_on &&
                    notification_data.publish_end
                      ? `From ${notification_data.publish_on} to ${notification_data.publish_end}`
                      : "-"}
                  </span>
                </td>
              </tr>
              {notification_data.channel
                ? notification_data.channel
                    .map((x) => x.name)
                    .indexOf("Home Banner") > -1 && (
                    <tr>
                      <td className="font-weight-600 font-size-16">
                        Home Banner Image
                      </td>
                      <td>
                        {notification_data.home_banner_image
                          ? notification_data.home_banner_image.length > 0
                            ? notification_data.home_banner_image.map(
                                (v, k) => (
                                  <img
                                    key={k}
                                    src={v.s3_url}
                                    width={174}
                                    style={{ paddingRight: "10px" }}
                                  />
                                )
                              )
                            : "-"
                          : "-"}
                      </td>
                    </tr>
                  )
                : null}
              {notification_data.channel
                ? notification_data.channel
                    .map((x) => x.name)
                    .indexOf("Pop-up") > -1 && (
                    <tr>
                      <td className="font-weight-600 font-size-16">
                        PopUp Image
                      </td>
                      <td>
                        {notification_data.popup_image
                          ? notification_data.popup_image.length > 0
                            ? notification_data.popup_image.map((v, k) => (
                                <img
                                  key={k}
                                  src={v.s3_url}
                                  width={174}
                                  style={{ paddingRight: "10px" }}
                                />
                              ))
                            : "-"
                          : "-"}
                      </td>
                    </tr>
                  )
                : null}
              {notification_data.channel
                ? notification_data.channel
                    .map((x) => x.name)
                    .indexOf("Splash Screen") > -1 && (
                    <tr>
                      <td className="font-weight-600 font-size-16">
                        Splash Image
                      </td>
                      <td>
                        {notification_data.splash_image
                          ? notification_data.splash_image.length > 0
                            ? notification_data.splash_image.map((v, k) => (
                                <img
                                  key={k}
                                  src={v.s3_url}
                                  width={174}
                                  style={{ paddingRight: "10px" }}
                                />
                              ))
                            : "-"
                          : "-"}
                      </td>
                    </tr>
                  )
                : null}

              <tr>
                <td className="font-weight-600 font-size-16">Message Image</td>
                <td>
                  {notification_data.message_image
                    ? notification_data.message_image.length > 0
                      ? notification_data.message_image.map((v) => (
                          <img
                            key={v.url}
                            src={v.s3_url}
                            width={174}
                            style={{ paddingRight: "10px" }}
                          />
                        ))
                      : "-"
                    : "-"}
                </td>
              </tr>
              {/* {notification_data.channel_names
                ? notification_data.channel_names.indexOf("Home Banner") >
                    -1 && (
                    <tr>
                      <td className="font-weight-600 font-size-16">
                        Home Banner Image
                      </td>
                      <td>
                        {notification_data.home_banner_image
                          ? notification_data.home_banner_image.length > 0
                            ? notification_data.home_banner_image.map((v) => (
                                <img key={v.url} src={v.s3_url} width={174} />
                              ))
                            : "-"
                          : "-"}
                      </td>
                    </tr>
                  )
                : null}
              {notification_data.channel_names
                ? notification_data.channel_names.indexOf("Splash Screen") >
                    -1 && (
                    <tr>
                      <td className="font-weight-600 font-size-16">
                        Splash Image
                      </td>
                      <td>
                        {notification_data.splash_image
                          ? notification_data.splash_image.length > 0
                            ? notification_data.splash_image.map((v) => (
                                <img key={v.url} src={v.s3_url} width={174} />
                              ))
                            : "-"
                          : "-"}
                      </td>
                    </tr>
                  )
                : null}
              {notification_data.channel_names
                ? notification_data.channel_names.indexOf("Pop-up") > -1 && (
                    <tr>
                      <td className="font-weight-600 font-size-16">
                        PopUp Image
                      </td>
                      <td>
                        {notification_data.popup_image
                          ? notification_data.popup_image
                            ? notification_data.popup_image.map((v) => (
                                <img key={v.url} src={v.s3_url} width={174} } />
                              ))
                            : "-"
                          : null}
                      </td>
                    </tr>
                  )
                : null} */}
              {/* <tr>
                            <td className="font-weight-600 font-size-16">Appreciation Wall Image</td>
                            <td>
                                {notification_data.app_wall_image>0? notification_data.app_wall_image.map((v)=>
                                <img key={v.url} src={v.s3_url} width={174} />
                                )
                                : "-"}
                            </td>
                        </tr> */}
              <tr>
                <td className="font-weight-600 font-size-16 attach-td">
                  Message Attachment
                </td>
                <td className="attach-td attach-file-td">
                  {notification_data.message_attachment
                    ? notification_data.message_attachment.length > 0
                      ? notification_data.message_attachment.map((v) => (
                          <a href={v.s3_url} target="_blank">
                            <div
                              className="attach-file review-file"
                              style={{ paddingRight: "10px" }}
                            >
                              <img
                                className="attach-file-icon"
                                src={Attachment_File}
                              />
                              <label className="attach-file-label font-weight-600">
                                {v.name}
                              </label>
                            </div>
                          </a>
                        ))
                      : "-"
                    : "-"}
                </td>
              </tr>
              <tr>
                <td className="font-weight-600 font-size-16 pad-top-20">
                  Upload Video
                </td>
                <td className="pad-top-20">
                  {notification_data.message_video
                    ? notification_data.message_video.length > 0
                      ? notification_data.message_video.map((v) => (
                          <ReactPlayer
                            width={150}
                            height={106}
                            url={v.s3_url}
                            controls={true}
                            style={{ paddingRight: "10px" }}
                          />
                        ))
                      : "-"
                    : "-"}
                </td>
              </tr>
            </table>
            <div className="footer no-pad-left">
              <div className="footer-left-btn-groups">
                <Button
                  className="footer-previous-btn font-family-poppins"
                  onClick={props.backBtncallback}
                >
                  Previous
                </Button>
                <Button
                  className="footer-save-btn"
                  onClick={(e) => onSubmitReview(e, true)}
                  disabled={disabled}
                >
                  Save
                </Button>
              </div>
              <div className="footer-right">
                <Button
                  className="footer-next-btn bg-purple"
                  type="submit"
                  onClick={(e) => onSubmitReview(e, false)}
                  disabled={disabled}
                >
                  Submit
                </Button>
              </div>
            </div>
          </form>
        </>
      )}
    </>
  );
};
