import React from 'react';
import { Editor, EditorTools, EditorUtils } from "@progress/kendo-react-editor";
import "./manage_tutorial.css";

const MessageBox = React.memo(({ title, value, onChange, errorMessage }) => {
    const {
        Bold,
        Italic,
        Underline,
        AlignCenter,
        AlignRight,
        AlignJustify,
        OrderedList,
        UnorderedList,
    } = EditorTools;

    return (
        <div className="tutorial_create_item_main_box">
            <div className="tutorial_create_item_main_box_title">{title}</div>
            <div>
                <Editor
                    tools={[
                        [
                            Bold,
                            Italic,
                            Underline,
                            AlignCenter,
                            AlignJustify,
                            AlignRight,
                            OrderedList,
                            UnorderedList,
                        ],
                    ]}
                    className="tutorial_create_item_main_box_field"
                    value={value}
                    onChange={onChange}
                />
                {errorMessage && (
                    <div className="tutorial_create_error_text">{errorMessage}</div>
                )}
            </div>
        </div>
    );
});

export default MessageBox;
