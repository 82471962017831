import { Dialog } from "@progress/kendo-react-dialogs";
import React, { useState,useEffect } from "react";
import { Navigate, useNavigate,useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import "../admin_tools.css";
import { TextBox } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { fetchMessage, saveMessage } from "../../../store/isafe";

import {
    getUserPermission,
    checkMultiPermission,
    ADMIN_TOOLS_ISAFE_SAVE_SAFETY_MESSAGE,
  } from "../../../config/permission_helper";

export const ISafe = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [message, setMessage] = useState("");
    const [id, setID] = useState("");
    const [actionMessage,setActionMessage] = useState("");
    const [showAlert,setshowAlert] = useState(false);
    const { messages, loading } = useSelector(
        (state) => state.isafe);
    useEffect(() => {
        setMessage(messages.description);
        setID(messages.id);
        console.log('Message>>>>',messages);
      }, [messages]);
    useEffect(() => {
        dispatch(fetchMessage());
    }, []);

    const shouldShowSave = () => {
        const userPermissions = getUserPermission();
        return checkMultiPermission(userPermissions, ADMIN_TOOLS_ISAFE_SAVE_SAFETY_MESSAGE)
          ? true
          : false;
      };

    const submit = () => {
        let data = {
            id:id,
            description:message
        }
        console.log('Submitted data>>>',data)
        dispatch(saveMessage({data})).then (
            result => {
                console.log("Save iSafe RES-",result);
                if(result.payload.status == "success"){
                  setActionMessage('Successfully save iSafe message!');
                  setshowAlert(true);
                }
                else{
                  setActionMessage(result.payload.message);
                  setshowAlert(true);
                }
              }
        );
        
    };
    // console.log('messages>>>>',messages);
    return (
        <div>
            {showAlert && (
                <Dialog
                    className="alert-form"
                    title={"Alert"}                    
                    width={400}
                    // height={280}
                    onClose={()=>setshowAlert(false)}
                >
                    <p>{actionMessage}</p>
                </Dialog>
        )}
        <div className="isafe_form">
            <div>
                <div>
                    <div><b>iSafe safety message</b></div>
                    <div>
                    {/* {isCreate ? (<div><TextBox value={department} placeholder="Enter Department Name"  onChange={(e)=> setDepartment(e.target.value)}/>  </div>
                    ):(<div><TextBox value={department} onChange={(e)=> setDepartment(e.target.value)}/> </div>)} */}
                        <div><TextBox value={message} onChange={(e)=> setMessage(e.target.value)} maxlength="100"/> </div>
                    </div>
                </div>
            </div>
            <div className="isafe-button-div">
                {shouldShowSave() && <Button className="detail-button detail-submit" onClick={submit}>Save</Button>}
            </div>
            </div>
        
            <div >
                            
                        </div>
        </div>
    );
};