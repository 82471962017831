// redux

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionTokenWithPromise } from "../config/userPool";

const { INTERCHANGE_DEPOT_ENDPOINT } = config.api_endpoint;

const initialState = {
  loading: false,
  interchanges: [],
  error: null,
};

export const fetchAllInterchanges = createAsyncThunk(
  "depot/fetchAllInterchanges",
  async (depot_ids, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.post(
        INTERCHANGE_DEPOT_ENDPOINT,
        JSON.stringify({ depot: depot_ids }),
        token
      );
      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

const InterchangeSlice = createSlice({
  name: "depot",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllInterchanges.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllInterchanges.fulfilled, (state, action) => {
        // console.log(action);
        state.loading = false;
        state.interchanges = action.payload;
      })
      .addCase(fetchAllInterchanges.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

const InterchangeReducer = InterchangeSlice.reducer;
export default InterchangeReducer;
