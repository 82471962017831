import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
// redux
import {
  fetchAllCommRoleList,
  deleteCommRole,
} from "../../../../../store/commRole";
// kendo
import { Loader } from "@progress/kendo-react-indicators";
// component
import { TableHeader } from "../../../../../components/table/Tableheader";
import { CommsRoleTableBody } from "../../../../../components/table/commRoleTable";
import { SearchRole } from "../../../../../components/dialog/comm_role_search";
import { FilterRole } from "../../../../../components/dialog/comm_role_filter";
// routes
import {
  ADMIN_TOOLS_ROLE_LIST,
  ADMIN_TOOLS_ROLE_ADD,
} from "../../../../../routes";
// permissions
import {
  getUserPermission,
  checkMultiPermission,
} from "../../../../../config/permission_helper";
import {
  ADMIN_TOOLS_ADD_EDIT_ROLE,
  ADMIN_TOOLS_DELETE_ROLE,
} from "../../../../../config/permission_helper";

export const CommRoleList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  // redux
  const { roles_loading, roles, delete_role_loading } = useSelector(
    (state) => state.groupMgmt
  );
  // states
  const [loading, setLoading] = useState(false);
  const [allRoles, setAllRoles] = useState([]);
  const [filteredRoles, setFilteredRoles] = useState([]);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [showSearchDialog, setShowSearchDialog] = useState(false);

  // methods
  const shouldHideAddButton = () => {
    const userPermissions = getUserPermission();
    return checkMultiPermission(userPermissions, ADMIN_TOOLS_ADD_EDIT_ROLE)
      ? false
      : true;
  };

  const shouldShowDeleteButton = () => {
    const userPermissions = getUserPermission();
    return checkMultiPermission(userPermissions, ADMIN_TOOLS_DELETE_ROLE)
      ? true
      : false;
  };

  const handleFilteringData = () => {
    let results = allRoles;
    if (query.get("name")) {
      results = results.filter(
        (v) =>
          v.name.toLowerCase().indexOf(query.get("name").toLowerCase()) > -1
      );
    }
    if (query.get("category")) {
      let all_categories = query.get("category").split(",");
      if (all_categories.indexOf("All") === -1) {
        results = results.filter((v) => {
          let cat_names = v.category_list.map((x) => x.name);
          return cat_names.filter((x) => all_categories.includes(x)).length > 0;
        });
      }
    }
    setFilteredRoles(results);
  };

  const handleSearchCallback = (data) => {
    console.log(data);
    const { name } = data;
    query.set("name", name);
    if (name) {
      query.set("search", true);
    }
    navigate(ADMIN_TOOLS_ROLE_LIST + "?" + query.toString());
    setShowSearchDialog(false);
    handleFilteringData();
  };

  const handleClearFilterOptions = () => {
    query.delete("filter");
    query.delete("category");

    navigate(ADMIN_TOOLS_ROLE_LIST + "?" + query.toString());
    handleFilteringData();
  };

  const handleClearSearchOptions = () => {
    query.delete("search");
    query.delete("name");
    navigate(ADMIN_TOOLS_ROLE_LIST + "?" + query.toString());
    handleFilteringData();
  };

  const handleFilterCallback = (data) => {
    console.log("handleFilterCallback:", data);
    const { category } = data;
    query.set("category", category.toString());

    if (category.length > 0) {
      query.set("filter", true);
    }
    navigate(ADMIN_TOOLS_ROLE_LIST + "?" + query.toString());
    setShowFilterDialog(false);
    handleFilteringData();
  };

  const handleDeleteAction = (id) => {
    setLoading(true);
    dispatch(deleteCommRole(id)).then((r) => {
      dispatch(fetchAllCommRoleList()).then((res) => {
        let results = res.payload;
        setAllRoles(results);
        setLoading(false);
      });
    });
  };

  // useEffect
  useEffect(() => {
    let results = allRoles;
    if (query.toString()) {
      if (query.get("name")) {
        results = results.filter(
          (v) =>
            v.name.toLowerCase().indexOf(query.get("name").toLowerCase()) > -1
        );
      }
      if (query.get("category")) {
        let all_categories = query.get("category").split(",");
        if (all_categories.indexOf("All") === -1) {
          results = results.filter((v) => {
            let cat_names = v.category_list.map((x) => x.name);
            return (
              cat_names.filter((x) => all_categories.includes(x)).length > 0
            );
          });
        }
      }
    }
    setFilteredRoles(results);
    setLoading(false);
  }, [allRoles]);

  useEffect(() => {
    setLoading(true);
    dispatch(fetchAllCommRoleList()).then((res) => {
      let results = res.payload;
      setAllRoles(results);
    });
  }, []);

  return (
    <div className="admin-tool-comm-role-list-content">
      {showFilterDialog && (
        <FilterRole
          setShowFilterDialog={setShowFilterDialog}
          onFiltercallback={handleFilterCallback}
        />
      )}
      {showSearchDialog && (
        <SearchRole
          setShowSearchDialog={setShowSearchDialog}
          onSearchcallback={handleSearchCallback}
        />
      )}
      <TableHeader
        title="Manage Role"
        onRefresh={() => {
          dispatch(fetchAllCommRoleList()).then((res) => {
            let results = res.payload;
            setAllRoles(results);
          });
        }}
        hideaddbtn={shouldHideAddButton()}
        loading={loading}
        onFilter={() => setShowFilterDialog(true)}
        onSearch={() => setShowSearchDialog(true)}
        addurl={ADMIN_TOOLS_ROLE_ADD}
      />
      {(query.get("filter") || query.get("search")) && (
        <div className="table-filter-div">
          {query.get("filter") && (
            <div className="table-filter-item">
              <div className="table-filter-title">Filter applied</div>
              <span
                className="table-filter-clear"
                onClick={handleClearFilterOptions}
              >
                Clear filter
              </span>
            </div>
          )}
          {query.get("search") && (
            <div className="table-filter-item">
              <div className="table-filter-title">Keyword search applied</div>
              <span
                className="table-filter-clear"
                onClick={handleClearSearchOptions}
              >
                Clear search
              </span>
            </div>
          )}
        </div>
      )}
      {roles_loading || loading || delete_role_loading ? (
        <Loader
          type="infinite-spinner"
          style={{
            display: "flex",
            alignItems: "center",
            width: "40px",
            margin: "auto",
            justifyContent: "center",
            height: "100vh",
          }}
        />
      ) : (
        <CommsRoleTableBody
          data={filteredRoles}
          handleDeleteAction={handleDeleteAction}
          showDeleteOption={shouldShowDeleteButton()}
        />
      )}
    </div>
  );
};
