import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import "./policy_doc.css";
import { Loader } from "@progress/kendo-react-indicators";
import { getCurrentLoggedInSessionToken } from "../../config/userPool";
import api from "../../config/api";
import { Dialog } from "@progress/kendo-react-dialogs";
import moment from "moment";

const PolicyDocument = () => {
  const uploadFile = useRef([]);
  const [loading, setLoading] = useState(false);
  const [policyData, setPolicyData] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [showErr, setShowErr] = useState(false);

  uploadFile.current = policyData.map(
    (_, i) => uploadFile.current[i] ?? createRef()
  );

  useEffect(() => {
    callData();
  }, []);

  const callData = async () => {
    setLoading(true);
    const token = getCurrentLoggedInSessionToken();
    const results = await api.get("/api-vmb/policy-docs-list/", {}, token);
    console.log(results);
    if (results.status_code === 200) {
      setPolicyData(results.data);
      setLoading(false);
    }
  };
  const uploadImage = (e, id, i, refID) => {
    refID.current.value = "";
    setUploadLoading(true);
    const formData = new FormData();

    formData.append("file", e);
    const token = getCurrentLoggedInSessionToken();
    api.get("/api-vmb/get-presigned-url/", {}, token).then((res) => {
      formData.append("key", res.data.key);
      api.postDiff(res.data.presigned_url, e).then((response) => {
        console.log(response);
        if (response === 200) {
          submit(i.pk, res.data.key, token);
        } else {
          setShowErr(true);
          setUploadLoading(false);
        }
      });
    });
  };

  const submit = (pk, url, token) => {
    api
      .post(
        `/api-vmb/policy-docs/${pk}`,
        JSON.stringify({
          id: pk,
          file_url: url,
        }),
        token
      )
      .then((response) => {
        console.log(response);
        if (response.status_code === 200) {
          setShowSuccess(true);
          setUploadLoading(false);
        }
      });
  };

  const runFunction = (i) => {
    i.current.click();
  };

  return (
    <>
      {loading ? (
        <Loader
          type="converging-spinner"
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#ffffff4d",
            position: "absolute",
            zIndex: "999",
          }}
        />
      ) : (
        <>
          {uploadLoading && (
            <Loader
              type="converging-spinner"
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#ffffff4d",
                position: "absolute",
                zIndex: "999",
              }}
            />
          )}
          {policyData.map((i, id) => {
            return (
              <div key={id} className="policy_doc_main_box">
                <div className="policy_doc_view">
                  <a
                    key={id}
                    href={i.updated_by === "" ? "" : i.file_url}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    {i.document_name}
                  </a>
                </div>
                <div className="policy_doc_upload_main_box">
                  <button
                    className="policy_doc_button"
                    onClick={() => runFunction(uploadFile.current[id])}
                  >
                    Upload and replace
                  </button>
                  <input
                    type="file"
                    accept="application/pdf"
                    ref={uploadFile.current[id]}
                    onChange={(e) => {
                      console.log(e.target.files[0]);
                      uploadImage(
                        e.target.files[0],
                        i.document_name,
                        i,
                        uploadFile.current[id]
                      );
                    }}
                    style={{ display: "none" }}
                  />

                  {i.last_updated === "" ? (
                    <div>File Not Found</div>
                  ) : (
                    <div>
                      Uploaded by {i.updated_by.name} on{" "}
                      {moment(i.last_updated).format("DD-MM-YYYY, h:mm a")}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
          {showSuccess && (
            <Dialog>
              <div className="policy_doc_popup_main_box">
                <div>Success!</div>
                <div
                  className="policy_doc_popup_button"
                  onClick={() => {
                    setShowSuccess(false);
                    callData();
                  }}
                >
                  Ok
                </div>
              </div>
            </Dialog>
          )}
          {showErr && (
            <Dialog>
              <div className="policy_doc_popup_main_box">
                <div style={{ textAlign: "center" }}>
                  Error. Can't upload files. Please try again later!
                </div>
                <div
                  className="policy_doc_popup_button"
                  onClick={() => {
                    setShowErr(false);
                  }}
                >
                  Ok
                </div>
              </div>
            </Dialog>
          )}
        </>
      )}
    </>
  );
};

export default PolicyDocument;
