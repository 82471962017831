import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router";
import "../communication.css";
import BackIcon from "../../../assets/communication/Back.svg";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { 
  INBOX_ROUTE
} from "../../../routes";
import moment from "moment";

export const AddNotificationHeader = (props) => {
  const navigate = useNavigate();
  return (
    <div className="notification_body">
        <div className="notification_addheader" >
          <div className="back-title-group" onClick={(e)=> props.backBtncallback(e)}>
            <img src={BackIcon} className="back-icon" />
            <span className="back-text">Back</span>
          </div>
          {props.state !== 3 && (
            <div className="auto-saved-div">
              <Button className="auto-saved-button" disabled>Auto Saved</Button>            
              <span className="last-autosave">{props.lastUpdate}</span>
            </div>
          )}
        </div>
    </div>
  );
};