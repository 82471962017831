import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import { RadioButton, TextArea, TextBox } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import "./reports.css";
import { FobReportActionTakenDialog } from "../../../components/dialog/fob_report_actionList";
import { CellRender, RowRender } from "./render-cell";
const EDIT_FIELD = "inEdit";

const initialDataState = {
  skip: 0,
  take: 10,
  dir: "desc",
};

const initialSort = [
  {
    field: "date",
    dir: "desc",
  },
];

export const FOBReportTableBody = (props) => {
  const selectedColumns = props.columns;
  const [data,setData] = useState(props.data);
  
  const [page, setPage] = useState(initialDataState);
  const [pageSizeValue, setPageSizeValue] = useState();
  const [sort, setSort] = useState(initialSort);
  const [selectedRow,setSelectedRow] = useState({});
  const [showActionTaken,setShowActionTaken] = useState(false);
  const enterEdit = (dataItem, field) => {
    const newData = data.map((item) => ({
      ...item,
      [EDIT_FIELD]: item.id === dataItem.id ? field : undefined,
    }));
    setData(newData);
  };
  const exitEdit = () => {
    const newData = data.map((item) => ({
      ...item,
      [EDIT_FIELD]: undefined,
    }));
    setData(newData);
  };
  const itemChange = (event) => {
    let field = event.field || "";
    event.dataItem[field] = event.value;
    let newData = data.map((item) => {
      if (item.id === event.dataItem.id) {
        item[field] = event.value;
      }
      return item;
    });
    setData(newData);
  };
  const pageChange = (event) => {
    const targetEvent = event.targetEvent;
    const take = targetEvent.value === "All" ? 77 : event.page.take;
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };
  const onChangeIsMeetTrainingObject = ((e, Rowid)=>{
    setData(data.map(({is_meet_training,id,...rest})=>({
      ...rest,      
      id,
      is_meet_training: Rowid === id? e.value: is_meet_training
    })));
  });
  const onChangeRemark= ((e,Rowid)=>{
    setData(data.map(({report_remark,id,...rest})=>({
      ...rest,      
      id,
      report_remark: Rowid === id? e.target.value: report_remark
    })));
  });
  const onSave = ((e,data)=>{
    let updateData = {
      "id": data.id,
      "sk": data.sk,
      "remark": data.report_remark,
      "is_meet_training": data.is_meet_training
    }
    console.log(updateData);
    props.onhandleReportUpdate(updateData);
  });
  const customCellRender = (td, props) => (
    <CellRender
      originalProps={props}
      td={td}
      enterEdit={enterEdit}
      editField={EDIT_FIELD}
    />
  );
  const customRowRender = (tr, props) => (
    <RowRender
      originalProps={props}
      tr={tr}
      exitEdit={exitEdit}
      editField={EDIT_FIELD}
    />
  );

  return (
    <div className="tbody-content fob-table-report">
      <Grid
        data={
          data
            ? orderBy(data.slice(page.skip, page.take + page.skip), sort)
            : []
        }
        skip={page.skip}
        take={page.take}
        total={data ? data.length : 0}
        sortable={true}
        sort={sort}
        onSortChange={(e) => {
          setSort(e.sort);
        }}  
        pageable={{
          buttonCount: 4,
          pageSizes: [5, 10, 15, "All"],
          pageSizeValue: pageSizeValue,
          info: false,
          pageSizes: false,
        }}
        pageSize={false}
        info={false}
        pageSizes={false}
        onPageChange={pageChange}
        style={{width:"100%"}}
        onItemChange={itemChange}
        cellRender={customCellRender}
        rowRender={customRowRender}
        editField={EDIT_FIELD}
      >
        {(selectedColumns.length === 0 || selectedColumns.includes('Date') || (selectedColumns.includes('FeedbackScheme'))
        || selectedColumns.includes('Type') || selectedColumns.includes('ServiceNumber') || selectedColumns.includes('AssignedBC')
        || selectedColumns.includes('SubmittedBy')
      ) && (
        <GridColumn>
          { (selectedColumns.includes('Date') || selectedColumns.length === 0) && (
            <GridColumn field="date" title="Date" width={170} cell={(cellprops)=>(
              <td >
                <div className="report-table-date-div">

                  <a className="report-table-ref-no" >
                    {cellprops.dataItem.reference_no}
                  </a>
                  <div>{cellprops.dataItem.date}</div>
                </div>
              </td>
            )}/>
          )}
          { (selectedColumns.includes('FeedbackScheme') || selectedColumns.length === 0) && (
            <GridColumn title="Feedback Scheme" field="scheme" width={200} editable={false} />
          )}
          {(selectedColumns.length === 0 || selectedColumns.includes('Type')) && (
            <GridColumn title="Type" field="type" width={100} editable={false}/>
          )}
          {(selectedColumns.length === 0 || selectedColumns.includes('ServiceNumber')) && (
            <GridColumn title="SVC No" width={100} field="svc" editable={false}/>
          )}
          {(selectedColumns.length === 0 || selectedColumns.includes('AssignedBC')) && (
            <GridColumn title="Assigned BC" field="assigned_bc" width={150} editable={false}/>
          )}
          {(selectedColumns.length === 0 || selectedColumns.includes('SubmittedBy')) && (
            <GridColumn title="Submitted By" field="created_by.name" width={150} editable={false}/>
          )}
        </GridColumn>
        )}
        {(selectedColumns.length === 0 || selectedColumns.includes('BusRegistrationNumber') || (selectedColumns.includes('BusArrivalTime'))
        || selectedColumns.includes('PassengerLoading')
      ) && (        
        <GridColumn title="1st Bus">
            <GridColumn title="Bus Reg No" width={100} field="bus_reg_no" editable={false}/>
            <GridColumn title="Bus Arrival Time" width={100} field="bus_arrival_time" editable={false}/>
            <GridColumn title="Passenger Loading" width={200} field="passenger_loading" editable={false}/>
        </GridColumn>
      )}
        <GridColumn title="2nd Bus">
            <GridColumn title="Bus Reg No" width={100} cell={(cellprops)=>(<td>-</td>)}/>
            <GridColumn title="Bus Arrival Time" width={100} cell={(cellprops)=>(<td>-</td>)}/>
            <GridColumn title="Passenger Loading" width={200} cell={(cellprops)=>(<td>-</td>)}/>
        </GridColumn>
        <GridColumn title="3rd Bus">
            <GridColumn title="Bus Reg No" width={100} cell={(cellprops)=>(<td>-</td>)}/>
            <GridColumn title="Bus Arrival Time" width={100} cell={(cellprops)=>(<td>-</td>)}/>
            <GridColumn title="Passenger Loading" width={200} cell={(cellprops)=>(<td>-</td>)}/>
        </GridColumn>                
        <GridColumn>
            <GridColumn title="Time Board Bus" width={100} field="time_board_bus" editable={false}/>
        </GridColumn>
        <GridColumn title="Service Attributes">
            <GridColumn title="Aircon" width={140} field="aircon" editable={false}/>
            <GridColumn title="Cleanliness" width={200} field="cleanliness" editable={false}/>
            <GridColumn title="Bus Conditions" width={200} field="bus_conditions" editable={false}/>
            <GridColumn title="Category" width={200} field="category" editable={false}/>
            <GridColumn title="BC Service" width={200} field="bc_service" editable={false}/>
            <GridColumn title="English Literacy" width={150} field="eng_literacy" editable={false}/>
            <GridColumn title="BC Driving (Positive)" width={200} field="bc_driving_positive" editable={false}/>
            <GridColumn title="BC Driving (Negative)" width={200} field="bc_driving_negative" editable={false}/>
            <GridColumn title="Trip Experience" width={200} field="overall_exp" editable={false}/>
            <GridColumn title="Compliments" width={200} field="compliments" editable={false}/>
            <GridColumn title="Other Feedback" width={200} field="other_feedback" editable={false}/>
            <GridColumn title="Time Alight Bus" width={200} field="time_alight_bus" editable={false}/>
        </GridColumn>
        <GridColumn title="1st Commuter Feedback">
            <GridColumn title="I find the waiting time for this bus acceptable" width={200} cell={(cellprops)=>(<td>-</td>)}/>
            <GridColumn title="I find the bus ride comfortable" width={200} cell={(cellprops)=>(<td>-</td>)}/>
            <GridColumn title="I find the bus ride safe" width={200} cell={(cellprops)=>(<td>-</td>)}/>
            <GridColumn title="I find the Bus Captain courteous and polite" width={200} cell={(cellprops)=>(<td>-</td>)}/>
            <GridColumn title="I had a good experience on this bus" width={200} cell={(cellprops)=>(<td>-</td>)}/>
            <GridColumn title="Any suggestions to Improve our service?" width={200} cell={(cellprops)=>(<td>-</td>)}/>
            <GridColumn title="How do we address you?" width={200} cell={(cellprops)=>(<td>-</td>)}/>
            <GridColumn title="How can we contact you?" width={200} cell={(cellprops)=>(<td>-</td>)}/>
        </GridColumn>
        <GridColumn title="2nd Commuter Feedback">
            <GridColumn title="I find the waiting time for this bus acceptable" width={200} cell={(cellprops)=>(<td>-</td>)}/>
            <GridColumn title="I find the bus ride comfortable" width={200} cell={(cellprops)=>(<td>-</td>)}/>
            <GridColumn title="I find the bus ride safe" width={200} cell={(cellprops)=>(<td>-</td>)}/>
            <GridColumn title="I find the Bus Captain courteous and polite" width={200} cell={(cellprops)=>(<td>-</td>)}/>
            <GridColumn title="I had a good experience on this bus" width={200} cell={(cellprops)=>(<td>-</td>)}/>
            <GridColumn title="Any suggestions to Improve our service?" width={200} cell={(cellprops)=>(<td>-</td>)}/>
            <GridColumn title="How do we address you?" width={200} cell={(cellprops)=>(<td>-</td>)}/>
            <GridColumn title="How can we contact you?" width={200} cell={(cellprops)=>(<td>-</td>)}/>
        </GridColumn>        
        <GridColumn title="3rd Commuter Feedback">
            <GridColumn title="I find the waiting time for this bus acceptable" width={200} cell={(cellprops)=>(<td>-</td>)}/>
            <GridColumn title="I find the bus ride comfortable" width={200} cell={(cellprops)=>(<td>-</td>)}/>
            <GridColumn title="I find the bus ride safe" width={200} cell={(cellprops)=>(<td>-</td>)}/>
            <GridColumn title="I find the Bus Captain courteous and polite" width={200} cell={(cellprops)=>(<td>-</td>)}/>
            <GridColumn title="I had a good experience on this bus" width={200} cell={(cellprops)=>(<td>-</td>)}/>
            <GridColumn title="Any suggestions to Improve our service?" width={200} cell={(cellprops)=>(<td>-</td>)}/>
            <GridColumn title="How do we address you?" width={200} cell={(cellprops)=>(<td>-</td>)}/>
            <GridColumn title="How can we contact you?" width={200} cell={(cellprops)=>(<td>-</td>)}/>
        </GridColumn>        
        <GridColumn>
          <GridColumn title="Action Taken" width={200} cell={(cellprops)=>(
            <td>
              <div className="actiontakenDiv" onClick={()=> {
                setSelectedRow(cellprops.dataItem);
                setShowActionTaken(true);        
              }}>{cellprops.dataItem.action_takens}</div>
            </td>
          )}/>
          <GridColumn title="Meet Training Object" width={200} cell={(cellprops)=>(
            <td>
              <RadioButton
                name={cellprops.dataItem.reference_no}
                value={true} 
                checked={cellprops.dataItem.is_meet_training === true}
                onChange={((e)=> onChangeIsMeetTrainingObject(e,cellprops.dataItem.id))}
                label="Yes"/>                
                <br/><br/>
              <RadioButton 
                name={cellprops.dataItem.reference_no}
                value={false} 
                checked={cellprops.dataItem.is_meet_training === false}
                onChange={((e)=> onChangeIsMeetTrainingObject(e,cellprops.dataItem.id))}
                label="No"/>
            </td>
          )}/>
          <GridColumn title="Remark" field="report_remark" width={200} editable={true} />
          <GridColumn title="Action" width={250} cell={(props)=>(
            <td>
              <Button className="report-table-save-btn" onClick={(e)=> onSave(e,props.dataItem)}>Save</Button>
            </td>
          )}/>
        </GridColumn>
    </Grid>
    {showActionTaken && (
      <FobReportActionTakenDialog onCloseDialog={()=>setShowActionTaken(false)} data={selectedRow} />
    )}
    </div>
  );
};
