import { Loader } from "@progress/kendo-react-indicators";
import Icons, { IconSize } from '../../../../../../../components/icons';
import Modal from '../../components/modal';
import { useUpload, useReploadBSQC } from './action';

const Component = props => {
  const { file, filename, onAddFile, onDeleteFile } = useUpload();
  const { onReUpload, isFetching } = useReploadBSQC({
    id: props?.id,
    file,
    filename,
    onSuccess: () => {
      onDeleteFile(); // Reset form
      if (typeof props?.onSuccess === 'function') props?.onSuccess()
    }
  })

  return (
    <Modal
      isOpen={props?.isOpen}
      onSubmit={onReUpload}
      onClose={() => {
        if (isFetching) return;

        props?.onClose();
      }}
      width={350}
      height={400}
      actionComponent={
        <div style={{ display: 'grid', gridTemplateColumns: 'auto auto', columnGap: 20 }}>
          <button disabled={isFetching} onClick={props?.onClose} className="button-cancel">
            Cancel
          </button>

          <button disabled={isFetching} onClick={onReUpload} className="button-submit">
            {(props?.loading || isFetching) ? 'Loading ...' : 'Re-upload'}
          </button>
        </div>
      }
    >
      <div>
        <div className='form-uploader'>
          <div>
            <span>
              <span style={{ fontWeight: 'bold' }}>Upload Excel File</span>
              <span style={{ color: 'red' }}>*</span>
            </span>
            <span>(no more than 5MB each)</span>
          </div>
          <div className='lisf-of-file'>
            {isFetching ? (
              <div style={{ marginTop: 20 }}>
                <Loader
                  type="infinite-spinner"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "40px",
                    margin: "auto",
                    justifyContent: "center"
                  }}
                />
              </div>
            ) : (
              <>
                {(file && filename) && (
                  <div className='card-item'>
                    <div className='card-file-upload cursor-pointer'>
                      <Icons className="fa fa-file-lines" style={{ color: '#73007D'}} />
                      <span>{filename}</span>
                    </div>
                    <div className='card-action'>
                      <Icons
                        onClick={onDeleteFile}
                        className="fa fa-trash fa-lg cursor-pointer"
                        size={IconSize.large}
                        style={{ color: '#73007D'}}
                      />
                    </div>
                  </div>
                )}
                {(!filename && !file) && (
                  <div className='card-item cursor-pointer position-relative'>
                    <input onChange={onAddFile} className="input-file-csv" type="file" accept=".xlsx" />
                    <Icons className="fa fa-file-lines" style={{ color: '#73007D'}} />
                    <span>Choose File</span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default Component;
