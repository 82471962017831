import React, { useState, useEffect, useRef } from "react";
import {
  TextBox,
} from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Icon } from "@progress/kendo-react-common";
import api from "../../../../config/api";
import { useNavigate } from "react-router";
import { ADMIN_TOOLS_CARES_IMAGES } from "../../../../routes";
import { getCurrentLoggedInSessionTokenWithPromise } from "../../../../config/userPool";

export const EcardImageForm = ({
  data,
  isEdit,
  setIsEdit,
  submitForm,
  setLoading,
  isCreate,
}) => {
  const navigate = useNavigate();
  const [name, setName] = useState(null);
  const [iconImage, setIconImage] = useState({
    file: null,
    url: null,
    name: null,
    icon_url: null,
  });

  const hiddeniconRef = useRef(null);

  useEffect(() => {
    if (data) {
      const icon_ = {
        name: data.icons,
        url: data.url,
        file: null,
        icon_url: data.s3_url
      };
      setIconImage(icon_);
      setName(data.name);
    }
  }, [data]);

  const onUploadFile = async (e) => {
    setIconImage({
      name: e.name,
      url: null,
      file: e
    });
  };
  
  const submitAccessoryForm = async() => {
    setLoading(true);
    if(iconImage.file){
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const extension_ =  iconImage.type === "" ? iconImage.file.name.split(".")[1] : iconImage.file.type.split("/")[1];
      console.log(extension_);
      api
        .post(
          "/api-cares/get-presigned-url",
          JSON.stringify({
            extension: extension_,
          }),
          token
        ).then((res) => {
          console.log(res);
          api.postDiff(res.data.presigned_url, iconImage.file).then((res1) => {
            console.log(res1);
            if (res1 === 200) {
              submitForm({
                name,
                url: res.data.key
              });
            } else {
              alert("An error occured while uploading file! Please try again!");
            }
          });
        });    
    }else{
      submitForm({
        name,
        url: iconImage.url
      })
    }
  };
  const cancelForm = () => {
    console.log(data);
    if (data & isEdit) {
      // setIsEdit(true);
    } else {
      navigate(ADMIN_TOOLS_CARES_IMAGES);
    }
  };

  return (
    <>
      <table>
        <tr>
          <td>Name</td>
          {isEdit ? (
            <td>
              <TextBox value={name} onChange={(e) => setName(e.target.value)} />
            </td>
          ) : (
            <td>{data.name}</td>
          )}
        </tr>
        <tr>
          <td>Icon</td>
          <td>
            <input
              ref={hiddeniconRef}
              type="file"
              accept="image/png, image/jpeg"
              hidden
              onChange={(e)=>onUploadFile(e.target.files[0])}
            />
            {isEdit ? (
              <>
                {console.log(iconImage)}
                {iconImage.file || iconImage.icon_url ? (
                  <div className="detail-file-div">
                    <img
                      src={
                        iconImage.file
                          ? URL.createObjectURL(iconImage.file)
                          : iconImage.icon_url
                      }
                      width={150}
                    />
                    <label className="detail-file-name">{iconImage.name}</label>
                    <Icon
                      name="delete"
                      size="large"
                      onClick={() =>
                        setIconImage({ file: null, url: null, name: null })
                      }
                    ></Icon>
                  </div>
                ) : (
                  <>
                    <Button onClick={(e) => hiddeniconRef.current.click()}>
                      + <span className="gray-color-text">Add</span>
                    </Button>
                  </>
                )}
              </>
            ) : (
              <div className="detail-file-div">
                <img src={iconImage.icon_url} width={150} />
                <label className="detail-file-name">{iconImage.name}</label>
              </div>
            )}
          </td>
        </tr>
        {isEdit && (
          <tr>
            <td colSpan={2}>
              <div className="detail-button-div">
                <Button
                  className="detail-button detail-cancel"
                  onClick={cancelForm}
                >
                  Cancel
                </Button>
                <Button
                  className="detail-button detail-submit"
                  onClick={submitAccessoryForm}
                >
                  Submit
                </Button>
              </div>
            </td>
          </tr>
        )}
      </table>
    </>
  );
};
