import React, { useState, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
// redux
import { useDispatch, useSelector } from "react-redux";
import { createRole } from "../../../../../store/commRole";
// kendo
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
// routes
import { ADMIN_TOOLS_ROLE_LIST } from "../../../../../routes";
// component
import { CommRoleForm } from "../form";
// image
import ArrorRightIcon from "../../../../../assets/common/arrao-right.svg";
import BackIcon from "../../../../../assets/communication/Back.svg";
import EditIcon from "../../../../../assets/common/edit_icon.svg";
// css
import "../comm_role.css";

export const CommRoleAddNew = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // redux
  const { create_role, create_role_error, create_role_loading } = useSelector(
    (state) => state.commRole
  );

  // state
  const [loading, setLoading] = useState(false);

  // method
  const handleSaveRole = (data) => {
    console.log("handleSaveRole: ", data);
    dispatch(createRole(data)).then(() => {
      setLoading(false);
      navigate(ADMIN_TOOLS_ROLE_LIST);
    });
  };
  return (
    <>
      <div className="role-detail-body">
        {loading || create_role_loading ? (
          <Loader
            type="infinite-spinner"
            style={{
              display: "flex",
              alignItems: "center",
              width: "40px",
              margin: "auto",
              justifyContent: "center",
              height: "100vh",
            }}
          />
        ) : (
          <>
            <div className="role-detail-header">
              <div
                className="role-detail-group-div"
                onClick={() => navigate(ADMIN_TOOLS_ROLE_LIST)}
              >
                <img src={BackIcon} className="role-detail-icon" />
                <span className="role-detail-label">Back</span>
              </div>
            </div>
            <div className="role-detail-content">
              <CommRoleForm
                data={null}
                isEdit={true}
                setIsEdit={true}
                setLoading={setLoading}
                submitForm={handleSaveRole}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
