export const ConfigData = [
  {
    id: "name",
    name: "Name",
    isShow: true,
    width: "160px",
    filter: true,
  },
  {
    id: "url",
    name: "Image",
    isShow: true,
    width: "450px",
    filter: false,
    type: "image",
    imageSize: { width: "150px", height: "150px" },
  },
  {
    id: "button",
    name: "Action",
    type: "dropdown",
    isShow: true,
    width: "117px",
    action: ["View Details", "Delete"],
  },
];
