import React, { useState, useEffect, useRef } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
// import "../../admin_tools.css";
import BackIcon from "../../../../assets/communication/Back.svg";
import { TELEPHONE_DIRECTORY_DEPARTMENT_LIST } from "../../../../routes";
import { Loader } from "@progress/kendo-react-indicators";
import { DepartmentForm } from "../detail/department_form";
import { createDepartment } from "../../../../store/telephoneDirectory";

export const AddDepartment = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const {
        update_data,
        update_error,
        update_loading
    } = useSelector((state) => state.telephone_directory);

    const handleSaveDepartment = (data) => {
        console.log('submitForm:',data);
        dispatch(createDepartment({ data })).then(() => {
            navigate(TELEPHONE_DIRECTORY_DEPARTMENT_LIST)
        })
    }
    console.log('Add Form');
    return (
        <>
            <div className="detail-body">
                {update_loading || loading ? (
                    <Loader
                        type="infinite-spinner"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            width: "40px",
                            margin: "auto",
                            justifyContent: "center",
                            height: "100vh",
                        }}
                    />
                ) : (
                    <>
                        <div className="detail-header">
                            <div className="categoryback-group-div" onClick={() => navigate(TELEPHONE_DIRECTORY_DEPARTMENT_LIST)}>
                                <img src={BackIcon} className="categoryback-icon" />
                                <span className="categoryback-label">Back</span>
                            </div>
                        </div>
                        <div className="detail-content">
                            <DepartmentForm
                                data={null}
                                isEdit={true}
                                isCreate = {true}
                                submitForm={handleSaveDepartment}
                                setLoading={setLoading}
                            />

                        </div>
                    </>
                )}

            </div>
        </>

    );
};