import React, { useState, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
// redux
import { useDispatch, useSelector } from "react-redux";
import { fetchGroupById, updateGroup } from "../../../store/groupMgmt";
// kendo
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
// routes
import { USER_MANAGEMENT_GROUPS } from "../../../routes";
// component
import { GroupManagementForm } from "../form";
// image
import ArrorRightIcon from "../../../assets/common/arrao-right.svg";
import BackIcon from "../../../assets/communication/Back.svg";
import EditIcon from "../../../assets/common/edit_icon.svg";
// css
import "../group_management.css";

export const GroupManagementDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const group_id = query.get("id");

  // state
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  // redux
  const { group_details_loading, group_details, group_details_error } =
    useSelector((state) => state.groupMgmt);

  // method
  const handleSaveGroup = (data) => {
    console.log("handleSaveGroup: ", data);
    dispatch(updateGroup({ id: group_id, data: data })).then(() => {
      setLoading(false);
      navigate(USER_MANAGEMENT_GROUPS);
    });
  };

  // useEffect
  useEffect(() => {
    if (group_id) {
      dispatch(fetchGroupById(group_id));
    }
  }, []);

  return (
    <>
      <div className="group-detail-body">
        {loading || group_details_loading ? (
          <Loader
            type="infinite-spinner"
            style={{
              display: "flex",
              alignItems: "center",
              width: "40px",
              margin: "auto",
              justifyContent: "center",
              height: "100vh",
            }}
          />
        ) : (
          <>
            <div className="group-detail-header">
              <div
                className="group-detail-group-div"
                onClick={() => navigate(USER_MANAGEMENT_GROUPS)}
              >
                <img src={BackIcon} className="group-detail-icon" />
                <span className="group-detail-label">Back</span>
              </div>
              {!isEdit && (
                <div>
                  <Button
                    className="group-detail-edit-btn"
                    onClick={() => setIsEdit(true)}
                  >
                    <img src={EditIcon} style={{ verticalAlign: "middle" }} />
                    <span className="group-detail-edit-btn-label">Edit</span>
                    <img
                      src={ArrorRightIcon}
                      style={{ verticalAlign: "middle" }}
                    />
                  </Button>
                </div>
              )}
            </div>
            <div className="group-detail-content">
              <GroupManagementForm
                data={group_details}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                setLoading={setLoading}
                submitForm={handleSaveGroup}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
