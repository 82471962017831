import { Dialog } from "@progress/kendo-react-dialogs";

export const MicImageDialog = (props) => {
  return (
    <Dialog
      title={props.image.filename}
      width={500}
      height={500}
      onClose={props.onImageDialogClose}
    >
      <div style={{ width: "auto", display: "inline-block" }}>
        <img
          style={{
            maxWidth: "100%",
            height: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          src={props.image.s3_url ?? ""}
          alt="attachment"
        />
      </div>
    </Dialog>
  );
};
