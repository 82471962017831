// redux
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionToken } from "../config/userPool";

const { DR_SAFE_CONTACT_ENDPOINT } = config.api_endpoint;

const initialState = {
  loading: false,
  details_loading: false,
  drSafeContactList: [],
  drSafeContactDetails: [],
  drSafeContactNewDetails: {},
  drSafeContactUpdateDetails: {},
  error: null,
  details_error: null,
};

export const fetchAllDRSafeContacts = createAsyncThunk(
  "dr_safe_contacts/fetchAllContacts",
  async (_, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(DR_SAFE_CONTACT_ENDPOINT, {}, token);
      return results.data;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const fetchAllDRSafeContactDetails = createAsyncThunk(
  "dr_safe_contacts/fetchAllContactDetails",
  async (id, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(
        `${DR_SAFE_CONTACT_ENDPOINT}/${id}`,
        {},
        token
      );
      return results.data;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const saveDRSafeNewContactDetails = createAsyncThunk(
  "dr_safe_contacts/saveNewContactDetails",
  async ({ contact_name, contact_number, occ }, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.post(
        `${DR_SAFE_CONTACT_ENDPOINT}`,
        JSON.stringify({
          contact_name: contact_name,
          contact_number: contact_number,
          occ: occ,
        }),
        token
      );
      return results.data;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const updateDRSafeContactDetails = createAsyncThunk(
  "dr_safe_contacts/updateContactDetails",
  async ({ id, contact_name, contact_number, occ }, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.post(
        `${DR_SAFE_CONTACT_ENDPOINT}/${id}`,
        JSON.stringify({
          contact_name: contact_name,
          contact_number: contact_number,
          occ: occ,
        }),
        token
      );
      return results.data;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

const drSafeContactSlice = createSlice({
  name: "dr_safe_contacts",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllDRSafeContacts.pending, (state) => {
        console.log("fetch all dr safe contacts pending: ", state);
        state.loading = true;
      })
      .addCase(fetchAllDRSafeContacts.fulfilled, (state, action) => {
        console.log("fetch all dr safe contacts fulfilled: ", state, action);
        state.loading = false;
        state.drSafeContactList = action.payload;
      })
      .addCase(fetchAllDRSafeContacts.rejected, (state, action) => {
        console.log("fetch all dr safe contacts rejected: ", state, action);
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchAllDRSafeContactDetails.pending, (state) => {
        console.log("fetch all dr safe contact details pending: ", state);
        state.details_loading = true;
      })
      .addCase(fetchAllDRSafeContactDetails.fulfilled, (state, action) => {
        console.log(
          "fetch all dr safe contact details fulfilled: ",
          state,
          action
        );
        state.details_loading = false;
        state.drSafeContactDetails = action.payload;
      })
      .addCase(fetchAllDRSafeContactDetails.rejected, (state, action) => {
        console.log(
          "fetch all dr safe contact details rejected: ",
          state,
          action
        );
        state.details_loading = false;
        state.details_error = action.error.message;
      })
      .addCase(saveDRSafeNewContactDetails.pending, (state) => {
        console.log("save new dr safe contact details pending: ", state);
        state.details_loading = true;
      })
      .addCase(saveDRSafeNewContactDetails.fulfilled, (state, action) => {
        console.log(
          "save new dr safe contact details fulfilled: ",
          state,
          action
        );
        state.details_loading = false;
        state.drSafeContactNewDetails = action.payload;
      })
      .addCase(saveDRSafeNewContactDetails.rejected, (state, action) => {
        console.log(
          "save new dr safe contact details rejected: ",
          state,
          action
        );
        state.details_loading = false;
        state.details_error = action.error.message;
      })
      .addCase(updateDRSafeContactDetails.pending, (state) => {
        console.log("update dr safe contact details pending: ", state);
        state.details_loading = true;
      })
      .addCase(updateDRSafeContactDetails.fulfilled, (state, action) => {
        console.log(
          "update dr safe contact details fulfilled: ",
          state,
          action
        );
        state.details_loading = false;
        state.drSafeContactUpdateDetails = action.payload;
      })
      .addCase(updateDRSafeContactDetails.rejected, (state, action) => {
        console.log("update dr safe contact details rejected: ", state, action);
        state.details_loading = false;
        state.details_error = action.error.message;
      });
  },
});

const drSafeContactReducer = drSafeContactSlice.reducer;
export default drSafeContactReducer;
