import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { TableHeader } from "../../../../components/table/Tableheader";
import {
  ADMIN_TOOLS_MIC_DEPARTMENT_ADD,
  ADMIN_TOOLS_MIC_DEPARTMENT_DETAILS,
  ADMIN_TOOLS_MIC_DEPARTMENT,
} from "../../../../routes";
import { cloneElement, useEffect, useMemo, useState } from "react";
import { GetCategory, DeleteDepartment } from "../../../../services/ideas";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-react-data-tools";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Loader } from "@progress/kendo-react-indicators";
import {
  checkPermission,
  getUserPermission,
} from "../../../../config/permission_helper";
import { useNavigate } from "react-router";
import { Confirmation } from "../../../../components/dialog/confirmation";
import { SearchMicDept } from "../../../../components/dialog/mic_dept_search";

const initialDataState = {
  skip: 0,
  take: 10,
  dir: "asc",
};

const initialSort = [
  {
    field: "name",
    dir: "desc",
  },
];

const itemRender = (li, itemProps) => {
  const itemChildren = (
    <span
      style={{
        display: "flex",
      }}
    >
      {li.props.children}
    </span>
  );
  return cloneElement(li, li.props, itemChildren);
};

const Page = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userPermissions = getUserPermission();
  let { search } = useLocation();
  const query = new URLSearchParams(search);

  const [page, setPage] = useState(initialDataState);
  const [pageSizeValue, setPageSizeValue] = useState();
  const [sort, setSort] = useState(initialSort);

  const [allDepts, setAllDepts] = useState([]);
  const [list, setList] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [selectedID, setSelectedID] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showSearchDialog, setShowSearchDialog] = useState(false);

  const onFetchData = async () => {
    setIsFetching(true);
    const [err, response] = await GetCategory();

    if (!err && response) {
      setList(response?.data);
      setAllDepts(response?.data);
    }

    setIsFetching(false);
  };

  const handleConfirmDelete = async () => {
    setShowConfirm(false);
    setIsFetching(true);
    const [err, response] = await DeleteDepartment(selectedID);
    if (!err && response) {
      onFetchData();
    } else {
      setIsFetching(false);
      alert(err);
    }
  };

  const handleItemDelete = async (id) => {
    setSelectedID(id);
    setShowConfirm(true);
  };

  useEffect(() => {
    onFetchData();
  }, []);

  const pageChange = (event) => {
    const targetEvent = event.targetEvent;
    const take = targetEvent.value === "All" ? 77 : event.page.take;
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const handleFilterAction = () => {
    let newData = orderBy(
      allDepts.slice(page.skip, page.take + page.skip),
      sort
    );

    if (query.toString()) {
      if (query.get("name")) {
        newData = newData.filter(
          (v) =>
            v.desc.toLowerCase().indexOf(query.get("name").toLowerCase()) > -1
        );
      }
    }

    newData = newData?.map(({ user_list, ...rest }) => ({
      ...rest,
      user_list: user_list?.map(({ name }) => name),
    }));
    setList(newData);
  };

  useEffect(() => {
    if ((page, allDepts, sort)) {
      handleFilterAction();
    }
  }, [page, allDepts, sort]);

  const isAbleToCreate = checkPermission(
    userPermissions,
    "admin_tools.add_edit_MIC_department"
  );

  const handleSearchCallback = (data) => {
    const { name } = data;

    query.delete("search");
    query.set("name", name);
    if (name) {
      query.set("search", true);
    }
    navigate(ADMIN_TOOLS_MIC_DEPARTMENT + "?" + query.toString());
    setShowSearchDialog(false);
    handleFilterAction();
  };

  const handleClearSearchOptions = () => {
    query.delete("name");
    query.delete("search");
    navigate(ADMIN_TOOLS_MIC_DEPARTMENT + "?" + query.toString());
    handleFilterAction();
  };

  return (
    <div className="body">
      {showSearchDialog && (
        <SearchMicDept
          setShowSearchDialog={setShowSearchDialog}
          onSearchcallback={handleSearchCallback}
        />
      )}
      {showConfirm && (
        <Confirmation
          onClickYes={handleConfirmDelete}
          onClickNo={() => setShowConfirm(false)}
        />
      )}
      <TableHeader
        title="Admin Tools MIC Department"
        onRefresh={onFetchData}
        loading={isFetching}
        hideaddbtn={!isAbleToCreate}
        hideFilterBtn
        addurl={ADMIN_TOOLS_MIC_DEPARTMENT_ADD}
        onSearch={() => setShowSearchDialog(true)}
      />
      {query.get("search") && (
        <div className="table-filter-div">
          <div className="table-filter-item">
            <div className="table-filter-title">Keyword search applied</div>
            <span
              className="table-filter-clear"
              onClick={handleClearSearchOptions}
            >
              Clear search
            </span>
          </div>
        </div>
      )}

      <div className="tbody-content">
        {isFetching ? (
          <Loader
            type="infinite-spinner"
            style={{
              display: "flex",
              alignItems: "center",
              width: "40px",
              margin: "auto",
              justifyContent: "center",
              height: "100vh",
            }}
          />
        ) : (
          <Grid
            data={list}
            skip={page.skip}
            take={page.take}
            total={10}
            sortable={true}
            sort={sort}
            onSortChange={(e) => {
              setSort(e.sort);
            }}
            pageable={{
              buttonCount: 4,
              pageSizes: [5, 10, 15, "All"],
              pageSizeValue: pageSizeValue,
              info: false,
              pageSizes: false,
            }}
            pageSize={false}
            info={false}
            pageSizes={false}
            onPageChange={pageChange}
          >
            <GridColumn field="pk" title="ID" />
            <GridColumn field="desc" title="Name" />
            <GridColumn field="user_list" title="User List" />
            <GridColumn
              sortable={false}
              width={200}
              field="Action"
              cell={(props) => (
                <td className="k-table-td">
                  <DropDownList
                    size={"large"}
                    fillMode={"solid"}
                    rounded={"large"}
                    className="action-btn-dropdown"
                    data={["View Details", "Delete"]}
                    // valueRender={valueRender}
                    itemRender={itemRender}
                    defaultValue={"Action"}
                    onChange={(e) => {
                      const targetEvent = e?.target?.value ?? "";
                      console.log(
                        "targetEvent: ",
                        targetEvent,
                        props?.dataItem
                      );

                      if (targetEvent === "View Details") {
                        const pageId = props?.dataItem?.pk ?? "";

                        navigate(
                          ADMIN_TOOLS_MIC_DEPARTMENT_DETAILS.replace(
                            ":id",
                            pageId
                          )
                        );

                        return;
                      } else if (targetEvent === "Delete") {
                        const pageId = props?.dataItem?.pk ?? "";
                        handleItemDelete(pageId);
                      }
                    }}
                    style={{
                      fontSize: "15px",
                    }}
                  />
                </td>
              )}
            />
          </Grid>
        )}
      </div>
    </div>
  );
};

export default Page;
