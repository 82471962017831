import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// kendo
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { TextArea } from "@progress/kendo-react-inputs";
// icon
import CrossIcon from "../../../../assets/dr_safe/cross_gray_icon.png";
// css
import "./ifeedback_details.css";


const IFeedbackDetailsShareDialog = ({  
  onToggleDialog, 
  onSubmitShare,
}) => {
  const navigate = useNavigate();
  const [shareEmails, setShareEmails] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const onShareChange = (e) => {
    setShareEmails(e.target.value);
  };

  const onSubmit = () => {
    if(shareEmails == ''){
      setErrorMessage("Please fill out the below field")
    }
    else
    {
      console.log("Share Emails in Dialog: ",shareEmails);
      onSubmitShare(shareEmails)
    }
  };
  return (
    <Dialog>
      <div className="ifeedback-details-share-dialog">
        <div
          style={{
            position: "absolute",
            top: "15px",
            right: "13px",
            cursor: "pointer",
          }}
          onClick={onToggleDialog}
        >
        <img src={CrossIcon} />
        </div>
        <div className="ifeedback-details-status-dialog-title">Share</div>
        
        <div className="ifeedback-details-updates-input-wrapper">
          
          <TextArea className="ifeedback-share-updates-input" placeholder='Emails, separate by comma' onChange={onShareChange} 
            />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            className="ifeedback-details-status-dialog-button"
            onClick={onSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default IFeedbackDetailsShareDialog;