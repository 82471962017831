import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Dialog } from "@progress/kendo-react-dialogs";
// components
import DrInfraDetailsContent from "./dr_infra_details_content";
import DrInfraDetailsUpdates from "./dr_infra_details_updates";
import DrInfraDetailsStatus from "./dr_infra_details_status";
import DrInfraDetailsActions from "./dr_infra_details_actions";
import DrInfraDetailsAudits from "./dr_infra_details_audits";
// css
import "./dr_infra_details.css";
import {
  fetchAllDRInfraIncidentDetails,
  fetchAllDRInfraIncidentRemarks,
  saveDRInfraIncidentRemarks,
  changeDRInfraIncidentStatus,
  fetchAllDRInfraIncidents,
  saveDRInfraMarkAsDuplicate,
  saveDRInfraIncidentShare,
  saveDRInfraIncidentGroup
} from "../../../../store/drInfraIncidient";
import { fetchUserById } from "../../../../store/userMgmt";
import {
  getUserPermission,
  checkMultiPermission,
  DR_INFRA_PERMISSIONS_FOR_UPDATE_STATUS,
  DR_INFRA_PERMISSIONS_FOR_SUBMIT_UPDATE,
  DR_INFRA_PERMISSIONS_FOR_ROUTE,
  DR_INFRA_PERMISSIONS_FOR_SHARE,
  DR_INFRA_PERMISSIONS_FOR_MARK_DUPLICATE
} from "../../../../config/permission_helper";

const DrInfraDetails = () => {
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const dispatch = useDispatch();

  const {
    drInfraIncidentDetails:incident,
    details_loading: loading,
    details_error: error,
    drInfraIncidentRemarks:updates,
    remarks_loading,
    remarks_error,
    drInfraIncidentList:parentCasesList, 
    parentcaseloading
  } = useSelector((state) => state.drInfraIncident);
  const { user_details_loading, user_details, user_details_error } = useSelector((state) => state.userMgmt);
  const [audits, setAudits] = useState([]);
  const [locations, setLocations] = useState([]);
  const [formLoading,setFormLoading] = useState(false);
  const [showChangeStatusDialog, setShowChangeStatusDialog] = useState(false);
  const [showDuplicateDialog,setShowDuplicateDialog] = useState(false);
  const [showRouteToDepeDialog,setShowRouteToDepDialog] = useState(false);
  const [showChangeShareDialog, setshowChangeShareDialog] = useState(false);
  const [actionMessage, setActionMessage] = useState("");
  const [showAlert,setshowAlert] = useState(false);
  const incidentStatuses = [
    "Submitted",
    "Checked",
    "Verified",
    "Completed",    
  ];  
  
  const [currentIncidentStatus, setCurrentIncidentStatus] = useState("");
  const [createdByDetails, setCreatedByDetails] = useState({});
  const [updatedByDetails, setUpdatedByDetails] = useState({});

  const case_ref_no = query.get("case_ref_no")
  const handleChangeIncidentStatus = (e) => {
    setCurrentIncidentStatus(e.target.value);
  };

  const handleSaveIncidentStatus = () => {
    dispatch(
      changeDRInfraIncidentStatus({
        case_ref_no: case_ref_no,
        incidentStatus: currentIncidentStatus.toLowerCase(),
      })
    ).then(result => {
        console.log("status RES-",result);
        if(result.payload.status == "success"){
          setActionMessage('Updated Status');
          setshowAlert(true);
        }
        else{
          setActionMessage(result.payload.message);
          setshowAlert(true);
        }
      })
      .then(()=>{
      dispatch(fetchAllDRInfraIncidentDetails(case_ref_no))
    });
    setShowChangeStatusDialog(false);
  };

  const handleUpdateStatusBtnClicked = () => {
    setCurrentIncidentStatus(incident.status_str);
    setShowChangeStatusDialog(true);
  };
  const handleChangeIncidentStatusDialogClose = () => {
    setShowChangeStatusDialog(false);
  };

  const saveRemarks = (description) => {
    console.log("saveRemarks:", case_ref_no, description);
    dispatch(saveDRInfraIncidentRemarks({ case_ref_no, description }));
  };

  const handleDuplicateAction = (currentParentCase,description)=>{
    setShowDuplicateDialog(false);
    dispatch(saveDRInfraMarkAsDuplicate({ 
      case_ref_no, currentParentCase, description 
    })).then(result => {
        console.log("duplicate RES-",result);
        if(result.payload.status == "success"){
          setActionMessage('Duplicated Successfully!');
          setshowAlert(true);
        }
        else{
          setActionMessage(result.payload.message);
          setshowAlert(true);
        }
      })
      .then(()=>{
        dispatch(fetchAllDRInfraIncidentDetails(case_ref_no))
    });
  };

  const handleRouteToDepAction = async(data)=>{
    setShowRouteToDepDialog(false);
    // const body = { group_id : data }
    dispatch(saveDRInfraIncidentGroup({case_ref_no,group_id: data})).
    then(result => {
      console.log("route to dep RES-",result);
      if(result.payload.status == "success"){
        setActionMessage('Successfully route to other department!');
        setshowAlert(true);
      }
      else{
        setActionMessage(result.payload.message);
        setshowAlert(true);
      }
    })
    .then(()=>{
      dispatch(fetchAllDRInfraIncidentDetails(case_ref_no))
    })
  }
  
  const saveShare= (emails) =>{
    setshowChangeShareDialog(false);
    let case_ref_no = incident.case_ref_no;
    console.log("dr safe save Share:", case_ref_no, emails);
    dispatch(saveDRInfraIncidentShare({ case_ref_no, emails }))
      .then(result => {
        console.log("Share RES- ",result);
        if (result.payload.status == "success"){
          setActionMessage('Submitted successfully!');
          setshowAlert(true);
        }
        else {
          setActionMessage(result.payload.message);
          setshowAlert(true);
        }
      })
      .catch(error => {setActionMessage('Share failed.');
    });    
  };

  const shouldShowBtnUpdateStatus = () => {
    const userPermissions = getUserPermission();
    if (checkMultiPermission(userPermissions, DR_INFRA_PERMISSIONS_FOR_UPDATE_STATUS)) {
      return true;
    } else {
      return false;
    }
  };

  const shouldShowBtnUpdate = () => {
    const userPermissions = getUserPermission();
    if (checkMultiPermission(userPermissions, DR_INFRA_PERMISSIONS_FOR_SUBMIT_UPDATE)) {
      return true;
    } else {
      return false;
    }
  };

  const shouldShowBtnRoute = () => {
    const userPermissions = getUserPermission();
    if (checkMultiPermission(userPermissions, DR_INFRA_PERMISSIONS_FOR_ROUTE)) {
      return true;
    } else {
      return false;
    }
  };

  const shouldShowBtnShare = () => {
    const userPermissions = getUserPermission();
    if (checkMultiPermission(userPermissions, DR_INFRA_PERMISSIONS_FOR_SHARE)) {
      return true;
    } else {
      return false;
    }
  };

  const shouldShowBtnMarkDuplicate = () => {
    const userPermissions = getUserPermission();
    if (checkMultiPermission(userPermissions, DR_INFRA_PERMISSIONS_FOR_MARK_DUPLICATE)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (case_ref_no) {
      dispatch(fetchAllDRInfraIncidentDetails(case_ref_no));
      dispatch(fetchAllDRInfraIncidentRemarks(case_ref_no));      
    }
    dispatch(fetchAllDRInfraIncidents());
  }, []);
  useEffect(()=>{
    if(incident.created_by){
      if(incident.created_by.username === incident.updated_by.username){
        dispatch(fetchUserById(incident.created_by.username));
      }else{
        dispatch(fetchUserById(incident.created_by.username));
        dispatch(fetchUserById(incident.updated_by.username));
      }
    }
  },[incident]);
  useEffect(()=>{
    if(user_details.username && incident.created_by){
      if(user_details.username === incident.created_by.username){
        setCreatedByDetails(user_details);
      }
      if(user_details.username === incident.updated_by.username){
        setUpdatedByDetails(user_details);
      }
      
    }
  },[user_details]);
  return (
    <div className="dr-infra-details-wrapper">
      <div className="dr-infra-details-left">
      {showAlert && (
                <Dialog
                    className="alert-form"
                    title={"Alert"}                    
                    width={400}
                    // height={280}
                    onClose={()=>setshowAlert(false)}
                >
                    <p>{actionMessage}</p>
                </Dialog>
        )}
        <DrInfraDetailsContent
          loading={loading || formLoading || user_details_loading}
          incident={incident}
          locations={locations}
          createdUser = {createdByDetails}
          updatedUser = {updatedByDetails}
        />
        {/* <hr className="dr-infra-details-content-details-hr"></hr> */}
        <DrInfraDetailsUpdates
          updates={updates}
          saveRemarks={saveRemarks}
          loading={remarks_loading}
          shouldShowBtnUpdate={shouldShowBtnUpdate()}
        />
      </div>
      <div className="dr-infra-details-right">
        <DrInfraDetailsStatus
          loading={loading}
          status={incident ? incident.status_str : ""}
        />
        <DrInfraDetailsActions
          case_ref_no={case_ref_no}        
          // Status Update Obj
          onToggleDialog={handleChangeIncidentStatusDialogClose}
          incidentStatuses={incidentStatuses}
          currentIncidentStatus={currentIncidentStatus}
          onChangeIncidentStatus={handleChangeIncidentStatus}
          onSaveIncidentStatus={handleSaveIncidentStatus}
          showChangeStatusDialog={showChangeStatusDialog}
          onUpdateStatusBtnClicked={handleUpdateStatusBtnClicked}
          // Duplicate Obj
          onDuplicateClose = {()=>setShowDuplicateDialog(false)}
          onDuplicateOpen = {()=>setShowDuplicateDialog(true)}
          showDuplicateDialog={showDuplicateDialog}
          onDuplicateAction = {(case_ref_no,description)=>handleDuplicateAction(case_ref_no,description)}
          parentCasesList={parentCasesList}
          parentcaseloading={parentcaseloading}
          // Share OBj
          showChangeShareDialog={showChangeShareDialog}
          onShareBtnClicked={()=> setshowChangeShareDialog(true)}
          onShareDialogClose = {()=> setshowChangeShareDialog(false)}
          onSubmitShare={saveShare}          
          // Route to Dept Obj
          showRouteToDepeDialog={showRouteToDepeDialog}
          onRouteToDepClose={()=>setShowRouteToDepDialog(false)}
          onRouteToDepOpen={()=>setShowRouteToDepDialog(true)}
          onRouteToDepAction={(res)=>handleRouteToDepAction(res)}

          shouldShowBtnUpdateStatus={shouldShowBtnUpdateStatus()}
          shouldShowBtnRoute={shouldShowBtnRoute()}
          shouldShowBtnShare={shouldShowBtnShare()}
          shouldShowBtnMarkDuplicate={shouldShowBtnMarkDuplicate()}
        />
        <DrInfraDetailsAudits loading={loading} audits={audits} />
      </div>
    </div>
  );
};

export default DrInfraDetails;
