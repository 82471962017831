import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// components
import ContactListHeader from "./contact_list_header";
import ContactListBody from "./contact_list_body";
// store
import { fetchAllDRSecureContacts } from "../../../../store/drSecureContact";
// css
import "./contact_list.css";

const DrSecureContactList = () => {
  const dispatch = useDispatch();

  const [contactList, setContactList] = useState();
  const { drSecureContactList, loading, error } = useSelector(
    (state) => state.drSecureContact
  );

  useEffect(() => {
    console.log("drSecureContactList:", drSecureContactList);
    if (drSecureContactList) {
      setContactList(drSecureContactList);
    }
  }, [drSecureContactList]);

  useEffect(() => {
    dispatch(fetchAllDRSecureContacts());
  }, []);

  return (
    <div className="dr-secure-contact-list-wrapper">
      <ContactListHeader />
      <ContactListBody loading={loading} records={contactList} />
    </div>
  );
};

export default DrSecureContactList;
