import React, { useState, useRef, useEffect } from "react";

import { Navigate, useLocation, useNavigate } from "react-router";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";

import "../../../../components/table/table.css";
import RefreshIcon from "../../../../assets/header/refresh.svg";
import SearchIcon from "../../../../assets/header/search.svg";
import FilterIcon from "../../../../assets/header/filter.svg";
import ExportIcon from "../../../../assets/header/export.svg";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { exportToCSViFeedback } from "../../../../store/ifeedback";

export const TableHeader = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const exportLink = useRef(null);
  const [loading, setLoading] = useState(false);
  const [exportData, setExportData] = useState([]);

  const formatExportValue = (value) => {
    return value.replaceAll(/"/g, '""');
  };

  useEffect(() => {
    if (exportData.length > 0) {
      console.log("ExportExcel useEffect:", exportData);
      exportLink.current.link.click();
    }
  }, [exportData]);
  // const onClickAddButton = ()=>{
  //   const {addurl} = props;
  //   navigate(addurl);
  // };
  const onClickExportButton = () => {
    setLoading(true);
    let data = {};
    dispatch(exportToCSViFeedback(data)).then((res) => {
      console.log("Export method res-", res.payload);
      let data = res.payload;
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].length; j++) {
          data[i][j] = formatExportValue(data[i][j]);
        }
      }
      setExportData(data);
      setLoading(false);
      //
    });
  };
  return (
    <div className="table-header">
      <div className="table-header-div">
        <p className="table-name">{props.title}</p>
        <div className="buttons_group">
          {!props.hiderefreshbtn && (
            <Button
              className="button2"
              title="Refresh"
              onClick={() => props.loadData()}
              disabled={props.loading}
            >
              <img src={RefreshIcon} />
            </Button>
          )}
          {props.showiFeedbackExport && (
            <>
              <CSVLink
                uFEFF={false}
                asyncOnClick={true}
                data={exportData}
                filename="export_ifeedback"
                ref={exportLink}
                hidden
              >
                Download
              </CSVLink>
              <Button
                className="button2"
                title="Export"
                onClick={onClickExportButton}
                disabled={loading}
              >
                <img src={ExportIcon} />
              </Button>
            </>
          )}
          {!props.hideFilterBtn && (
            <Button
              className="button2"
              title="Filter"
              onClick={() => props.setShowFilterDialog(true)}
            >
              <img src={FilterIcon} />
            </Button>
          )}
          {!props.hideSearchBtn && (
            <Button
              className="button2"
              title="Search"
              onClick={() => props.setShowSearchDialog(true)}
            >
              <img src={SearchIcon} />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
