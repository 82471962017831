import { useEffect, useState } from "react";
import { GetAssignmentListing } from "../../../services/assignment-listing";

export const useAction = () => {
  const MockData = [
    {
      id: "1",
      assignedTo: "Ong Leng Har-00116845",
      feedbackScheme: "Bus Service Quality Check",
      assignedBC: "-",
      serviceNo: "5",
      direction: "1",
      boardingBusStop: "84479",
      type: "-",
      checkerNote: "No",
      commuterFeedback: "-"
    },
    {
      id: "1",
      assignedTo: "Ong Leng Har-00116845",
      feedbackScheme: "Bus Service Quality Check",
      assignedBC: "-",
      serviceNo: "5",
      direction: "1",
      boardingBusStop: "-",
      type: "-",
      checkerNote: "No",
      commuterFeedback: "-"
    },
    {
      id: "1",
      assignedTo: "Ong Leng Har-00116845",
      feedbackScheme: "Bus Service Quality Check",
      assignedBC: "-",
      serviceNo: "5",
      direction: "1",
      boardingBusStop: "-",
      type: "-",
      checkerNote: "No",
      commuterFeedback: "-"
    },
  ];
  const [assignment, setAssignment] = useState(MockData);

  useEffect(() => {
    const GetAssignmentList = async () => {
      const payload = { service_no: 5, bus_stop_code: 84479, direction: 1, checker_name: "iLink Admin" };
      const [err, response] = GetAssignmentListing(payload);

      console.log('RESPONSE: ', err, response);
    };

    GetAssignmentList();
  }, [])

  return { data: assignment }
}