import React, { useState, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
// redux
import { useDispatch, useSelector } from "react-redux";
import { fetchUserById } from "../../../store/userMgmt";
// kendo
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
// routes
import { USER_MANAGEMENT_USERS } from "../../../routes";
// component
import { UserManagementForm } from "../form";
// image
import ArrorRightIcon from "../../../assets/common/arrao-right.svg";
import BackIcon from "../../../assets/communication/Back.svg";
import EditIcon from "../../../assets/common/edit_icon.svg";
// css
import "../user_management.css";

export const UserManagementDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const user_id = query.get("id");

  // state
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  // redux
  const { user_details_loading, user_details, user_details_error } =
    useSelector((state) => state.userMgmt);

  // useEffect
  useEffect(() => {
    console.log("User Details: ", user_details);
  }, [user_details]);

  useEffect(() => {
    if (user_id) {
      dispatch(fetchUserById(user_id));
    }
  }, []);

  return (
    <>
      <div className="user-detail-body">
        {loading || user_details_loading ? (
          <Loader
            type="infinite-spinner"
            style={{
              display: "flex",
              alignItems: "center",
              width: "40px",
              margin: "auto",
              justifyContent: "center",
              height: "100vh",
            }}
          />
        ) : (
          <>
            <div className="user-detail-header">
              <div
                className="user-detail-group-div"
                onClick={() => navigate(USER_MANAGEMENT_USERS)}
              >
                <img src={BackIcon} className="user-detail-icon" />
                <span className="user-detail-label">Back</span>
              </div>
            </div>
            <div className="user-detail-content">
              <UserManagementForm
                data={user_details}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                setLoading={setLoading}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
