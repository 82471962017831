// redux
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionTokenWithPromise } from "../config/userPool";

const { EMAIL_TEMPLATE_LIST_ENDPOINT, EMAIL_TEMPLATE_DETAILS_ENDPOINT } =
  config.api_endpoint;

const initialState = {
  email_templates_loading: false,
  email_templates: [],
  email_templates_error: null,
  email_template_details_loading: false,
  email_template_details: [],
  email_template_details_error: null,
  create_email_template_loading: false,
  create_email_template: {},
  create_email_template_error: null,
  update_email_template_loading: false,
  update_email_template: {},
  update_email_template_error: null,
  delete_email_template_loading: false,
  delete_email_template: {},
  
  delete_email_template_error: null,
};

export const fetchAllEmailTemplateList = createAsyncThunk(
  "emailTemplate/fetchAllEmailTemplateList",
  async (_, { rejectWithValue }) => {
    try {

      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(EMAIL_TEMPLATE_LIST_ENDPOINT, {}, token);
      console.log("fetchAllEmailTemplateList Results:", results);
      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetchEmailTemplateById = createAsyncThunk(
  "emailTemplate/fetchEmailTemplateById",
  async (id, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(
        EMAIL_TEMPLATE_DETAILS_ENDPOINT + "/" + id,
        {},
        token
      );
      console.log("fetchEmailTemplateById Results:", results);

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const createEmailTemplate = createAsyncThunk(
  "emailTemplate/createEmailTemplate",
  async (data, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.post(
        EMAIL_TEMPLATE_LIST_ENDPOINT,
        JSON.stringify(data),
        token
      );
      console.log("createEmailTemplate Results:", results);

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const updateEmailTemplate = createAsyncThunk(
  "emailTemplate/updateEmailTemplate",
  async (data, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.post(
        EMAIL_TEMPLATE_DETAILS_ENDPOINT + "/" + data.id,
        JSON.stringify(data.data),
        token
      );
      console.log("updateEmailTemplate Results:", results);

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const deleteEmailTemplate = createAsyncThunk(
  "emailTemplate/deleteEmailTemplate",
  async (id, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.remove(
        EMAIL_TEMPLATE_DETAILS_ENDPOINT + "/" + id,
        JSON.stringify({ id: id }),
        token
      );
      console.log("deleteEmailTemplate Results:", results);

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetchBSQCEmailTemplate = createAsyncThunk(
  "emailTemplate/fetchBSQCEmailTemplate",
  async (_, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(
        EMAIL_TEMPLATE_LIST_ENDPOINT + "/?type=BSQC",
        {},
        token
      );
      console.log("fetchBSQCEmailTemplate Results:", results);

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);


const EmailTemplateSlice = createSlice({
  name: "emailTemplate",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBSQCEmailTemplate.pending, (state) => {
        state.email_templates_loading = true;
      })
      .addCase(fetchBSQCEmailTemplate.fulfilled, (state, action) => {
        state.email_templates_loading = false;
        state.email_templates = action.payload;
      })
      .addCase(fetchBSQCEmailTemplate.rejected, (state, action) => {
        state.email_templates_loading = false;
        state.email_templates_error = action.error.message;
      })
      .addCase(fetchAllEmailTemplateList.pending, (state) => {
        state.email_templates_loading = true;
      })
      .addCase(fetchAllEmailTemplateList.fulfilled, (state, action) => {
        state.email_templates_loading = false;
        state.email_templates = action.payload;
      })
      .addCase(fetchAllEmailTemplateList.rejected, (state, action) => {
        state.email_templates_loading = false;
        state.email_templates_error = action.error.message;
      })
      .addCase(fetchEmailTemplateById.pending, (state) => {
        state.email_template_details_loading = true;
      })
      .addCase(fetchEmailTemplateById.fulfilled, (state, action) => {
        state.email_template_details_loading = false;
        state.email_template_details = action.payload;
      })
      .addCase(fetchEmailTemplateById.rejected, (state, action) => {
        state.email_template_details_loading = false;
        state.email_template_details_error = action.error.message;
      })
      .addCase(createEmailTemplate.pending, (state) => {
        state.create_email_template_loading = true;
      })
      .addCase(createEmailTemplate.fulfilled, (state, action) => {
        state.create_email_template_loading = false;
        state.create_email_template = action.payload;
      })
      .addCase(createEmailTemplate.rejected, (state, action) => {
        state.create_email_template_loading = false;
        state.create_email_template_error = action.error.message;
      })
      .addCase(updateEmailTemplate.pending, (state) => {
        state.update_email_template_loading = true;
      })
      .addCase(updateEmailTemplate.fulfilled, (state, action) => {
        state.update_email_template_loading = false;
        state.update_email_template = action.payload;
      })
      .addCase(updateEmailTemplate.rejected, (state, action) => {
        state.update_email_template_loading = false;
        state.update_email_template_error = action.error.message;
      })
      .addCase(deleteEmailTemplate.pending, (state) => {
        state.delete_email_template_loading = true;
      })
      .addCase(deleteEmailTemplate.fulfilled, (state, action) => {
        state.delete_email_template_loading = false;
        state.delete_email_template = action.payload;
      })
      .addCase(deleteEmailTemplate.rejected, (state, action) => {
        state.delete_email_template_loading = false;
        state.delete_email_template_error = action.error.message;
      });
  },
});

const EmailTemplateReducer = EmailTemplateSlice.reducer;
export default EmailTemplateReducer;
