import Request from './index';

export const GetBSQCPeriod = async (paramsSearch = {}) => {
  const endpoint = '/api-fob/fob-period';

  return await Request.get({ url: endpoint, params: paramsSearch });
}

export const CheckPeriodValid = async (start_date, end_date) => {
  const endpoint = '/api-fob/fob-check-period';
  const params = { start_date, end_date };

  return await Request.get({ url: endpoint, params });
}

export const CreateNewFeedbackOnBus = async payload => {
  console.log('TEST')
  const endpoint = '/api-fob/fob-upload-period';

  return await Request.post({
    url: endpoint,
    payload,
    isFormData: true
  });
}

export const GetFOBStopBusList = async (id) => {
  const endpoint = `/api-fob/fob-bus-stop-list/${id}`;

  return await Request.get({ url: endpoint });
}

export const GetFOBPeriod = async (id) => {
  const endpoint = `/api-fob/fob-period/${id}`;

  return await Request.get({ url: endpoint });
}

export const PublishCheckPeriod = async (id, payload) => {
  const endpoint = `/api-fob/fob-period-publish/${id}`;

  return await Request.post({ url: endpoint, payload});
}

export const DeleteBSQCPeriodByID = async (id) => {
  const endpoint = `/api-fob/fob-period/${id}`
  const payload = { id }
  
  return await Request.delete({ url: endpoint, payload });
}

export const ReUploadBSQCByID = async (id, payload = {}) => {
  const endpoint = `/api-fob/reupload-bus-stop/${id}`
  
  return await Request.post({ url: endpoint, payload, isFormData: true });
}

export const GetFOBCheckerList = async (id = '') => {
  const endpoint = `/api-fob/fob-get-checker-list/${id}`

  return await Request.get({ url: endpoint });
}

export const GetFOBApprovedCheckerList = async (id = '') => {
  const endpoint = `/fob-get-approved-checker-list/${id}`

  return await Request.get({ url: endpoint });
}

export const GetServiceDetailByID = async (service = "", bus_stop_code = "", options) => {
  const endpoint = `/api-fob/fob-get-service-details/${service}/${bus_stop_code}`;
  
  return await Request.get({ url: endpoint }, options);
}

export const CreateOrUpdateBusService = async (id = "", service = "", depot = "", interchange ="", payload = {}) => {
  const endpoint = `/api-fob/fob-bus-stop/${id}/${service}/${depot}/${interchange}`;
  
  return await Request.post({ url: endpoint, payload });
}

export const GetBusServiceList = async (id = "", selected = true) => {
  const endpoint = `/api-fob/fob-bus-service-list/${id}/${selected ? "selected" : "unselected"}`;

  return await Request.get({ url: endpoint });
}

// /fob-checker-assigned
export const GetDetailCheckerAssigned = async ({ id, cognito_username }) => {
  const endpoint = `/api-fob/fob-checker-assigned/${id}/${cognito_username}`

  return await Request.get({ url: endpoint });
}

export const RetrieveBusStop = async ({ id, svc, bus_stop, amPm }) => {
  const endpoint = `/api-fob/fob-stop-detail-by-service/${id}/${svc}/${bus_stop}/${amPm === "AM" ? "true": "false"}`;

  return await Request.get({ url: endpoint })
}

export const CheckerAssigned = async ({ cognito_username, id, name, assigned }) => {
  const endpoint = `/api-fob//fob-checker-assigned/${id}/${cognito_username}`
  const payload = {
    cognito_username,
    id,
    name,
    assigned
  }

  return await Request.post({ url: endpoint, payload })
}

export const FOBPeriodApprove = async (id) => {
  const endpoint = `/api-fob/fob-period-approved/${id}`;
  const payload = {
    id,
    is_approved: true
  }

  return await Request.post({ url: endpoint, payload })
}

export const FOBCheckerExport = async (id) => {
  const endpoint = `/api-fob/fob-get-checker-list-csv/${id}`;

  return await Request.get({ url: endpoint });
}

export const GetListChecker = async (id) => {
  const endpoint = `/api-fob/fob-get-checker-by-status/${id}`;
  
  return await Request.get({ url: endpoint });
}

export const AssignChecker = async (payload) => {
  const endpoint = `/api-fob/fob-admin-assign-checker/${payload?.id}`;

  return await Request.post({ url: endpoint, payload });
}

// /fob-approved-assigned/{id}
export const GetFOBApprovedAssigned = async (id) => {
  const endpoint = `/api-fob/fob-approved-assigned/${id}`;

  return await Request.get({ url: endpoint });
}

export const ExportFOBCheckPeriodByID = async (id = '') => {
  const endpoint = `/api-fob/fob-export-bus-stop-list/${id}`;

  return await Request.get({ url: endpoint });
}

export const AssignMultipleChecker = async (id, sk, checkers = []) => {
  const endpoint = `/api-fob/fob-admin-assign-multiple-checker/${id}`;
  const payload = { id, sk, checkers };

  return await Request.post({ url: endpoint, payload });
}

export const DeleteItemBSQCPeriod = async ({ id, interchange, depot, service }) => {
  const endpoint = `/api-fob/fob-bus-stop/${id}/${service}/${depot}/${interchange}`;
  const payload = { id, depot, interchange, service };

  return await Request.delete({ url: endpoint, payload });
}

export const GetSummaryCount = async () => {
  const endpoint = "/api-fob/fob-summary-count";

  return await Request.get({ url: endpoint });
}

/**
 * Summary Of Checks
 */

export const GetFeedbackList = async (params = {}) => {
  const endpoint = "/api-fob/fob-feedback-list";

  return await Request.get({ url: endpoint, params });
}

export const DeleteFeedbackSumaryItem = async (pk = "", sk = "") => {
  const endpoint = `/api-fob/fob-feedback-detail/${pk}/${sk}`;

  return await Request.delete({ url: endpoint, payload: { pk, sk } });
}

export const GetFeedbackDetail = async ({id = "", sk = ""}) => {
  const endpoint = `/api-fob/fob-feedback-detail/${id}/${sk}`;

  return await Request.get({ url: endpoint });
}

export const GetFeedbackRoute = async (pk = "", sk = "") => {
  const endpoint = `/api-fob/fob-route/${pk}/${sk}`;

  return await Request.get({ url: endpoint });
}

export const GetRoadNameByAM = async({pk = ""}) =>{
  const endpoint = `/api-fob/fob-particular-assignment-busstop/?pk=${pk}`;
  return await Request.get({url:endpoint});
}