import DatePicker from "react-datepicker";
import Modal from "../../components/modal";
import SelectOption from "../../../../components/select-options";
import { CustomDatePicker } from "../../../../../../../components/custom/datepicker";
import { PublishCheckPeriod } from "../../../../../../../services/feedbackOnBus";
import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  checkPermission,
  getUserPermission,
} from "../../../../../../../config/permission_helper";

const Component = (props) => {
  const [isFetching, setIsFetching] = useState(false);
  const { selectedCheckPeriod } = useSelector((state) => state.feedbackOnBus);
  const [form, setForm] = useState({});
  const [selected, setSelected] = useState(props?.selectedPeriod);

  useEffect(() => {
    if (props?.selectedPeriod) {
      setSelected(props?.selectedPeriod);
    }
  }, [props?.selectedPeriod]);
  const onChange = (e) => {
    const name = e?.target?.name;
    const value = e?.target?.value;

    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const onSubmit = async () => {
    setIsFetching(true);
    const payload = {
      ...form,
      id: selectedCheckPeriod,
      is_published: true,
    };

    const [err, _] = await PublishCheckPeriod(selectedCheckPeriod, payload);

    if (err) {
      props?.onError(err?.message);

      setIsFetching(false);
      return;
    }

    setIsFetching(false);
    props?.onSuccess();
    props?.onSubmit(form);
  };

  const onChangeDate = (date, type) => {
    setForm({
      ...form,
      [type]: date,
    });
  };

  const selectedPeriod = useMemo(
    () => props?.listCheckPeriod?.filter(({ id }) => selected?.id === id) ?? [],
    [selected?.id, props?.listCheckPeriod]
  );

  return (
    <Modal
      isOpen={props?.isOpen}
      onClose={props?.onClose}
      width={500}
      height={600}
      actionComponent={
        <>
          {checkPermission(
            getUserPermission(),
            "fob.bsqc_publish_check_period"
          ) && (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "auto auto",
                columnGap: 20,
              }}
            >
              <button onClick={props?.onClose} className="button-cancel">
                Cancel
              </button>

              <button onClick={onSubmit} className="button-submit">
                {isFetching ? "Loading ..." : "Publish"}
              </button>
            </div>
          )}
        </>
      }
    >
      <>
        <h3>Publish BSQC Period</h3>

        <div className="input-group">
          <div className="input-date-label">
            <span>
              <span style={{ fontWeight: "bold" }}>BSQC Check Period</span>
              <span style={{ color: "red" }}>*</span>
            </span>
          </div>
          <SelectOption
            disabled
            className="check-period-options"
            title={selectedPeriod?.[0]?.label ?? "BSQC Check Period"}
            actionName="Delete"
            inputStyle={{ padding: "8px 32px 8px 16px" }}
            select={selectedPeriod?.[0]}
            options={props?.listCheckPeriod}
            onSelect={(data) => setSelected(data)}
          />
        </div>

        <div className="input-group" style={{ marginTop: 20 }}>
          <div className="input-date-label">
            <span>
              <span style={{ fontWeight: "bold" }}>
                Check Preference Selection Period
              </span>
              <span style={{ color: "red" }}>*</span>
            </span>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto auto",
              columnGap: 10,
            }}
          >
            <div className="datepicker-wrapper">
              <DatePicker
                className="filter-date-range"
                format={"dd MMM yyyy"}
                selected={form["start_date"]}
                onChange={(date) => onChangeDate(date, "start_date")}
                customInput={<CustomDatePicker iconColor={"#781D7E"} />}
              />
            </div>

            <div className="datepicker-wrapper">
              <DatePicker
                className="filter-date-range"
                format={"dd MMM yyyy"}
                selected={form["end_date"]}
                onChange={(date) => onChangeDate(date, "end_date")}
                customInput={<CustomDatePicker iconColor={"#781D7E"} />}
              />
            </div>
          </div>
        </div>

        <div className="input-group" style={{ marginTop: 20 }}>
          <div className="input-date-label">
            <span>
              <span style={{ fontWeight: "bold" }}>
                Maximum allocation per Service
              </span>
              <span style={{ color: "red" }}>*</span>
            </span>
          </div>
          <input
            name="max_allocation"
            type="number"
            value={form["max_allocation"]}
            onChange={onChange}
            className="textfield"
          />
        </div>
      </>
    </Modal>
  );
};

export default Component;
