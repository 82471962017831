import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router";
import { TableHeader } from "../../../components/table/Tableheader";
import { TableBody } from "../../../components/table/TableBody";
import "../communication.css";

export const Newsfeed = () => {
  const navigate = useNavigate();
  return (
    <div className="body">
      <TableHeader 
        title = "Newsfeed"
      />
      <TableBody data={[]}/>
    </div>
  );
};