import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router";
import "../../communication.css";
import { Upload } from "@progress/kendo-react-upload";
import XLSX from "xlsx";
import config from "../../../../config/config";
import api from "../../../../config/api";
import Template from "../../FilesToDownload/Upload Recepient.xlsx";
import TemplateWithAttachment from "../../FilesToDownload/Upload Recepient With Attachments.xlsx";

import { getCurrentLoggedInSessionToken } from "../../../../config/userPool";

const { UPLOAD_RECIPIENTS_ENDPOINT } = config.api_endpoint;

export const UploadExcels = (props) => {
  const navigate = useNavigate();
  const onhandleAdd = async (e) => {
    let file = e.newState[0].getRawFile();
    const formData = new FormData();
    formData.append("file", file);
    formData.append("filename", file.name);
    const token = getCurrentLoggedInSessionToken();
    props.setLoading(true);
    await api
      .postForm(UPLOAD_RECIPIENTS_ENDPOINT, formData, token)
      .then(async (res) => {
        let result = await res.data;
        result = result.filter((v) => (v.SAP !== null) & (v.Name !== null));
        // console.log(result.filter((v)=> v.SAP && v.Name));
        props.onChangeFile(result);
        // props.setLoading(false);
        setTimeout(() => {
          props.setLoading(false);
        }, 2000);
      });
  };

  return (
    <>
      <div className="select-user-group">
        <div className="tab-name" style={{ width: "790px" }}>
          Upload Excel{" "}
          <a href={Template} download style={{ color: "black" }}>
            (Download Template)
          </a>&nbsp;&nbsp;
          <a href={TemplateWithAttachment} download style={{ color: "black" }}>
            (Download Template for Targeted Comm)
          </a>
        </div>
        <div className="user-group-box">
          <div className="search-users-box">
            <label className="user-group-label">File</label>
            <Upload
              className="upload-excel-file"
              autoUpload={false}
              batch={false}
              multiple={false}
              defaultFiles={[]}
              withCredentials={false}
              showActionButtons={false}
              restrictions={{
                allowedExtensions: [".xlsx", ".csv"],
                maxFileSize: 4000000,
              }}
              onAdd={(e) => onhandleAdd(e)}
              onRemove={(e) => props.onChangeFile([])}
            />
          </div>
        </div>
      </div>
    </>
  );
};
