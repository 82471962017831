import React, { useState, useEffect, useRef } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import "../../admin_tools.css";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import BackIcon from "../../../../assets/communication/Back.svg";
import { ADMIN_TOOLS_CARES_ACCESSORY } from "../../../../routes";
import EditIcon from "../../../../assets/common/edit_icon.svg";
import ArrorRightIcon from "../../../../assets/common/arrao-right.svg";
import { Loader } from "@progress/kendo-react-indicators";
import { EcardAccessoryForm } from "./form";
import { updateCategory } from "../../../../store/category";
import { fetchAccessoryStickersById,saveEcardAccessoryStickers } from "../../../../store/ecards";

export const EcardAccessoryDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const id = query.get("id");
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [errorTxt, setErrorTxt] = useState("");
  const {
    sticker_detail,
    sticker_detail_error,
    sticker_detail_loading,
  } = useSelector((state) => state.ecard);

  useEffect(() => {
    if (id) {
      dispatch(fetchAccessoryStickersById(id));
    }
  }, []);
  const handleSaveAccessory = ({name,url}) => {
    let payload = {
      id,
      name,
      url
    }
    console.log(payload);
    dispatch(saveEcardAccessoryStickers(payload)).then((response) => {
      console.log("handleSaveAccessory response:", response);
      if (response.error && response.error.message) {
        setErrorTxt(response.payload || response.error.message);
        setShowErrorDialog(true);
      } else {
        setIsEdit(false);
        setLoading(false);
        dispatch(fetchAccessoryStickersById(id));
      }
    });
  };
  return (
    <>
      {showErrorDialog && (
        <Dialog
          className="category-details-alert-form"
          title={"Alert"}
          onClose={() => setShowErrorDialog(false)}
        >
          <p
            style={{
              margin: "25px",
              textAlign: "center",
            }}
          >
            {errorTxt}
          </p>
        </Dialog>
      )}
      <div className="detail-body">
        {sticker_detail_loading || loading ? (
          <Loader
            type="infinite-spinner"
            style={{
              display: "flex",
              alignItems: "center",
              width: "40px",
              margin: "auto",
              justifyContent: "center",
              height: "100vh",
            }}
          />
        ) : (
          <>
            <div className="detail-header">
              <div
                className="categoryback-group-div"
                onClick={() => navigate(ADMIN_TOOLS_CARES_ACCESSORY)}
              >
                <img src={BackIcon} className="categoryback-icon" />
                <span className="categoryback-label">Back</span>
              </div>
              {!isEdit && (
                <div>
                  <Button className="editBtn" onClick={() => setIsEdit(true)}>
                    <img src={EditIcon} style={{ verticalAlign: "middle" }} />
                    <span className="editBtn-label">Edit</span>
                    <img
                      src={ArrorRightIcon}
                      style={{ verticalAlign: "middle" }}
                    />
                  </Button>
                </div>
              )}
            </div>
            <div className="detail-content">
              <EcardAccessoryForm
                data={sticker_detail}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                setLoading={setLoading}
                submitForm={handleSaveAccessory}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
