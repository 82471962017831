import React, { useState, useEffect } from "react";
// redux
import { useDispatch, useSelector } from "react-redux";
import { fetchAllUserList } from "../../store/groupMgmt";
// kendo
import {
  Checkbox,
  InputSeparator,
  InputSuffix,
  TextBox,
} from "@progress/kendo-react-inputs";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Loader } from "@progress/kendo-react-indicators";
import { Icon } from "@progress/kendo-react-common";
// image
import SearchIcon from "../../assets/communication/search_icon.svg";
// css
import "./dialog.css";

export const UserListUserMgmtDialog = (props) => {
  const dispatch = useDispatch();
  const [selectedUsers, setSelectedUsers] = useState(props.defaultSelected);
  const { group_users, group_users_loading } = useSelector(
    (state) => state?.groupMgmt
  );
  const [searchValue, setSearchValue] = useState("");
  const [filteredGroupUsers, setFilteredGroupUsers] = useState([]);

  let delay;

  const filterGroupUsers = () => {
    console.log("filterGroupUsers: ", searchValue);
    console.log();
    if (searchValue === "") {
      setFilteredGroupUsers(group_users);
    } else {
      const groups = group_users.filter(
        (x) =>
          (x.username !== null &&
            x.username.includes(searchValue.toLowerCase())) ||
          (x.user_name !== null &&
            x.user_name.toLowerCase().includes(searchValue.toLowerCase()))
      );
      console.log("filterGroupUsers groups:", groups);
      setFilteredGroupUsers(groups);
    }
  };

  useEffect(() => {
    delay = setTimeout(() => {
      if (
        (searchValue.length >= 3 || searchValue.length === 0) &&
        group_users.length > 0
      )
        filterGroupUsers();
    }, 1000);

    return () => clearTimeout(delay);
  }, [searchValue]);

  const handleSearchTextChanged = React.useCallback((event) => {
    setSearchValue(event.target.value);
    console.log("handleChange: ", event.target.value);
  }, []);

  useEffect(() => {
    if (group_users.length > 0) {
      setFilteredGroupUsers(group_users);
    }
  }, [group_users]);

  useEffect(() => {
    dispatch(fetchAllUserList());
  }, []);

  const handleSelected = (e, val) => {
    let updatedList = [...selectedUsers];
    if (e.target.value) {
      updatedList = [...selectedUsers, val];
    } else {
      const selected_index = selectedUsers
        .map((v) => v.username)
        .indexOf(val.username);
      updatedList.splice(selected_index, 1);
    }
    setSelectedUsers(updatedList);
  };
  const handleSubmitUsers = () => {
    props.callback(selectedUsers);
  };
  const isChecked = (item) =>
    selectedUsers.map((v) => v.username).includes(item) ? true : false;
  return (
    <Dialog
      title={`Please select user(s): ${selectedUsers.length} selected`}
      width={500}
      height={500}
      onClose={props.onClickNo}
      className="group-user-dialog"
    >
      <div className="group-user-list-div">
        {group_users_loading ? (
          <Loader
            type="infinite-spinner"
            style={{
              display: "flex",
              alignItems: "center",
              width: "40px",
              margin: "auto",
              justifyContent: "center",
              height: "350px",
            }}
          />
        ) : (
          <>
            <TextBox
              className="group-user-search-box-txtbox"
              value={searchValue}
              onChange={handleSearchTextChanged}
              placeholder="Search by Username / Name (min 3 characters)"
              suffix={() => (
                <>
                  <InputSuffix>
                    <Icon>
                      <img src={SearchIcon} />
                      &nbsp;
                    </Icon>
                  </InputSuffix>
                  <InputSeparator />
                </>
              )}
            />
            {filteredGroupUsers.map((v) => (
              <Checkbox
                key={v.username}
                label={v.username + " - " + v.user_name}
                onChange={(e) => handleSelected(e, v)}
                checked={isChecked(v.username)}
              />
            ))}
          </>
        )}
      </div>
      <DialogActionsBar>
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base group-user-cancel-button"
          onClick={props.onClickNo}
        >
          No
        </button>
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base group-user-submit-button"
          onClick={handleSubmitUsers}
        >
          Yes
        </button>
      </DialogActionsBar>
    </Dialog>
  );
};
