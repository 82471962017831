import Modal from "../../../bus-checker-periods/partials/list-of-check-period/components/modal";
import SelectOption from "../../../bus-checker-periods/components/select-options";
import IcDelete from "../../../../../assets/bsqc/ic-delete.svg";
import ModalStatus from "../../../../../components/modal-status";
import config from "../../../../../config/config";
import { useCheckerPref } from "./actions";

import {
  checkPermission,
  getUserPermission,
} from "../../../../../config/permission_helper";
import { useEffect, useState } from "react";

const SELECTED_TIME = [
  { label: "AM", id: "am", isSelected: true, data: { label: "AM", id: "am" } },
  { label: "PM", id: "pm", isSelected: true, data: { label: "PM", id: "pm" } },
];

const Component = (props) => {
  const {
    isAbleToSubmit,
    isFetching,
    success,
    message,
    recipient,
    onChange,
    onSubmit,
    onCloseMessageModal,
    onCloseSuccessModal,
    onAddNewRow,
    onDeleteRow,
    hasErrors,
    onSelectTime,
    onFetchDetail,
    onCleanUpForm,
    busStopList,
    onSelectSVC,
    onSelectBusStop
  } = useCheckerPref({
    isOpen: props?.isOpen,
    data: props?.data,
    onClose: props?.onToggle,
    onSubmit: props?.onSubmit,
  });
  const [amsvcList,setAmSVCList] = useState([]);
  const [pmsvcList,setPmSVCList] = useState([]);
  const [busList,setBusList] = useState([]);

  useEffect(()=>{
    if(busStopList){      
      setAmSVCList(busStopList.filter((x)=>x.is_am === true).map((v)=>{
        return {id: v.svc, label: v.svc,isam: false, isSelected: true, data:{label: v.svc, id: v.svc}}
      }));
      setPmSVCList(busStopList.filter((x)=>x.is_am === false).map((v)=>{
        return {id: v.svc, label: v.svc,isam: false, isSelected: true, data:{label: v.svc, id: v.svc}}
      }));
      setBusList(busStopList.map((v)=>{
        return {id: v.bus_stop_code, label: v.bus_stop_code,svc: v.svc,am: v.is_am? "am": "pm", isSelected: true, data:{label: v.bus_stop_code,id: v.bus_stop_code}}
      }));
    }
  },[busStopList])
  return (
    <>
      <Modal
        width={1200}
        height={400}
        isOpen={props?.isOpen}
        onClose={() => {
          onCleanUpForm();
          props?.onToggle();
        }}
        actionComponent={
          <>
            {checkPermission(
              getUserPermission(),
              "fob.bsqc_can_assign_checker_preference"
            ) && (
              <div
                style={{
                  display: "flex",
                  columnGap: 10,
                  position: "absolute",
                  right: 0,
                }}
              >
                <button
                  disabled={isFetching}
                  onClick={onSubmit}
                  className="button-submit"
                >
                  {isFetching ? "Loading ..." : "SUBMIT"}
                </button>

                <button onClick={props?.onToggle} className="button-cancel">
                  CANCEL
                </button>
              </div>
            )}
          </>
        }
      >
        <div style={{ padding: "20px", height: "100%" }}>
          <h3>Update Checker Detail</h3>
          <table>
            <thead>
              <tr>
                <th style={{ padding: 16 }}>AM/PM</th>
                <th style={{ padding: 16 }}>Service No.</th>
                <th style={{ padding: 16 }}>Bus Stop</th>
                <th style={{ padding: 16 }}>Bus Stop Description depot</th>
                <th style={{ padding: 16 }}>Road name</th>
                <th style={{ padding: 16 }}>Time</th>
                <th style={{ padding: 16 }}>Direction</th>
                <th style={{ padding: 16 }}></th>
              </tr>
            </thead>
            <tbody>
              {recipient?.map((fields, idx) => (
                <tr key={idx} style={{ border: "none" }}>
                  <td>
                    <SelectOption
                      className="check-period-options"
                      title={fields?.time?.label}
                      select={fields?.time}
                      options={SELECTED_TIME}
                      onSelect={(data) => {
                        onSelectTime(idx, data);
                      }}
                      inputStyle={{
                        minHeight: 20,
                        margin: "unset",
                        minWidth: 50,
                      }}
                    />
                    
                     {/* <div
                      onClick={() => onFetchDetail(idx)}
                      style={{
                        fontSize: 10,
                        fontWeight: "bold",
                        cursor: "pointer",
                        color: "rgb(120, 9, 130)",
                        textAlign: "center",
                        marginTop: 6,
                      }}
                    >
                      fetch detail
                    </div> */}
                  </td>                  
                  <td>
                  <SelectOption
                      className="check-period-options"
                      title={fields?.service_no}
                      select={fields?.service_no}
                      options={fields?.am === "am"? amsvcList: pmsvcList}
                      onSelect={(data) => {
                        console.log(data);
                        onSelectSVC(idx, data);
                      }}
                      inputStyle={{
                        minHeight: 20,
                        margin: "unset",
                        minWidth: 50,
                      }}
                    />                    
                    {/* <input
                      name={`service_no:${idx}`}
                      onChange={onChange}
                      value={fields?.service_no}
                      class="search-options"
                      type="text"
                      placeholder="Please input"
                      style={{
                        border: "1px solid #E7ECEF",
                        borderRadius: 6,
                        padding: 12,
                      }}
                    /> */}
                  </td>
                  <td>
                    {/* <input
                      name={`bus_stop:${idx}`}
                      onChange={onChange}
                      value={fields?.bus_stop}
                      class="search-options"
                      type="text"
                      placeholder="Please input"
                      style={{
                        border: "1px solid #E7ECEF",
                        borderRadius: 6,
                        padding: 12,
                        maxWidth: 60,
                      }}
                    /> */}
                    <select
                      className="check-period-options" 
                      title={fields?.bus_stop}
                      onChange={(e)=>{
                       onSelectBusStop(idx,e.target.value);
                      }}
                      value={fields?.bus_stop}
                      style={{
                        minWidth:100,
                        minHeight:20,
                        border: "1px solid #E7ECEF",
                        padding: 10,
                        borderRadius:5,
                        textAlign: "center"
                      }}
                    >
                      {fields?.service_no &&(
                        <>
                          <option></option>
                          {busList.filter((x)=> x.svc === fields.service_no & x.am === fields.am).map((v)=>(
                            <option value={v.id}>{v.label}</option>
                          ))}
                          {/* <option>1</option>                         */}
                        </>
                      )}
                    </select>
                    {/* <SelectOption
                      className="check-period-options"
                      title={fields?.bus_stop}
                      select={fields?.bus_stop}
                      options={fields?.service_no? busList: []}
                      onSelect={(data) => {
                        console.log(data);
                        onSelectBusStop(idx, data);
                      }}
                      inputStyle={{
                        minHeight: 20,
                        margin: "unset",
                        minWidth: 100,
                      }}
                    />                     */}
                    {/* <div style={{ height: 18 }}></div> */}
                  </td>
                  <td>
                    <input
                      disabled
                      name="bus_stop_desc"
                      value={fields.bus_stop_desc}
                      defaultValue={fields.bus_stop_desc}
                      onChange={(e) => onChange(e, 0)}
                      class="search-options"
                      type="text"
                      placeholder="Please input"
                      style={{
                        border: `1px solid ${
                          hasErrors(idx) ? "red" : "#E7ECEF"
                        }`,
                        borderRadius: 6,
                        padding: 12,
                      }}
                    />

                    {/* <div style={{ height: 18 }}></div> */}
                  </td>
                  <td>
                    <input
                      disabled
                      value={fields.road_name}
                      name="road_name"
                      onChange={(e) => onChange(e, 0)}
                      class="search-options"
                      type="text"
                      placeholder="Please input"
                      style={{
                        border: `1px solid ${
                          hasErrors(idx) ? "red" : "#E7ECEF"
                        }`,
                        borderRadius: 6,
                        padding: 12,
                      }}
                    />

                    {/* <div style={{ height: 18 }}></div> */}
                  </td>
                  <td>
                    <input
                      disabled
                      name="time"
                      value={fields.time_am_pm}
                      onChange={(e) => onChange(e, 0)}
                      class="search-options"
                      type="text"
                      placeholder="Please input"
                      style={{
                        border: `1px solid ${
                          hasErrors(idx) ? "red" : "#E7ECEF"
                        }`,
                        borderRadius: 6,
                        padding: 12,
                      }}
                    />

                    {/* <div style={{ height: 18 }}></div> */}
                  </td>
                  <td>
                    <input
                      disabled
                      name="direction"
                      value={fields.direction}
                      onChange={(e) => onChange(e, 0)}
                      class="search-options"
                      type="text"
                      placeholder="Please input"
                      style={{
                        border: `1px solid ${
                          hasErrors(idx) ? "red" : "#E7ECEF"
                        }`,
                        borderRadius: 6,
                        padding: 12,
                      }}
                    />

                    {/* <div style={{ height: 18 }}></div> */}
                  </td>
                  <td>
                    <img
                      onClick={() => onDeleteRow(idx)}
                      src={IcDelete}
                      width={20}
                      height={20}
                      alt="ic-delete"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button
            disabled={isAbleToSubmit()}
            onClick={onAddNewRow}
            style={{
              border: 0,
              backgroundColor: "white",
              marginTop: 10,
              cursor: "pointer",
              fontSize: 14,
            }}
          >
            <span>+ </span>
            <span style={{ color: "#780982ff", fontWeight: "bold" }}>Add</span>
          </button>
        </div>
      </Modal>

      <ModalStatus
        isOpen={!!message}
        message={message}
        type={"errors"}
        onClose={onCloseMessageModal}
      />
      <ModalStatus
        isOpen={!!success}
        message={success}
        type={"success"}
        onClose={onCloseSuccessModal}
      />
    </>
  );
};

export default Component;
