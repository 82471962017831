import { Dialog } from "@progress/kendo-react-dialogs";
import Button from "../../../components/button";
import { X } from "@phosphor-icons/react";

import "../mic/mic.css";
import { useState } from "react";
import { UpdateVisibility } from "../../../services/ideas";

const Component = (props) => {
  const [isVisible, setIsVisible] = useState("");
  const [comment, setComment] = useState("");
  const [isFetch, setIsFetch] = useState(false);

  const onChangeVisibility = (e) => {
    const value = e?.target?.value;
    setIsVisible(
      value !== "default" ? (value === "unpublished" ? false : true) : ""
    );
  };

  const onChangeText = (e) => setComment(e?.target?.value ?? "");

  const onSubmit = async () => {
    console.log("data: ", comment, isVisible);
    if (isFetch) return;

    if (!comment || isVisible === "") {
      alert("Visibility & Comment are required");
      return;
    }

    try {
      setIsFetch(true);
      const payload = {
        id: props?.micId,
        sk: props?.sk,
        is_visible: isVisible,
        comment,
      };

      const [err, response] = await UpdateVisibility(payload);

      setIsFetch(false);
      if (err) return props?.onFailed(err);
      props?.onSuccess({ ...response, visibility: isVisible });
    } catch (err) {
      setIsFetch(false);
      props?.onFailed(err);
    }
  };

  const listOfDropdown = props?.list ?? [];

  return (
    <Dialog onClose={props?.onToggle}>
      <div className="mic-popup__wrapper">
        <div className="button-close-popup" onClick={props?.onToggle}>
          <X size={16} />
        </div>
        <div>
          <h3>{props?.title}</h3>

          <div className="dropdown">
            <div className="dropdown-label">
              <strong>Set Visibility</strong>
            </div>
            <select
              onChange={onChangeVisibility}
              style={{ margin: "0px 14px", width: "calc(100% - 30px)" }}
              className="dropdown-select"
              name={props?.section}
              id="lang"
            >
              <option key={"default"} value={"default"}>
                Select
              </option>
              {listOfDropdown.map(({ id, label }) => (
                <option key={id} value={id}>
                  {label}
                </option>
              ))}
            </select>

            <div className="dropdown-label" style={{ marginTop: 20 }}>
              <strong>Comment *</strong>
            </div>
            <textarea
              onChange={onChangeText}
              className="input-area"
              placeholder={"Add comment"}
            />

            <div className="footer-section">
              <Button onClick={onSubmit}>
                {isFetch ? "Loading ..." : "Submit"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default Component;
