import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
// redux
import { useDispatch, useSelector } from "react-redux";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import "./dialog.css";
import { fetchAcknowledgeFOBSummaryChecksList } from "../../store/feedbackonbusSummaryChecks";
import CloseSvg from "../../assets/bsqc/close.svg";

export const FobReportActionTakenDialog = (props) => {
  const dispatch = useDispatch();
  const {feedback_acknowledges,feedback_acknowledges_loading} = useSelector((state)=> state.SummaryCheckfeedbackOnBus);

  useEffect(() => {
    let {id,sk} = props.data;
    console.log(props.data);
    console.log(id);
    if (id && sk){
      let data = {id,sk};
      dispatch(fetchAcknowledgeFOBSummaryChecksList(data));
    }
  }, []);
  console.log(feedback_acknowledges);
  return (
    <>
      <Dialog
          width={500}
          onClose={props.onCloseDialog}
      >
        <div className="fob-report-action-taken-div">
          <div className="fob-report-action-taken-close-button">
            <img src={CloseSvg} onClick={props.onCloseDialog}/>
          </div>
          <div className="fob-report-action-taken-title">Action Taken ({props.data.reference_no})</div>
          <div className="fob-report-action-taken-content">
            {feedback_acknowledges.map((v)=>(
              <div className="fob-report-action-taken-item">
              <div className="fob-report-action-taken-desc">
                {v.remark}
              </div>
              <div className="fob-report-action-taken-rolenamedate">
                {v.created_by.designation? v.created_by.designation: "-"} - {v.created_by.name  }; {v.formatted_created_on}
              </div>
            </div>                     
            ))}
          </div>
        </div>
      </Dialog>      
    </>
  );
};
