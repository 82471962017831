// redux

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionTokenWithPromise } from "../config/userPool";

const { 
    FEEDBACK_ON_BUS_REPORT_LIST,
    FEEDBACK_ON_BUS_EXPORT_REPORT
  } = config.api_endpoint;

const initialState = {
    loading: false,
    data: [],
    error: null,
    export_loading: false,
    export_data: [],
    export_error: null,
    update_loading: false,
    update_data: [],
    update_error: null
};

export const fetchFOBReportList = createAsyncThunk(
    "feedbackonbus_report/fetchFOBReportList",
    async (query, { rejectWithValue }) => {
      try {
        const token = await getCurrentLoggedInSessionTokenWithPromise();
        const results = await api.get(
            FEEDBACK_ON_BUS_REPORT_LIST+"?"+query.toString(),
          {},
          token
        );
        console.log("fetchFOBReportList Results:", results);
  
        return results.data;
      } catch (error) {
        return rejectWithValue(error.details);
      }
    }
  );

export const exportFobReport = createAsyncThunk(
  "feedbackonbus_report/exportFobReport",
  async (query, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(
        FEEDBACK_ON_BUS_EXPORT_REPORT+"?"+query.toString(),
        {},
        token
      );
      console.log("exportFobReport Results:", results);

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const updateFOBReport = createAsyncThunk(
  "feedbackonbus_report/updateFOBReport",
  async (data, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.post(
        FEEDBACK_ON_BUS_REPORT_LIST,
        JSON.stringify(data),
        token
      );
      console.log("updateFOBReport Results:", results);

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

const FeedbackOnBusReportSlice = createSlice({
    name: "FeedbackOnBusReport",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchFOBReportList.pending, (state) => {
          state.loading = true;
        })
        .addCase(fetchFOBReportList.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
        })
        .addCase(fetchFOBReportList.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        })
        .addCase(exportFobReport.pending, (state) => {
          state.export_loading = true;
        })
        .addCase(exportFobReport.fulfilled, (state, action) => {
          state.export_loading = false;
          state.export_data = action.payload;
        })
        .addCase(exportFobReport.rejected, (state, action) => {
          state.export_loading = false;
          state.export_error = action.error.message;
        })
        .addCase(updateFOBReport.pending, (state) => {
          state.update_loading = true;
        })
        .addCase(updateFOBReport.fulfilled, (state, action) => {
          state.update_loading = false;
          state.update_data = action.payload;
        })
        .addCase(updateFOBReport.rejected, (state, action) => {
          state.update_loading = false;
          state.update_error = action.error.message;
        })        
        ;
    },
  });
  
  const FeedbackOnReportSliceReducer = FeedbackOnBusReportSlice.reducer;
  export default FeedbackOnReportSliceReducer;
  