import React from "react";

export const PermissionDeninedPage = (props) => {
  return (
    <div
      className="Permission_Denined_box"
      style={{ height: props.height ? props.height : "calc(100% - 76px)" }}
    >
      <div>{props.title ? props.title : ""}</div>
    </div>
  );
};
