import moment from "moment";
import { useEffect, useMemo, useState } from "react";

import { useMICContext } from "../../action";

const useAction = props => {
  const { actions, filter } = useMICContext()
  const [createdOn, setCreatedOn] = useState('');
  const [categories, setCategories] = useState([]);
  const [status, setStatus] = useState('');
  const [date, setDate] = useState({
    startFrom: '',
    startTo: ''
  })

  useEffect(() => {
    if (filter?.group?.length) {
      setCategories(filter?.group)
    }
  }, [filter?.group])

  const onCheckBox = (value, checked, target) => {
    const onStateChange = target === 'category' ? setCategories : setStatus;

    onStateChange((prev) => {
      if (checked) return [...prev, value]
      else return prev.filter(itm => itm !== value);
    })
  };

  const onSelectCategory = (e) => {
    const idx = e?.target?.name;
    const checked = e?.target?.checked;

    onCheckBox(idx, checked, 'category');
  }

  const onSelectStatus = (e) => {
    const idx = e?.target?.name;
    const checked = e?.target?.checked;

    onCheckBox(idx, checked, 'status');
  }

  const onChangeDate = (e) => {
    const value = e?.target?.value ?? "";
    const name = e?.target?.name ?? ""
    const newDate = moment(new Date(value)).format('YYYY-MM-DD');
    
    setDate(prev => ({ ...prev, [name]: newDate }))
  }

  const onChangeCreatedOn = e => setCreatedOn(e?.target?.name ?? "");

  const onSubmit = async () => {
    actions?.onSetLoading(true);
    const statusFilter = status?.length ? status?.join(",") : ""
    const payload = {
      ...(categories?.length ? { group: categories } : {}),
      ...(statusFilter ? { status: statusFilter } : {}),
      ...(date.startFrom && date.startTo ? { start_date: date?.startFrom, end_date: date?.startTo } : {})
    };

    if (!Object.keys(payload)?.length) {
      actions?.onClearData(null, '')
    }

    props?.onClose();

    // console.log(payload);
    actions?.onFilterQuery(payload);
    await actions?.onFilter(payload);
    actions?.onSetLoading(false);
  }
  
  return {
    createdOn,
    categories,
    status,
    date,
    onSubmit,
    onCheckBox,
    onChangeDate,
    onSelectCategory,
    onSelectStatus,
    onChangeCreatedOn
  }
}

export default  useAction;