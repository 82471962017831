import React, { useState, useEffect, useRef } from "react";
import SoundIcon from "../../../../assets/admintools/sound.svg";
import { TextBox, RadioButton, InputPrefix } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Icon } from "@progress/kendo-react-common";
import api from "../../../../config/api";
import config from "../../../../config/config";
import { UserListDialog } from "../../../../components/dialog/userListDialog";
import { ADMIN_TOOLS_MATTER_LIST } from "../../../../routes";
import { useNavigate } from "react-router";


export const MatterForm = ({
    data,
    isEdit,
    isCreate,
    setIsEdit,
    submitForm
}) => {
    const navigate = useNavigate();
    const [description, setName] = useState(null);
    const [no, setCode] = useState(null);
    const [users, setUsers] = useState([]);
    const [showUserList, setShowUserList] = useState(false);
    console.log('Matter Data--',data);
    useEffect(() => {
        if (data) {
            setName(data.description);
            setCode(data.no);
            setUsers(data.user_list)
        }
    }, [])

    const submitMatter = () => {
        let data = {
            description,
            no,
            user_list:users
        }
        submitForm(data)
    }
    const cancelForm = ()=>{
        if(data && isEdit){
            setIsEdit(false);
        }else{
            navigate(ADMIN_TOOLS_MATTER_LIST);
        }
    }
    const usersSelectedCallback = (data) => {
        console.log("Call back---",data);
        setUsers(data);
        setShowUserList(false);
    }
    console.log('Selected User--',users)
    return (
        <>
        {showUserList && (
                <UserListDialog
                    callback={usersSelectedCallback}
                    defaultSelected={users}
                    onClickNo={() => setShowUserList(false)}
                />
            )}
            <table>
                <tr>
                    <td>Matter</td>
                    {isEdit ? (
                        <td><TextBox value={ description } onChange={(e) => setName(e.target.value)} /></td>
                    ) : (
                        <td>{data.description}</td>
                    )
                    }
                </tr>
                <tr>
                    <td>Code</td>
                    {!isEdit ?(
                        <td>{data.no}</td>
                    ):(
                        <>
                        {isCreate ?(
                             <td><TextBox value={no} onChange={(e)=> setCode(e.target.value)}/></td>
                        ):(
                            <td>{data.no}</td>
                        )}
                        </>
                    )}
                </tr>
                <tr>
                    <td>User</td>
                    <td>
                    {isEdit ? (
                            <div className="edit-depot-layout">
                                <Button onClick={() => setShowUserList(true)}>Click here to update the user(s)</Button>
                                {users ? users.map((v) => (
                                    <div className="division-depot-item">{v.name}</div>
                                )) : null}
                            </div>
                        ) : (
                            <div className="edit-depot-layout">
                                {data.user_list ? data.user_list.map((v) => (
                                    <div className="division-depot-item">{v.name}</div>
                                )) : null}

                            </div>
                        )}
                    </td>
                </tr>
                {!isEdit && (
                    <>
                        <tr>
                            <td>Created By</td>
                            <td>{data.created_by ? data.created_by.name : "N/A"}</td>
                        </tr>
                        <tr>
                            <td>Created On</td>
                            <td>{data.created_on ? data.created_on : "N/A"}</td>
                        </tr>
                        <tr>
                            <td>Updated By</td>
                            <td>{data.updated_by ? data.updated_by.name : "N/A"}</td>
                        </tr>
                        <tr>
                            <td>Updated On</td>
                            <td>{data.updated_on ? data.updated_on : "N/A"}</td>
                        </tr>

                    </>
                )}
                {isEdit && (
                    <tr>
                        <td colSpan={2}>
                            <div className="detail-button-div">
                                <Button className="detail-button detail-cancel" onClick={cancelForm}>Cancel</Button>
                                <Button className="detail-button detail-submit" onClick={submitMatter}>Submit</Button>
                            </div>
                        </td>
                    </tr>
                )}
            </table>
        </>
    );
};