import { useNavigate } from "react-router-dom";
// kendo
import { DropDownList } from "@progress/kendo-react-dropdowns";
// icons
import ExportIcon from "../../../../assets/dr_safe/export.png";
import SearchIcon from "../../../../assets/dr_safe/search.png";
// components
import PageTitle from "../../../../components/pageTitle/page_title";
// routes
import { DR_SECURE_CONTACT_DETAILS } from "../../../../routes";

const ContactListHeader = () => {
  const navigate = useNavigate();

  const handleActionDropdownClicked = () => {
    navigate(DR_SECURE_CONTACT_DETAILS);
  };

  return (
    <div className="dr-secure-contact-list-header">
      <PageTitle title="DR Secure Contact List" />
      <div className="dr-secure-contact-list-header-right">
        <DropDownList
          value={"Action"}
          data={["Add New Contact"]}
          className="dr-secure-contact-list-header-action"
          onChange={handleActionDropdownClicked}
        />
        <div className="dr-secure-contact-list-header-icon">
          <img src={ExportIcon} />
        </div>
        <div className="dr-secure-contact-list-header-icon">
          <img src={SearchIcon} />
        </div>
      </div>
    </div>
  );
};

export default ContactListHeader;
