import { CSVLink } from "react-csv";
import { Button } from "@progress/kendo-react-buttons";
import RefreshIcon from "../../../assets/header/refresh.svg";
import ExportIcon from "../../../assets/header/export.svg";
import SelectOption from "../bus-checker-periods/components/select-options";

import "./select-services.css";
import { TABS, useExport, useSelectService } from "./actions";
import { useMemo, useState } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { TableBody } from "../../../components/table/TableBody";

import AssignedPeople from "./partials/assigned-people";
import {
  checkPermission,
  getUserPermission,
} from "../../../config/permission_helper";

const ACTIONS = {
  ASSIGN_PEOPLE: "Assign People",
  VIEW_DETAILS: "View Details",
};

const STATIC_HEADER = [
  {
    field: "svc",
    title: "Service No",
    style: {
      width: 100,
    },
  },
  {
    field: "AmPm",
    title: "AM/PM",
    style: {
      width: 100,
    },
  },
  {
    field: "bus_stop_name",
    title: "Boarding Bus Stop",
  },
  {
    field: "direction",
    title: "Direction",
    style: {
      width: 100,
    },
  },
];

const Page = () => {
  const [isOpenAssignPeople, setOpenAssignPeople] = useState(false);
  const [selectItem, setSelectItem] = useState("");

  const {
    id,
    tab,
    list,
    bsqcPeriodPublish,
    selectPeriodPublish,
    onSelectPeriod,
    setTab,
    refetch,
    isFetching,
    isAbleShowList,
  } = useSelectService();

  const HEADER_DATA = useMemo(
    () => {
      const header = [...STATIC_HEADER];

      if (tab === TABS.selected) {
        header.push({
          field: "checkers_count",
          title: "No. of checkers (auto)",
          style: {
            width: 200,
          },
        });
      }

      if (tab === TABS.unselected) {
        header.push({
          field: "Action",
          style: { width: 160 },
          cell: (props) => (
            <td>
              <DropDownList
                style={{
                  width: "120px",
                  height: "48px",
                  backgroundColor: "#EB6E1F",
                  color: "white",
                  textAlign: "center",
                  borderRadius: "8px",
                }}
                defaultValue={"Action"}
                data={
                  checkPermission(
                    getUserPermission(),
                    "fob.bsqc_select_services_assign_people"
                  )
                    ? [ACTIONS.ASSIGN_PEOPLE]
                    : []
                }
                // data={[]}
                onChange={(e) => {
                  const targetEvent = e?.target?.value ?? "";

                  if (targetEvent === ACTIONS.ASSIGN_PEOPLE) {
                    setSelectItem(props?.dataItem?.sk);
                    onToggleAssignPeople();
                  }
                }}
              />
            </td>
          ),
        });
      }

      return header;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tab]
  );

  const { ref, data, onExport, loading } = useExport(HEADER_DATA);

  const onToggleAssignPeople = () => {
    setOpenAssignPeople((prev) => !prev);
  };

  const onCloseAssignPeople = () => {
    onToggleAssignPeople();
    refetch();
  };

  const filenameExport = useMemo(() => {
    return (
      "export-select-service-" +
      (tab === TABS.selected ? "selected" : "unselected") +
      "-" +
      id
    );
  }, [id, tab]);

  return (
    <div className="approved-assignment">
      <div className="container">
        <div className="checker-preference-container">
          <div className="checker-preference-headers">
            <h2 style={{ fontSize: 32 }}>Select Service</h2>
            {isAbleShowList && (
              <div className="filter-group">
                <Button onClick={refetch} className="custom-button">
                  <img
                    style={{ width: 20, height: 20 }}
                    src={RefreshIcon}
                    alt="refresh-icon"
                  />
                </Button>
                <CSVLink
                  uFEFF={false}
                  asyncOnClick={true}
                  data={data}
                  filename={filenameExport}
                  ref={ref}
                  hidden
                >
                  Download
                </CSVLink>
                {checkPermission(
                  getUserPermission(),
                  "fob.bsqc_export_select_services"
                ) && (
                  <Button className="custom-button" onClick={onExport}>
                    {loading ? (
                      <>Loading ...</>
                    ) : (
                      <img
                        style={{ width: 20, height: 20 }}
                        src={ExportIcon}
                        alt="export-icon"
                      />
                    )}
                  </Button>
                )}
              </div>
            )}
          </div>

          {isFetching ? (
            <div>Loading ...</div>
          ) : (
            <>
              <div className="form-date-select">
                <SelectOption
                  className="check-period-options"
                  title={
                    selectPeriodPublish?.label ?? "Select BSQC Check Period"
                  }
                  select={selectPeriodPublish}
                  options={bsqcPeriodPublish}
                  onSelect={onSelectPeriod}
                />
              </div>
              {isAbleShowList && !!(bsqcPeriodPublish ?? []).length && (
                <>
                  <div className="tabs">
                    <div
                      onClick={() => setTab(TABS.selected)}
                      className={`tab ${
                        tab === TABS.selected ? "isactive" : ""
                      }`}
                    >
                      {TABS.selected}
                    </div>
                    <div className="divider"></div>
                    <div
                      onClick={() => setTab(TABS.unselected)}
                      className={`tab ${
                        tab === TABS.unselected ? "isactive" : ""
                      }`}
                    >
                      {TABS.unselected}
                    </div>
                  </div>

                  <TableBody unablePagination table={HEADER_DATA} data={list} />
                </>
              )}
            </>
          )}
          <AssignedPeople
            id={id}
            sk={selectItem}
            isOpen={isOpenAssignPeople}
            onToggle={onCloseAssignPeople}
          />
        </div>
      </div>
    </div>
  );
};

export default Page;
