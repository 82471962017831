import React, { useState, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
// redux
import { useDispatch, useSelector } from "react-redux";
import { fetchAllDepot } from "../../store/depot";
import { fetchAllDesignations } from "../../store/designation";
import { fetchAllInterchanges } from "../../store/interchange";
// kendo
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Checkbox, Input, RadioButton } from "@progress/kendo-react-inputs";
import { Loader } from "@progress/kendo-react-indicators";
import { MultiSelect } from "@progress/kendo-react-dropdowns";

import "./notification.css";

const status_options = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

export const FilterUser = (props) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  // redux
  const {
    depot_ids,
    loading: depot_loading,
    error: depot_error,
  } = useSelector((state) => state.depot);
  const {
    interchanges,
    loading: interchange_loading,
    error: interchange_error,
  } = useSelector((state) => state.interchange);
  const {
    designations,
    loading: designation_loading,
    error: designation_error,
  } = useSelector((state) => state.designation);

  // state
  const [loading, setLoading] = useState(true);
  const [allDepots, setAllDepots] = useState([]);
  const [allInterchanges, setAllInterchanges] = useState([]);
  const [allDesignations, setAllDesignations] = useState([]);

  const [selectedDepot, setSelectedDepot] = useState([]);
  const [selectedInterchange, setSelectedInterchange] = useState([]);
  const [selectedDesignation, setSelectedDesignation] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("na");

  // methods
  const handleClearFilter = () => {
    setSelectedDepot([]);
    setSelectedInterchange([]);
    setSelectedDesignation([]);
    setSelectedStatus("na");
  };

  const onSubmitFilter = () => {
    let data = {
      depot: selectedDepot,
      interchange: selectedInterchange,
      designation: selectedDesignation,
      status: selectedStatus,
    };
    console.log("onSubmitFilter:", data);
    props.onFiltercallback(data);
  };

  const handleDepotDropdownChanged = (e) => {
    setSelectedDepot(e.target.value.slice(0, 3));
  };

  const handleInterchangeDropdownChanged = (e) => {
    setSelectedInterchange(e.target.value.slice(0, 3));
  };

  const handleDesignationDropdownChanged = (e) => {
    setSelectedDesignation(e.target.value.slice(0, 3));
  };

  const handleStatusRadioBtnChanged = (e, value) => {
    setSelectedStatus(value);
  };

  const isStatusChecked = (item) => {
    return selectedStatus === item ? true : false;
  };

  // useEffect
  useEffect(() => {
    if (depot_ids && interchanges && designations) {
      let all_depots = ["All"];
      depot_ids.filter((x) => all_depots.push(x.name));
      setAllDepots(all_depots);

      let all_interchanges = ["All"];
      interchanges.filter((x) => all_interchanges.push(x.name));
      setAllInterchanges(all_interchanges);

      let all_designations = ["All"];
      designations.filter((x) => all_designations.push(x.name));
      setAllDesignations(all_designations);

      setLoading(false);
    }
  }, [depot_ids, interchanges, designations]);

  useEffect(() => {
    if (query.toString()) {
      if (query.get("depot")) {
        const depot = query.get("depot");
        console.log("filter depot:", depot);
        if (depot) {
          setSelectedDepot(depot.split(","));
        }
      }
      if (query.get("interchange")) {
        const interchange = query.get("interchange");
        console.log("filter interchange:", interchange);
        if (interchange) {
          setSelectedInterchange(interchange.split(","));
        }
      }
      if (query.get("designation")) {
        const designation = query.get("designation");
        console.log("filter designation:", designation);
        if (designation) {
          setSelectedDesignation(designation.split(","));
        }
      }
      if (query.get("status")) {
        const status = query.get("status");
        let val = "na";
        if (status === "true") {
          val = true;
        }
        if (status === "false") {
          val = false;
        }
        setSelectedStatus(val);
      }
    }
    dispatch(fetchAllDepot());
    dispatch(fetchAllDesignations());
    dispatch(fetchAllInterchanges("all"));
  }, []);

  return (
    <>
      <Dialog
        className="search-notification"
        title={" "}
        width={395}
        height={"100%"}
        onClose={() => props.setShowFilterDialog(false)}
      >
        {depot_loading ||
        interchange_loading ||
        designation_loading ||
        loading ? (
          <Loader
            type="infinite-spinner"
            style={{
              display: "flex",
              alignItems: "center",
              width: "40px",
              margin: "auto",
              justifyContent: "center",
              height: "100vh",
            }}
          />
        ) : (
          <div className="search-body">
            <div className="filter-title">
              <div className="title">Filter</div>
              <div className="clear-filter" onClick={handleClearFilter}>
                <u>Clear Filter</u>
              </div>
            </div>
            <div className="filter-body">
              <p className="filter-sub-title">Account Enabled</p>
              <div className="category-flex-row">
                {status_options.map((v) => (
                  <div className="date-option-div">
                    <RadioButton
                      style={{ border: "1px solid #CCC" }}
                      key={v.value}
                      value={v.value}
                      onChange={(e) => handleStatusRadioBtnChanged(e, v.value)}
                      checked={isStatusChecked(v.value)}
                    />
                    <label>{v.label}</label>
                  </div>
                ))}
              </div>
              <p className="filter-sub-title">Depot</p>
              <div>
                <div className="user-filter-select-multi">
                  <MultiSelect
                    className="user-filter-dropdown-multi"
                    style={{ width: "100%" }}
                    data={allDepots}
                    onChange={handleDepotDropdownChanged}
                    value={selectedDepot}
                    placeholder="Select (max 3 items)"
                    disabled={depot_loading}
                  />
                </div>
              </div>
              <p className="filter-sub-title">Interchange</p>
              <div>
                <div className="user-filter-select-multi">
                  <MultiSelect
                    className="user-filter-dropdown-multi"
                    style={{ width: "100%" }}
                    data={allInterchanges}
                    onChange={handleInterchangeDropdownChanged}
                    value={selectedInterchange}
                    placeholder="Select (max 3 items)"
                    disabled={interchange_loading}
                  />
                </div>
              </div>
              <p className="filter-sub-title">Designation</p>
              <div>
                <div className="user-filter-select-multi">
                  <MultiSelect
                    className="user-filter-dropdown-multi"
                    style={{ width: "100%" }}
                    data={allDesignations}
                    onChange={handleDesignationDropdownChanged}
                    value={selectedDesignation}
                    placeholder="Select (max 3 items)"
                    disabled={designation_loading}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <DialogActionsBar>
          <Button
            className="search-button cancel-btn"
            onClick={() => props.setShowFilterDialog(false)}
          >
            Cancel
          </Button>
          <Button
            className="search-button search-btn"
            onClick={() => onSubmitFilter()}
            disabled={
              interchange_loading || depot_loading || designation_loading
            }
          >
            Submit
          </Button>
        </DialogActionsBar>
      </Dialog>
    </>
  );
};
