import React, { useState,useEffect } from "react";
import { Navigate, useNavigate } from "react-router";
import { useDispatch,useSelector } from "react-redux";

// import DrTableHeader from "../../../components/drTable/dr_table_header";
import DrInfraSubSystemTable from "../../../components/drTable/dr_infra_subsystem_table";
import DrInfraSubSystemListHeader from "./list/dr_infra_subsystem_list_header";
import "../dr_infra.css";
import { fetchAllDrInfraSubSystem, syncDrInfraSubSystem } from "../../../store/drInfraSubSystem";
import { Loader } from "@progress/kendo-react-indicators";

import {
  getUserPermission,
  checkMultiPermission,
  DR_INFRA_PERMISSIONS_SYNC_SUBSYSTEM_SYSTEM
} from "../../../config/permission_helper";

export const DrInfraSubSystemList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDrSearchDialog,setShowDrSearchDialog] = useState(false);
  const [showSyncBtnLoading,setSyncBtnLoading] = useState(false);
  const [searchResult,setSearchResult] = useState([]);
  const [isSearch,setIsSearch] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { subsystem_data ,loading, error } = useSelector((state) => state.subsystem);

  useEffect(() => {
    dispatch(fetchAllDrInfraSubSystem({}));
  }, []);

  const shouldShowSyncBtn = () => {
    const userPermissions = getUserPermission();
    if (checkMultiPermission(userPermissions, DR_INFRA_PERMISSIONS_SYNC_SUBSYSTEM_SYSTEM)) {
      return true;
    } else {
      return false;
    }
  };

  const handleSearchAction= async (subsystemName, subSystemRailLineId) => {
    const res= await dispatch(fetchAllDrInfraSubSystem({ subsystemName, subSystemRailLineId }));
    if (res.payload.length > 0){
      setSearchResult(res.payload);
      setIsSearch("search");
      setErrorMessage("");
    }
    else{
      setSearchResult([]);
      setErrorMessage("");
    }   
    setShowDrSearchDialog(false);
  };

  const handleSyncAction = async() => {
    setSyncBtnLoading(true);
    const res= await dispatch(syncDrInfraSubSystem({}));
    console.log(res);
    setSyncBtnLoading(false);
    if (res.payload) {
      if (res.payload.status == "success") {
        alert("Sub System & System saved successfully.");
        handleSearchAction("", "")
      } else {
        alert("Error saving Sub System & System.");
      }
    } else {
      alert("Error saving Sub System & System.");
    }
  }

  console.log(subsystem_data);
  return (
    <div className="dr-infra-list-wrapper">
      <DrInfraSubSystemListHeader 
        title={"Sub System"} 
        showDrSearchDialog={showDrSearchDialog}
        onSearchBtnClicked={() => setShowDrSearchDialog(true)}
        onSearchDialogClose={() => setShowDrSearchDialog(false)}
        onSearchAction = {(subSystemName, subSystemRailLineId)=> handleSearchAction(subSystemName, subSystemRailLineId)}
        showSyncBtnLoading={showSyncBtnLoading}
        onShowSyncLoading={() => setSyncBtnLoading(false)}
        onSyncAction = {() => handleSyncAction()}
        shouldShowSyncButton={shouldShowSyncBtn()}
      />
      {loading ? (
        <Loader
          type="infinite-spinner"
          style={{
              display: "flex",
              alignItems: "center",
              width: "40px",
              margin: "auto",
              justifyContent: "center",
              height: "100vh",
          }}
          />
      ): (
        <DrInfraSubSystemTable 
        data={isSearch=="search" ? searchResult: subsystem_data}/>
      )}
    </div>
  );
};