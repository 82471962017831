// redux
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionTokenWithPromise } from "../config/userPool";

const { ALL_COMM_ROLES_ENDPOINT } = config.api_endpoint;

const initialState = {
  roles_loading: false,
  roles: [],
  roles_error: null,
  role_details_loading: false,
  role_details: [],
  role_details_error: null,
  create_role_loading: false,
  create_role: {},
  create_role_error: null,
  update_role_loading: false,
  update_role: {},
  update_role_error: null,
  delete_role_loading: false,
  delete_role: {},
  delete_role_error: null,
};

export const fetchAllCommRoleList = createAsyncThunk(
  "commRole/fetchAllCommRoleList",
  async (_, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(ALL_COMM_ROLES_ENDPOINT, {}, token);
      console.log("fetchAllCommRoleList Results:", results);

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetchCommRoleById = createAsyncThunk(
  "commRole/fetchCommRoleById",
  async (id, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(
        ALL_COMM_ROLES_ENDPOINT + "/" + id,
        {},
        token
      );
      console.log("fetchCommRoleById Results:", results);

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const createRole = createAsyncThunk(
  "commRole/createRole",
  async (data, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.post(
        ALL_COMM_ROLES_ENDPOINT,
        JSON.stringify(data),
        token
      );
      console.log("createRole Results:", results);

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const updateRole = createAsyncThunk(
  "commRole/updateRole",
  async (data, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.post(
        ALL_COMM_ROLES_ENDPOINT + "/" + data.id,
        JSON.stringify(data.data),
        token
      );
      console.log("updateRole Results:", results);

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const deleteCommRole = createAsyncThunk(
  "commRole/deleteCommRole",
  async (id, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.remove(
        ALL_COMM_ROLES_ENDPOINT + "/" + id,
        JSON.stringify({ id: id }),
        token
      );
      console.log("deleteCommRole Results:", results);

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

const CommRoleSlice = createSlice({
  name: "commRole",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCommRoleList.pending, (state) => {
        state.roles_loading = true;
      })
      .addCase(fetchAllCommRoleList.fulfilled, (state, action) => {
        state.roles_loading = false;
        state.roles = action.payload;
      })
      .addCase(fetchAllCommRoleList.rejected, (state, action) => {
        state.roles_loading = false;
        state.roles_error = action.error.message;
      })
      .addCase(fetchCommRoleById.pending, (state) => {
        state.role_details_loading = true;
      })
      .addCase(fetchCommRoleById.fulfilled, (state, action) => {
        state.role_details_loading = false;
        state.role_details = action.payload;
      })
      .addCase(fetchCommRoleById.rejected, (state, action) => {
        state.role_details_loading = false;
        state.role_details_error = action.error.message;
      })
      .addCase(createRole.pending, (state) => {
        state.create_role_loading = true;
      })
      .addCase(createRole.fulfilled, (state, action) => {
        state.create_role_loading = false;
        state.create_role = action.payload;
      })
      .addCase(createRole.rejected, (state, action) => {
        state.create_role_loading = false;
        state.create_role_error = action.error.message;
      })
      .addCase(updateRole.pending, (state) => {
        state.update_role_loading = true;
      })
      .addCase(updateRole.fulfilled, (state, action) => {
        state.update_role_loading = false;
        state.update_role = action.payload;
      })
      .addCase(updateRole.rejected, (state, action) => {
        state.update_role_loading = false;
        state.update_role_error = action.error.message;
      })
      .addCase(deleteCommRole.pending, (state) => {
        state.delete_role_loading = true;
      })
      .addCase(deleteCommRole.fulfilled, (state, action) => {
        state.delete_role_loading = false;
        state.delete_role = action.payload;
      })
      .addCase(deleteCommRole.rejected, (state, action) => {
        state.delete_role_loading = false;
        state.delete_role_error = action.error.message;
      });
  },
});

const CommRoleReducer = CommRoleSlice.reducer;
export default CommRoleReducer;
