import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
// kendo
import { Dialog } from "@progress/kendo-react-dialogs";
import { Loader } from "@progress/kendo-react-indicators";
// redux
import { useDispatch, useSelector } from "react-redux";
import {
  fetchiFeedbackById,
  fetchiFeedbackRemarksUpdates,
  fetchiFeedbackRemarksInternalnotes,
  saveIfeedbackRemarksUpdates,
  saveIfeedbackRemarksInternalnotes,
  saveIfeedbackShare,
  changeiFeedbackStatus,
  saveiFeedbackGroup,
  saveiFeedbackMatter,
  fetchiFeedbackDetailsAudit,
} from "../../../../store/ifeedback";

import { fetchUserById } from "../../../../store/userMgmt";

// component
import { IFeedbackForm } from "./ifeedback_form";
import { IFeedbackDetailsUpdates } from "./ifeedback_details_updates";
import { IFeedbackDetailsActions } from "./ifeedback_details_actions";
import { IFeedbackDetailsStatus } from "./ifeedback_details_status";
import { IFeedbackDetailsAudit } from "./ifeedback_details_audit";
// route
import { IFEEDBACK_LIST } from "../../../../routes";
// image
import BackIcon from "../../../../assets/communication/Back.svg";
// css
import "./ifeedback_details.css";

export const IFeedbackDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const ifeedback_id = query.get("id");
  console.log("ifeedback_id:", ifeedback_id);
  const ifeedbackStatuses = ["Submitted", "Investigating", "Closed"];
  // redux
  const {
    detail_data,
    detail_loading,
    detail_error,
    remarks_updates_loading,
    internalnotes_loading,
    remarks_error,
    internalnotes_error,
    ifeedbackUpdates,
    ifeedbackInternalnotes,
    detail_audits_loading,
    detail_audits,
    detail_audits_error,
    refresh_audit,
  } = useSelector((state) => state.ifeedback);
  console.log("ifeedback_id-- ", detail_data);

  const { user_details_loading, user_details, user_details_error } = useSelector((state) => state.userMgmt);

  // state
  const [showChangeShareDialog, setshowChangeShareDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentiFeedbackStatus, setCurrentiFeedbackStatus] = useState("");
  const [currentiFeedbackMatter, setCurrentiFeedbackMatter] = useState("");
  const [updates, setUpdates] = useState([]);
  const [internalNotes, setInternalNotes] = useState([]);
  const [actionMessage, setActionMessage] = useState("");
  const [showAlert, setshowAlert] = useState(false);
  const [showChangeStatusDialog, setShowChangeStatusDialog] = useState(false);
  const [showRouteToDepeDialog, setShowRouteToDepDialog] = useState(false);
  const [showMatterDialog, setShowMatterDialog] = useState(false);
  const [createdByDetails,setCreatedByDetails] = useState({});
  const [updatedByDetails,setUpdatedByDetails] = useState({});
  // methods
  const handleChangeiFeedbackStatus = (e) => {
    setCurrentiFeedbackStatus(e.target.value);
  };

  const handleSaveiFeedbackStatus = () => {
    setLoading(true);
    dispatch(
      changeiFeedbackStatus({
        ifeedback_id,
        ifeedbackStatus: currentiFeedbackStatus.toLowerCase(),
      })
    )
      .then((result) => {
        console.log("status RES-", result);
        setLoading(false);
        if (result.payload.status == "success") {
          setActionMessage("Updated Status");
          setshowAlert(true);
        } else {
          setActionMessage(result.payload.message);
          setshowAlert(true);
        }
      })
      .then(() => {
        dispatch(fetchiFeedbackById(ifeedback_id));
        dispatch(fetchiFeedbackDetailsAudit(ifeedback_id));
      });
    setShowChangeStatusDialog(false);
  };

  const handleUpdateStatusBtnClicked = () => {
    setCurrentiFeedbackStatus(detail_data.status);
    setShowChangeStatusDialog(true);
  };

  const handleRouteToDepAction = async (data) => {
    setShowRouteToDepDialog(false);
    setLoading(true);
    dispatch(saveiFeedbackGroup({ ifeedback_id, group_id: data }))
      .then((result) => {
        console.log("route to dep RES-", result);
        setLoading(false);
        if (result.payload.status == "success") {
          setActionMessage("Successfully route to other department!");
          setshowAlert(true);
        } else {
          setActionMessage(result.payload.message);
          setshowAlert(true);
        }
      })
      .then(() => {
        dispatch(fetchiFeedbackById(ifeedback_id));
        dispatch(fetchiFeedbackDetailsAudit(ifeedback_id));
      });
  };

  const handleMatterAction = async (data) => {
    setShowMatterDialog(false);
    setLoading(true);
    console.log("Matter Action- ", data);
    dispatch(saveiFeedbackMatter({ ifeedback_id, no: data }))
      .then((result) => {
        console.log("Change matter type RES-", result);
        setLoading(false);
        if (result.payload.status == "success") {
          setActionMessage("Successfully change matter type!");
          setshowAlert(true);
        } else {
          setActionMessage(result.payload.message);
          setshowAlert(true);
        }
      })
      .then(() => {
        dispatch(fetchiFeedbackById(ifeedback_id));
        dispatch(fetchiFeedbackDetailsAudit(ifeedback_id));
      });
  };

  const saveNewUpdates = (description) => {
    let type = "updates";
    console.log("ifeedback saveNewUpdates:", ifeedback_id, description, type);
    dispatch(saveIfeedbackRemarksUpdates({ ifeedback_id, description, type }));
  };

  const saveNewInternalNotes = (description) => {
    console.log("iFeedback saveNewInternalNotes: ", ifeedback_id, description);
    dispatch(saveIfeedbackRemarksInternalnotes({ ifeedback_id, description }));
  };

  const handleChangeiFeedbackStatusDialogClose = () => {
    setShowChangeStatusDialog(false);
    // setshowChangeMarkAsDuplicateDialog(false);
    setshowChangeShareDialog(false);
  };

  const handleShareBtnClicked = () => {
    // setCurrentIncidentStatus(incident.status);
    setshowChangeShareDialog(true);
  };

  const saveShare = (emails) => {
    setshowChangeShareDialog(false);
    setLoading(true);
    console.log("iFeedback Share:", ifeedback_id, emails);
    dispatch(saveIfeedbackShare({ ifeedback_id, emails }))
      .then((result) => {
        console.log("share RES- ", result);
        setLoading(false);
        if (result.payload.status == "success") {
          setActionMessage("Shared successfully!");
          setshowAlert(true);
        } else {
          setActionMessage(result.payload.message);
          setshowAlert(true);
        }
      })
      .catch((error) => {
        setActionMessage("Share failed.");
      })
      .then(() => {
        dispatch(fetchiFeedbackById(ifeedback_id));
        dispatch(fetchiFeedbackDetailsAudit(ifeedback_id));
      });
  };

  // useEffect
  useEffect(() => {
    console.log(1234);
    if (ifeedback_id) {
      dispatch(fetchiFeedbackById(ifeedback_id));
      dispatch(fetchiFeedbackRemarksUpdates({ ifeedback_id }));
      // .then(result => {
      //     setUpdates(result.payload);
      //   });
      dispatch(fetchiFeedbackRemarksInternalnotes({ ifeedback_id }));
      // .then(result => {
      //     setInternalNotes(result.payload);
      // });
      console.log("Updates- ", updates, "INternalnotes- ", internalNotes);

      dispatch(fetchiFeedbackDetailsAudit(ifeedback_id));
    }
  }, []);

  console.log("Detail2222 data: ", detail_data);
  console.log("Detail loading ", detail_loading);

  useEffect(() => {
    if (detail_data) {
      console.log("before ifeedbackUpdates- ", detail_data.matter);
      setUpdates(ifeedbackUpdates);
      setCurrentiFeedbackMatter(detail_data.matter);
      console.log("currentiFeedbackMatter-", currentiFeedbackMatter);
    }
  }, [ifeedbackUpdates]);

  useEffect(() => {
    if (detail_data) {
      console.log("before ifeedbackInternalnotes- ", updates);
      setInternalNotes(ifeedbackInternalnotes);
    }
  }, [ifeedbackInternalnotes]);

  useEffect(() => {
    if (refresh_audit) {
      console.log("Refresh audit flag is up. Refresh again.");
      dispatch(fetchiFeedbackDetailsAudit(ifeedback_id));
    }
  }, [refresh_audit]);
  useEffect(()=>{
    if(detail_data.created_by){
      console.log(detail_data);
      if(detail_data.created_by.username){
        dispatch(fetchUserById(detail_data.created_by.username));
      }
      if(detail_data.updated_by.username){
        dispatch(fetchUserById(detail_data.updated_by.username));
      }  
    }
  },[detail_data]);
  useEffect(()=>{
    if(user_details.username && detail_data.created_by){
      if(user_details.username === detail_data.created_by.username && user_details.username === detail_data.updated_by.username){
        setCreatedByDetails(user_details);
        setUpdatedByDetails(user_details);
      }
      if(user_details.username === detail_data.created_by.username){
        setCreatedByDetails(user_details);
      }
      else if(user_details.username === detail_data.updated_by.username){
        setUpdatedByDetails(user_details);
      }  
    }
  },[user_details])
  return (
    <>
      <div className="ifeedback-details-wrapper">
        <div className="ifeedback-details-left">
          {loading && (
            <div className="k-loading-mask">
              <span className="k-loading-text">Loading</span>
              <div className="k-loading-image"></div>
              <div className="k-loading-color"></div>
            </div>
          )}
          {detail_loading || user_details_loading ? (
            <Loader
              type="infinite-spinner"
              style={{
                display: "flex",
                alignItems: "center",
                width: "40px",
                margin: "auto",
                justifyContent: "center",
                height: "100vh",
              }}
            />
          ) : (
            <>
              <div className="detail-header">
                <div
                  className="categoryback-group-div"
                  onClick={() => navigate(IFEEDBACK_LIST)}
                >
                  <img src={BackIcon} className="categoryback-icon" />
                  <span className="categoryback-label">Back</span>
                </div>
              </div>

              <IFeedbackForm data={detail_data} createdUser={createdByDetails} updatedUser={updatedByDetails} />
            </>
          )}

          {showAlert && (
            <Dialog
              className="alert-form"
              title={"Alert"}
              width={400}
              // height={280}
              onClose={() => setshowAlert(false)}
            >
              <p>{actionMessage}</p>
            </Dialog>
          )}
          <IFeedbackDetailsUpdates
            updates={updates}
            saveNewUpdates={saveNewUpdates}
            update_loading={remarks_updates_loading}
            internalNotes={internalNotes}
            saveNewInternalNotes={saveNewInternalNotes}
            notes_loading={internalnotes_loading}
          />
        </div>
        <div className="ifeedback-details-right">
          <IFeedbackDetailsStatus
            loading={detail_loading}
            status={detail_data ? detail_data.status : ""}
          />
          <IFeedbackDetailsActions
            onToggleDialog={handleChangeiFeedbackStatusDialogClose}
            ifeedbackStatuses={ifeedbackStatuses}
            currentiFeedbackStatus={currentiFeedbackStatus}
            onChangeiFeedbackStatus={handleChangeiFeedbackStatus}
            onSaveiFeedbackStatus={handleSaveiFeedbackStatus}
            showChangeStatusDialog={showChangeStatusDialog}
            onUpdateStatusBtnClicked={handleUpdateStatusBtnClicked}
            // showChangeMarkAsDuplicateDialog={showChangeMarkAsDuplicateDialog}
            // onMarkAsDuplicateBtnClicked={handleMarkAsDuplicateBtnClicked}
            // onSubmitMarkAsDuplicate={saveMarkAsDuplicate}
            // parentCasesList={parentCasesList}
            // parentcaseloading={parentcaseloading}
            showChangeShareDialog={showChangeShareDialog}
            onShareBtnClicked={handleShareBtnClicked}
            onSubmitShare={saveShare}
            // Route to Dept Obj
            showRouteToDepeDialog={showRouteToDepeDialog}
            onRouteToDepClose={() => setShowRouteToDepDialog(false)}
            onRouteToDepOpen={() => setShowRouteToDepDialog(true)}
            onRouteToDepAction={(res) => handleRouteToDepAction(res)}
            //   change matter type
            showMatterDialog={showMatterDialog}
            currentiFeedbackMatter={currentiFeedbackMatter}
            onMatterDialogClose={() => setShowMatterDialog(false)}
            onMatterDialogOpen={() => setShowMatterDialog(true)}
            onMatterAction={(res) => handleMatterAction(res)}
          />
          <IFeedbackDetailsAudit
            loading={detail_audits_loading}
            audits={detail_audits}
          />
        </div>
      </div>
    </>
  );
};
// import React, { useState, useEffect, useRef } from "react";
// import { Navigate, useLocation, useNavigate } from "react-router";
// import { useDispatch, useSelector } from "react-redux";
// // import "../../admin_tools.css";
// import { Button } from "@progress/kendo-react-buttons";
// import BackIcon from "../../../../assets/communication/Back.svg";
// import { IFEEDBACK_LIST } from "../../../../routes";
// // import EditIcon from "../../../../assets/common/edit_icon.svg";
// import ArrorRightIcon from "../../../../assets/common/arrao-right.svg";
// import { Loader } from "@progress/kendo-react-indicators";
// import { fetchiFeedbackById } from "../../../../store/ifeedback";
// import { IFeedbackForm } from "./ifeedback_form";

// export const IFeedbackDetails = () => {
//     const navigate = useNavigate();
//     const dispatch = useDispatch();
//     let { search } = useLocation();
//     const query = new URLSearchParams(search);
//     const ifeedback_id = query.get("id");
//     const [isEdit, setIsEdit] = useState(false);
//     const [loading, setLoading] = useState(false);
//     const {
//         detail_data,
//         detail_loading,
//         detail_error,
//     } = useSelector((state) => state.ifeedback);

//     useEffect(() => {
//         console.log('useeffect');
//         if (ifeedback_id) {
//             dispatch(fetchiFeedbackById(ifeedback_id));
//         }
//     },)

//     return (
//         <>
//             <div className="detail-body">
//                 {detail_loading ? (
//                     <Loader
//                         type="infinite-spinner"
//                         style={{
//                             display: "flex",
//                             alignItems: "center",
//                             width: "40px",
//                             margin: "auto",
//                             justifyContent: "center",
//                             height: "100vh",
//                         }}
//                     />
//                 ) : (
//                     <>
//                         <div className="detail-header">
//                             <div className="categoryback-group-div" onClick={() => navigate(IFEEDBACK_LIST)}>
//                                 <img src={BackIcon} className="categoryback-icon" />
//                                 <span className="categoryback-label">Back</span>
//                             </div>
//                         </div>
//                         <div className="detail-content">
//                             <IFeedbackForm
//                                 data={detail_data}
//                             />
//                         </div>
//                     </>
//                 )}

//             </div>
//         </>

//     );
// };
