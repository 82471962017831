import React, { useEffect, useRef, useState } from "react";
import "./articles.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Loader } from "@progress/kendo-react-indicators";
import {
  HAPPENINGS_ARTICLES_CREATE_ROUTE,
  HAPPENINGS_ARTICLES_DETAIL_ROUTE,
  HAPPENINGS_ARTICLES_DUPLICATE_ROUTE,
} from "../../../routes";
import SearchIcon from "../../../assets/header/search.svg";
import FilterIcon from "../../../assets/header/filter.svg";
import ExportIcon from "../../../assets/header/export.svg";
import {
  fetchArticlesDelete,
  fetchArticlesExport,
  fetchArticlesFilter,
  fetchArticlesListing,
  fetchArticlesUpdateStatus,
} from "../../../store/happenings/articles";
import { TableHeader } from "../../../components/reusableItems/header";
import { fetchCategoryListing } from "../../../store/admin_articles/manage_Category";
import { Search } from "../../../components/reusableItems/search";
import { ConfigData } from "./ConfigData";
import { Reusable_table } from "../../../components/reusableItems/table";
import { searchFilter } from "../../../components/reusableItems/filter/filter_helper";
import { Filter } from "./filter";
import moment from "moment";
import { fetchApreUpdateStatus } from "../../../store/aprewall/aprewall";
import { ShowDialog } from "../../../components/reusableItems/dialog";
import { CSVLink } from "react-csv";
import {
  checkPermission,
  getUserPermission,
} from "../../../config/permission_helper";
import { PermissionDeninedPage } from "../../../components/reusableItems/permission_denined";

export const Article_listing = () => {
  const exportLink = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tempData, setTempData] = useState([]);
  const [search, setSearch] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [listingLoading, setListingLoading] = useState(false);
  const [showPublish, setShowPublish] = useState(false);
  const [showUnPublish, setShowUnPublish] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [category, setCategory] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [exportLoading, setExportLoading] = useState(false);
  const [filterValue, setFilterValue] = useState({
    startDate_from: "",
    startDate_to: "",
    startDate: "all",
    createdDate_from: "",
    createdDate_to: "",
    createdDate: "all",
    status: [],
    category: [],
  });
  const [tempUpdate, setTempUpdate] = useState({
    id: "",
    type: "",
  });
  const { listing_data, export_data } = useSelector((state) => state.articles);
  const userPermissions = getUserPermission();
  const viewPermssion = checkPermission(
    userPermissions,
    "happening.access_happening_article_list"
  );

  const handleSearchAction = () => {
    setSearch(!search);
  };

  const handleFilterAction = () => {
    setShowFilter(!showFilter);
  };

  const handleCreateAction = () => {
    navigate(HAPPENINGS_ARTICLES_CREATE_ROUTE);
  };

  const handleExportAction = () => {
    setExportLoading(true);
    dispatch(fetchArticlesExport()).then((res) => {
      exportLink.current.link.click();
      setExportLoading(false);
    });
  };

  const icons = [
    {
      buttonName: "+ Create new",
      action: handleCreateAction,
    },
    {
      icon: ExportIcon,
      action: handleExportAction,
    },
    {
      icon: FilterIcon,
      action: handleFilterAction,
    },
    {
      icon: SearchIcon,
      action: handleSearchAction,
    },
  ];

  useEffect(() => {
    setListingLoading(true);
    dispatch(fetchCategoryListing()).then((res) => {
      if (res.payload.status_code === 200) {
        setCategory(res.payload.data);
      }
    });
    dispatch(fetchArticlesListing()).then((res) => {
      if (res.payload.status_code === 200) {
        setTempData(res.payload.data);
        setListingLoading(false);
      } else {
        setListingLoading(false);
        alert(res.payload.message);
      }
    });
  }, []);

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value === "") applySearch("");
  };

  const handleClear = async () => {
    setListingLoading(true);
    setSearchValue("");
    await setTempData(listing_data);
    setListingLoading(false);
  };

  const applySearch = async (value) => {
    setListingLoading(true);
    let searchColumn = [{ id: "title_en", caseSensitive: true }];
    const filtered = await searchFilter(
      listing_data,
      searchColumn,
      value === "" ? "" : searchValue
    );
    setTempData(filtered);
    setListingLoading(false);
  };

  const handleTableClick = (type, id) => {
    if (type === "View Details") {
      navigate(`${HAPPENINGS_ARTICLES_DETAIL_ROUTE}?id=${id}`);
    } else if (type === "Duplicate") {
      navigate(`${HAPPENINGS_ARTICLES_DUPLICATE_ROUTE}?duplicateID=${id}`);
    } else if (type === "Publish" || type === "Unpublish") {
      setTempUpdate({
        ...tempUpdate,
        id: id,
        type: type,
      });
      if (type === "Publish") {
        setShowPublish(true);
      } else {
        setShowUnPublish(true);
      }
    } else {
      setTempUpdate({
        ...tempUpdate,
        id: id,
        type: type,
      });
      setShowDelete(true);
    }
  };

  const FilterAction = () => {
    setListingLoading(true);
    setShowFilter(false);
    let params = [];
    if (filterValue.startDate === "date") {
      if (
        filterValue.startDate_from === "" ||
        filterValue.startDate_to === ""
      ) {
        return;
      } else if (
        new Date(filterValue.startDate_from) >
        new Date(filterValue.startDate_to)
      ) {
        return;
      } else {
        params.push(
          `publish_on_from=${moment(
            new Date(filterValue.startDate_from)
          ).format("YYYY-MM-DD")}`,
          `publish_on_to=${moment(new Date(filterValue.startDate_to)).format(
            "YYYY-MM-DD"
          )}`
        );
      }
    }
    if (filterValue.createdDate === "date") {
      if (filterValue.createdDate === "" || filterValue.createdDate === "") {
        return;
      } else if (
        new Date(filterValue.createdDate_from) >
        new Date(filterValue.createdDate_to)
      ) {
        return;
      } else {
        params.push(
          `created_on_from=${moment(
            new Date(filterValue.createdDate_from)
          ).format("YYYY-MM-DD")}`,
          `created_on_to=${moment(new Date(filterValue.createdDate_to)).format(
            "YYYY-MM-DD"
          )}`
        );
      }
    }
    if (filterValue.status.length > 0) {
      params.push(`status=${filterValue.status.join(",")}`);
    }
    if (filterValue.category.length > 0) {
      params.push(`category=${filterValue.category.join(",")}`);
    }
    dispatch(fetchArticlesFilter(params.join("&"))).then((res) => {
      if (res.payload.status_code === 200) {
        setTempData(res.payload.data);
        setListingLoading(false);
      } else {
        setListingLoading(false);
        alert(res.payload.message);
      }
    });
  };

  const filterClearAction = async () => {
    setShowFilter(false);
    setListingLoading(true);
    setFilterValue({
      ...filterValue,
      startDate_from: "",
      startDate_to: "",
      startDate: "all",
      createdDate_from: "",
      createdDate_to: "",
      createdDate: "all",
      status: [],
      category: [],
    });
    await setTempData(listing_data);
    setListingLoading(false);
  };

  const actionTrigger = () => {
    if (tempUpdate.type === "Publish" || tempUpdate.type === "Unpublish") {
      setShowPublish(false);
      setShowUnPublish(false);
      setListingLoading(true);
      dispatch(
        fetchArticlesUpdateStatus({
          status: tempUpdate.type === "Publish" ? "Published" : "Unpublished",
          id: tempUpdate.id,
        })
      ).then((res) => {
        if (res.payload.status_code === 200) {
          dispatch(fetchArticlesListing()).then((res) => {
            if (res.payload.status_code === 200) {
              setTempData(res.payload.data);
              setListingLoading(false);
            } else {
              setListingLoading(false);
              alert(res.payload.message);
            }
          });
        } else {
          setListingLoading(false);
          alert(res.payload.message);
        }
      });
    } else {
      setShowDelete(false);
      setListingLoading(true);
      dispatch(fetchArticlesDelete(tempUpdate.id)).then((res) => {
        if (res.payload.status_code === 200) {
          dispatch(fetchArticlesListing()).then((res) => {
            if (res.payload.status_code === 200) {
              setTempData(res.payload.data);
              setListingLoading(false);
            } else {
              setListingLoading(false);
              alert(res.payload.message);
            }
          });
        } else {
          setListingLoading(false);
          alert(res.payload.message);
        }
      });
    }
  };

  console.log(listing_data, filterValue.category.join(","));

  return (
    <>
      {viewPermssion ? (
        <>
          {exportLoading && (
            <Loader
              type="converging-spinner"
              style={{
                width: "100%",
                height: "calc(100% - 76px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#ffffff4d",
                position: "absolute",
                zIndex: "999",
              }}
            />
          )}
          <TableHeader title={"Articles"} icons={icons} />
          <CSVLink
            uFEFF={false}
            asyncOnClick={true}
            data={export_data}
            filename="export_happening_articles"
            ref={exportLink}
            hidden
          />
          {showFilter && (
            <Filter
              setShowFilter={setShowFilter}
              filterValue={filterValue}
              category={category}
              setFilterValue={setFilterValue}
              FilterAction={FilterAction}
              filterClearAction={filterClearAction}
            />
          )}
          {showDelete && (
            <ShowDialog
              title={"Please Confirm!"}
              content={"Are you sure you want to delete?"}
              cancelContent={"Cancel"}
              confrimContent={"Delete"}
              cancelbtn={() => setShowDelete(false)}
              confrinbtn={actionTrigger}
            />
          )}
          {showPublish && (
            <ShowDialog
              title={"Please Confirm!"}
              content={"Are you sure you want to Publish?"}
              cancelContent={"Cancel"}
              confrimContent={"Publish"}
              cancelbtn={() => setShowPublish(false)}
              confrinbtn={actionTrigger}
            />
          )}
          {showUnPublish && (
            <ShowDialog
              title={"Please Confirm!"}
              content={"Are you sure you want to Unpublish?"}
              cancelContent={"Cancel"}
              confrimContent={"Unpublish"}
              cancelbtn={() => setShowUnPublish(false)}
              confrinbtn={actionTrigger}
            />
          )}
          {search && (
            <Search
              handleSearch={handleSearch}
              type={"text"}
              searchValue={searchValue}
              placeholder={"Search"}
              handleClear={handleClear}
              applySearch={applySearch}
            />
          )}
          {listingLoading ? (
            <Loader
              type="converging-spinner"
              style={{
                width: "100%",
                height: "calc(100vh - 165px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#ffffff4d",
                position: "absolute",
                zIndex: "999",
              }}
            />
          ) : (
            <Reusable_table
              header={ConfigData}
              data={tempData}
              pagination={true}
              calculatePagi={true}
              pageChangeLoading={false}
              showRow={true}
              defaultSorting={false}
              handleAction={handleTableClick}
              maxHeight={
                search ? "calc(-282px + 100vh)" : "calc(100vh - 220px)"
              }
              maxWidth={"calc(100vw - 240px)"}
            />
          )}
        </>
      ) : (
        <PermissionDeninedPage
          height={"calc(100% - 76px)"}
          title={"Sorry, You don't have permission to access this page!"}
        />
      )}
    </>
  );
};
