import { Grid, GridColumn } from "@progress/kendo-react-grid"

const Component = (props) => {
  const headers = props?.headers ?? []
  return (
    <Grid
      data={props.data}
      sort={props?.sort}
      onSortChange={props?.onSort}
      style={{
        minHeight: 350,
        minWidth:1200,
      }}
    >
      {headers.length && headers.map(
        ({ title, field, cell,width }, key) => (
          <GridColumn key={key} field={field} title={title} cell={cell} width={width? width: "default"} />
        )
      )}
    </Grid>
  )
}

export default Component;
