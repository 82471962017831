export const getHeaders = (bsqcPeriodName = ' ') => [
  {
    Header: bsqcPeriodName || ' ',
    rows: [
      {
        Headers: '~'
      }
    ],
    columns: [
      {
        Header: 'No.',
        accessor: 'sn'
      },
      {
        Header: 'Checker',
        accessor: 'name'
      }
    ]
  },
  {
    Header: 'BSQC Check Preference',
    columns: [
      {
        Header: 'Allocation 1',
        columns: [
          { Header: 'Service No.', accessor: 'service_no' },
          { Header: 'Bus Stop', accessor: 'bus_stop_code' },
          { Header: 'Bus Stop Description', accessor: 'bus_stop_name' },
          { Header: 'Road Name', accessor: 'road_name' },
          { Header: 'AM/PM', accessor: 'am_pm' },
          { Header: 'Time', accessor: 'time' },
          { Header: 'Direction', accessor: 'direction' },
          {
            Header: "Action",
            accessor: "action",
          }
        ]
      }
    ]
  }
]