// redux
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionTokenWithPromise } from "../config/userPool";

const { USER_BY_PERMISSION_ENDPOINT } = config.api_endpoint;

const initialState = {
  senders_loading: false,
  approvers_loading: false,
  senders: [],
  approvers: [],
  senders_error: null,
  approvers_error: null,
};

export const fetchAllSenders = createAsyncThunk(
  "depot/fetchAllSenders",
  async (_, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.post(
        USER_BY_PERMISSION_ENDPOINT,
        JSON.stringify({ permission: "comms.limited_access_to_add_comms" }),
        token
      );
      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetchAllApprovers = createAsyncThunk(
  "depot/fetchAllApprovers",
  async (_, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.post(
        USER_BY_PERMISSION_ENDPOINT,
        JSON.stringify({
          permission: "comms.limited_access_to_approve_message",
        }),
        token
      );
      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

const CategoryUserListSlice = createSlice({
  name: "categoryUserList",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllSenders.pending, (state) => {
        state.senders_loading = true;
      })
      .addCase(fetchAllSenders.fulfilled, (state, action) => {
        state.senders_loading = false;
        state.senders = action.payload;
      })
      .addCase(fetchAllSenders.rejected, (state, action) => {
        state.senders_loading = false;
        state.senders_error = action.error.message;
      })
      .addCase(fetchAllApprovers.pending, (state) => {
        state.approvers_loading = true;
      })
      .addCase(fetchAllApprovers.fulfilled, (state, action) => {
        state.approvers_loading = false;
        state.approvers = action.payload;
      })
      .addCase(fetchAllApprovers.rejected, (state, action) => {
        state.approvers_loading = false;
        state.approvers_error = action.error.message;
      });
  },
});

const CategoryUserListReducer = CategoryUserListSlice.reducer;
export default CategoryUserListReducer;
