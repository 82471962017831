import { Fragment } from 'react';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

import CloseIcon from '../../assets/common/ic-close.svg';

const Component = props => {
  return (
    <Dialog
      className="filter-popup"
      width={props?.width ?? 300}
      height={"100%"}
      onClose={props?.onClose}>
      <div>
        <img
          style={{ float: 'right', cursor: 'pointer' }}
          width={25}
          height={25}
          src={CloseIcon}
          alt="icon-close"
          onClick={props?.onClose}
        />
      </div>
     <Fragment>{props?.children}</Fragment>
     {props?.dialogComponents()}
    </Dialog>
  )
}

export default Component;
