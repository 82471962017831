import React, { useState } from "react";
import "../../communication.css";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import { getCommRecipientsFile } from "../../../../store/notification";
import { useDispatch, useSelector } from "react-redux";

const initialSort = [
  {
    field: "name",
    dir: "asc",
  },
];



export const ReviewRecipientList = (props) => {
  const dispatch = useDispatch();
  const [sort, setSort] = useState(initialSort);
  const [skip, setSkip] = useState(0);
  const onClickOpenFile = (key)=>{
    console.log(key);
    const data = { key };
    dispatch(getCommRecipientsFile(data)).then((res)=>{
      console.log(res.payload.data);
      if(res.payload.data){
        window.open(res.payload.data);
      }
    })
  };
  const customDateCell = (props) => {
    if (props.dataItem[props.field] !== "") {
      return (
        <td style={{background: "transparent"}}>
          <span style={{textDecoration: "underline",fontWeight:700,color: "#781D7E"}} onClick={()=> onClickOpenFile(props.dataItem[props.field])}>{props.dataItem[props.field]}</span>
        </td>
      );
    }
    return <td style={{background: "transparent"}}>{props.dataItem[props.field]}</td>;
  };
  const pageChange = (event) => {
    setSkip(event.page.skip);
  };
  return (
    <>
      <Grid
        data={orderBy(props.data, sort).slice(skip, skip + 25)}
        style={{ height: "400px" }}
        sortable={"virtual"}
        sort={sort}
        pageSize={25}
        total={props.data.length}
        skip={skip}
        scrollable={"virtual"}
        onPageChange={pageChange}
        onSortChange={(e) => {
          setSort(e.sort);
        }}
      >
        <GridColumn field="name" title="Name" width={130}/>
        <GridColumn field="id" title="SAP No" width={100}/>
        <GridColumn field="depot" title="Department /Depot" width={130}/>
        <GridColumn field="interchange" title="Section/ Interchange Group" width={120}/>
        <GridColumn field="designation" title="Designation" width={120}/>
        <GridColumn field="file1" title="Attachment 1" cell={customDateCell} width={140}/>
        <GridColumn field="file2" title="Attachment 2" cell={customDateCell} width={140}/>
        <GridColumn field="file3" title="Attachment 3" cell={customDateCell} width={140}/>
        
        {/* <GridColumn field="leadership" title="Leadership" />
                <GridColumn field="citizenship" title="Citizenship" />
                <GridColumn field="nationality" title="Nationality" /> */}
      </Grid>
    </>
  );
};
