import React, { useState, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { EcardAccessoryTable } from "../../../../components/table/ecardAccessoryTable";
import { fetchAllStickers,DeleteEcardSticker } from "../../../../store/ecards";
import { Loader } from "@progress/kendo-react-indicators";
import {
  ADMIN_TOOLS_CARES_ACCESSORY_ADD,
  ADMIN_TOOLS_CARES_ACCESSORY_DETAIL,
  ADMIN_TOOLS_CATEGORY_LIST,
} from "../../../../routes";
import "../../admin_tools.css";
import RefreshIcon from "../../../../assets/header/refresh.svg";
import { FilterCategory } from "../../../../components/dialog/category_filter";
import { SearchCategory } from "../../../../components/dialog/category_search";
import { TableHeader } from "../../../../components/reusableItems/header";
import { Reusable_table } from "../../../../components/reusableItems/table";
import { ConfigData } from "./configData";

export const EcardAccessoryList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const [loading, setLoading ] = useState(false);
  const { stickers, stickers_loading, stickers_error } = useSelector(
    (state) => state.ecard
  );
  
  useEffect(() => {
    dispatch(fetchAllStickers());
  }, []);

  const handleCreateAction = () => {
    navigate(ADMIN_TOOLS_CARES_ACCESSORY_ADD);
  };

  const handleRefreshAction = () => {
    dispatch(fetchAllStickers());
  };

  const icons = [
    {
      buttonName: "+ Add new",
      action: handleCreateAction,
    },
    {
      icon: RefreshIcon,
      action: handleRefreshAction,
    },
  ];

  const handleTableClick = (type, id) => {
    if (type === "View Details") {
      navigate(`${ADMIN_TOOLS_CARES_ACCESSORY_DETAIL}?id=${id}`);
    }
    else{
      setLoading(true);
      dispatch(DeleteEcardSticker(id)).then((res)=>{
        setLoading(false);
        dispatch(fetchAllStickers());
        console.log(res);
      })
    }
  };

  return (
    <div>
      {/* <TableHeader
        title="Manage eCard Stickers"
        loading={stickers_loading}
        // onFilter={() => setShowFilterDialog(true)}
        // onSearch={() => setShowSearchDialog(true)}
        addurl={ADMIN_TOOLS_CARES_ACCESSORY_ADD}
        hideFilterBtn={true}
        hideSearchBtn={true}
        onRefresh={() => {
          dispatch(fetchAllStickers());
        }}
      /> */}
      <TableHeader title={"Manage eCard Stickers"} icons={icons} />
      {stickers_loading || loading ? (
        <Loader
          type="converging-spinner"
          style={{
            width: "100%",
            height: "calc(100vh - 165px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#ffffff4d",
            position: "absolute",
            zIndex: "999",
          }}
        />
      ) : (
        // <EcardAccessoryTable
        //   data={stickers}
        //   detail_url={ADMIN_TOOLS_CARES_ACCESSORY_DETAIL}
        //   //   handleDeleteAction={handleDeleteAction}
        // />
        <Reusable_table
          header={ConfigData}
          data={stickers}
          pagination={true}
          calculatePagi={true}
          pageChangeLoading={false}
          showRow={true}
          handleAction={handleTableClick}
          maxWidth={"calc(100vw - 240px)"}
          maxHeight={"calc(-220px + 100vh)"}
        />
      )}
    </div>
  );
};
