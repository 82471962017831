import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import qs from "qs";

import "../communication.css";
import { Button } from "@progress/kendo-react-buttons";
import BackIcon from "../../../assets/communication/Back.svg";
import { INBOX_ROUTE, ADD_NOTIFICATION_INFORMATION } from "../../../routes";
import { Stepper } from "@progress/kendo-react-layout";
import { CustomStep } from "../../../components/common/custom_steps";
import { getCurrentLoggedInSessionToken } from "../../../config/userPool";
import api from "../../../config/api";
import config from "../../../config/config";
import { Loader } from "@progress/kendo-react-indicators";
import { Notification_Details } from "./view/notification";
import { RecipientsDetails } from "./view/recipients";
import {
  fetchNotificationRecipients,
  fetchNotificationById,
  fetchNotificationOverviewById,
  fetchNotificationAudit,
} from "../../../store/notification";
import { fetchUserById } from "../../../store/userMgmt";
import { Confirmation } from "../../../components/dialog/confirmation";
import { filterActionByPermissions } from "../inbox/permission_checker";

const { NOTIFICATION_ACTION_ENDPOINT, NOTIFICATION_ENDPOINT } =
  config.api_endpoint;

const TABS = {
  NOTIFICATION: 0,
  RECIPIENTS: 1,
};

export const ViewNotification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const [notification_id, setNotificationId] = useState(query.get("id"));
  const [loading, setLoading] = useState(false);

  const [tab, setTab] = useState(+(query.get("tab") ?? TABS.NOTIFICATION));
  const [showPrompt, setShowPrompt] = useState(false);
  const [createdByDetails, setCreatedByDetails] = useState({});
  const [updatedByDetails, setUpdatedByDetails] = useState({});

  const [selectedAction, setSelectedAction] = useState();
  const {
    notification_data,
    ndata_loading,
    ndata_error,
    recipients,
    recipient_loading,
    recipient_error,
    overview_data,
    overview_loading,
    overview_error,
    audit_data,
    audit_loading,
    audit_error,
  } = useSelector((state) => state.notification);
  const { user_details_loading, user_details, user_details_error } = useSelector((state) => state.userMgmt);
  useEffect(()=>{
    if(notification_data.created_by){
      if(notification_data.created_by.username){
        if(notification_data.created_by.username === notification_data.updated_by.username){
          dispatch(fetchUserById(notification_data.created_by.username))
        }
        else{
          dispatch(fetchUserById(notification_data.created_by.username));
          dispatch(fetchUserById(notification_data.updated_by.username));
        }
      }
    }
  },[notification_data]);
  useEffect(()=>{
    if(user_details.username && notification_data.created_by){
      if(user_details.username === notification_data.created_by.username){
        setCreatedByDetails(user_details);
      }
      if(user_details.username === notification_data.updated_by.username){
        setUpdatedByDetails(user_details);
      }
    }
  },[user_details]);
  useEffect(() => {
    if (tab === TABS.NOTIFICATION) {
      dispatch(fetchNotificationById(notification_id));
      dispatch(fetchNotificationAudit(notification_id));
      dispatch(fetchNotificationOverviewById(notification_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification_id, tab]);

  useEffect(() => {
    if (tab === TABS.RECIPIENTS) {
      onGetNotificationRecipients();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification_id, tab]);

  const onGetNotificationRecipients = () => {
    dispatch(fetchNotificationById(notification_id));
    dispatch(fetchNotificationOverviewById(notification_id));
    dispatch(fetchNotificationRecipients(notification_id));
    dispatch(fetchNotificationAudit(notification_id));
  };

  const handleActionNotification = (name) => {
    if (name === "duplicate") {
      if (query.get("id")) {
        query.delete("id");
      }
      query.set("copyId", notification_id);
      query.set("state", 0);
      navigate(ADD_NOTIFICATION_INFORMATION + "?" + query.toString());
    } else if (name === "approve") {
      setShowPrompt(true);
      setSelectedAction(name);
      // setLoading(true);
      // notification_action({ notification_id: notification_id, id: "approve" });
    } else if (name == "reject") {
      setShowPrompt(true);
      setSelectedAction(name);
      // setLoading(true);
      // notification_action({ notification_id: notification_id, id: "reject" });
    }
    // else if(name === "publish"){
    //     setLoading(true);
    //     notification_action({ notification_id: notification_id, id: "publish" });
    // }
    else if (name === "recall" || name === "archive" || name === "delete") {
      setShowPrompt(true);
      setSelectedAction(name);
    } else if (name === "resubmit") {
      setLoading(true);
      notification_action(
        { notification_id: notification_id, id: "resubmit" },
        ADD_NOTIFICATION_INFORMATION + "?id=" + query.get("id") + "&state=0"
      );
    }
  };

  const DeleteItem = (recid) => {
    const token = getCurrentLoggedInSessionToken();
    const body = {
      id: recid,
    };
    api
      .remove(`${NOTIFICATION_ENDPOINT}/${recid}`, JSON.stringify(body), token)
      .then((res) => {
        if (res.status === "success") {
          if (query.get("id")) {
            query.delete("id");
          }
          navigate(INBOX_ROUTE);
        } else {
          alert("error");
        }
      });
  };

  const notification_action = (data, redirect_to = null) => {
    const token = getCurrentLoggedInSessionToken();
    api
      .post(NOTIFICATION_ACTION_ENDPOINT, JSON.stringify(data), token)
      .then((res) => {
        if (res.status === "success") {
          query.set("refresh",true);
          console.log(res);
          if (redirect_to) {
            navigate(redirect_to);
          } else {
            if (tab === TABS.RECIPIENTS) {
              onGetNotificationRecipients();
            } else {
              dispatch(fetchNotificationOverviewById(notification_id));
              dispatch(fetchNotificationById(notification_id));
              dispatch(fetchNotificationAudit(notification_id));
            }
            setLoading(false);
          }
        }
      });
  };

  const onChangeTab = (data) => {
    const queries = {
      id: query.get("id"),
      tab: data,
    };
    const queryBuild = qs.stringify(queries);

    navigate(`/communication/inbox/view?${queryBuild}`);
    setTab(data);
  };

  const onClickYes = () => {
    setLoading(true);
    setShowPrompt(false);
    if (selectedAction === "delete") {
      DeleteItem(notification_id);
    } else if (selectedAction === "recall" || selectedAction === "archive") {
      notification_action({
        notification_id: notification_id,
        id: selectedAction,
      });
    } else if (selectedAction === "approve") {
      notification_action({ notification_id: notification_id, id: "approve" });
    } else if (selectedAction === "reject") {
      notification_action({ notification_id: notification_id, id: "reject" });
    }
  };

  console.log(overview_loading);

  return (
    <>
      <div style={{ width: 1227 }}>
        {loading ||
        overview_loading ||
        ndata_loading ||
        recipient_loading ||
        audit_loading ? (
          <Loader
            type="infinite-spinner"
            style={{
              display: "flex",
              alignItems: "center",
              width: "40px",
              margin: "auto",
              justifyContent: "center",
              height: "100vh",
            }}
          />
        ) : (
          <div className="recall-body">
            {showPrompt && (
              <Confirmation
                title={"Please Confirm"}
                onClickNo={() => setShowPrompt(false)}
                onClickYes={onClickYes}
              />
            )}
            <div className="recall-body-left">
              <div className="recall-left-header">
                <div className="recall-back-group">
                  <a
                    className="recall-back-group-div"
                    onClick={() => navigate(INBOX_ROUTE+"?"+query.toString())}
                  >
                    <span className="recall-back-label">Back</span>
                    <img src={BackIcon} className="recall-back-icon" />
                  </a>
                </div>
              </div>
              {tab === 0 ? (
                <Notification_Details
                  data={notification_data}
                  createdUser={createdByDetails}
                  updatedUser={updatedByDetails}
                  onChange={(data) => onChangeTab(data)}
                />
              ) : (
                <RecipientsDetails
                  data={notification_data}
                  onChange={(data) => onChangeTab(data)}
                  onRefresh={onGetNotificationRecipients}
                  users={recipients}
                  overview_data={overview_data}
                />
              )}
            </div>
            {console.log(notification_data, overview_data)}
            <div className="recall-body-right">
              <div className="recall-status-div">
                <div className="recall-status">
                  <span className="recall-status-label">Status</span>
                  {notification_data.status && (
                    <div className="recall-status-value">
                      {notification_data.status}
                    </div>
                  )}
                </div>
              </div>
              <div className="recall-action-div">
                <div className="recall-action">
                  <span className="recall-status-label">Actions</span>
                  {notification_data.action
                    ? filterActionByPermissions(
                        notification_data.action,
                        overview_data
                      ).map((v) => (
                        <Button
                          className="duplicate-noti-btn"
                          onClick={() => handleActionNotification(v.id)}
                        >
                          {v.name}
                        </Button>
                      ))
                    : null}
                </div>
              </div>
              <div className="recall-audit-div">
                <div className="recall-audit">
                  <span className="recall-status-label">Activity Trail</span>
                  <div style={{}}>
                    <Stepper
                      orientation={"vertical"}
                      items={audit_data}
                      item={CustomStep}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
