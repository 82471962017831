import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { GetBSQCPeriod } from "../../../services/feedbackOnBus";
import { onSetListOfPeriod } from "../../../store/feedback-on-bus";

export const useMasterData = () => {
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(true);
  const { listOfPeriod } = useSelector((state) => state.feedbackOnBus);

  const getListBSQCPeriod = async (isRefetch) => {
    if (!isRefetch && listOfPeriod?.length) {
      // ne need to fetching due have existing data on storage
      return;
    }

    const [err, response] = await GetBSQCPeriod();

    if (!err) {
      dispatch(
        onSetListOfPeriod(
          response?.data ?? []
        )
      )

      setIsFetching(false);
    }

    setIsFetching(false);
  };

  const onRefetch = () => getListBSQCPeriod(true);

  useEffect(() => {
    getListBSQCPeriod();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isFetching, refetch: onRefetch };
}

export const useSelectMasterData = () => {
  const { step, selectedCheckPeriod, listOfPeriod } = useSelector((state) => state.feedbackOnBus);

  return {
    listOfPeriod,
    selectedCheckPeriod,
    step
  }
}