import React, { useEffect, useState } from "react";
import BackIcon from "../../assets/communication/Back.svg";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import "./assignment.css";
import { TextBox } from "@progress/kendo-react-inputs";
import { Loader } from "@progress/kendo-react-indicators";
import { getCurrentLoggedInSessionToken } from "../../config/userPool";
import api from "../../config/api";
import config from "../../config/config";
import { useNavigate } from "react-router";
import { FEEDBACK_ON_BUS_ASSIGNMENT_LISTING } from "../../routes";

export const AssignmentCreate = () => {
  const navigate = useNavigate();
  const [semiLoading, setSemiLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [period, setPeriod] = useState([]);
  const [busStop, setBusStop] = useState([]);
  const [checker, setChecker] = useState([]);
  const [formData, setFormData] = useState({
    feedbackScheme: "",
    direction: "",
    serviceNo: "",
    periodData: "",
    is_am: "",
    timeToCondut: "",
    busStopDescription: "",
    busStopName: "",
    busStopCode: "",
    assgined_to: "",
    assigned_bc: "",
    note: "",
    roadName: "",
    am_pm_text: "",
  });
  const {
    FOB_ASSIGNMENT_PERIOD_ENDPOINT,
    FOB_ASSIGNMENT_ROADNAME_ENDPOINT,
    FOB_ASSIGNMENT_CHECKER_ENDPOINT,
    FOB_ASSIGNMENT_CREATE_ENDPOINT,
  } = config.api_endpoint;

  const CallChecker = async () => {
    setSemiLoading(true);
    const token = getCurrentLoggedInSessionToken();
    const checkers = await api.get(FOB_ASSIGNMENT_CHECKER_ENDPOINT, {}, token);
    setChecker(checkers.data);
    console.log(checkers);
    setSemiLoading(false);
  };

  const CallAPI = async () => {
    const token = getCurrentLoggedInSessionToken();
    const periods = await api.get(FOB_ASSIGNMENT_PERIOD_ENDPOINT, {}, token);
    setPeriod(periods.data);
    setSemiLoading(false);
    console.log(periods);
  };

  const CallBusStop = async (type, value) => {
    setSemiLoading(true);
    const token = getCurrentLoggedInSessionToken();
    console.log(formData);
    let APIData = {
      [type]: value,
    };
    if (formData.periodData !== "" && APIData.pk === undefined) {
      APIData = {
        ...APIData,
        pk: formData.periodData,
      };
    }
    if (formData.serviceNo !== "" && APIData.svc === undefined) {
      APIData = {
        ...APIData,
        svc: formData.serviceNo,
      };
    }
    if (formData.direction !== "" && APIData.direction === undefined) {
      APIData = {
        ...APIData,
        direction: formData.direction,
      };
    }
    if (formData.is_am !== "" && APIData.is_am === undefined) {
      APIData = {
        ...APIData,
        is_am: formData.is_am,
      };
    }
    const BusStop = await api.get(
      FOB_ASSIGNMENT_ROADNAME_ENDPOINT,
      APIData,
      token
    );
    const busStopData = [];
    BusStop.data.map((i) => {
      busStopData.push({
        ...i,
        busStopDataShow: `${i.bus_stop_code} - ${i.bus_stop_name}`,
      });
    });
    console.log(BusStop);
    setBusStop(busStopData);
    setSemiLoading(false);
  };

  const onSchemeChange = (e) => {
    console.log(e.target.value);
    setFormData({
      ...formData,
      feedbackScheme: e.target.value,
      direction: "",
      serviceNo: "",
      periodData: "",
      is_am: "",
      timeToCondut: "",
      busStopDescription: "",
      busStopName: "",
      busStopCode: "",
      assgined_to: "",
      assigned_bc: "",
      note: "",
      roadName: "",
      am_pm_text: "",
    });
    if (e.target.value === "Bus Service Quality Check (BSQC)") {
      setSemiLoading(true);
      CallAPI();
    }
  };

  const onCheckPeriodChange = (e) => {
    setFormData({
      ...formData,
      periodData: e.target.value.pk,
    });
    console.log(formData);
    setSemiLoading(true);
    CallBusStop("pk", e.target.value.pk);
  };

  const onAMPMChange = (e) => {
    setFormData({
      ...formData,
      is_am: e.target.value === "AM" ? true : false,
    });
    console.log(formData);
    setSemiLoading(true);
    CallBusStop("is_am", e.target.value === "AM" ? true : false);
  };

  const onRoadNameChange = (e) => {
    console.log(e.target.value);
    setFormData({
      ...formData,
      roadName: e.target.value.road_name,
      timeToCondut: `${e.target.value.start_time} - ${e.target.value.end_time}`,
      busStopDescription: `${e.target.value.bus_stop_name} (${e.target.value.bus_stop_code})`,
      busStopName: e.target.value.bus_stop_name,
      busStopCode: e.target.value.bus_stop_code,
      serviceNo: e.target.value.svc,
      direction: e.target.value.direction,
      am_pm_text: e.target.value.am_pm_text,
    });
  };

  const onAssignedChange = (e) => {
    console.log(e.target.value);
    setFormData({
      ...formData,
      assgined_to: {
        id: e.target.value.username,
        name: e.target.value.name,
      },
    });
  };

  const SubmitToAPI = async () => {
    if (
      formData.feedbackScheme === "" ||
      formData.assgined_to === "" 
      // formData.assigned_bc === "" ||
      // formData.busStopCode === "" ||
      // formData.busStopName === "" ||
      // formData.direction === "" ||
      // formData.roadName === "" ||
      // formData.serviceNo === ""
    ) {
      return;
    } else {
      setLoading(true);
      let uploadData = {
        scheme:
          formData.feedbackScheme === "Bus Service Quality Check (BSQC)"
            ? "BSQC"
            : "MRCBC",
        checker: formData.assgined_to,
        is_am: formData.is_am === "" ? true : formData.is_am,
        svc: formData.serviceNo,
        bus_stop_code: formData.busStopCode,
        bus_stop_name: formData.busStopName,
        road_name: formData.roadName,
        time: formData.timeToCondut,
        am_pm_text: formData.am_pm_text,
        direction: formData.direction,
        checker_to_note: formData.note,
        assigned_bc: formData.assigned_bc,
      };
      if (formData.feedbackScheme === "Bus Service Quality Check (BSQC)") {
        uploadData = {
          ...uploadData,
          pk: formData.periodData,
        };
      }
      const token = getCurrentLoggedInSessionToken();
      const result = await api.post(
        FOB_ASSIGNMENT_CREATE_ENDPOINT,
        JSON.stringify(uploadData),
        token
      );
      console.log(result);
      if (result.status_code === 200) {
        navigate(FEEDBACK_ON_BUS_ASSIGNMENT_LISTING);
      } else {
        alert(result.message);
      }
    }
  };

  useEffect(() => {
    // CallAPI();
    CallChecker();
  }, []);

  console.log(formData);
  return (
    <>
      {semiLoading && (
        <div className="fullscreen-loader">
          <Loader type="infinite-spinner" />
        </div>
      )}
      {loading ? (
        <Loader
          type="infinite-spinner"
          style={{
            display: "flex",
            alignItems: "center",
            width: "40px",
            margin: "auto",
            justifyContent: "center",
            height: "92%",
          }}
        />
      ) : (
        <div>
          <div className="notification_body">
            <div className="notification_addheader">
              <div
                className="back-title-group"
                onClick={(e) => navigate(FEEDBACK_ON_BUS_ASSIGNMENT_LISTING)}
              >
                <img src={BackIcon} className="back-icon" />
                <span className="back-text">Back</span>
              </div>
            </div>
          </div>
          <div style={{ height: "calc(100vh - 235px)", overflow: "scroll" }}>
            <div className="Assignment_create_sub_box">
              <div className="Assignment_create_flex">
                <div className="Assignment_create_title">Feedback Scheme</div>
                <div>
                  <DropDownList
                    className="search-input"
                    value={formData.feedbackScheme}
                    style={{ width: "100%" }}
                    data={[
                      "Bus Service Quality Check (BSQC)",
                      "Mystery Rider Check(BC) (MRCBC)",
                    ]}
                    onChange={onSchemeChange}
                    placeholder="---Please select incident type---"
                  />
                </div>
              </div>
              {formData.feedbackScheme !==
                "Bus Service Quality Check (BSQC)" && (
                <div className="Assignment_create_flex">
                  <div className="Assignment_create_title">Direction</div>
                  <div>
                    <TextBox
                      className="search-input"
                      style={{width: "100%"}}
                      value={formData.direction}
                      onChange={(e)=> setFormData({...formData,direction: e.target.value})}
                    />
                    {/* <DropDownList
                      className="search-input"
                      value={formData.direction}
                      style={{ width: "100%" }}
                      data={[1, 2]}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          direction: e.target.value,
                        });
                        CallBusStop("direction", e.target.value);
                      }}
                      placeholder="---Please select incident type---"
                    /> */}
                  </div>
                </div>
              )}
            </div>
            <div className="Assignment_create_sub_box">
              {formData.feedbackScheme ===
                "Bus Service Quality Check (BSQC)" && (
                <div className="Assignment_create_flex">
                  <div className="Assignment_create_title">
                    BSQC Check Period
                  </div>
                  <div>
                    <DropDownList
                      className="search-input"
                      //   value={currentIncidentType}
                      style={{ width: "100%" }}
                      textField="name"
                      data={period}
                      dataItemKey={"pk"}
                      onChange={onCheckPeriodChange}
                      placeholder="---Please select incident type---"
                    />
                  </div>
                </div>
              )}
              {formData.feedbackScheme ===
                "Bus Service Quality Check (BSQC)" && (
                <div className="Assignment_create_flex">
                  <div className="Assignment_create_title">AM / PM</div>
                  <div>
                    <DropDownList
                      className="search-input"
                      style={{ width: "100%" }}
                      value={formData.is_am ? "AM" : "PM"}
                      data={["AM", "PM"]}
                      onChange={onAMPMChange}
                      placeholder="---Please select incident type---"
                    />
                  </div>
                </div>
              )}
              {formData.feedbackScheme !==
                "Bus Service Quality Check (BSQC)" && (
                <div className="Assignment_create_flex">
                  <div className="Assignment_create_title">
                    Boarding Bus Stop
                  </div>
                  <div>
                    <TextBox
                      className="search-input"
                      style={{width: "100%"}}
                      value={formData.busStopCode}
                      onChange={(e)=> setFormData({...formData,busStopCode: e.target.value })}                      
                    />
                    {/* <DropDownList
                      className="search-input"
                      //   value={currentIncidentType}
                      style={{ width: "100%" }}
                      data={busStop}
                      dataItemKey="bus_stop_code"
                      textField="busStopDataShow"
                      onChange={onRoadNameChange}
                      placeholder="---Please select incident type---"
                    /> */}
                  </div>
                </div>
              )}              
              {formData.feedbackScheme !==
                "Bus Service Quality Check (BSQC)" && (
                <div className="Assignment_create_flex">
                  <div className="Assignment_create_title">Service No.</div>
                  <div>
                    <TextBox
                      className="search-input"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          serviceNo: e.target.value,
                        });
                        CallBusStop("serviceNo", e.target.value);
                      }}
                      style={{ width: "100%" }}
                      value={formData.serviceNo}
                    />
                  </div>
                </div>
              )}

            </div>
            <div className="Assignment_create_sub_box">
              {formData.feedbackScheme ===
                "Bus Service Quality Check (BSQC)" && (
                <div className="Assignment_create_flex">
                  <div className="Assignment_create_title">Road Name</div>
                  <div>
                    <DropDownList
                      className="search-input"
                      //   value={currentIncidentType}
                      style={{ width: "100%" }}
                      dataItemKey="road_name"
                      textField="road_name"
                      data={busStop}
                      onChange={onRoadNameChange}
                      placeholder="---Please select incident type---"
                    />
                  </div>
                </div>
              )}
              {formData.feedbackScheme ===
                "Bus Service Quality Check (BSQC)" && (
                <div className="Assignment_create_flex">
                  <div className="Assignment_create_title">Service No.</div>
                  <div>
                    <TextBox
                      className="search-input"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          serviceNo: e.target.value,
                        });
                      }}
                      style={{ width: "100%" }}
                      value={formData.serviceNo}
                    />
                  </div>
                </div>
              )}
            </div>
            {formData.feedbackScheme === "Bus Service Quality Check (BSQC)" && (
              <div className="Assignment_create_sub_box">
                <div className="Assignment_create_flex">
                  <div className="Assignment_create_title">Time to Conduct</div>
                  <div>
                    <TextBox
                      className="search-input"
                      //   onChange={handleSearchCreator}
                      style={{ width: "100%" }}
                      value={formData.timeToCondut}
                    />
                  </div>
                </div>
                <div className="Assignment_create_flex">
                  <div className="Assignment_create_title">
                    Bus Stop Description (B / S No)
                  </div>
                  <div>
                    <TextBox
                      className="search-input"
                      //   onChange={handleSearchCreator}
                      style={{ width: "100%" }}
                      value={formData.busStopDescription}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="Assignment_create_sub_box">
              <div className="Assignment_create_flex">
                <div className="Assignment_create_title">Assigned to</div>
                <div>
                  <DropDownList
                    className="search-input"
                    //   value={currentIncidentType}
                    style={{ width: "100%" }}
                    data={checker}
                    dataItemKey="username"
                    textField="name"
                    onChange={onAssignedChange}
                    placeholder="---Please select incident type---"
                  />
                </div>
              </div>
              <div className="Assignment_create_flex">
                <div className="Assignment_create_title">Assigned BC</div>
                <div>
                  <TextBox
                    className="search-input"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        assigned_bc: e.target.value,
                      });
                    }}
                    style={{ width: "100%" }}
                    value={formData.assigned_bc}
                  />
                </div>
              </div>
            </div>
            {formData.feedbackScheme !==
                "Bus Service Quality Check (BSQC)" && (
                  <div className="Assignment_create_sub_box">
                  <div className="Assignment_create_flex">
                    <div className="Assignment_create_title">Checker to Note</div>
                    <div>
                      <TextBox
                        className="search-input"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            note: e.target.value,
                          });
                        }}
                        style={{ width: "100%" }}
                        value={formData.note}
                      />
                    </div>
                  </div>
                </div>
              )}

          </div>
          <div className="Assignment_footer_button_box">
            <div
              className="Assignment_footer_button_cancel"
              onClick={() => navigate(FEEDBACK_ON_BUS_ASSIGNMENT_LISTING)}
            >
              Cancel
            </div>
            <div
              className="Assignment_footer_button_send"
              onClick={SubmitToAPI}
              style={{
                // pointerEvents:
                //   formData.feedbackScheme === "" ||
                //   formData.assgined_to === ""
                //   // formData.assigned_bc === "" ||
                //   // formData.busStopCode === "" ||
                //   // formData.busStopName === "" ||
                //   // formData.direction === "" ||
                //   // formData.roadName === "" ||
                //   // formData.serviceNo === ""
                //     ? "none"
                //     : "",
                // opacity:
                //   formData.feedbackScheme === "" ||
                //   formData.assgined_to === ""
                //   // formData.assigned_bc === "" ||
                //   // formData.busStopCode === "" ||
                //   // formData.busStopName === "" ||
                //   // formData.direction === "" ||
                //   // formData.roadName === "" ||
                //   // formData.serviceNo === ""
                //     ? "0.5"
                //     : "1",
              }}
            >
              Send
            </div>
          </div>
        </div>
      )}
    </>
  );
};
