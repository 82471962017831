import React, { useState,useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { TableHeader } from "../../../../components/table/Tableheader";
import { EcardAccessoryTable } from "../../../../components/table/ecardAccessoryTable";

import { fetchAllEcardImages,DeleteEcardImage } from "../../../../store/ecards";

import { Loader } from "@progress/kendo-react-indicators";
import { ADMIN_TOOLS_CARES_IMAGES_ADD, ADMIN_TOOLS_CARES_IMAGES_DETAIL} from "../../../../routes";
import "../../admin_tools.css";


export const EcardImageList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let {search} = useLocation();
  const query = new URLSearchParams(search);
  const [loading, setLoading] = useState(false);
  const { images, images_loading,images_error } = useSelector((state) => state.ecard);
  useEffect(()=>{
    dispatch(fetchAllEcardImages());
  },[]);
  const handleDeleteAction = (id)=>{
    setLoading(true);
    dispatch(DeleteEcardImage(id)).then((res)=>{
      setLoading(false);
      dispatch(fetchAllEcardImages());
    })
  }
  return (
    <div className="body">
      <TableHeader 
        title = "Manage eCard Images"
        loading={images_loading}
        addurl={ADMIN_TOOLS_CARES_IMAGES_ADD}
        hideFilterBtn={true}
        hideSearchBtn={true}
        onRefresh={(()=>{
          dispatch(fetchAllEcardImages());
        })}
      />      
      {images_loading  || loading ? (
      <Loader
        type="infinite-spinner"
        style={{
          display: "flex",
          alignItems: "center",
          width: "40px",
          margin: "auto",
          justifyContent: "center",
          height: "100vh",
        }}
      />
      ):(
        <EcardAccessoryTable
          data={images}
          detail_url = {ADMIN_TOOLS_CARES_IMAGES_DETAIL}
          handleDeleteAction={handleDeleteAction}
        />
       )}

    </div>
  );
};