import { useEffect, useState } from "react";
import BackIcon from "../../../../../assets/communication/Back.svg";
import RemoveIcon from "../../../../../assets/bsqc/remove.svg";
import "../../summary-of-checks.css";
import { useNavigate, useLocation } from "react-router";
import { TextBox } from "@progress/kendo-react-inputs";
import "./email_template.css";
import { Icon } from "@progress/kendo-react-common";
import { FEEDBACK_ON_BUS_SUMMARY_OF_CHECK_DETAILS } from "../../../../../routes";
import { Editor, EditorTools, EditorUtils } from "@progress/kendo-react-editor";
import { Button } from "@progress/kendo-react-buttons";
import { fetchBSQCEmailTemplate } from "../../../../../store/emailTemplate";
import { fetchFOBEmailAddress } from "../../../../../store/fobemailAddress";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "@progress/kendo-react-indicators";
import { RouteFOBSummaryChecks } from "../../../../../store/feedbackonbusSummaryChecks";
import { getCurrentUserDetails } from "../../../../../config/userPool";

const {
    Bold,
    Italic,
    Underline,
    AlignCenter,
    AlignRight,
    AlignJustify,
    OrderedList,
    UnorderedList,
    FontName
} = EditorTools;
const defaultContent = "<p style='font-family: Arial, Helvetica, sans-serif;font-size:14px;'></p>";
const FobSummaryCheckEmailTemplate = () => {
    const userDetails = getCurrentUserDetails();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const [selectedEmails, setSelectedEmails] = useState([]);
    const [selectedCCEmails, setSelectedCCEmails] = useState([]);
    const [newEmail, setNewEmail] = useState("");
    const [newCCEmail, setNewCCEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [content, setContent] = useState("");
    const {
        email_templates_loading,
        email_templates,
    } = useSelector((state) => state.emailTemplate);
    const { loading: email_address_loading, email_address, error } = useSelector((state) => state.FOBEmailAddress)
    const { route_loading, route_data, route_error } = useSelector((state) => state.SummaryCheckfeedbackOnBus)
    
    // states
    const onRemoveEmails = (index) => {
        let updatedList = selectedEmails;
        updatedList.splice(index, 1);
        console.log(updatedList);
        setSelectedEmails([...updatedList]);
    }
    const onNewEmailKeyPress = (e) => {
        if (e.code == "Enter") {
            setSelectedEmails([...selectedEmails, newEmail]);
            setNewEmail("");
        }
    }
    const onRemoveCCEmails = (index) => {
        let updatedList = selectedCCEmails;
        updatedList.splice(index, 1);
        console.log(updatedList);
        setSelectedCCEmails([...updatedList]);
    }
    const onNewCCEmailKeyPress = (e) => {
        if (e.code == "Enter") {
            setSelectedCCEmails([...selectedCCEmails, newCCEmail]);
            setNewCCEmail("");
        }
    }
    const onhandleSubmitRoute = ()=>{
        let data = {
            remark: query.get("remarks"),
            email_groups: JSON.parse(query.get("group_id")),
            to: selectedEmails.toString(),
            cc: selectedCCEmails.toString(),
            subject,
            content,
            id: query.get("id"),
            sk: query.get("sk")
        }
        console.log("Payload",data);
        dispatch(RouteFOBSummaryChecks(data)).then((res)=>{
            query.delete("group_id");
            query.delete("remarks");
            navigate(FEEDBACK_ON_BUS_SUMMARY_OF_CHECK_DETAILS + "?" + query.toString())
        });
    }
    useEffect(() => {
        if (email_templates.length > 0) {
            // setSubject(email_templates[0].subject+" "+ query.get("svc")+"() By "+ userDetails.name);
            setSubject(`${email_templates[0].subject} ${query.get("svc")} (${query.get("reference_no")}) By ${userDetails.name}`);
            setContent(email_templates[0].content);
        }
    }, [email_templates])
    useEffect(() => {
        setSelectedEmails(email_address);
    }, [email_address])
    useEffect(() => {
        if(query.get("group_id") != null){
            const group_ids = JSON.parse(query.get("group_id"));        
            const group_ids_str = group_ids.map((v)=> v.id).toString()
            dispatch(fetchBSQCEmailTemplate());
            dispatch(fetchFOBEmailAddress(group_ids_str));    
        }
    }, [])

    return (
        <>
            {email_templates_loading || email_address_loading || route_loading ? (
                <Loader
                    type="infinite-spinner"
                    style={{
                        height: "80vh",
                        display: "flex",
                        alignItems: "center",
                        width: "40px",
                        margin: "auto",
                        justifyContent: "center",
                        minHeight: "100%",
                    }}
                />
            ) : (
                <div className="summarycheck-email-content">
                    <div className="summarycheck-detail-header">
                        <div className="summarycheck-detail-header-item">
                            <div className="detail-header">
                                <div className="detail-header-back-button" onClick={() => navigate(FEEDBACK_ON_BUS_SUMMARY_OF_CHECK_DETAILS + "?" + query.toString())}>
                                    <img src={BackIcon} className="categoryback-icon" width={10} />
                                    Back
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="summarycheck-email-container">
                        <div className="summarycheck-email-template-row email-template-sub-title">
                            To <span style={{ color: "red" }}>*</span>
                        </div>
                        <div className="summarycheck-email-template-row">
                            <TextBox value={newEmail} onChange={(e) => setNewEmail(e.target.value)} onKeyPress={(e) => onNewEmailKeyPress(e)} />
                        </div>
                        <div className="summarycheck-email-template-row summarycheck-email-list">
                            {selectedEmails.map((v, index) => (
                                <div>{v}<Icon onClick={(e) => onRemoveEmails(index)}><img src={RemoveIcon} /></Icon></div>
                            ))}
                        </div>
                        <div className="summarycheck-email-template-row email-template-sub-title">
                            CC
                        </div>
                        <div className="summarycheck-email-template-row">
                            <TextBox value={newCCEmail} onChange={(e) => setNewCCEmail(e.target.value)} onKeyPress={(e) => onNewCCEmailKeyPress(e)} />
                        </div>
                        <div className="summarycheck-email-template-row summarycheck-email-list">
                            {selectedCCEmails.map((v, index) => (
                                <div>{v}<Icon onClick={(e) => onRemoveCCEmails(index)}><img src={RemoveIcon} /></Icon></div>
                            ))}
                        </div>
                        <div className="summarycheck-email-template-row email-template-sub-title">
                            Subject <span style={{ color: "red" }}>*</span>
                        </div>
                        <div className="summarycheck-email-template-row">
                            <TextBox value={subject} onChange={(e) => setSubject(e.target.value)} />
                        </div>
                        <div className="summarycheck-email-template-row email-template-sub-title">
                            Content <span style={{ color: "red" }}>*</span>
                        </div>
                        <div className="summarycheck-email-template-row">
                            <Editor
                                tools={[
                                    [
                                        Bold,
                                        Italic,
                                        Underline,
                                        AlignCenter,
                                        AlignJustify,
                                        AlignRight,
                                        OrderedList,
                                        UnorderedList,
                                        // FontName
                                    ],
                                ]}
                                defaultContent={defaultContent}
                                value={content}
                                onChange={(e) => {
                                    setContent(e.html);
                                }}
                                contentStyle={{fontFamily: "Poppins, sans-serif"}}
                                className="summarycheck-email-template-content"
                            />
                        </div>
                    </div>
                    <div className="summarycheck-email-buttongroups">
                        <div>
                            <Button onClick={() => navigate(FEEDBACK_ON_BUS_SUMMARY_OF_CHECK_DETAILS + "?" + query.toString())}>Cancel</Button>
                            <Button onClick={()=> onhandleSubmitRoute()}>Submit</Button>
                        </div>
                    </div>

                </div>
            )}

        </>
    )
}
export default FobSummaryCheckEmailTemplate;