import React, { useState, useEffect } from "react";
// redux
import { useNavigate } from "react-router";
// component
import { UserListUserMgmtDialog } from "../../../../../components/dialog/userListUserMgmtDialog";
// kendo
import { Button } from "@progress/kendo-react-buttons";
import { TextBox } from "@progress/kendo-react-inputs";
// routes
import { ADMIN_TOOLS_EMAIL_GROUP_LIST } from "../../../../../routes";

export const EmailGroupForm = ({
  id: email_group_id,
  data,
  isEdit,
  submitForm,
  setLoading,
}) => {
  // redux
  const navigate = useNavigate();
  // state
  const [name, setName] = useState(null);
  const [type, setType] = useState(null);
  const [userList, setUserList] = useState([]);
  const [showUserList, setShowUserList] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);

  // methods
  const userListSelectedCallback = (data) => {
    let users = [];
    data.filter((x) => {
      if (x.user_name) {
        users.push({
          name: x.user_name,
          username: x.username,
          email: x.email,
        });
      } else {
        users.push(x);
      }
    });
    console.log("userListSelectedCallback:", users);
    setUserList(users);
    setShowUserList(false);
  };

  const cancelForm = () => {
    console.log(data);
    if (data & isEdit) {
      // setIsEdit(true);
    } else {
      navigate(ADMIN_TOOLS_EMAIL_GROUP_LIST);
    }
  };

  const submitData = () => {
    setLoading(true);
    let submit_data = {
      name,
      type,
      user_list: userList,
    };
    if (email_group_id) {
      submit_data["id"] = email_group_id;
    }
    submitForm(submit_data);
  };

  // useEffect
  useEffect(() => {
    console.log("Group Details data:", data);
    if (data) {
      setName(data.name);
      setType(data.type);
      setUserList(data.user_list);
    }
  }, []);

  useEffect(() => {
    if (name && type && userList.length > 0) {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  }, [name, type, userList]);

  return (
    <>
      {showUserList && (
        <UserListUserMgmtDialog
          callback={userListSelectedCallback}
          defaultSelected={userList}
          onClickNo={() => setShowUserList(false)}
        />
      )}
      <table>
        <tr>
          <td>Group Type</td>
          {isEdit ? (
            <td>
              <TextBox value={type} onChange={(e) => setType(e.target.value)} />
            </td>
          ) : (
            <td>{data.type}</td>
          )}
        </tr>
        <tr>
          <td>Group Name</td>
          {isEdit ? (
            <td>
              <TextBox value={name} onChange={(e) => setName(e.target.value)} />
            </td>
          ) : (
            <td>{data.name}</td>
          )}
        </tr>
        <tr>
          <td>Employee</td>
          <td>
            {isEdit ? (
              <div className="email-group-edit-user-list-layout">
                <Button onClick={() => setShowUserList(true)}>
                  Click here to update the employee(s)
                </Button>
                {userList
                  ? userList.map((v) => (
                      <div className="email-group-user-list-item">{v.name}</div>
                    ))
                  : null}
              </div>
            ) : (
              <div className="email-group-edit-user-list-layout">
                {data.user_list
                  ? data.user_list.map((v) => (
                      <div className="email-group-user-list-item">{v.name}</div>
                    ))
                  : null}
              </div>
            )}
          </td>
        </tr>
        {isEdit && (
          <tr>
            <td colSpan={2}>
              <div className="email-group-detail-button-div">
                <Button
                  className="email-group-detail-button email-group-detail-cancel"
                  onClick={cancelForm}
                >
                  Cancel
                </Button>
                <Button
                  className="email-group-detail-button email-group-detail-submit"
                  onClick={submitData}
                  disabled={disableSubmit}
                >
                  Submit
                </Button>
              </div>
            </td>
          </tr>
        )}
      </table>
    </>
  );
};
