import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import RefreshIcon from "../../../assets/header/refresh.svg";
import ExportIcon from "../../../assets/header/export.svg";
import FilterIcon from "../../../assets/header/filter.svg";
import SearchIcon from "../../../assets/header/search.svg";
import "react-datepicker/dist/react-datepicker.css";

import "./summary-of-checks.css";
import { useEffect, useMemo, useState } from "react";
import { orderBy, process } from '@progress/kendo-data-query';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useFeedbackListQuery, useGetSummaryCountQuery } from "./actions";
import { Navigate, useNavigate } from "react-router";

import SummaryCount from './partials/summary-count'
import FeedbackTable from './partials/feedback-table'
import FilterFeedback from './partials/filter-feedback'
import { FEEDBACK_ON_BUS_SUMMARY_OF_CHECK_DETAILS } from '../../../routes';
import { Confirmation } from "../../../components/dialog/confirmation";
import { DeleteFOBSummaryChecks } from "../../../store/feedbackonbusSummaryChecks";
import { useSelector,useDispatch } from "react-redux";
import { FilterSummaryChecks } from "../../../components/dialog/summarychecksfilter";
import { fetchFOBSummaryCheckExport } from "../../../store/feedbackonbusSummaryChecks";
import { useLocation } from "react-router";

const products = [
  { ProductID: 1, ProductName: 'Product 1', Price: 50 },
  { ProductID: 2, ProductName: 'Product 2', Price: 75 },
  { ProductID: 3, ProductName: 'Product 3', Price: 100 },
  { ProductID: 3, ProductName: 'Product 3', Price: 100 },
  { ProductID: 3, ProductName: 'Product 3', Price: 100 },
  { ProductID: 3, ProductName: 'Product 3', Price: 100 },
  { ProductID: 3, ProductName: 'Product 3', Price: 100 },
  { ProductID: 3, ProductName: 'Product 3', Price: 100 },
  { ProductID: 3, ProductName: 'Product 3', Price: 100 },
];


const pageSize = 6; // Number of items per page

const Component = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const dispatch = useDispatch();
  const navigate = useNavigate();  
  const { export_data,export_loadng,export_error } = useSelector(
    (state) => state.SummaryCheckfeedbackOnBus
  );
  const { data: dataFeedback, queryString, loading: loadingList, onRefresh } = useFeedbackListQuery();
  const { data: dataSummary, loading: loadingSummary, refetch } = useGetSummaryCountQuery();
  const [openFilter, setOpenFilter] = useState(false);
  const [showConfirm,setShowConfirm] = useState(false);
  const [recordToDelete,setRecordToDelete] = useState(null);
  const [data, setData] = useState(products);
  const [sort, setSort] = useState([{ field: 'Price', dir: 'asc' }]);
  const [page, setPage] = useState({ skip: 0, take: pageSize });

  const HEADERS = [
    { field: "No", title: "No",width: 50,cell:(props)=>(
      <td className="k-table-td">{props.dataIndex+1}</td>
    )},
    { field: "scheme_name", title: "Feedback Scheme" },
    { field: "reference_no", title: "Reference No", width:150, cell:(props)=>(
      <td className="k-table-td">
        <a style={{color:"#2B63CA",fontWeight:500,textDecoration:"underline",cursor:"pointer"}} onClick={()=> navigate(FEEDBACK_ON_BUS_SUMMARY_OF_CHECK_DETAILS+"/?id="+props.dataItem.pk+"&sk="+props.dataItem.sk)}>{props.dataItem.reference_no}</a>
      </td>
    ) },
    { field: "submitted_date", title: "Date" , width:220},
    { field: "service_no", title: "Service No" ,width: 100},
    { field: "operator", title: "Operator", width:100 },
    { field: "cat", title: "Cat1/Cat2" },
    { field: "updated_by.name", title: "Updated By" },
    { field: "status", title: "Status" },
    { field: "action", title: "Action", cell: (props) => (
      <td colSpan={1} className="k-table-td">
        <DropDownList
          style={{
            width: "100px",
            height: "48px",
            backgroundColor: "white",
            color: "#333333",
            textAlign: "center",
            borderRadius: "8px",
            borderColor: "#333333"
          }}
          defaultValue={"Action"}
          data={["View", "Delete"]}
          onChange={(e)=> onItemActionChange(e,props.dataItem)}
        />
      </td>
    ) }
  ]
  const onItemActionChange = (e,val) =>{
    if(e.target.value.toLowerCase()== "view"){
      navigate(FEEDBACK_ON_BUS_SUMMARY_OF_CHECK_DETAILS+"/?id="+val.pk+"&sk="+val.sk);
    }else{
      setRecordToDelete(val);
      setShowConfirm(true);
    }
  }
  const handleSortChange = (event) => {
    setSort(event.sort);
    setData(orderBy(products, event.sort));
  };

  const handlePageChange = (event) => {
    setPage({
      skip: event.page.skip,
      take: event.page.take,
    });
  };

  const processedData = useMemo(
    () => process(dataFeedback ?? [], {
      sort: sort,
      skip: page.skip,
      take: page.take,
    }),
    [dataFeedback, page.skip, page.take, sort]
  );

  const onRefreshPage = () => {
    // dispatch(fetchFOBSummaryCheckList(query))
    onRefresh();
    refetch();
  }
  const onhandleFilterCallback = (data)=>{
    console.log(data);
    setOpenFilter(false);
  }
  const onToggleFilter = () => setOpenFilter(prev => !prev)
  const onhandleDeleteConfirm = ()=>{
    setShowConfirm(false);
    let data = {
      'id': recordToDelete.pk,
      'sk': recordToDelete.sk
    }
    dispatch(DeleteFOBSummaryChecks(data));    
    console.log(recordToDelete);
  }
  const onClickExport = ()=>{
    dispatch(fetchFOBSummaryCheckExport());
  }
  // useEffect(()=>{

  // },[dataFeedback])
  // console.log('queryString: ', queryString);
  return (
    <div className="page-wrapper">
      <div className="container">
        <div className="header">
          <div className="page-header">
            <h2 style={{ fontSize: 32 }}>Feedback On Bus Service</h2>
            <div className="filter-group">
              <Button className="custom-button" onClick={onRefreshPage}>
                <img style={{ width: 20, height: 20 }} src={RefreshIcon} alt="refresh-icon" />
              </Button>
              {/* <Button className="custom-button" onClick={onClickExport} disabled={export_loadng}>
                <img style={{ width: 20, height: 20 }} src={ExportIcon} alt="export-icon" />
              </Button> */}
              <Button onClick={() => setOpenFilter(prev => !prev)} className="custom-button">
                <img style={{ width: 20, height: 20 }} src={FilterIcon} alt="filter-icon" />
              </Button>
              {/* <Button className="custom-button">
                <img style={{ width: 20, height: 20 }} src={SearchIcon} alt="search-icon" />
              </Button> */}
            </div>
          </div>
        </div>
        {showConfirm && (
        <Confirmation
          title={"Confirmation"}
          message={"Are you sure, you want to delete ?"}
          onClickNo={(e)=> setShowConfirm(false)}
          onClickYes={onhandleDeleteConfirm}
        />
        )}
        <div className="page-body">
          {(loadingList || loadingSummary ) ? (
            <Loader
              type="infinite-spinner"
              style={{
                height: "80vh",
                display: "flex",
                alignItems: "center",
                width: "40px",
                margin: "auto",
                justifyContent: "center",
                minHeight: "100%",
              }}
            />
          ) : (
            <>
              <SummaryCount {...dataSummary} />

              {/* Table */}
              <FeedbackTable
                headers={HEADERS}
                data={processedData.data}
                sort={sort}
                onSort={handleSortChange}
              />

              {/* Filter Feedback */}
              {openFilter && (
                <FilterSummaryChecks onClose ={()=> setOpenFilter(false)} onhandleSubmit={onhandleFilterCallback}/>
                // <FilterFeedback onToggle={onToggleFilter} />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Component;
