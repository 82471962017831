import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import EditIcon from "../../../../assets/common/edit_icon.svg";
import ArrorRightIcon from "../../../../assets/common/arrao-right.svg";
import { Loader } from "@progress/kendo-react-indicators";
import BackIcon from "../../../../assets/communication/Back.svg";
import { TELEPHONE_DIRECTORY_DEPARTMENT_LIST } from "../../../../routes";
import { fetchDepartmentById, updateDepartment } from "../../../../store/telephoneDirectory";
import { DepartmentForm } from "./department_form";

export const DepartmentDetails = () => {
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const dept_id = query.get("id")
    console.log('ID in detail',dept_id)
    const navigate = useNavigate();
    const dispatch = useDispatch();
   
    const [isEdit, setIsEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const {
        dept_detail_data,
        dept_detail_loading,
        detail_error,
    } = useSelector((state) => state.telephone_directory);
    
    useEffect(() => {
        console.log('dept_id',dept_id);
        if (dept_id) {
            dispatch(fetchDepartmentById(dept_id));
        }
    }, [])
    const handleSaveDepartment = (data) => {
        data.id = dept_id
        console.log('Handle Save Department--',data);
        dispatch(updateDepartment({ data, dept_id }))
            .then(() => {
                setIsEdit(false);
                // dispatch(fetchDepartmentById(dept_id));
                navigate(TELEPHONE_DIRECTORY_DEPARTMENT_LIST);
            });
    }
    return (
        <>
            <div className="detail-body">
                {dept_detail_loading ? (
                    <Loader
                        type="infinite-spinner"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            width: "40px",
                            margin: "auto",
                            justifyContent: "center",
                            height: "100vh",
                        }}
                    />
                ) : (
                    <>
                        <div className="detail-header">
                            <div className="categoryback-group-div" onClick={() => navigate(TELEPHONE_DIRECTORY_DEPARTMENT_LIST)}>
                                <img src={BackIcon} className="categoryback-icon" />
                                <span className="categoryback-label">Back</span>
                            </div>
                            <div>
                                    {!isEdit && (
                                    <div>
                                        <Button className="editBtn" onClick={() => setIsEdit(true)}>
                                            <img src={EditIcon} style={{ verticalAlign: "middle" }} />
                                            <span className="editBtn-label">Edit</span>
                                            <img src={ArrorRightIcon} style={{ verticalAlign: "middle" }} />
                                        </Button>
                                    </div>

                                )}
                            
                            {/* {!isEdit && (
                                    <div>
                                        <Button className="editBtn" onClick={() => setIsEdit(true)}>
                                            <img src={EditIcon} style={{ verticalAlign: "middle" }} />
                                            <span className="editBtn-label">Edit</span>
                                            <img src={ArrorRightIcon} style={{ verticalAlign: "middle" }} />
                                        </Button>
                                    </div>

                                )} */}
                            </div>
                        </div>
                        <div className="detail-content">
                            <DepartmentForm
                                data={dept_detail_data}
                                setIsEdit={setIsEdit}
                                isEdit={isEdit}
                                submitForm={handleSaveDepartment}
                            />
                        </div>
                    </>
                )}

            </div>
        </>

    );
};