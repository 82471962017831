import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
// redux
import { useDispatch, useSelector } from "react-redux";
import {
  fetchEmailTemplateById,
  updateEmailTemplate,
} from "../../../../../store/emailTemplate";
// kendo
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
// routes
import { ADMIN_TOOLS_EMAIL_TEMPLATE_LIST } from "../../../../../routes";
// component
import { EmailTemplateForm } from "../form";
// permissions
import {
  getUserPermission,
  checkMultiPermission,
} from "../../../../../config/permission_helper";
import { ADMIN_TOOLS_FOB_ADD_EDIT_EMAIL_TEMPLATE } from "../../../../../config/permission_helper";
// image
import ArrorRightIcon from "../../../../../assets/common/arrao-right.svg";
import BackIcon from "../../../../../assets/communication/Back.svg";
import EditIcon from "../../../../../assets/common/edit_icon.svg";
// css
import "../email_template.css";

export const EmailTemplateDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const email_template_id = query.get("id");

  // state
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  // redux
  const {
    email_template_details_loading,
    email_template_details,
    email_template_details_error,
  } = useSelector((state) => state.emailTemplate);

  // method
  const shouldShowAddButton = () => {
    const userPermissions = getUserPermission();
    return checkMultiPermission(
      userPermissions,
      ADMIN_TOOLS_FOB_ADD_EDIT_EMAIL_TEMPLATE
    )
      ? true
      : false;
  };

  const handleSaveEmailTemplate = (data) => {
    console.log("handleSaveEmailTemplate: ", data);
    dispatch(updateEmailTemplate({ id: email_template_id, data: data })).then(
      () => {
        setLoading(false);
        navigate(ADMIN_TOOLS_EMAIL_TEMPLATE_LIST);
      }
    );
  };

  // useEffect
  useEffect(() => {
    if (email_template_id) {
      dispatch(fetchEmailTemplateById(email_template_id));
    }
  }, []);

  return (
    <>
      <div className="email-template-detail-body">
        {loading || email_template_details_loading ? (
          <Loader
            type="infinite-spinner"
            style={{
              display: "flex",
              alignItems: "center",
              width: "40px",
              margin: "auto",
              justifyContent: "center",
              height: "100vh",
            }}
          />
        ) : (
          <>
            <div className="email-template-detail-header">
              <div
                className="email-template-detail-group-div"
                onClick={() => navigate(ADMIN_TOOLS_EMAIL_TEMPLATE_LIST)}
              >
                <img src={BackIcon} className="email-template-detail-icon" />
                <span className="email-template-detail-label">Back</span>
              </div>
              {!isEdit && shouldShowAddButton() && (
                <div>
                  <Button
                    className="email-template-detail-edit-btn"
                    onClick={() => setIsEdit(true)}
                  >
                    <img src={EditIcon} style={{ verticalAlign: "middle" }} />
                    <span className="email-template-detail-edit-btn-label">
                      Edit
                    </span>
                    <img
                      src={ArrorRightIcon}
                      style={{ verticalAlign: "middle" }}
                    />
                  </Button>
                </div>
              )}
            </div>
            <div className="email-template-detail-content">
              <EmailTemplateForm
                id={email_template_id}
                data={email_template_details}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                setLoading={setLoading}
                submitForm={handleSaveEmailTemplate}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
