import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";

import "./table.css";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { ADMIN_TOOLS_CATEGORY_DETAILS } from "../../routes";
import { Confirmation } from "../dialog/confirmation";
import { deleteCategory } from "../../store/category";

const initialDataState = {
  skip: 0,
  take: 10,
  dir: "asc",
};

const initialSort = [
  {
    field: "name",
    dir: "desc",
  },
];

const itemRender = (li, itemProps) => {
  const index = itemProps.index;
  const itemChildren = (
    <span
      style={{
        display: "flex",
      }}
    >
      {li.props.children}
    </span>
  );
  return React.cloneElement(li, li.props, itemChildren);
};

const valueRender = (element, value) => {
  if (!value) {
    return element;
  }
  const children = [
    <span>&nbsp;&nbsp;&nbsp;&nbsp; {element.props.children}</span>,
  ];
  return React.cloneElement(
    element,
    {
      ...element.props,
    },
    children
  );
};

export const EcardAccessoryTable = (props) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(initialDataState);
  const [pageSizeValue, setPageSizeValue] = useState();
  const [sort, setSort] = useState(initialSort);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedID, setSelectedID] = useState(null);

  const pageChange = (event) => {
    const targetEvent = event.targetEvent;
    const take = targetEvent.value === "All" ? 77 : event.page.take;
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const onItemActionChange = (e, val) => {
    const selected = e.target.value;
    const { id } = val;
    if (selected === "View Details") {
      navigate(`${props.detail_url}?id=${id}`);
    } else {
      props.handleDeleteAction(val.id);
      // setSelectedID(val.id);
      // setShowConfirm(true);
    }
  };
  const handleConfirmDelete = () => {
    setShowConfirm(false);
    props.handleDeleteAction(selectedID);
  };
  return (
    <div className="tbody-content">
      <Grid
        data={orderBy(props.data.slice(page.skip, page.take + page.skip), sort)}
        skip={page.skip}
        take={page.take}
        total={props.data.length}
        sortable={true}
        sort={sort}
        onSortChange={(e) => {
          setSort(e.sort);
        }}
        pageable={{
          buttonCount: 4,
          pageSizes: [5, 10, 15, "All"],
          pageSizeValue: pageSizeValue,
          info: false,
          pageSizes: false,
        }}
        pageSize={false}
        info={false}
        pageSizes={false}
        onPageChange={pageChange}
      >
        <GridColumn field="name" title="Name" width={200} />
        <GridColumn field="url" title="Image" cell={(itemProps)=>(
          <td className="k-table-td">
            <img src={itemProps.dataItem.url} width={100} height={100}/>
          </td>
        )}/>
        <GridColumn
          width={150}
          sortable={false}
          field="Action"
          cell={(props) => (
            <td className="k-table-td">
              <DropDownList
                size={"large"}
                fillMode={"solid"}
                rounded={"large"}
                className="action-btn-dropdown"
                data={["View Details", "Delete"]}
                valueRender={valueRender}
                itemRender={itemRender}
                defaultValue={"Action"}
                onChange={(e) => onItemActionChange(e, props.dataItem)}
                style={{
                  fontSize: "15px",
                }}
              />
            </td>
          )}
        />
      </Grid>
    </div>
  );
};
