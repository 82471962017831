import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { Dialog,DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Input } from "@progress/kendo-react-inputs";
import { TextBox } from "@progress/kendo-react-inputs";

import "../../../../components/dialog/notification.css";

export const DepotSearch= (props) => {
    let {search} = useLocation();
    const query = new URLSearchParams(search);
    const [searchName,setSearchTitle] = useState("");
    const [searchAcronym,setSearchAcronym] = useState("");
    const [searchParentDepot,setSearchParentDepot] = useState("");
    useEffect(() => {
        if(query.get("name")){
            setSearchTitle(query.get("name"));            
        }
        if(query.get("acronym")){
            setSearchAcronym(query.get("acronym"));
        }
        if(query.get("parent_depot")){
            setSearchParentDepot(query.get("parent_depot"));
        }
    },[])
    const onhandleSearch = ()=>{
        const data = {
            name : searchName? searchName: "",
            acronym : searchAcronym? searchAcronym: "",
            parent_depot : searchParentDepot? searchParentDepot: "",
        }
        console.log("Contact Search",data);
        props.onSearchcallback(data);
    }
    const handleSearchName = (e)=>{
        setSearchTitle(e.target.value);
    }
    const handleSearchCreator = (e)=>
        setSearchAcronym(e.target.value);
    const handleSearchParentDepot = (e)=>
        setSearchParentDepot(e.target.value);
    return (
        <>
        <Dialog
            className="search-notification"
            title={" "}
            // width={500}
            height={"100%"}
            onClose={()=> props.setShowSearchDialog(false)}
            >
            <div className="search-body">
                <div className="noti-dialog-title dialog-row">Search</div>
                <div className="dialog-row">
                    <span className="search-row-title">By Depot / Sub Depot Name</span>
                    <TextBox 
                        className="search-input" 
                        value={searchName}
                        onChange={handleSearchName}
                        suffix={()=>(
                            <Button className="clear-button-dialog" onClick={()=> setSearchTitle("")}>X</Button>
                        )}
                    />
                </div>
                <div className="dialog-row" style={{height:"80%"}}>
                    <span className="search-row-title">By Acronym</span>
                    <TextBox 
                        className="search-input" 
                        onChange={handleSearchCreator} 
                        value={searchAcronym}
                        suffix={()=>(
                            <Button className="clear-button-dialog" onClick={()=> setSearchAcronym("")}>X</Button>
                        )}/>
                </div>
                <div className="dialog-row" style={{height:"80%"}}>
                    <span className="search-row-title">By Parent Depot</span>
                    <TextBox 
                        className="search-input" 
                        onChange={handleSearchParentDepot} 
                        value={searchParentDepot}
                        suffix={()=>(
                            <Button className="clear-button-dialog" onClick={()=> setSearchParentDepot("")}>X</Button>
                        )}/>
                </div>
            </div>
            <DialogActionsBar>
                <Button className="search-button cancel-btn" onClick={()=> props.setShowSearchDialog(false)}>Cancel</Button>
                <Button className="search-button search-btn" onClick={onhandleSearch}>Submit</Button>
            </DialogActionsBar>
        </Dialog>        
        </>
        
    );
  };