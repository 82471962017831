import React from "react";
// kendo
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
// icon
import CrossIcon from "../../../../assets/dr_safe/cross_gray_icon.png";
// css
import "./dr_secure_details.css";

const DrSecureDetailsStatusDialog = ({
  onToggleDialog,
  incidentStatuses,
  currentIncidentStatus,
  onChangeIncidentStatus,
  onSaveIncidentStatus,
}) => {
  return (
    <Dialog>
      <div className="dr-secure-details-status-dialog">
        <div
          style={{
            position: "absolute",
            top: "15px",
            right: "13px",
            cursor: "pointer",
          }}
          onClick={onToggleDialog}
        >
          <img src={CrossIcon} />
        </div>
        <div className="dr-secure-details-status-dialog-title">Filter</div>
        <div className="dr-secure-details-status-dialog-dropdown">
          {/* <DropDownList
            data={incidentStatuses}
            value={currentIncidentStatus}
            onChange={onChangeIncidentStatus}
            className="dr-secure-details-status-dialog-dropdown-value"
            style={{
              width: "100%",
            }}
          /> */}
          <select onChange={onChangeIncidentStatus} className='dr-secure-details-status-dialog-dropdown-value select-case-dropdown'
            style={{
                width: "100%",
              }}>
                  <option key={'currentIncidentStatus'} value={'currentIncidentStatus'}>{currentIncidentStatus}</option>
                  {incidentStatuses.map(
                      (item,i) => (
                          <option>{item}</option>
                      )
                  )}
          </select>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            className="dr-secure-details-status-dialog-button"
            onClick={onSaveIncidentStatus}
          >
            Save
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default DrSecureDetailsStatusDialog;
