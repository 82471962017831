import React, { useState, useEffect } from "react";
// redux
import { useDispatch, useSelector } from "react-redux";
import { fetchAllApprovers } from "../../store/categoryUserList";
// kendo
import { Checkbox } from "@progress/kendo-react-inputs";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Loader } from "@progress/kendo-react-indicators";
// css
import "./dialog.css";

export const ApproverListDialog = (props) => {
  const dispatch = useDispatch();
  const [selectedApprovers, setSelectedApprovers] = useState(
    props.defaultSelected
  );
  const { approvers_loading, approvers, approvers_error } = useSelector(
    (state) => state.categoryUserList
  );
  useEffect(() => {
    dispatch(fetchAllApprovers());
  }, []);
  const handleSelected = (e, val) => {
    var updatedList = [...selectedApprovers];
    if (e.target.value) {
      updatedList = [...selectedApprovers, val];
    } else {
      var selected_index = selectedApprovers.map((v) => v.id).indexOf(val.id);
      updatedList.splice(selected_index, 1);
    }
    setSelectedApprovers(updatedList);
  };
  const handleSubmitApprovers = () => {
    let newSelectedApprovers = approvers.filter((v1)=> selectedApprovers.some((v2)=> v1.username === v2.username));
    console.log(newSelectedApprovers);
    props.callback(newSelectedApprovers);
  };
  const isChecked = (item) =>
    selectedApprovers.map((v) => v.username).includes(item) ? true : false;
  return (
    <Dialog
      title={`Please select approver(s): ${selectedApprovers.length} selected`}
      width={500}
      height={500}
      onClose={props.onClickNo}
      className="sender-dialog"
    >
      <div className="sender-list-div">
        {approvers_loading ? (
          <Loader
            type="infinite-spinner"
            style={{
              display: "flex",
              alignItems: "center",
              width: "40px",
              margin: "auto",
              justifyContent: "center",
              height: "350px",
            }}
          />
        ) : (
          approvers.map((v) => (
            <Checkbox
              label={v.name}
              onChange={(e) => handleSelected(e, v)}
              checked={isChecked(v.username)}
            />
          ))
        )}
      </div>
      <DialogActionsBar>
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base sender-cancel-button"
          onClick={props.onClickNo}
        >
          No
        </button>
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base sender-submit-button"
          onClick={handleSubmitApprovers}
        >
          Yes
        </button>
      </DialogActionsBar>
    </Dialog>
  );
};
