import React, { useState, useEffect} from "react";
import { useDispatch,useSelector } from "react-redux";
import { Dialog,DialogActionsBar } from "@progress/kendo-react-dialogs";
import { fetchAllCategories } from "../../store/category";
import { fetchAllDepot } from "../../store/depot";

import { Checkbox } from "@progress/kendo-react-inputs";
import "./dialog.css";

export const DepotListDialog = (props) => {
    const dispatch = useDispatch();
    const [selectedDepot,setSelectedDepot] = useState(props.defaultSelected);
    const {loading,depot_ids,error} = useSelector((state)=>state.depot);
    useEffect(()=>{
        dispatch(fetchAllDepot());
    },[])
    const handleSelected = (e,val)=>{
        var updatedList = [...selectedDepot];
        if (e.target.value) {
          updatedList = [...selectedDepot, val];
        } else {
          var selected_index = selectedDepot.map((v)=>v.id).indexOf(val.id)
          updatedList.splice(selected_index, 1);
        }
        setSelectedDepot(updatedList);
    }
    const handleSubmitDepots = ()=>{
        props.callback(selectedDepot);
    }
    const isChecked = (item) =>
        selectedDepot.map((v)=>v.name).includes(item) ? true:false;
    return (
        <Dialog 
            title={`Please select depot(s): ${selectedDepot.length} selected`} 
            width={500}
            height={500}
            onClose={props.onClickNo}
            className="depot-dialog"
            >
                <div className="depot-list-div">
                    {depot_ids.map((v)=>(
                        <Checkbox label={v.name} onChange={(e)=>handleSelected(e,v)} checked={isChecked(v.name)} />
                    ))}
                </div>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base depot-cancel-button"
              onClick={props.onClickNo}
            >
              No
            </button>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base depot-submit-button"
              onClick={handleSubmitDepots}
            >
              Yes
            </button>
          </DialogActionsBar>
        </Dialog>
    );
  };