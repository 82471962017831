import React, { useState, useEffect } from "react";
// redux
import { useNavigate } from "react-router";
// component
import { SenderListDialog } from "../../../../../components/dialog/senderListDialog";
import { ApproverListDialog } from "../../../../../components/dialog/approverListDialog";
import { CategoryListDialog } from "../../../../../components/dialog/categoryListDialog";
// kendo
import { Button } from "@progress/kendo-react-buttons";
import { TextBox } from "@progress/kendo-react-inputs";
// routes
import { ADMIN_TOOLS_ROLE_LIST } from "../../../../../routes";

export const CommRoleForm = ({ data, isEdit, submitForm, setLoading }) => {
  // redux
  const navigate = useNavigate();
  // state
  const [name, setName] = useState(null);
  const [senders, setSenders] = useState([]);
  const [approvers, setApprovers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [showSenderList, setShowSenderList] = useState(false);
  const [showApproverList, setShowApproverList] = useState(false);
  const [showCategoryList, setShowCategoyList] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);

  // methods
  const senderSelectedCallback = (data) => {
    setSenders(data);
    setShowSenderList(false);
  };

  const approverSelectedCallback = (data) => {
    setApprovers(data);
    setShowApproverList(false);
  };

  const categorySelectedCallback = (data) => {
    setCategories(data);
    setShowCategoyList(false);
  };

  const cancelForm = () => {
    console.log(data);
    if (data & isEdit) {
      // setIsEdit(true);
    } else {
      navigate(ADMIN_TOOLS_ROLE_LIST);
    }
  };

  const submitData = () => {
    setLoading(true);
    let submit_data = {
      name,
      sender_user_list: senders,
      approver_user_list: approvers,
      category_list: categories.map((x) => x.id),
    };
    if (data && data.id) {
      submit_data["id"] = data.id;
    }
    submitForm(submit_data);
  };

  // useEffect
  useEffect(() => {
    console.log("Group Details data:", data);
    if (data) {
      setName(data.name);
      setSenders(data.sender_user_list);
      setApprovers(data.approver_user_list);
      setCategories(data.category_list);
    }
  }, []);

  useEffect(() => {
    if (
      name &&
      senders.length > 0 &&
      approvers.length > 0 &&
      categories.length > 0
    ) {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  }, [name, senders, approvers, categories]);
  console.log(senders);
  return (
    <>
      {showSenderList && (
        <SenderListDialog
          callback={senderSelectedCallback}
          defaultSelected={senders}
          onClickNo={() => setShowSenderList(false)}
        />
      )}
      {showApproverList && (
        <ApproverListDialog
          callback={approverSelectedCallback}
          defaultSelected={approvers}
          onClickNo={() => setShowApproverList(false)}
        />
      )}
      {showCategoryList && (
        <CategoryListDialog
          callback={categorySelectedCallback}
          defaultSelected={categories}
          onClickNo={() => setShowCategoyList(false)}
        />
      )}
      <table>
        <tr>
          <td>Role Name</td>
          {isEdit ? (
            <td>
              <TextBox value={name} onChange={(e) => setName(e.target.value)} />
            </td>
          ) : (
            <td>{data.name}</td>
          )}
        </tr>
        <tr>
          <td>List of Category</td>
          <td>
            {isEdit ? (
              <div className="role-edit-permission-layout">
                <Button onClick={() => setShowCategoyList(true)}>
                  Click here to update the category(s)
                </Button>
                {categories
                  ? categories.map((v) => (
                      <div className="role-permission-item">{v.name}</div>
                    ))
                  : null}
              </div>
            ) : (
              <div className="role-edit-permission-layout">
                {data.category_list
                  ? data.category_list.map((v) => (
                      <div className="role-permission-item">{v.name}</div>
                    ))
                  : null}
              </div>
            )}
          </td>
        </tr>
        <tr>
          <td>List of Sender</td>
          <td>
            {isEdit ? (
              <div className="role-edit-permission-layout">
                <Button onClick={() => setShowSenderList(true)}>
                  Click here to update the sender(s)
                </Button>
                {senders
                  ? senders.map((v) => (
                      <div className="role-permission-item">{v.name}</div>
                    ))
                  : null}
              </div>
            ) : (
              <div className="role-edit-permission-layout">
                {data.sender_user_list
                  ? data.sender_user_list.map((v) => (
                      <div className="role-permission-item">{v.name}</div>
                    ))
                  : null}
              </div>
            )}
          </td>
        </tr>
        <tr>
          <td>List of Approver</td>
          <td>
            {isEdit ? (
              <div className="role-edit-permission-layout">
                <Button onClick={() => setShowApproverList(true)}>
                  Click here to update the approver(s)
                </Button>
                {approvers
                  ? approvers.map((v) => (
                      <div className="role-permission-item">{v.name}</div>
                    ))
                  : null}
              </div>
            ) : (
              <div className="role-edit-permission-layout">
                {data.approver_user_list
                  ? data.approver_user_list.map((v) => (
                      <div className="role-permission-item">{v.name}</div>
                    ))
                  : null}
              </div>
            )}
          </td>
        </tr>
        {isEdit && (
          <tr>
            <td colSpan={2}>
              <div className="role-detail-button-div">
                <Button
                  className="role-detail-button role-detail-cancel"
                  onClick={cancelForm}
                >
                  Cancel
                </Button>
                <Button
                  className="role-detail-button role-detail-submit"
                  onClick={submitData}
                  disabled={disableSubmit}
                >
                  Submit
                </Button>
              </div>
            </td>
          </tr>
        )}
      </table>
    </>
  );
};
