import React, { useState, useEffect, useRef } from "react";
import {useDispatch} from "react-redux";
import { TextBox, RadioButton, InputPrefix } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { ColorPicker, ColorPickerChangeEvent,  } from "@progress/kendo-react-inputs";
import api from "../../../../config/api";
import config from "../../../../config/config";
import { UserListDialog } from "../../../../components/dialog/userListDialog";
import { fetchAllDepots, fetchAllSubDepots } from '../../../../store/telephoneDirectory';
import { TELEPHONE_DIRECTORY_DEPOT_LIST } from "../../../../routes";
import { useNavigate } from "react-router";
import "../../telephone_directory.css";
// import '@progress/kendo-theme-default/dist/all.css';

export const DepotForm = ({
    data,
    isEdit,
    isCreate,
    setIsEdit,
    submitForm
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [parentDepot, setParentDepot] = useState(null);
    const [childDepot, setChildDepot] = useState(null);
    const [acronym, setAcronym] = useState(null);
    const [depotColor, setDepotColor] = useState("");
    const [parentDepotsList, setparentDepotsList] = useState([]);
    // const [depotColor, setDepotColor] = React.useState("rgba(237, 126, 50, 1)");
    // const [depotColor, setDepotColor] = useState('#000000');
    const [is_general, setIsGeneral] = useState(false);
    const [is_emergency, setIsEmergency] = useState(false);
    const [occ, setOCC] = useState(false);
    console.log('Data--',data);
    useEffect(() => {
        if (data) {
            if ('parent_depot' in data){
                setParentDepot(data.parent_depot);
            }
            else{setParentDepot("Select Parent Depot");}
            setChildDepot(data.depot);
            setAcronym(data.acronym);
            setIsEmergency(data.is_emergency);
            setIsGeneral(data.is_general);
            setOCC(data.occ);
            setDepotColor(data.depot_color);
        }
        dispatch(fetchAllDepots()).then ((res) =>{
            console.log("All Depots>>>>",res.payload);
            setparentDepotsList(res.payload)});
    }, [])

    const submitContact = () => {
        console.log('Submitted',parentDepot);
        // if (parentDepot === 'Select Parent Depot'){
        //     console.log('<<<<<<<<<parentDepot>>>>');
        //     setParentDepot(null);
        // }
        let data = {
            depot:parentDepot,
            child_depot:childDepot,
            acronym,
            depot_color:depotColor ,
            
        }
        console.log('Submitted DATA----',data);
        submitForm(data)
    }
    const cancelForm = ()=>{
        if(data && isCreate){
            setIsEdit(false);
        }else{
            navigate(TELEPHONE_DIRECTORY_DEPOT_LIST);
        }
    }
    // const handleOnChange = (event: ColorPickerChangeEvent) =>{
    //     console.log('COLOR PICKER HANDLE----',event.target.value);
    //     setDepotColor(event.value);
    // }
    const handleOnChange = (e) =>{
        console.log('COLOR PICKER HANDLE----',e);
        setDepotColor(e.value);
    };
    
    return (
        <div>
        <div className="details-info">
            <div className="details-info-left">
            <div className="details-info__grp">
                    <div>Depot/ Sub Depot Name<span style={{color:"red"}}>*</span></div>                    
                    <div><TextBox value={childDepot} placeholder="Enter Depot / Sub Depot Name" onChange={(e)=> setChildDepot(e.target.value)}/></div>
                    
                </div>
                <div className="details-info__grp">
                    <div>Parent Depot</div>
                    <select  className='details-info--dropdown-value select-case-dropdown'
                    onChange={(e)=> setParentDepot(e.target.value)}
                        style={{
                            width: "100%",
                          }}>
                            
                            {isEdit || isCreate ? (<option key={'Select Parent Depot'} value={'Select Parent Depot'}>Select Parent Depot</option>):(<option key={'default'} value={parentDepot}>{parentDepot}</option>)}
                            
                            {parentDepotsList ? parentDepotsList.map(
                                ({ id, depot }) => (
                                    <option key={id} value={depot}>{depot}</option>
                                )
                            ):null}
                        </select>
                </div>
                
            </div>
            <div className="details-info-right">
            
                
                <div className="details-info__grp">
                    <div>Acronym <span style={{color:"red"}}>*</span></div>
                    <div>
                    <div><TextBox value={acronym} placeholder="Enter Acronym" onChange={(e)=> setAcronym(e.target.value)}/></div>
                    </div>
                </div>
                {isCreate ? (<div className="details-info__grp">
                    <div>Depot Color</div>
                    <div>
                        {/* <ColorPicker value={depotColor} onChange={handleOnChange} /> */}
                    <div><TextBox value={depotColor} placeholder={'FFFFFF'} onChange={(e)=> setDepotColor(e.target.value)}/></div>
                    </div>
                </div>):null}
                
                
            </div>
            
        </div>
        <div>
                {isEdit && (
                    <div>
                        <div colSpan={2}>
                            <div className="detail-button-div">
                                <Button className="detail-button detail-cancel" onClick={cancelForm}>Cancel</Button>
                                <Button className="detail-button detail-submit" onClick={submitContact}>Submit</Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};