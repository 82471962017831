import React, { useEffect, useRef, useState } from "react";
import "./promotions.css";
import { TextArea, TextBox } from "@progress/kendo-react-inputs";
import { Stepper } from "@progress/kendo-react-layout";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import DatePicker from "react-datepicker";
import { CustomDatePicker } from "../../../components/custom/datepicker";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategoryListing } from "../../../store/admin_articles/manage_Category";
import { HAPPENINGS_PROMOTIONS_ROUTE } from "../../../routes";
import moment from "moment";
import { Loader } from "@progress/kendo-react-indicators";
import { useLocation, useNavigate } from "react-router";
import {
  fetchPromotionCreate,
  fetchPromotionDetail,
  fetchPromotionUpdate,
} from "../../../store/happenings/promotion";
import config from "../../../config/config";
import { getCurrentLoggedInSessionTokenWithPromise } from "../../../config/userPool";
import api from "../../../config/api";
import { ShowDialog } from "../../../components/reusableItems/dialog";
import {
  checkPermission,
  getUserPermission,
} from "../../../config/permission_helper";
import { PermissionDeninedPage } from "../../../components/reusableItems/permission_denined";

const steps = [
  {
    label: "Information",
    value: 0,
  },
  {
    label: "Review",
    value: 1,
  },
];

export const Promotion_Form = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const id = query.get("id");
  const duplicateID = query.get("duplicateID");
  const FileRef = useRef();
  const DocRef = useRef();
  const dispatch = useDispatch();
  const [mainLoading, setMainLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const [file, setFile] = useState("");
  const [doc, setDoc] = useState([]);
  const [imageLoading, setImageLoading] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [editData, setEditData] = useState({
    title: "",
    title_zh: "",
    category: "",
    description: "",
    description_zh: "",
    start_date: "",
    end_date: "",
    published_on: "",
    published_end: "",
    image: "",
    document_upload: [],
  });
  const [error, setError] = useState({
    title: "",
    // title_zh: "",
    category: "",
    start_date: "",
    end_date: "",
    published_on: "",
    published_end: "",
  });
  const { loading, listing_data } = useSelector((state) => state.categoryAdmin);
  const { detail_data } = useSelector((state) => state.promotion);
  const { HAPPENING_PROMOTION_UPLOAD_ENDPOINT } = config.api_endpoint;
  const userPermissions = getUserPermission();
  const AddPermssion = checkPermission(
    userPermissions,
    "happening.create_happening_promotion"
  );

  useEffect(() => {
    dispatch(fetchCategoryListing());
    if (id || duplicateID) {
      let findID = id ? id : duplicateID;
      setMainLoading(true);
      dispatch(fetchPromotionDetail(findID)).then((res) => {
        if (res.payload.status_code === 200) {
          setEditData({
            ...editData,
            image:
              res.payload.data && res.payload.data.image
                ? res.payload.data.image
                : "",
            title:
              res.payload.data && res.payload.data.title
                ? res.payload.data.title
                : "",
            title_zh:
              res.payload.data && res.payload.data.title_zh
                ? res.payload.data.title_zh
                : "",
            category:
              res.payload.data && res.payload.data.category
                ? res.payload.data.category
                : "",
            description:
              res.payload.data && res.payload.data.description
                ? res.payload.data.description
                : "",
            description_zh:
              res.payload.data && res.payload.data.description_zh
                ? res.payload.data.description_zh
                : "",
            start_date:
              res.payload.data &&
              moment(res.payload.data.start_date.split("T")[0]).format(
                "YYYY-MM-DD"
              ),
            end_date:
              res.payload.data &&
              moment(res.payload.data.end_date.split("T")[0]).format(
                "YYYY-MM-DD"
              ),
            published_on:
              res.payload.data && res.payload.data.published_on
                ? moment(res.payload.data.published_on.split("T")[0]).format(
                  "YYYY-MM-DD"
                )
                : "",
            published_end:
              res.payload.data && res.payload.data.published_end
                ? moment(res.payload.data.published_end.split("T")[0]).format(
                  "YYYY-MM-DD"
                )
                : "",
            document_upload:
              res.payload.data && res.payload.data.document_upload
                ? res.payload.data.document_upload
                : [],
          });
          setMainLoading(false);
        } else {
          setMainLoading(false);
          alert(res.payload.message);
        }
      });
    }
  }, []);

  const uploadFile = () => {
    FileRef.current.click();
  };

  const docUpload = () => {
    DocRef.current.click();
  };

  const firstTabSubmit = () => {
    let error = null;
    if (
      new Date().setHours(0, 0, 0, 0) > new Date(editData.start_date) ||
      new Date().setHours(0, 0, 0, 0) > new Date(editData.end_date)
    ) {
      error = {
        ...error,
        start_date: "Start date cannot be earlier than today date!",
        end_date: "End date cannot be earlier than today date!",
      };
    }
    if (editData.start_date === "" || editData.end_date === "") {
      error = {
        ...error,
        start_date: "Start date field is required!",
        end_date: "End date field is required!",
      };
    } else if (new Date(editData.start_date) > new Date(editData.end_date)) {
      error = {
        ...error,
        start_date: "Start date should not be later than End date!",
        end_date: "End date should not be earlier than Start date!",
      };
    } else if (editData.start_date === editData.end_date) {
      error = {
        ...error,
        start_date: "Start date and End date should not be the same!",
        end_date: "Start date and End date should not be the same!",
      };
    }
    if (
      (editData.published_on === "" && editData.published_end !== "") ||
      (editData.published_on !== "" && editData.published_end === "") ||
      (editData.published_on !== "" && editData.published_end !== "")
    ) {
      if (
        new Date().setHours(0, 0, 0, 0) > new Date(editData.published_on) ||
        new Date().setHours(0, 0, 0, 0) > new Date(editData.published_end)
      ) {
        error = {
          ...error,
          published_on: "Publish On date cannot be earlier than today date!",
          published_end: "Publish End date cannot be earlier than today date!",
        };
      }
      if (editData.published_on === "" || editData.published_end === "") {
        error = {
          ...error,
          published_on: "Publish On date field is required!",
          published_end: "Publish End date field is required!",
        };
      } else if (
        new Date(editData.published_on) > new Date(editData.published_end)
      ) {
        error = {
          ...error,
          published_on:
            "Publish On date should not be later than Publish End date!",
          published_end:
            "Publish End date should not be earlier than Publish On date!",
        };
      } else if (editData.published_on === editData.published_end) {
        error = {
          ...error,
          published_on:
            "Publish On date and Publish End date should not be the same!",
          published_end:
            "Publish On date and Publish End date should not be the same!",
        };
      }
    }
    if (editData.title === "") {
      error = {
        ...error,
        title: "Title in English field is required!",
      };
    }
    // if (editData.title_zh === "") {
    //   error = {
    //     ...error,
    //     title_zh: "Title in Chinese field is required!",
    //   };
    // }
    if (editData.category === "") {
      error = {
        ...error,
        category: "Category field is required!",
      };
    }
    if (error !== null) {
      setError(error);
      return;
    }
    setError({
      ...error,
      title: "",
      category: "",
      start_date: "",
      end_date: "",
      published_on: "",
      published_end: "",
    });
    setTab(tab + 1);
  };

  const handleSubmit = (status) => {
    setMainLoading(true);
    if ((id && detail_data.status === "Published") || status === "Published") {
      if (editData.published_on === "" || editData.published_end === "") {
        setShowValidation(true);
        setMainLoading(false);
        return;
      } else if (
        new Date(editData.published_on) > new Date(editData.published_end)
      ) {
        setShowValidation(true);
        setMainLoading(false);
        return;
      } else if (
        new Date(editData.published_on) === new Date(editData.published_end)
      ) {
        setShowValidation(true);
        setMainLoading(false);
        return;
      }
    }
    let params = id
      ? {
        ...editData,
        status: detail_data.status,
        id: id,
        image:
          file === ""
            ? editData.image.includes("http")
              ? getFilePath(editData.image)
              : editData.image
            : editData.image,
        document_upload:
          Array.isArray(editData.document_upload)
            ? editData.document_upload.map((url) =>
              url.includes("http")
                ? getFilePath(url)
                : url
            )
            : []
      }
      : duplicateID
        ? {
          ...editData,
          status: status,
          image:
            file === ""
              ? editData.image.includes("http")
                ? getFilePath(editData.image)
                : editData.image
              : editData.image,
          document_upload:
            Array.isArray(editData.document_upload)
              ? editData.document_upload.map((url) =>
                url.includes("http")
                  ? getFilePath(url)
                  : url
              )
              : []
        }
        : {
          ...editData,
          status: status,
        };
      
    if (id) {
      dispatch(fetchPromotionUpdate(params)).then((res) => {
        if (res.payload.status_code === 200) {
          navigate(HAPPENINGS_PROMOTIONS_ROUTE);
        } else {
          setMainLoading(false);
          alert(res.payload.message);
        }
      });
    } else {
      dispatch(fetchPromotionCreate(params)).then((res) => {
        console.log(res);
        if (res.payload.status_code === 200) {
          navigate(HAPPENINGS_PROMOTIONS_ROUTE);
        } else {
          setMainLoading(false);
          alert(res.payload.message);
        }
      });
    }
  };

  function getFilePath(url) {
    // Match everything between the domain and the query string
    const match = url.match(/https:\/\/[^/]+\/(.+?)\?/);
    return match ? match[1] : url;
  }

  const ImageHandler = () => {
    let url =
      id || duplicateID
        ? file === ""
          ? editData.image !== "" && editData.image
          : URL.createObjectURL(file)
        : file !== "" && URL.createObjectURL(file);
    let name =
      id || duplicateID
        ? file === ""
          ? editData.image !== "" && editData.image.split("/")[4].split("?")[0]
          : file.name
        : file !== "" && file.name;
    return (
      <div className="Promotions_create_file_main_box">
        <div className="Promotions_create_file_child_box">
          <div>
            <img src={url} className="Promotions_create_file_image" />
          </div>
          <div className="Promotions_create_file_child_box_text">{name}</div>
        </div>
        <div>
          <span
            class="k-icon k-font-icon k-i-delete Promotions_create_file_child_box_delete_icon"
            onClick={() => {
              setFile("");
              setEditData({
                ...editData,
                image: "",
              });
            }}
          />
        </div>
      </div>
    );
  };

  const DocHandler = () => {

    const documents = Array.isArray(editData.document_upload)
      ? editData.document_upload
      : [editData.document_upload];

    return (
      <div className="Promotions_create_file_main_container">
        {documents.map((docUrl, index) => {
          const name = docUrl ? docUrl.split("/").pop().split("?")[0] : "Unknown Document";
          return (
            <div className="Promotions_create_file_main_box">
              <div key={index} className="Promotions_create_file_child_box">
                <div className="Promotions_create_file_child_box_text">{name}</div>

              </div>
              <span
                className="k-icon k-font-icon k-i-delete Promotions_create_file_child_box_delete_icon"
                onClick={() => handleDelete(index)}
              />
            </div>
          );
        })}
      </div>
    );
  };

  // Delete handler for removing a specific document
  const handleDelete = (index) => {
    const updatedDocs = [...editData.document_upload];
    updatedDocs.splice(index, 1);
    setEditData({ ...editData, document_upload: updatedDocs });
  };


  const onUploadFile = async (e) => {
    setImageLoading(true);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    const extension_ =
      e.type === "" ? e.name.split(".")[1] : e.type.split("/")[1];
    api
      .post(
        HAPPENING_PROMOTION_UPLOAD_ENDPOINT,
        JSON.stringify({
          extensions: [extension_],
        }),
        token
      )
      .then((res) => {
        console.log(res);
        api.postDiff(res.data[0].presigned_url, e).then((res1) => {
          console.log(res1);
          if (res1 === 200) {
            setFile(e);
            setEditData({
              ...editData,
              image: res.data[0].key,
            });
            setImageLoading(false);
          } else {
            alert("An error occured while uploading file! Please try again!");
          }
        });
      });
  };

  const onUploadDoc = async (files) => {
    setImageLoading(true);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    console.log(files);
    // Extract file extensions from all selected files
    const extensions = Array.from(files).map((file) => file.name.split('.').pop());

    api
      .post(
        HAPPENING_PROMOTION_UPLOAD_ENDPOINT,
        JSON.stringify({
          extensions: extensions,
        }),
        token
      )
      .then((res) => {
        const uploadPromises = [];

        // Use forEach to handle each presigned URL and file upload
        res.data.forEach((fileData, index) => {
          const file = files[index];
          const uploadPromise = api.postDiff(fileData.presigned_url, file);
          uploadPromises.push(uploadPromise);
        });

        // Await all uploads to complete
        Promise.all(uploadPromises)
          .then((responses) => {
            setDoc(files);
            const keys = res.data.map((fileData) => fileData.key);

            setEditData((prevData) => ({
              ...prevData,
              document_upload: [...prevData.document_upload, ...keys], // Store array of keys
            }));
            setImageLoading(false);
          })
          .catch(() => alert("An error occurred during file upload!"));
      });
  };

  const EditForm = () => {
    setShowValidation(false);
    setTab(tab - 1);
  };
  console.log(editData, listing_data, detail_data);
  return (
    <>
      {AddPermssion ? (
        <>
          {showValidation && (
            <ShowDialog
              title={"Please edit the form accordingly!"}
              content={
                "Scheduled/Published On and Scheduled/Published End cannot be empty when published!"
              }
              cancelContent={"Cancel"}
              confrimContent={"Edit"}
              cancelbtn={() => setShowValidation(false)}
              confrinbtn={EditForm}
            />
          )}
          {imageLoading && (
            <Loader
              type="converging-spinner"
              style={{
                width: "100%",
                height: "calc(100% - 76px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#ffffff4d",
                position: "absolute",
                zIndex: "999",
              }}
            />
          )}
          {loading || mainLoading ? (
            <Loader
              type="converging-spinner"
              style={{
                width: "100%",
                height: "calc(100% - 96px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#ffffff4d",
                position: "absolute",
                zIndex: "999",
              }}
            />
          ) : (
            <>
              <div
                className="Promotion_create_header"
                //remove style if autosave needs to show
                style={{ minHeight: "49px" }}
              >
                <div
                  className="Promotion_create_back"
                  onClick={() => navigate(HAPPENINGS_PROMOTIONS_ROUTE)}
                >
                  <span class="k-icon k-font-icon k-i-arrow-chevron-left" />
                  Back
                </div>
              </div>
              <div>
                <Stepper
                  items={steps}
                  value={tab}
                  className="Promotion_steps"
                />
              </div>
              {tab === 0 ? (
                <div className="Promotion_create_scroll_box">
                  <div>
                    <div className="Promotion_create_item_main_box">
                      <div className="Promotion_create_item_main_box_title">
                        Title in English
                      </div>
                      <div>
                        <TextBox
                          className="Promotion_create_item_main_box_field"
                          value={editData.title}
                          onChange={(e) =>
                            setEditData({
                              ...editData,
                              title: e.target.value,
                            })
                          }
                        />
                        {error.title !== "" && (
                          <div className="Promotion_create_error_text">
                            {error.title}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="Promotion_create_item_main_box">
                      <div className="Promotion_create_item_main_box_title">
                        Title in Chinese
                      </div>
                      <div>
                        <TextBox
                          className="Promotion_create_item_main_box_field"
                          value={editData.title_zh}
                          onChange={(e) =>
                            setEditData({
                              ...editData,
                              title_zh: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="Promotion_create_item_main_box">
                      <div className="Promotion_create_item_main_box_title">
                        Description in English
                      </div>
                      <div>
                        <TextArea
                          className="Promotion_create_item_main_box_field"
                          style={{ minHeight: "150px" }}
                          value={editData.description}
                          onChange={(e) =>
                            setEditData({
                              ...editData,
                              description: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="Promotion_create_item_main_box">
                      <div className="Promotion_create_item_main_box_title">
                        Description in Chinese
                      </div>
                      <div>
                        <TextArea
                          className="Promotion_create_item_main_box_field"
                          style={{ minHeight: "150px" }}
                          value={editData.description_zh}
                          onChange={(e) =>
                            setEditData({
                              ...editData,
                              description_zh: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="Promotion_create_item_main_box">
                      <div className="Promotion_create_item_main_box_title">
                        Category
                      </div>
                      <div className="Promotion_create_dropdown">
                        <DropDownList
                          data={listing_data.length > 0 ? listing_data : []}
                          value={
                            listing_data.length > 0 &&
                            listing_data.find(
                              (i) => i.category === editData.category
                            )
                          }
                          dataItemKey="category"
                          textField="category"
                          size={"large"}
                          onChange={(e) =>
                            setEditData({
                              ...editData,
                              category: e.target.value.category,
                            })
                          }
                          iconColor="#73007D"
                          iconClassName="k-icon k-font-icon k-i-arrow-chevron-down"
                        />
                        {error.category !== "" && (
                          <div className="Promotion_create_error_text">
                            {error.category}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="Promotion_create_item_main_box">
                      <div className="Promotion_create_item_main_box_title">
                        Publish Rule
                      </div>
                      <div className="Promotion_create_date_main_box">
                        <div className="Promotion_create_date_child_box">
                          <div className="Promotion_create_date_child_box_title">
                            Start Date
                          </div>
                          <div className="Promotion_create_date_child_box_field_box">
                            <div className="Promotion_create_date_child_box_field_box_item">
                              <DatePicker
                                dateFormatCalendar="MMMM"
                                customInput={<CustomDatePicker />}
                                minDate={new Date()}
                                selected={
                                  editData.start_date !== ""
                                    ? new Date(editData.start_date)
                                    : ""
                                }
                                onChange={(e) =>
                                  setEditData({
                                    ...editData,
                                    start_date: moment(e).format("YYYY-MM-DD"),
                                  })
                                }
                              />
                            </div>
                          </div>
                          {error.start_date !== "" && (
                            <div className="Promotion_create_error_text">
                              {error.start_date}
                            </div>
                          )}
                        </div>
                        <div className="Promotion_create_date_child_box">
                          <div className="Promotion_create_date_child_box_title">
                            End Date
                          </div>
                          <div className="Promotion_create_date_child_box_field_box">
                            <div className="Promotion_create_date_child_box_field_box_item">
                              <DatePicker
                                dateFormatCalendar="MMMM"
                                customInput={<CustomDatePicker />}
                                minDate={new Date()}
                                selected={
                                  editData.end_date !== ""
                                    ? new Date(editData.end_date)
                                    : ""
                                }
                                onChange={(e) =>
                                  setEditData({
                                    ...editData,
                                    end_date: moment(e).format("YYYY-MM-DD"),
                                  })
                                }
                              />
                            </div>
                          </div>
                          {error.end_date !== "" && (
                            <div className="Promotion_create_error_text">
                              {error.end_date}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="Promotion_create_item_main_box">
                      <div className="Promotion_create_item_main_box_title">
                        Publish Rules
                      </div>
                      <div className="Promotion_create_date_main_box">
                        <div className="Promotion_create_date_child_box">
                          <div className="Promotion_create_date_child_box_title">
                            Scheduled/Published On
                          </div>
                          <div className="Promotion_create_date_child_box_field_box">
                            <div className="Promotion_create_date_child_box_field_box_item">
                              <DatePicker
                                dateFormatCalendar="MMMM"
                                customInput={<CustomDatePicker />}
                                minDate={new Date()}
                                selected={
                                  editData.published_on !== ""
                                    ? new Date(editData.published_on)
                                    : ""
                                }
                                onChange={(e) =>
                                  setEditData({
                                    ...editData,
                                    published_on:
                                      moment(e).format("YYYY-MM-DD"),
                                  })
                                }
                              />
                            </div>
                          </div>
                          {error.published_on !== "" && (
                            <div className="Promotion_create_error_text">
                              {error.published_on}
                            </div>
                          )}
                        </div>
                        <div className="Promotion_create_date_child_box">
                          <div className="Promotion_create_date_child_box_title">
                            Scheduled/Published End
                            {id && detail_data.status !== "Submitted" ? (
                              ""
                            ) : (
                              <span
                                className="Apprewall_create_item_main_box_title_span Promotions_clear_date"
                                onClick={() => {
                                  setError({
                                    ...error,
                                    published_end: "",
                                    published_on: "",
                                  });
                                  setEditData({
                                    ...editData,
                                    published_end: "",
                                    published_on: "",
                                  });
                                }}
                              >
                                Clear date
                              </span>
                            )}
                          </div>
                          <div className="Promotion_create_date_child_box_field_box">
                            <div className="Promotion_create_date_child_box_field_box_item">
                              <DatePicker
                                dateFormatCalendar="MMMM"
                                customInput={<CustomDatePicker />}
                                minDate={new Date()}
                                selected={
                                  editData.published_end !== ""
                                    ? new Date(editData.published_end)
                                    : ""
                                }
                                onChange={(e) =>
                                  setEditData({
                                    ...editData,
                                    published_end:
                                      moment(e).format("YYYY-MM-DD"),
                                  })
                                }
                              />
                            </div>
                          </div>
                          {error.published_end !== "" && (
                            <div className="Promotion_create_error_text">
                              {error.published_end}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="Promotion_create_item_main_box">
                      <div className="Promotion_create_upload_main_box">
                        <div className="Promotion_create_item_main_box_title">
                          Image Upload
                          <span className="Promotion_create_item_main_box_title_span">
                            {/* (Max 1 file, no more than 5MB each) */}
                          </span>
                        </div>
                        <div className="Promotion_create_upload_button_box">
                          <div onClick={uploadFile}>
                            <div>+ Upload Image</div>
                            <input
                              accept={[
                                "image/jpeg",
                                "image/png",
                                "image/jpg",
                                //   "application/pdf",
                                //   "application/msword",
                              ]}
                              ref={FileRef}
                              type="file"
                              hidden
                              onClick={(e) => (e.target.value = "")}
                              onChange={(e) => onUploadFile(e.target.files[0])}
                            />
                          </div>
                        </div>
                      </div>
                      {id || duplicateID
                        ? editData.image !== "" && <ImageHandler />
                        : file !== "" && <ImageHandler />}
                    </div>
                    <div className="Promotion_create_item_main_box">
                      <div className="Promotion_create_upload_main_box">
                        <div className="Promotion_create_item_main_box_title">
                          Document Upload
                          <span className="Promotion_create_item_main_box_title_span">
                            {/* (Max 1 file, no more than 5MB each) */}
                          </span>
                        </div>
                        <div className="Promotion_create_upload_button_box">
                          <div onClick={docUpload}>
                            <div>+ Upload Document</div>
                            <input
                              accept={[
                                // "image/jpeg",
                                // "image/png",
                                // "image/jpg",
                                "application/pdf",
                                "application/msword",
                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                "application/vnd.ms-excel",
                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                              ]}
                              ref={DocRef}
                              type="file"
                              hidden
                              multiple
                              onClick={(e) => (e.target.value = "")}
                              onChange={(e) => onUploadDoc(e.target.files)}
                            />
                          </div>
                        </div>
                      </div>
                      {id || duplicateID
                        ? editData.document_upload && <DocHandler />
                        : doc && <DocHandler />}
                    </div>
                    {/* added */}
                  </div>
                </div>
              ) : (
                <div className="Promotion_create_scroll_box_review">
                  <div
                    className="Promotion_create_review_main_box"
                    style={{ minHeight: "55px" }}
                  >
                    <div className="Promotion_create_review_left">
                      Title in English
                    </div>
                    <div className="Promotion_create_review_middle" />
                    <div className="Promotion_create_review_right Promotion_create_review_flex">
                      {editData.title}
                    </div>
                  </div>
                  <div
                    className="Promotion_create_review_main_box"
                    style={{ minHeight: "55px" }}
                  >
                    <div className="Promotion_create_review_left">
                      Title in Chinese
                    </div>
                    <div className="Promotion_create_review_middle" />
                    <div className="Promotion_create_review_right Promotion_create_review_flex">
                      {editData.title_zh}
                    </div>
                  </div>
                  <div
                    className="Promotion_create_review_main_box"
                    style={{ minHeight: "55px" }}
                  >
                    <div className="Promotion_create_review_left">
                      Description in English
                    </div>
                    <div className="Promotion_create_review_middle" />
                    <div className="Promotion_create_review_right Promotion_create_review_flex">
                      {editData.description}
                    </div>
                  </div>
                  <div
                    className="Promotion_create_review_main_box"
                    style={{ minHeight: "55px" }}
                  >
                    <div className="Promotion_create_review_left">
                      Description in Chinese
                    </div>
                    <div className="Promotion_create_review_middle" />
                    <div className="Promotion_create_review_right Promotion_create_review_flex">
                      {editData.description_zh}
                    </div>
                  </div>
                  <div
                    className="Promotion_create_review_main_box"
                    style={{ minHeight: "55px" }}
                  >
                    <div className="Promotion_create_review_left">Category</div>
                    <div className="Promotion_create_review_middle" />
                    <div className="Promotion_create_review_right Promotion_create_review_flex">
                      {editData.category}
                    </div>
                  </div>
                  <div
                    className="Promotion_create_review_main_box"
                    style={{ minHeight: "55px" }}
                  >
                    <div className="Promotion_create_review_left">
                      Start Date
                    </div>
                    <div className="Promotion_create_review_middle" />
                    <div className="Promotion_create_review_right Promotion_create_review_flex">
                      {moment(editData.start_date).format("DD MMM YYYY")}
                    </div>
                  </div>
                  <div
                    className="Promotion_create_review_main_box"
                    style={{ minHeight: "55px" }}
                  >
                    <div className="Promotion_create_review_left">End Date</div>
                    <div className="Promotion_create_review_middle" />
                    <div className="Promotion_create_review_right Promotion_create_review_flex">
                      {moment(editData.end_date).format("DD MMM YYYY")}
                    </div>
                  </div>
                  <div
                    className="Promotion_create_review_main_box"
                    style={{ minHeight: "55px" }}
                  >
                    <div className="Promotion_create_review_left">
                      Scheduled/Published On
                    </div>
                    <div className="Promotion_create_review_middle" />
                    <div className="Promotion_create_review_right Promotion_create_review_flex">
                      {editData.published_on !== ""
                        ? moment(editData.published_on).format("DD MMM YYYY")
                        : "-"}
                    </div>
                  </div>
                  <div
                    className="Promotion_create_review_main_box"
                    style={{ minHeight: "55px" }}
                  >
                    <div className="Promotion_create_review_left">
                      Scheduled/Published End
                    </div>
                    <div className="Promotion_create_review_middle" />
                    <div className="Promotion_create_review_right Promotion_create_review_flex">
                      {editData.published_end !== ""
                        ? moment(editData.published_end).format("DD MMM YYYY")
                        : "-"}
                    </div>
                  </div>
                  <div
                    className="Promotion_create_review_main_box"
                    style={{ minHeight: "55px" }}
                  >
                    <div className="Promotion_create_review_left">
                      Image Upload
                    </div>
                    <div className="Promotion_create_review_middle" />
                    <div className="Promotion_create_review_right Promotion_create_review_flex">
                      <img
                        style={{ maxWidth: "170px", height: "120px" }}
                        src={
                          id
                            ? file === ""
                              ? editData.image !== "" && editData.image
                              : URL.createObjectURL(file)
                            : file !== "" && URL.createObjectURL(file)
                        }
                      />
                    </div>
                  </div>
                  {/* added */}
                </div>
              )}
              {tab === 0 ? (
                <div className="Promotion_create_footer_main_box">
                  {/* <div className="Promotion_create_footer_button">Save</div> */}
                  <div
                    className="Promotion_create_footer_button"
                    style={{ marginLeft: "auto" }}
                    onClick={(e) => firstTabSubmit()}
                  >
                    Next &#x2192;
                  </div>
                </div>
              ) : (
                <div className="Promotion_create_footer_main_box">
                  <div className="Promotion_create_review_main_box">
                    <div
                      className="Promotion_create_footer_button_previous"
                      onClick={() => setTab(tab - 1)}
                    >
                      Previous
                    </div>
                    <div
                      className="Promotion_create_footer_button"
                      onClick={
                        () => handleSubmit("Submitted")
                        // handleSubmit(id ? detail_data.status : "Submitted")
                      }
                    >
                      Save
                    </div>
                  </div>
                  {id ? (
                    ""
                  ) : (
                    <div
                      className="Promotion_create_footer_button_publish"
                      onClick={() => handleSubmit("Published")}
                    >
                      Publish
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <PermissionDeninedPage
          height={"calc(100% - 76px)"}
          title={"Sorry, You don't have permission to access this page!"}
        />
      )}
    </>
  );
};
