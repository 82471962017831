import React, { useState, useEffect, useRef } from "react";
import SoundIcon from "../../../../assets/admintools/sound.svg";
import {
  TextBox,
  RadioButton,
  InputPrefix,
} from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Icon } from "@progress/kendo-react-common";
import api from "../../../../config/api";
import config from "../../../../config/config";
import { getCurrentLoggedInSessionToken } from "../../../../config/userPool";
import { useNavigate } from "react-router";
import { ADMIN_TOOLS_CATEGORY_LIST } from "../../../../routes";
// import { SenderListDialog } from "../../../../components/dialog/senderListDialog";
// import { ApproverListDialog } from "../../../../components/dialog/approverListDialog";

const { UPLOAD_ENDPOINT } = config.api_endpoint;

const yes_no_values = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const CategoryForm = ({
  data,
  // handleAudioPlay,
  isEdit,
  setIsEdit,
  submitForm,
  setLoading,
  isCreate,
}) => {
  const navigate = useNavigate();
  const [name, setName] = useState(null);
  const [code, setCode] = useState(null);
  const [textColor, setTextColor] = useState(null);
  const [iconImage, setIconImage] = useState({
    file: null,
    url: null,
    name: null,
    icon_url: null,
  });
  const [sound, setSound] = useState({
    file: null,
    url: null,
    name: null,
    sound_url: null,
  });
  const [ack_required, setAckRequired] = useState(false);
  const [push_silently, setPushSilently] = useState(false);
  // const [senders, setSenders] = useState([]);
  // const [approvers, setApprovers] = useState([]);
  // const [showSenderList, setShowSenderList] = useState(false);
  // const [showApproverList, setShowApproverList] = useState(false);

  const hiddeniconRef = useRef(null);
  const hiddenSoundRef = useRef(null);

  useEffect(() => {
    if (data) {
      const icon_ = {
        name: data.icons,
        url: data.icon_s3_link,
        file: null,
        upload_url: data.icon_url,
        file_type: "category_icon",
      };
      const sound_ = {
        name: data.sound,
        url: data.sound_s3_link,
        file: null,
        upload_url: data.sound_url,
        file_type: "category_sound",
      };
      setIconImage(icon_);
      setSound(sound_);
      setName(data.name);
      setCode(data.code);
      setTextColor(data.text_color);
      setAckRequired(data.ack_required);
      setPushSilently(data.push_silently);
      // setSenders(data.sender_user_list);
      // setApprovers(data.approver_user_list);
    }
  }, []);

  const handleIconImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      setIconImage({
        file,
        name: file.name,
        url: null,
        file_type: "category_icon",
      });
    }
  };
  const handleSoundChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSound({
        file,
        name: file.name,
        url: null,
        file_type: "category_sound",
      });
    }
  };
  const handleAudioPlay = () => {
    let audio;
    if (sound.url) {
      audio = new Audio(sound.url);
    } else {
      audio = new Audio(URL.createObjectURL(sound.file));
    }
    audio.play();
  };
  const upload_files = () => {
    const files = [iconImage, sound];
    const token = getCurrentLoggedInSessionToken();
    const uploaded_existing = files.filter((v) => v.url);
    const new_files = files.filter((v) => v.file);
    Promise.all(
      new_files.map(async (v) => {
        const formData = new FormData();
        formData.append("upload_type", v.file_type);
        formData.append("file", v.file);
        formData.append("filename", v.name);
        return await api
          .postForm(UPLOAD_ENDPOINT, formData, token)
          .then(async (res) => {
            const result = await res.data;
            if (result) {
              return { ...v, upload_url: result.url };
            }
          });
      })
    ).then((res) => {
      setLoading(false);
      let final_res = res.concat(uploaded_existing);
      let sound_files = final_res.filter(
        (v) => v.file_type == "category_sound"
      );
      let icons_files = final_res.filter(
        (v) => v.file_type === "category_icon"
      );
      let data = {
        name,
        code,
        text_color: textColor,
        ack_required,
        push_silently,
        sound: sound_files.length > 0 ? sound_files[0].name : null,
        sound_url: sound_files.length > 0 ? sound_files[0].upload_url : null,
        icons: icons_files.length > 0 ? icons_files[0].name : null,
        icon_url: icons_files.length > 0 ? icons_files[0].upload_url : null,
        // sender_user_list: senders,
        // approver_user_list: approvers,
      };
      submitForm(data);
    });
  };
  const submitCategory = () => {
    setLoading(true);
    upload_files();
  };
  const cancelForm = () => {
    console.log(data);
    if (data & isEdit) {
      // setIsEdit(true);
    } else {
      navigate(ADMIN_TOOLS_CATEGORY_LIST);
    }
  };
  // const senderSelectedCallback = (data) => {
  //   setSenders(data);
  //   setShowSenderList(false);
  // };
  // const approverSelectedCallback = (data) => {
  //   setApprovers(data);
  //   setShowApproverList(false);
  // };

  return (
    <>
      {/* {showSenderList && (
        <SenderListDialog
          callback={senderSelectedCallback}
          defaultSelected={senders}
          onClickNo={() => setShowSenderList(false)}
        />
      )}
      {showApproverList && (
        <ApproverListDialog
          callback={approverSelectedCallback}
          defaultSelected={approvers}
          onClickNo={() => setShowApproverList(false)}
        />
      )} */}

      <table>
        <tr>
          <td>Category Name</td>
          {isEdit ? (
            <td>
              <TextBox value={name} onChange={(e) => setName(e.target.value)} />
            </td>
          ) : (
            <td>{data.name}</td>
          )}
        </tr>
        <tr>
          <td>Code</td>
          {!isEdit ? (
            <td>{data.code}</td>
          ) : (
            <>
              {isCreate ? (
                <td>
                  <TextBox
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </td>
              ) : (
                <td>{data.code}</td>
              )}
            </>
          )}
        </tr>
        <tr>
          <td>Icon</td>
          <td>
            <input
              ref={hiddeniconRef}
              type="file"
              accept="image/png, image/jpeg"
              hidden
              onChange={handleIconImage}
            />
            {isEdit ? (
              <>
                {iconImage.file || iconImage.url ? (
                  <div className="detail-file-div">
                    <img
                      src={
                        iconImage.file
                          ? URL.createObjectURL(iconImage.file)
                          : iconImage.url
                      }
                      width={36}
                    />
                    <label className="detail-file-name">{iconImage.name}</label>
                    <Icon
                      name="delete"
                      size="large"
                      onClick={() =>
                        setIconImage({ file: null, url: null, name: null })
                      }
                    ></Icon>
                  </div>
                ) : (
                  <>
                    <Button onClick={(e) => hiddeniconRef.current.click()}>
                      + <span className="gray-color-text">Add</span>
                    </Button>
                  </>
                )}
              </>
            ) : (
              <div className="detail-file-div">
                <img src={iconImage.url} width={36} />
                <label className="detail-file-name">{iconImage.name}</label>
              </div>
            )}
          </td>
        </tr>
        <tr>
          <td>Notification Sound</td>
          <td>
            <input
              ref={hiddenSoundRef}
              type="file"
              accept=".mp3"
              hidden
              onChange={handleSoundChange}
            />
            {isEdit ? (
              <>
                {sound.file || sound.url ? (
                  <div className="detail-file-div">
                    <label className="detail-file-name">{sound.name}</label>
                    <img src={SoundIcon} width={24} onClick={handleAudioPlay} />
                    <Icon
                      name="delete"
                      size="large"
                      onClick={() =>
                        setSound({ file: null, url: null, name: null })
                      }
                    ></Icon>
                  </div>
                ) : (
                  <>
                    <Button onClick={(e) => hiddenSoundRef.current.click()}>
                      + <span className="gray-color-text">Add</span>
                    </Button>
                  </>
                )}
              </>
            ) : (
              <>
                {sound.url && (
                  <div className="detail-file-div">
                    <label className="detail-file-name">{sound.name}</label>
                    <img src={SoundIcon} width={24} onClick={handleAudioPlay} />
                  </div>
                )}
              </>
            )}
          </td>
        </tr>
        <tr>
          <td>Acknowledgement required</td>
          <td>
            {isEdit ? (
              <div className="detail-radio-div">
                {yes_no_values.map((v) => (
                  <RadioButton
                    name="ack-required-radio"
                    key={v.value}
                    label={v.label}
                    value={v.value}
                    onChange={(e) => setAckRequired(e.value)}
                    checked={ack_required === v.value}
                  />
                ))}
              </div>
            ) : (
              <>{ack_required ? "Yes" : "No"}</>
            )}
          </td>
        </tr>
        <tr>
          <td>Push silently</td>
          <td>
            {isEdit ? (
              <div className="detail-radio-div">
                {yes_no_values.map((v) => (
                  <RadioButton
                    name="push-silently-radio"
                    key={v.value}
                    label={v.label}
                    value={v.value}
                    onChange={(e) => setPushSilently(e.value)}
                    checked={push_silently === v.value}
                  />
                ))}
              </div>
            ) : (
              <>{push_silently ? "Yes" : "No"}</>
            )}
          </td>
        </tr>
        <tr>
          <td>Text colour code</td>
          <td>
            {isEdit ? (
              <TextBox
                prefix={() => <InputPrefix>#</InputPrefix>}
                value={textColor}
                onChange={(e) => setTextColor(e.target.value)}
              />
            ) : (
              <>#{data.text_color}</>
            )}
          </td>
        </tr>
        {/* <tr>
          <td>List of sender</td>
          <td>
            {isEdit ? (
              <div className="edit-category-layout">
                <Button onClick={() => setShowSenderList(true)}>
                  Click here to update the sender(s)
                </Button>
                {senders
                  ? senders.map((v) => (
                      <div className="category-sender-item">{v.name}</div>
                    ))
                  : null}
              </div>
            ) : (
              <div className="edit-category-layout">
                {data.sender_user_list
                  ? data.sender_user_list.map((v) => (
                      <div className="category-sender-item">{v.name}</div>
                    ))
                  : null}
              </div>
            )}
          </td>
        </tr>
        <tr>
          <td>List of approver</td>
          <td>
            {isEdit ? (
              <div className="edit-category-layout">
                <Button onClick={() => setShowApproverList(true)}>
                  Click here to update the approver(s)
                </Button>
                {approvers
                  ? approvers.map((v) => (
                      <div className="category-sender-item">{v.name}</div>
                    ))
                  : null}
              </div>
            ) : (
              <div className="edit-category-layout">
                {data.approver_user_list
                  ? data.approver_user_list.map((v) => (
                      <div className="category-sender-item">{v.name}</div>
                    ))
                  : null}
              </div>
            )}
          </td>
        </tr> */}
        {isEdit && (
          <tr>
            <td colSpan={2}>
              <div className="detail-button-div">
                <Button
                  className="detail-button detail-cancel"
                  onClick={cancelForm}
                >
                  Cancel
                </Button>
                <Button
                  className="detail-button detail-submit"
                  onClick={submitCategory}
                >
                  Submit
                </Button>
              </div>
            </td>
          </tr>
        )}
      </table>
    </>
  );
};
