import config from "../config/config";
import Fetch from "../libs/fetch";

const Init = () => {
  const token = localStorage.getItem("login");
  const instance = new Fetch({
    BaseURL: config.url ?? 'https://sbstilink-local-api.int.weeswares.com',
    headers: {
      Authorization: token ?? ""
    }
  })

  return instance;
}

export default Init();