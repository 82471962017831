import { DropDownList } from "@progress/kendo-react-dropdowns";
import { TableBody } from "../../../components/table/TableBody";
import { TableHeader } from "../../../components/table/Tableheader";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useJsonToCsv } from "react-json-csv";
import { MICProvider, TABS, useMICContext } from "./action";

import SearchModal from "./partials/search";
import SearchFilter from "./partials/filters";
import { useModal } from "../mic-details/actions";

import "./mic.css";
import { ExportIdeas, deleteMIC } from "../../../services/ideas";
import { STATUS, VISIBILITY } from "../constant";

import PopupStatus from "../components/modal-status";
import PopupVisibility from "../components/modal-visibility";

import Cross_icon from "../../../assets/mic/cross_icon.svg";

import {
  getUserPermission,
  checkMultiPermission,
  MIC_PERMISSIONS_FOR_EXPORT,
} from "../../../config/permission_helper";
import { Loader } from "@progress/kendo-react-indicators";
import { Dialog } from "@progress/kendo-react-dialogs";

const Component = () => {
  const [isExporting, setIsExporting] = useState(false);
  const [visibleSearch, setVisibleSearch] = useState(false);
  const [visibleFilter, setVisibleFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [MICDelete, setDelete] = useState({
    isDelete: false,
    deleteId: "",
  });
  const [popupInfo, setPopupInfo] = useState({
    title: "",
    section: "",
    micId: "",
    sk: "",
  });
  const userPermissions = getUserPermission();
  const check = checkMultiPermission(userPermissions, [
    "my_ideas.limited_delete_ideas",
    "my_ideas.delete_ideas",
  ]);

  const navigate = useNavigate();
  const { saveAsCsv } = useJsonToCsv();
  const { visible, onToggle } = useModal();
  const { visible: isOpenVisibility, onToggle: onToggleVisibility } =
    useModal();
  const {
    tab,
    isLoading,
    data,
    filter,
    search,
    paginateInfo,
    isShowFilterSearch,
    masterData,
    actions: {
      getIdeas: onRefresh,
      onFetchByPage,
      onChangePage,
      onBackPage,
      onClearData,
      onTabChange,
    },
  } = useMICContext();

  const Delete = async () => {
    setLoading(true);
    setDelete({
      ...MICDelete,
      isDelete: false,
      deleteId: "",
    });
    const [err, response] = await deleteMIC({ id: MICDelete.deleteId });
    setLoading(false);
    if (response.status === "success") onRefresh();
    if (err) alert(err);
  };

  const toggleModalSearch = () => setVisibleSearch((prev) => !prev);
  const toggleModalFilter = () => setVisibleFilter((prev) => !prev);

  const onSelectAction = (title = "", section = "", micId, sk = "") =>
    setPopupInfo({ section, title, micId, sk });

  const HEADER_DATA = useMemo(
    () => [
      {
        field: "pk",
        title: "MIC ID",
        style: {
          width: 200,
        },
      },
      {
        field: "created_on",
        title: "Created On",
      },
      {
        field: "group_str",
        title: "Department",
      },
      {
        field: "created_by.name",
        title: "Created By",
      },
      {
        field: "total_liked",
        title: "No. of Likes",
      },
      {
        field: "created_by_sap",
        title: "SAP",
      },
      {
        field: "status_str",
        title: "MIC Status",
      },
      {
        field: "Action",
        style: { width: 160 },
        cell: (props) => (
          <td>
            <DropDownList
              style={{
                width: "120px",
                height: "48px",
                backgroundColor: "#EB6E1F",
                color: "white",
                textAlign: "center",
                borderRadius: "8px",
              }}
              defaultValue={"Action"}
              data={
                check
                  ? ["Update Status", "Visibility", "View Detail", "Delete"]
                  : ["Update Status", "Visibility", "View Detail"]
              }
              onChange={(e) => {
                const targetEvent = e?.target?.value ?? "";
                if (targetEvent === "View Detail") {
                  const pageId = props?.dataItem?.pk ?? "";

                  navigate(`/mic/${pageId}`);

                  return;
                }

                if (
                  targetEvent === "Update Status" ||
                  targetEvent === "Visibility"
                ) {
                  onSelectAction(
                    targetEvent,
                    targetEvent === "Update Status"
                      ? "update-status"
                      : "visibility",
                    props?.dataItem?.pk ?? "",
                    props?.dataItem?.sk ?? ""
                  );

                  targetEvent === "Update Status"
                    ? onToggle()
                    : onToggleVisibility();
                }
                if (targetEvent === "Delete") {
                  setDelete({
                    ...MICDelete,
                    isDelete: true,
                    deleteId: props.dataItem.pk,
                  });
                }
              }}
            />
          </td>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onExport = async () => {
    setIsExporting(true);
    const [err, response] = await ExportIdeas();
    if (err) return;

    const headers = response?.data[0] ?? [];
    const data = (response?.data ?? [])?.slice(1);

    let fields = {};

    headers?.map((fieldItem) => {
      fields[fieldItem?.split(" ")?.join("-")] = fieldItem;
    });

    const items = data?.map((item = []) => {
      let newObj = {};

      item.map((idx, itmKey) => {
        newObj = {
          ...newObj,
          [`${headers[itmKey]?.split(" ")?.join("-")}`]:
            typeof idx === "string" ? idx?.replace(",", "") : idx,
        };
      });

      return newObj;
    });

    console.log(fields, items);
    await saveAsCsv({ data: items, fields, filename: "export-mic-list" });
    setIsExporting(false);
  };

  const shouldShowExport = () => {
    const userPermissions = getUserPermission();
    return !!checkMultiPermission(userPermissions, MIC_PERMISSIONS_FOR_EXPORT);
  };

  const tableHeaderCollectProps = useMemo(
    () => {
      if (isShowFilterSearch)
        return {
          onSearch: toggleModalSearch,
          onFilter: toggleModalFilter,
          onExport: () => !isExporting && onExport(),
          showNotificationExport: shouldShowExport(),
        };

      return {
        hideFilterBtn: true,
        hideSearchBtn: true,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isShowFilterSearch, isExporting]
  );
  return (
    <div className="">
      {loading && (
        <Loader
          type="converging-spinner"
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#ffffff4d",
            position: "absolute",
            zIndex: "999",
          }}
        />
      )}
      <TableHeader
        title="MIC List"
        onRefresh={() => onRefresh()}
        {...tableHeaderCollectProps}
        hideaddbtn
      />
      {(!!Object.keys(filter)?.length || !!search) && (
        <div className="table-filter-div">
          {!!Object.keys(filter)?.length && (
            <div className="table-filter-item">
              <div className="table-filter-title">Filter applied</div>
              <span
                className="table-filter-clear"
                onClick={() => {
                  onClearData(null, "");
                }}
              >
                Clear filter
              </span>
            </div>
          )}
          {!!search && (
            <div className="table-filter-item">
              <div className="table-filter-title">Keyword search applied</div>
              <span
                className="table-filter-clear"
                onClick={() => {
                  onClearData("", null);
                }}
              >
                Clear search
              </span>
            </div>
          )}
        </div>
      )}
      <div className="tabs" style={{ margin: "10px 20px" }}>
        <div
          onClick={() => onTabChange(TABS.ALL)}
          className={`tab ${tab === TABS.ALL ? "isactive" : ""}`}
        >
          {TABS.ALL}
        </div>
        <div style={{ borderLeft: "2px solid #CCCCCC" }}></div>
        <div
          onClick={() => onTabChange(TABS.MOST_LIKED)}
          className={`tab ${tab === TABS.MOST_LIKED ? "isactive" : ""}`}
        >
          {TABS.MOST_LIKED}
        </div>
      </div>
      <TableBody
        loading={isLoading}
        table={HEADER_DATA}
        data={data}
        paginateInfo={paginateInfo}
        onNextPage={onFetchByPage}
        onBackPage={onBackPage}
        onChangePage={onChangePage}
        useCustomPagination
        unablePagination={!!(tab === TABS.MOST_LIKED)}
      />
      {visibleSearch && <SearchModal onClose={toggleModalSearch} />}
      {visibleFilter && (
        <SearchFilter
          filter={masterData?.category ?? []}
          onClose={toggleModalFilter}
        />
      )}
      {visible && (
        <PopupStatus
          micId={popupInfo?.micId}
          sk={popupInfo?.sk}
          onToggle={onToggle}
          title={popupInfo?.title}
          list={STATUS}
          onSuccess={() => {
            onToggle();
            onRefresh();
          }}
          onFailed={() => {
            //
            onToggle();
          }}
        />
      )}
      {isOpenVisibility && (
        <PopupVisibility
          micId={popupInfo?.micId}
          sk={popupInfo?.sk}
          onToggle={onToggleVisibility}
          title={popupInfo?.title}
          list={VISIBILITY}
          onSuccess={() => {
            onToggleVisibility();
            onRefresh();
          }}
          onFailed={() => {
            //
            onToggleVisibility();
          }}
        />
      )}
      {MICDelete.isDelete && (
        <Dialog>
          <div style={{ position: "relative" }}>
            <div
              style={{
                position: "absolute",
                right: "10px",
                top: "15px",
                cursor: "pointer",
              }}
              onClick={() =>
                setDelete({
                  ...MICDelete,
                  isDelete: false,
                  deleteId: "",
                })
              }
            >
              <img src={Cross_icon} />
            </div>
          </div>
          <div className="MIC_delete_popup_box">
            <div className="MIC_delete_title">
              Are you sure, you want to delete {MICDelete.deleteId}
            </div>
            <div className="MIC_delete_footer_box">
              <div
                className="MIC_delete_footer_cancel"
                onClick={() =>
                  setDelete({
                    ...MICDelete,
                    isDelete: false,
                    deleteId: "",
                  })
                }
              >
                Cancel
              </div>
              <div className="MIC_delete_footer_delete" onClick={Delete}>
                Delete
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
};

const WrapperComponent = () => (
  <MICProvider>
    <Component />
  </MICProvider>
);

export default WrapperComponent;
