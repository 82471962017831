import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCurrentLoggedInSessionTokenWithPromise } from "../../config/userPool";
import config from "../../config/config";
import api from "../../config/api";

const { ADMIN_ARTICELS_CATEGORY_ENDPOINT } = config.api_endpoint;

const initialState = {
  loading: false,
  error: null,
  detail_data: {},
  listing_data: {},
  create_data: {},
  update_data: {},
  delete_data: {},
};

export const fetchCategoryListing = createAsyncThunk(
  "categoryAdmin/fetchCategoryListing",
  async (_, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(
        ADMIN_ARTICELS_CATEGORY_ENDPOINT,
        {},
        token
      );
      return results;
    } catch (err) {
      return rejectWithValue(err.details);
    }
  }
);

export const fetchCategoryDetail = createAsyncThunk(
  "categoryAdmin/fetchCategoryDetail",
  async (id, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(
        `${ADMIN_ARTICELS_CATEGORY_ENDPOINT}/${id}`,
        {},
        token
      );
      return results;
    } catch (err) {
      return rejectWithValue(err.details);
    }
  }
);

export const fetchCategoryCreate = createAsyncThunk(
  "categoryAdmin/fetchCategoryCreate",
  async (data, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.post(
        ADMIN_ARTICELS_CATEGORY_ENDPOINT,
        JSON.stringify(data),
        token
      );
      return results;
    } catch (err) {
      return rejectWithValue(err.details);
    }
  }
);

export const fetchCategoryUpdate = createAsyncThunk(
  "categoryAdmin/fetchCategoryUpdate",
  async (data, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.put(
        `${ADMIN_ARTICELS_CATEGORY_ENDPOINT}/${data.id}`,
        JSON.stringify({ category: data.category }),
        token
      );
      return results;
    } catch (err) {
      return rejectWithValue(err.details);
    }
  }
);

export const fetchCategoryDelete = createAsyncThunk(
  "categoryAdmin/fetchCategoryDelete",
  async (id, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.remove(
        `${ADMIN_ARTICELS_CATEGORY_ENDPOINT}/${id}`,
        {},
        token
      );
      return results;
    } catch (err) {
      return rejectWithValue(err.details);
    }
  }
);

const categorySlice = createSlice({
  name: "categoryAdmin",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategoryListing.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCategoryListing.fulfilled, (state, action) => {
        state.loading = false;
        state.listing_data = action.payload.data;
      })
      .addCase(fetchCategoryListing.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      })
      .addCase(fetchCategoryDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCategoryDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.detail_data = action.payload.data;
      })
      .addCase(fetchCategoryDetail.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      })
      .addCase(fetchCategoryCreate.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCategoryCreate.fulfilled, (state, action) => {
        state.loading = false;
        state.create_data = action.payload.data;
      })
      .addCase(fetchCategoryCreate.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      })
      .addCase(fetchCategoryUpdate.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCategoryUpdate.fulfilled, (state, action) => {
        state.loading = false;
        state.update_data = action.payload.data;
      })
      .addCase(fetchCategoryUpdate.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      })
      .addCase(fetchCategoryDelete.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCategoryDelete.fulfilled, (state, action) => {
        state.loading = false;
        state.delete_data = action.payload.data;
      })
      .addCase(fetchCategoryDelete.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      });
  },
});

const categoryReducer = categorySlice.reducer;
export default categoryReducer;
