// kendo
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
// icons
import ExportIcon from "../../../../assets/dr_safe/export.png";
import FilterIcon from "../../../../assets/dr_safe/filter.png";
import SearchIcon from "../../../../assets/dr_safe/search.png";
// components
import PageTitle from "../../../../components/pageTitle/page_title";
import { Navigate, useNavigate } from "react-router";
import DrSystemListSearchDialog  from "./dr_infra_system_list_search_dialog";

const DrInfraSystemListHeader = ({
  title,
  showDrSearchDialog,
  onSearchBtnClicked,
  onSearchDialogClose,
  onSearchAction,
}) => {
  const navigate = useNavigate();
  
  return (
    <div className="dr-infra-list-header">
      <PageTitle title={title} />
      <div className="dr-infra-list-header-right">
        <div className="dr-infra-list-header-icon">
        
        <Button className="button2" title="Search" onClick={onSearchBtnClicked} >
          <img src={SearchIcon} />
        </Button>
        
        </div>
      </div>
      {showDrSearchDialog && (
        <DrSystemListSearchDialog
          onToggleDialog={onSearchDialogClose}
          onSubmitShare={onSearchAction}
          
        />
      )}
    </div>
  );
};

export default DrInfraSystemListHeader;
