
import React, { useState, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";

import { FOBReportTableBody } from "./report-table";
import "./reports.css";
import { FOBReportTableHeader } from "./report-table-header";
import { FobReportActionTakenDialog } from "../../../components/dialog/fob_report_actionList";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "@progress/kendo-react-indicators";
import {
  fetchFOBReportList,
  updateFOBReport,
  exportFobReport
} from "../../../store/feedbackonbusReport";

// import { 
//   getUserPermission,
//   checkMultiPermission,
// } from "../../../../config/permission_helper";

const Component = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const query = new URLSearchParams(search);  
  const [selectedColumns,setSelectedColumns] = useState([]);
  const [exportData,setExportData] = useState([[]])
  const {
    data:report_data,
    loading,
    error,
    update_loading,
    update_data,
    update_error,
    export_loading,
    export_data,
    export_error,
  } = useSelector((state)=> state.FOBReport);
  const data = [];
  useEffect(() => {
    dispatch(fetchFOBReportList(query));
    dispatch(exportFobReport(query));
  }, []);
  useEffect(()=>{
    if(export_data){
      setExportData(export_data);
      console.log(export_data);
    }
  },[export_data])
  const onhandlePageColumnChange = ((selected)=>{
    console.log('Selected');
    setSelectedColumns(selected);
    dispatch(fetchFOBReportList(query));
    dispatch(exportFobReport(query));
  });
  const onhandleFilterChange = ((filter)=>{
    if(filter.startdate){
      query.set("start_date",filter.startdate);
    }
    if(filter.enddate){
      query.set("end_date",filter.enddate);
    }
    if(filter.type){
      query.set("type",filter.type)
    }
    dispatch(fetchFOBReportList(query));
    navigate(`/feedback-on-bus/reports?${query.toString()}`);
  })
  const onhandleReportUpdate = (data)=>{
    dispatch(updateFOBReport(data));
  }
  const onClickExportButton = () => {
    // setLoading(true);
    // dispatch(exportToCSVRecipients(data.id)).then((res) => {
    //   console.log("Export method res-", res.payload);
    //   let data = res.payload;
    //   setExportData(data);
    //   setLoading(false);
    // });
  };

  return (
    <div className="page-wrapper">
      <div className="container">
        <div className="header">
          <div className="page-header">
            <h2 style={{ fontSize: 32 }}>Reports</h2>
            <div className="filter-group">
            </div>
          </div>
        </div>
        <div style={{display:"flex",gap:"10px",flexDirection:"column"}}>
          <FOBReportTableHeader
            showNotificationExport={true} 
            data={exportData}
            onhandlePageColumnChange = {(selected)=> onhandlePageColumnChange(selected)}
            onhandleFilterChange = {onhandleFilterChange}
            onRefresh={()=> dispatch(fetchFOBReportList(query))}
            />
          {loading || export_loading ?(
            <Loader/>
          ):(
            <FOBReportTableBody 
              data={report_data} 
              columns={selectedColumns}
              onhandleReportUpdate={onhandleReportUpdate}
            />
          )}
        </div>
      </div>
      {/* {showActionTaken && (
        <FobReportActionTakenDialog onCloseDialog={()=> setShowActionTaken(false)} />
      )} */}
    </div>
  )
}

export default Component;
