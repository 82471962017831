import { Check, WarningCircle } from '@phosphor-icons/react';
import Modal from '../modal';
import { useMemo } from 'react';

const Component = props => {
  const IconStatus = useMemo(
    () => props?.type === 'success'
      ? <Check size={28} color="#6ce141" weight="bold" />
      : <WarningCircle size={40} color="#d25662" weight="bold" />,
    [props?.type]
  )
  return (
    <Modal
      width={400}
      height={160}
      isOpen={props?.isOpen}
      onClose={props?.onClose}
    >
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 'calc(100% - 25px)' }}>
        <div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
            {IconStatus}
          </div>
          <h3>{props?.message}</h3>
        </div>
      </div>
    </Modal>
  )
}

export default Component;