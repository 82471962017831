import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import EditIcon from "../../../../assets/common/edit_icon.svg";
import ArrorRightIcon from "../../../../assets/common/arrao-right.svg";
import { Loader } from "@progress/kendo-react-indicators";
import BackIcon from "../../../../assets/communication/Back.svg";
import { TELEPHONE_DIRECTORY_DEPOT_LIST } from "../../../../routes";
import { fetchDepotById, updateDepot, fetchChildDepotById ,updateSubDepot} from "../../../../store/telephoneDirectory";
import { DepotForm } from "./depot_form";

export const DepotDetails = () => {
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const id = query.get("id");
    const parent_depot = query.get("parent_depot");
    console.log('PDepot in index---',parent_depot);
    const navigate = useNavigate();
    const dispatch = useDispatch();
   
    const [isEdit, setIsEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const {
        depot_detail_data,
        depot_detail_loading,
        detail_error,
    } = useSelector((state) => state.telephone_directory);
    
    useEffect(() => {
        console.log('contact_id',id);
        if (id) {                
            if(parent_depot){
                console.log('Parent Depot is in query');
                // call child depot detail
                dispatch(fetchChildDepotById(id));
            }
            else{
                console.log('Parent Depot is not in query');
                //call depot detail
                dispatch(fetchDepotById(id));
            }
    }
    }, [])
    const handleSaveContact = (data) => {
        data.id = id
        console.log('Handle Save Depot--',data);
        // if (data.depot=== null) {
        //     data.depot =data.child_depot;
        //     data.child_depot="";
        //     data.entity_type= 'telephone-directory-depot'
        //     console.log('Update Depot',data);
        //     // data.entity = 'telephone-directory-depot'
        //     dispatch(updateDepot({ data, id })).then(() => {
        //         setIsEdit(false);
        //         navigate(TELEPHONE_DIRECTORY_DEPOT_LIST)
        //     })
        // }
        // else{
        //     console.log('update Sub Depot',data);
        //     data.entity_type= 'telephone-directory-child-depot'
        //     dispatch(updateSubDepot({ data, id })).then(() => {
        //         setIsEdit(false);
        //         navigate(TELEPHONE_DIRECTORY_DEPOT_LIST)
        //     })
        // }
        if (depot_detail_data.entity_type == 'telephone-directory-depot'){
            if( data.depot === null || data.depot ==='Select Parent Depot'){
                console.log("no entity type change",data);
                data.depot = data.child_depot;
                data.child_depot="";
                dispatch(updateDepot({ data, id })).then (() => {
                    setIsEdit(false);
                    navigate(TELEPHONE_DIRECTORY_DEPOT_LIST);
                })
            }
            else{
                console.log("change from depot to child depot",data);
                data.entity_type = depot_detail_data.entity_type;
                dispatch(updateSubDepot({ data, id })).then (() => {
                    setIsEdit(false);
                    navigate(TELEPHONE_DIRECTORY_DEPOT_LIST);
                })
            }
        }
        else if (depot_detail_data.entity_type == 'telephone-directory-child-depot'){
            if(data.depot === null || data.depot ==='Select Parent Depot'){
                console.log("change from child depot to depot",data);
                data.depot = data.child_depot;
                data.child_depot="";
                data.entity_type = depot_detail_data.entity_type ;
                dispatch(updateDepot({ data, id })).then (() => {
                    setIsEdit(false);
                    navigate(TELEPHONE_DIRECTORY_DEPOT_LIST);
                })
            }
            else{
                console.log("have parent and sub depot type- no entity type change",data);
                dispatch(updateSubDepot({ data, id })).then (() => {
                    setIsEdit(false);
                    navigate(TELEPHONE_DIRECTORY_DEPOT_LIST);
                })
            }
        }
    }
    return (
        <>
            <div className="detail-body">
                {depot_detail_loading ? (
                    <Loader
                        type="infinite-spinner"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            width: "40px",
                            margin: "auto",
                            justifyContent: "center",
                            height: "100vh",
                        }}
                    />
                ) : (
                    <>
                        <div className="detail-header">
                            <div className="categoryback-group-div" onClick={() => navigate(TELEPHONE_DIRECTORY_DEPOT_LIST)}>
                                <img src={BackIcon} className="categoryback-icon" />
                                <span className="categoryback-label">Back</span>
                            </div>
                            <div>
                                    {!isEdit && (
                                    <div>
                                        <Button className="editBtn" onClick={() => setIsEdit(true)}>
                                            <img src={EditIcon} style={{ verticalAlign: "middle" }} />
                                            <span className="editBtn-label">Edit</span>
                                            <img src={ArrorRightIcon} style={{ verticalAlign: "middle" }} />
                                        </Button>
                                    </div>

                                )}
                            
                            {/* {!isEdit && (
                                    <div>
                                        <Button className="editBtn" onClick={() => setIsEdit(true)}>
                                            <img src={EditIcon} style={{ verticalAlign: "middle" }} />
                                            <span className="editBtn-label">Edit</span>
                                            <img src={ArrorRightIcon} style={{ verticalAlign: "middle" }} />
                                        </Button>
                                    </div>

                                )} */}
                            </div>
                        </div>
                        <div className="detail-content">
                            <DepotForm
                                data={depot_detail_data}
                                setIsEdit={setIsEdit}
                                isEdit={isEdit}
                                submitForm={handleSaveContact}
                            />
                        </div>
                    </>
                )}

            </div>
        </>

    );
};