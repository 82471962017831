function htmlEntities(str) {
  return String(str)
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;");
}

export const searchFilter = (data, column, searchValue) => {
  console.log(data, column, searchValue);
  let tempData = [];
  if (data.length > 0) {
    data.map((i) => {
      column.map((j) => {
        if (j.caseSensitive) {
          if (i[j.id].includes(searchValue)) {
            if (!tempData.find((l) => l.id === i.id)) {
              tempData.push(i);
            }
          }
        } else {
          console.log(i[j.id].includes(searchValue));
          let valueItem = i[j.id].toLowerCase();
          let searchItem = searchValue.toLowerCase();
          if (valueItem.includes(searchItem)) {
            if (!tempData.find((l) => l.id === i.id)) {
              tempData.push(i);
            }
          }
        }
      });
    });
  }
  console.log(tempData);
  return tempData;
};
