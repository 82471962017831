// redux
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionTokenWithPromise } from "../config/userPool";

const {
  USER_GROUP_LIST_ENDPOINT,
  ALL_PERMISSIONS_USER_MGMT_BY_MODULES_ENDPOINT,
  ALL_USERS_USER_MGMT_ENDPOINT,
  SYNC_USERS_USER_MGMT_ENDPOINT
} = config.api_endpoint;

const initialState = {
  groups_loading: false,
  groups: [],
  groups_error: null,
  group_users_loading: false,
  group_users: [],
  group_users_error: null,
  group_details_loading: false,
  group_details: [],
  group_details_error: null,
  all_permissions_loading: false,
  all_permissions: [],
  all_permissions_error: null,
  create_group_loading: false,
  create_group: {},
  create_group_error: null,
  update_group_loading: false,
  update_group: {},
  update_group_error: null,
  delete_group_loading: false,
  delete_group: {},
  delete_group_error: null,
};

export const fetchAllUserList = createAsyncThunk(
  "groupMgmt/fetchAllUserList",
  async (_, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(ALL_USERS_USER_MGMT_ENDPOINT, {}, token);
      console.log("fetchAllUserList Results:", results);

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const syncUserList = createAsyncThunk(
  "groupMgmt/syncUserList",
  async (_, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.post(SYNC_USERS_USER_MGMT_ENDPOINT, JSON.stringify({}), token);
      console.log("syncAllUserList Results:", results);

      return {
        "status": results.status,
        "message": results.message,
        "data": results.data,
      }
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetchAllGroupList = createAsyncThunk(
  "groupMgmt/fetchAllGroupList",
  async (_, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(USER_GROUP_LIST_ENDPOINT, {}, token);
      console.log("fetchAllGroupList Results:", results);

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetchGroupById = createAsyncThunk(
  "groupMgmt/fetchGroupById",
  async (id, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(
        USER_GROUP_LIST_ENDPOINT + "/" + id,
        {},
        token
      );
      console.log("fetchGroupById Results:", results);

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetchAllPermissions = createAsyncThunk(
  "groupMgmt/fetchAllPermissions",
  async (id, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(
        ALL_PERMISSIONS_USER_MGMT_BY_MODULES_ENDPOINT,
        {},
        token
      );
      console.log("fetchAllPermissions Results:", results);

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const createGroup = createAsyncThunk(
  "groupMgmt/createGroup",
  async (data, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.post(
        USER_GROUP_LIST_ENDPOINT,
        JSON.stringify(data),
        token
      );
      console.log("createGroup Results:", results);

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const updateGroup = createAsyncThunk(
  "groupMgmt/updateGroup",
  async (data, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.post(
        USER_GROUP_LIST_ENDPOINT + "/" + data.id,
        JSON.stringify(data.data),
        token
      );
      console.log("updateGroup Results:", results);

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const deleteGroup = createAsyncThunk(
  "groupMgmt/deleteGroup",
  async (id, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.remove(
        USER_GROUP_LIST_ENDPOINT + "/" + id,
        JSON.stringify({ id: id }),
        token
      );
      console.log("deleteGroup Results:", results);

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

const GroupMgmtSlice = createSlice({
  name: "groupMgmt",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllGroupList.pending, (state) => {
        state.groups_loading = true;
      })
      .addCase(fetchAllGroupList.fulfilled, (state, action) => {
        state.groups_loading = false;
        state.groups = action.payload;
      })
      .addCase(fetchAllGroupList.rejected, (state, action) => {
        state.groups_loading = false;
        state.groups_error = action.error.message;
      })
      .addCase(fetchGroupById.pending, (state) => {
        state.group_details_loading = true;
      })
      .addCase(fetchGroupById.fulfilled, (state, action) => {
        state.group_details_loading = false;
        state.group_details = action.payload;
      })
      .addCase(fetchGroupById.rejected, (state, action) => {
        state.group_details_loading = false;
        state.group_details_error = action.error.message;
      })
      .addCase(fetchAllPermissions.pending, (state) => {
        state.all_permissions_loading = true;
      })
      .addCase(fetchAllPermissions.fulfilled, (state, action) => {
        state.all_permissions_loading = false;
        state.all_permissions = action.payload;
      })
      .addCase(fetchAllPermissions.rejected, (state, action) => {
        state.all_permissions_loading = false;
        state.all_permissions_error = action.error.message;
      })
      .addCase(createGroup.pending, (state) => {
        state.create_group_loading = true;
      })
      .addCase(createGroup.fulfilled, (state, action) => {
        state.create_group_loading = false;
        state.create_group = action.payload;
      })
      .addCase(createGroup.rejected, (state, action) => {
        state.create_group_loading = false;
        state.create_group_error = action.error.message;
      })
      .addCase(updateGroup.pending, (state) => {
        state.update_group_loading = true;
      })
      .addCase(updateGroup.fulfilled, (state, action) => {
        state.update_group_loading = false;
        state.update_group = action.payload;
      })
      .addCase(updateGroup.rejected, (state, action) => {
        state.update_group_loading = false;
        state.update_group_error = action.error.message;
      })
      .addCase(fetchAllUserList.pending, (state) => {
        state.group_users_loading = true;
      })
      .addCase(fetchAllUserList.fulfilled, (state, action) => {
        state.group_users_loading = false;
        state.group_users = action.payload;
      })
      .addCase(fetchAllUserList.rejected, (state, action) => {
        state.group_users_loading = false;
        state.group_users_error = action.error.message;
      })
      .addCase(deleteGroup.pending, (state) => {
        state.delete_group_loading = true;
      })
      .addCase(deleteGroup.fulfilled, (state, action) => {
        state.delete_group_loading = false;
        state.delete_group = action.payload;
      })
      .addCase(deleteGroup.rejected, (state, action) => {
        state.delete_group_loading = false;
        state.delete_group_error = action.error.message;
      });
  },
});

const GroupMgmtReducer = GroupMgmtSlice.reducer;
export default GroupMgmtReducer;
