// redux

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionToken } from "../config/userPool";

const { DR_INFRA_SUBSYSTEM_ENDPOINT, DR_INFRA_SYNC_SUBSYSTEM_ENDPOINT } = config.api_endpoint;

const initialState = {
  loading: false,
  subsystem_data: [],
  error: null,
};

export const fetchAllDrInfraSubSystem = createAsyncThunk(
  "dr_infra/fetchAllDrInfraSubSystem",
  async (query, { rejectWithValue }) => {
    try {
      let q_params = {}
      if (query["subsystemName"]) {
        q_params["subsystem_name"] = query["subsystemName"]
      }

      if (query["subSystemRailLineId"]) {
        q_params["rail_line_id"] = query["subSystemRailLineId"]
      }

      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(DR_INFRA_SUBSYSTEM_ENDPOINT, q_params, token);
      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const syncDrInfraSubSystem = createAsyncThunk(
  "dr_infra/syncDrInfraSubSystem",
  async (query, { rejectWithValue }) => {
    try {
      let q_params = {}
      const token = getCurrentLoggedInSessionToken();
      const results = await api.post(DR_INFRA_SYNC_SUBSYSTEM_ENDPOINT, q_params, token);
      return {
        "status": results.status,
        "message": results.message,
        "data": results.data,
      }
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

const DrInfraSubSystem = createSlice({
  name: "subsystem",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllDrInfraSubSystem.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllDrInfraSubSystem.fulfilled, (state, action) => {
        state.loading = false;
        state.subsystem_data = action.payload;
      })
      .addCase(fetchAllDrInfraSubSystem.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

const DrInfraSubSystemReducer = DrInfraSubSystem.reducer;
export default DrInfraSubSystemReducer;
