// redux
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionToken } from "../config/userPool";

const {
  CARES_ECARD_LISTING_ENDPOINT,
  CARES_ECARD_DETAIL_ENDPOINT,
  CARES_BUSINESS_AREAS_ENDPOINT,
  AUDIT_ENDPOINT,
  CARES_ECARD_UPDATE_VISIBILITY,
  CARES_ECARD_DELETE,
  CARES_BUSINESS_AREAS_DEPOT_ENDPOINT,
  CARES_ECARDS_EXPORT,
  CARES_ECARD_STICKERS_LISTING,
  CARES_ECARD_IMAGES_LISTING,
  CARES_ECARD_IMAGES_DETAIL_ENDPOINT,
  CARES_ECARD_STICKERS_DETAIL_ENDPOINT,
  CARES_ECARD_APPROVE_ENDPOINT,
  CARES_ECARD_STICKER_DELETE_ENDPOINT,
  CARES_ECARD_IMAGE_DELETE_ENDPOINT
} = config.api_endpoint;

const initialState = {
  loading: false,
  ecard_lists: [],
  error: null,
  next_key: {},
  detail_loading: false,
  detail_data: {},
  detail_error: null,
  business_areas: [],
  business_areas_loading: false,
  business_areas_error: null,
  audit_data: [],
  audit_loading: false,
  audit_error: null,
  depots: [],
  depots_loading: false,
  depots_error: null,
  export_data: [],
  export_loading: false,
  export_error: null,
  stickers: [],
  stickers_loading: false,
  stickers_error: null,
  images: [],
  images_loading: false,
  images_error: null,
  sticker_detail: {},
  sticker_detail_loading: false,
  sticker_detail_error: null,
  image_detail: {},
  image_detail_loading: false,
  image_detail_error: null,
};

export const fetchAlleCardsList = createAsyncThunk(
  "cares/fetchAlleCardsList",
  async (query, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const query_string = query.toString().replace("?", "");
      const url = `${CARES_ECARD_LISTING_ENDPOINT}?${query_string}`;
      console.log(url);
      const results = await api.get(url, {}, token);

      return results;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetchecardExport = createAsyncThunk(
  "cares/fetchecardExport",
  async (query, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const query_string = query.toString().replace("?", "");
      const url = `${CARES_ECARDS_EXPORT}`;
      console.log(url);
      const results = await api.post(url, JSON.stringify({}), token);

      return results;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);
export const fetcheCardDetailById = createAsyncThunk(
  "cares/fetcheCardDetailById",
  async (id, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionToken();
      const results = await api.get(
        CARES_ECARD_DETAIL_ENDPOINT + "/" + id,
        {},
        token
      );
      console.log("fetcheCardDetailById Results:", results);

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetchBusinessAreas = createAsyncThunk(
  "cares/fetchBusinessAreas",
  async (_, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(CARES_BUSINESS_AREAS_ENDPOINT, {}, token);

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);
export const fetchCaresAudit = createAsyncThunk(
  "cares/fetchCaresAudit",
  async (id, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const url = `${AUDIT_ENDPOINT}?table_name=cares&source_id=${id}`;
      const results = await api.get(url, {}, token);
      return results.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.details);
    }
  }
);
export const updateECardVisibility = createAsyncThunk(
  "cares/updateECardVisibility",
  async ({ id, status }, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const url = `${CARES_ECARD_UPDATE_VISIBILITY}/${id}/${status}`;
      const results = await api.post(url, JSON.stringify({}), token);
      return results.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.details);
    }
  }
);

export const approveEcards = createAsyncThunk(
  "cares/approveEcards",
  async (ids, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const url = `${CARES_ECARD_APPROVE_ENDPOINT}`;
      // const url = `${CARES_ECARD_UPDATE_VISIBILITY}/${id}/${status}`;
      const results = await api.post(
        url,
        JSON.stringify({
          ids: ids
        }),
        token
      );
      return results.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.details);
    }
  }
);
export const deleteECard = createAsyncThunk(
  "cares/deleteECard",
  async (id, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.post(
        CARES_ECARD_DELETE,
        JSON.stringify({ id: id }),
        token
      );
      return results.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.details);
    }
  }
);
export const fetchDepots = createAsyncThunk(
  "cares/fetchDepots",
  async (_, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(
        CARES_BUSINESS_AREAS_DEPOT_ENDPOINT + "?division=all",
        {},
        token
      );

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetchAllStickers = createAsyncThunk(
  "cares/fetchAllStickers",
  async (_, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(CARES_ECARD_STICKERS_LISTING, {}, token);

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const saveEcardAccessoryStickers = createAsyncThunk(
  "cares/saveEcardAccessoryStickers",
  async (data, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      console.log(data);
      const results = await api.post(
        CARES_ECARD_STICKERS_DETAIL_ENDPOINT,
        JSON.stringify(data),
        token
      );

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetchAccessoryStickersById = createAsyncThunk(
  "cares/fetchAccessoryStickersById",
  async (id, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(
        CARES_ECARD_STICKERS_DETAIL_ENDPOINT + "?id=" + id,
        {},
        token
      );

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetchAllEcardImages = createAsyncThunk(
  "cares/fetchAllEcardImages",
  async (_, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(CARES_ECARD_IMAGES_LISTING, {}, token);

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const saveEcardAccessoryImage = createAsyncThunk(
  "cares/saveEcardAccessoryImage",
  async (data, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.post(
        CARES_ECARD_IMAGES_DETAIL_ENDPOINT,
        JSON.stringify(data),
        token
      );
      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetchAccessoryImageById = createAsyncThunk(
  "cares/fetchAccessoryImageById",
  async (id, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(
        CARES_ECARD_IMAGES_DETAIL_ENDPOINT + "?id=" + id,
        {},
        token
      );

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);
export const DeleteEcardSticker = createAsyncThunk(
  "cares/DeleteEcardSticker",
  async (id, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.post(
        CARES_ECARD_STICKER_DELETE_ENDPOINT,
        JSON.stringify({id}),
        token
      );
      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);
export const DeleteEcardImage = createAsyncThunk(
  "cares/DeleteEcardImage",
  async (id, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.post(
        CARES_ECARD_IMAGE_DELETE_ENDPOINT,
        JSON.stringify({id}),
        token
      );
      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);
const careECardSlice = createSlice({
  name: "eCardList",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetcheCardDetailById.pending, (state) => {
        state.detail_loading = true;
      })
      .addCase(fetcheCardDetailById.fulfilled, (state, action) => {
        state.detail_loading = false;
        state.detail_data = action.payload;
      })
      .addCase(fetcheCardDetailById.rejected, (state, action) => {
        state.detail_loading = false;
        state.detail_error = action.error.message;
      })
      .addCase(updateECardVisibility.pending, (state) => {
        state.detail_loading = true;
      })
      .addCase(updateECardVisibility.fulfilled, (state, action) => {
        state.detail_loading = false;
        state.detail_data = action.payload;
      })
      .addCase(updateECardVisibility.rejected, (state, action) => {
        state.detail_loading = false;
        state.detail_error = action.error.message;
      })
      .addCase(approveEcards.pending, (state) => {
        state.detail_loading = true;
      })
      .addCase(approveEcards.fulfilled, (state, action) => {
        state.detail_loading = false;
        state.detail_data = action.payload;
      })
      .addCase(approveEcards.rejected, (state, action) => {
        state.detail_loading = false;
        state.detail_error = action.error.message;
      })
      .addCase(fetchBusinessAreas.pending, (state) => {
        state.business_areas_loading = true;
      })
      .addCase(fetchBusinessAreas.fulfilled, (state, action) => {
        state.business_areas_loading = false;
        state.business_areas = action.payload;
      })
      .addCase(fetchBusinessAreas.rejected, (state, action) => {
        state.business_areas_loading = false;
        state.business_areas_error = action.error.message;
      })
      .addCase(fetchDepots.pending, (state) => {
        state.depots_loading = true;
      })
      .addCase(fetchDepots.fulfilled, (state, action) => {
        state.depots_loading = false;
        state.depots = action.payload;
      })
      .addCase(fetchDepots.rejected, (state, action) => {
        state.depots_loading = false;
        state.depots_error = action.error.message;
      })
      .addCase(fetchAlleCardsList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAlleCardsList.fulfilled, (state, action) => {
        state.loading = false;
        state.ecard_lists = action.payload.data;
      })
      .addCase(fetchAlleCardsList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchecardExport.pending, (state) => {
        state.export_loading = true;
      })
      .addCase(fetchecardExport.fulfilled, (state, action) => {
        state.export_loading = false;
        state.export_data = action.payload.data;
      })
      .addCase(fetchecardExport.rejected, (state, action) => {
        state.export_loading = false;
        state.export_error = action.error.message;
      })
      .addCase(fetchCaresAudit.pending, (state) => {
        state.audit_loading = true;
      })
      .addCase(fetchCaresAudit.fulfilled, (state, action) => {
        state.audit_loading = false;
        state.audit_data = action.payload;
      })
      .addCase(fetchCaresAudit.rejected, (state, action) => {
        state.audit_loading = false;
        state.audit_error = action.error.message;
      })
      .addCase(fetchAllStickers.pending, (state) => {
        state.stickers_loading = true;
      })
      .addCase(fetchAllStickers.fulfilled, (state, action) => {
        state.stickers_loading = false;
        state.stickers = action.payload;
      })
      .addCase(fetchAllStickers.rejected, (state, action) => {
        state.stickers_loading = false;
        state.stickers_error = action.error.message;
      })
      .addCase(fetchAllEcardImages.pending, (state) => {
        state.images_loading = true;
      })
      .addCase(fetchAllEcardImages.fulfilled, (state, action) => {
        state.images_loading = false;
        state.images = action.payload;
      })
      .addCase(fetchAllEcardImages.rejected, (state, action) => {
        state.images_loading = false;
        state.images_error = action.error.message;
      })
      .addCase(fetchAccessoryImageById.pending, (state) => {
        state.image_detail_loading = true;
      })
      .addCase(fetchAccessoryImageById.fulfilled, (state, action) => {
        state.image_detail_loading = false;
        state.image_detail = action.payload;
      })
      .addCase(fetchAccessoryImageById.rejected, (state, action) => {
        state.image_detail_loading = false;
        state.image_detail_error = action.error.message;
      })
      .addCase(fetchAccessoryStickersById.pending, (state) => {
        state.sticker_detail_loading = true;
      })
      .addCase(fetchAccessoryStickersById.fulfilled, (state, action) => {
        state.sticker_detail_loading = false;
        state.sticker_detail = action.payload;
      })
      .addCase(fetchAccessoryStickersById.rejected, (state, action) => {
        state.sticker_detail_loading = false;
        state.sticker_detail_error = action.error.message;
      });
  },
});

const careECardReducer = careECardSlice.reducer;
export default careECardReducer;
