import { Loader } from "@progress/kendo-react-indicators";
import "./fullScreenLoader.css";
const FullscreenLoader = (props) => {
  return (
    <div
      className={
        props.total_fullscreen ? "total-fullscreen-loader" : "fullscreen-loader"
      }
    >
      <Loader type="infinite-spinner" />
    </div>
  );
};

export default FullscreenLoader;
