import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
// api
import api from "../../../config/api";
import config from "../../../config/config";
import { getCurrentLoggedInSessionTokenWithPromise } from "../../../config/userPool";
// redux
import { fetchAllUserList, syncUserList } from "../../../store/groupMgmt";
// kendo
import { Loader } from "@progress/kendo-react-indicators";
// components
import { TableHeader } from "../../../components/table/Tableheader";
import { UserTableBody } from "../../../components/table/userTable";
import { SearchUser } from "../../../components/dialog/user_search";
import { FilterUser } from "../../../components/dialog/user_filter";
// libs
import { useJsonToCsv } from "react-json-csv";
// permissions
import {
  getUserPermission,
  checkMultiPermission,
  USER_MGMT_EXPORT_USER,
  USER_MGMT_SYNC_USER,
} from "../../../config/permission_helper";
// routes
import { USER_MANAGEMENT_USERS } from "../../../routes";

export const UserManagementList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const { saveAsCsv } = useJsonToCsv();
  // api
  const { EXPORT_USER_ENDPOINT } = config.api_endpoint;
  // redux
  const { users_loading, users } = useSelector((state) => state.userMgmt);
  // states
  const [loading, setLoading] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [showSyncBtnLoading, setSyncBtnLoading] = useState(false);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [showSearchDialog, setShowSearchDialog] = useState(false);

  // methods
  const shouldShowExport = () => {
    const userPermissions = getUserPermission();
    return checkMultiPermission(userPermissions, USER_MGMT_EXPORT_USER)
      ? true
      : false;
  };

  const shouldShowSync = () => {
    const userPermissions = getUserPermission();
    return checkMultiPermission(userPermissions, USER_MGMT_SYNC_USER)
      ? true
      : false;
  };

  const handleFilteringData = () => {
    console.log("working");
    // console.log("query", query.get("name").toLowerCase());
    let results = allUsers;
    // console.log(
    //   "userFilter",
    //   results.filter(
    //     (j) =>
    //       j.user_name !== null &&
    //       j.user_name.toLowerCase().indexOf(query.get("name").toLowerCase()) >
    //         -1
    //   )
    // );
    if (query.get("name")) {
      results = results.filter(
        (v) =>
          v.user_name !== null &&
          v.user_name.toLowerCase().indexOf(query.get("name").toLowerCase()) >
            -1
      );
    }
    if (query.get("sap")) {
      results = results.filter(
        (v) =>
          (v.sap_no !== null &&
            v.sap_no.toLowerCase().indexOf(query.get("sap").toLowerCase()) >
              -1) ||
          v.username.toLowerCase().indexOf(query.get("sap").toLowerCase()) > -1
      );
    }
    if (query.get("depot")) {
      let all_depots = query.get("depot").split(",");
      if (all_depots.indexOf("All") === -1) {
        results = results.filter(
          (v) => v.dept_desc && all_depots.indexOf(v.dept_desc.trim()) > -1
        );
      }
    }
    if (query.get("interchange")) {
      let all_interchanges = query.get("interchange").split(",");
      if (all_interchanges.indexOf("All") === -1) {
        results = results.filter(
          (v) =>
            v.sect_desc && all_interchanges.indexOf(v.sect_desc.trim()) > -1
        );
      }
    }
    if (query.get("designation")) {
      let all_designations = query.get("designation").split(",");
      if (all_designations.indexOf("All") === -1) {
        results = results.filter(
          (v) =>
            v.designation && all_designations.indexOf(v.designation.trim()) > -1
        );
      }
    }
    if (query.get("status")) {
      if (query.get("status") !== "na") {
        results = results.filter(
          (v) => v.is_active.toString() === query.get("status")
        );
      }
    }
    setFilteredUsers(results);
  };

  const handleSearchCallback = (data) => {
    console.log("Search", data);
    const { name, sap } = data;
    query.set("name", name);
    query.set("sap", sap);
    if (name || sap) {
      query.set("search", true);
    }

    navigate(USER_MANAGEMENT_USERS + "?" + query.toString());
    setShowSearchDialog(false);
    handleFilteringData();
  };

  const handleFilterCallback = (data) => {
    console.log("handleFilterCallback:", data);
    const { depot, interchange, designation, status } = data;
    query.set("depot", depot.toString());
    query.set("interchange", interchange.toString());
    query.set("designation", designation.toString());
    query.set("status", status.toString());
    if (
      depot.length > 0 ||
      interchange.length > 0 ||
      designation.length > 0 ||
      status
    ) {
      query.set("filter", true);
    }
    navigate(USER_MANAGEMENT_USERS + "?" + query.toString());
    setShowFilterDialog(false);
    handleFilteringData();
  };

  const onExport = async () => {
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    await api.get(EXPORT_USER_ENDPOINT, {}, token).then(async (res) => {
      if (res.status === "success") {
        const data = res.data;
        console.log("Export user results:", data);
        const headers = data.length > 0 ? data[0] : [];
        const exportData = data.length > 1 ? data.slice(1) : [];
        let fields = {};

        headers?.map((fieldItem) => {
          fields[fieldItem?.split(" ")?.join("-")] = fieldItem;
        });

        const items = exportData?.map((item = [], key) => {
          let newObj = {};

          item.map((idx, itmKey) => {
            newObj = {
              ...newObj,
              [`${headers[itmKey]?.split(" ")?.join("-")}`]:
                typeof idx === "string" ? idx?.replace(",", "") : idx,
            };
          });

          return newObj;
        });

        console.log(fields, items);
        await saveAsCsv({ data: items, fields, filename: "user-list" });
      } else {
        alert("Unable to export.");
      }
    });
  };

  const handleSyncAction = async () => {
    setSyncBtnLoading(true);
    const res = await dispatch(syncUserList({}));
    setSyncBtnLoading(false);
    if (res.payload) {
      if (res.payload.status == "success") {
        alert("User sync started successfully.");
      } else {
        alert("Error on starting user sync.");
      }
    } else {
      alert("Error on starting user sync.");
    }
  };

  // useEffect
  useEffect(() => {
    let results = allUsers;
    console.log("results:", allUsers);
    if (query.toString()) {
      if (query.get("name")) {
        results = results.filter(
          (v) =>
            v.user_name !== null &&
            v.user_name.toLowerCase().indexOf(query.get("name").toLowerCase()) >
              -1
        );
      }
      if (query.get("sap")) {
        results = results.filter(
          (v) =>
            v.sap_no !== null &&
            v.sap_no.toLowerCase().indexOf(query.get("sap").toLowerCase()) > -1
        );
      }
      if (query.get("depot")) {
        let all_depots = query.get("depot").split(",");
        console.log("Filter all_depots:", all_depots);
        if (all_depots.indexOf("All") === -1) {
          results = results.filter(
            (v) => v.dept_desc && all_depots.indexOf(v.dept_desc.trim()) > -1
          );
          console.log("Filtered results:", results);
        }
      }
      if (query.get("interchange")) {
        let all_interchanges = query.get("interchange").split(",");
        if (all_interchanges.indexOf("All") === -1) {
          results = results.filter(
            (v) =>
              v.sect_desc && all_interchanges.indexOf(v.sect_desc.trim()) > -1
          );
        }
      }
      if (query.get("designation")) {
        let all_designations = query.get("designation").split(",");
        if (all_designations.indexOf("All") === -1) {
          results = results.filter(
            (v) =>
              v.designation &&
              all_designations.indexOf(v.designation.trim()) > -1
          );
        }
      }
      if (query.get("status")) {
        if (query.get("status") !== "na") {
          results = results.filter(
            (v) => v.is_active.toString() === query.get("status")
          );
        }
      }
    }
    setFilteredUsers(results);
    setLoading(false);
  }, [allUsers]);

  useEffect(() => {
    setLoading(true);
    dispatch(fetchAllUserList()).then((res) => {
      let results = res.payload;
      setAllUsers(results);
    });
  }, []);

  const reload = () => {
    setLoading(true);
    dispatch(fetchAllUserList()).then((res) => {
      let results = res.payload;
      setAllUsers(results);
      setLoading(false);
    });
  };

  return (
    <div className="user-mgmt-list-content">
      {showFilterDialog && (
        <FilterUser
          setShowFilterDialog={setShowFilterDialog}
          onFiltercallback={handleFilterCallback}
        />
      )}
      {showSearchDialog && (
        <SearchUser
          setShowSearchDialog={setShowSearchDialog}
          onSearchcallback={handleSearchCallback}
        />
      )}
      <TableHeader
        title="Manage User"
        onRefresh={() => {
          dispatch(fetchAllUserList()).then((res) => {
            let results = res.payload;
            setAllUsers(results);
          });
        }}
        hideaddbtn={true}
        loading={loading}
        onFilter={() => setShowFilterDialog(true)}
        onSearch={() => setShowSearchDialog(true)}
        onExport={onExport}
        showNotificationExport={shouldShowExport()}
        hideSyncUsers={!shouldShowSync()}
        onSyncAction={handleSyncAction}
        showSyncBtnLoading={showSyncBtnLoading}
        onShowSyncLoading={() => setSyncBtnLoading(false)}
      />
      {users_loading || loading ? (
        <Loader
          type="infinite-spinner"
          style={{
            display: "flex",
            alignItems: "center",
            width: "40px",
            margin: "auto",
            justifyContent: "center",
            height: "100vh",
          }}
        />
      ) : (
        <UserTableBody data={filteredUsers} reload={reload} />
      )}
    </div>
  );
};
