import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import EditIcon from "../../../../assets/common/edit_icon.svg";
import ArrorRightIcon from "../../../../assets/common/arrao-right.svg";
import { Loader } from "@progress/kendo-react-indicators";
import BackIcon from "../../../../assets/communication/Back.svg";
import { TELEPHONE_DIRECTORY_CONTACT_LIST } from "../../../../routes";
import { fetchContactById, updateContact } from "../../../../store/telephoneDirectory";
import { ContactForm } from "./contact_form";

export const ContactDetails = () => {
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const id = query.get("id")
    console.log('ID in detail',id)
    const navigate = useNavigate();
    const dispatch = useDispatch();
   
    const [isEdit, setIsEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const {
        contact_detail_data,
        contact_detail_loading,
        detail_error,
    } = useSelector((state) => state.telephone_directory);
    
    useEffect(() => {
        console.log('contact_id',id);
        if (id) {
            dispatch(fetchContactById(id));
        }
    }, [])
    const handleSaveContact = (data) => {
        data.id = id
        console.log('Handle Save Contact--',data);
        dispatch(updateContact({ data, id }))
            .then(() => {
                setIsEdit(false);
                navigate(TELEPHONE_DIRECTORY_CONTACT_LIST);
                // dispatch(fetchContactById(id));
            });
    }
    return (
        <>
            <div className="detail-body">
                {contact_detail_loading ? (
                    <Loader
                        type="infinite-spinner"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            width: "40px",
                            margin: "auto",
                            justifyContent: "center",
                            height: "100vh",
                        }}
                    />
                ) : (
                    <>
                        <div className="detail-header">
                            <div className="categoryback-group-div" onClick={() => navigate(TELEPHONE_DIRECTORY_CONTACT_LIST)}>
                                <img src={BackIcon} className="categoryback-icon" />
                                <span className="categoryback-label">Back</span>
                            </div>
                            <div>
                                    {!isEdit && (
                                    <div>
                                        <Button className="editBtn" onClick={() => setIsEdit(true)}>
                                            <img src={EditIcon} style={{ verticalAlign: "middle" }} />
                                            <span className="editBtn-label">Edit</span>
                                            <img src={ArrorRightIcon} style={{ verticalAlign: "middle" }} />
                                        </Button>
                                    </div>

                                )}
                            
                            {/* {!isEdit && (
                                    <div>
                                        <Button className="editBtn" onClick={() => setIsEdit(true)}>
                                            <img src={EditIcon} style={{ verticalAlign: "middle" }} />
                                            <span className="editBtn-label">Edit</span>
                                            <img src={ArrorRightIcon} style={{ verticalAlign: "middle" }} />
                                        </Button>
                                    </div>

                                )} */}
                            </div>
                        </div>
                        <div className="detail-content">
                            <ContactForm
                                data={contact_detail_data}
                                setIsEdit={setIsEdit}
                                isEdit={isEdit}
                                submitForm={handleSaveContact}
                            />
                        </div>
                    </>
                )}

            </div>
        </>

    );
};