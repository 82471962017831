import { useState, useEffect, useRef } from "react"
import { GetBSQCPeriod } from "../../../../../services/feedbackOnBus";
import debounce from "lodash.debounce";
import { useClickAway } from "ahooks";
import { useSelectMasterData } from "../../actions";

export const useAction = () => {
  const { listOfPeriod } = useSelectMasterData();

  const [isFetching, setIsFetching] = useState(true);
  const [isFetchSearch, setIsFetchSearch] = useState(false);
  const [bsqcPeriod, setBsqcPeriod] = useState([]);
  
  useEffect(() => {
    const getListBSQCPeriod = async () => {
      setBsqcPeriod(listOfPeriod);

      setIsFetching(false);
    };

    getListBSQCPeriod();
  }, [listOfPeriod]);

  const onSearch = debounce(
    async (e) => {
      setIsFetchSearch(true);
      const [err, response] = await GetBSQCPeriod({ search: e?.target?.value });

      console.log('err: ', err, response);
      if (!err) {
        setBsqcPeriod(response?.data ?? []);
      }

      setIsFetchSearch(false);
    },
    700
  )

  return { periods: bsqcPeriod, onSearch, isFetching, isFetchSearch }
}

export const useDropdown = () => {
  const ref = useRef();
  const [openDropdown, setOpenDropdown] = useState(false);

  useClickAway(() => {
    if (openDropdown) setOpenDropdown(false);
  }, ref);

  const onToggleSelectOption = () => setOpenDropdown(prev => !prev);

  return { open: openDropdown, onToggle: onToggleSelectOption, ref };
}