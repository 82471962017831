// redux

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionToken } from "../config/userPool";
import { AddCategory } from "../views/admin_tools/communication/category/category_add";

const { CATEGORY_ENDPOINT } = config.api_endpoint;

const initialState = {
  loading: false,
  category_ids: [],
  error: null,
  category_data: {},
  data_loading: false,
  data_error: null,
  update_data: {},
  update_error: null,
  update_loading: false,
  delete_res: {},
  delete_loading: false,
  delete_error: null,
};

export const fetchAllCategories = createAsyncThunk(
  "category/fetchAllCategories",
  async (_, { rejectWithValue }) => {
    try {
      // console.log(query);
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(CATEGORY_ENDPOINT, {}, token);
      console.log("fetchAllCategories results:", results);
      // console.log(results);
      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetchCategoryById = createAsyncThunk(
  "category/fetchCategoryById",
  async (category_id, { rejectWithValue }) => {
    try {
      console.log(category_id);
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(
        `${CATEGORY_ENDPOINT}/${category_id}`,
        {},
        token
      );
      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const updateCategory = createAsyncThunk(
  "category/updateCategory",
  async ({ data, category_id }, { rejectWithValue }) => {
    try {
      console.log(data);
      console.log(category_id);
      const token = getCurrentLoggedInSessionToken();
      const res = await api.post(
        `${CATEGORY_ENDPOINT}/${category_id}`,
        JSON.stringify(data),
        token
      );
      if (res.status_code >= 400) {
        return rejectWithValue(res.message);
      }
      return res.data;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);
export const createCategory = createAsyncThunk(
  "category/createCategory",
  async ({ data }, { rejectWithValue }) => {
    try {
      console.log(data);
      const token = getCurrentLoggedInSessionToken();
      const res = await api.post(
        `${CATEGORY_ENDPOINT}`,
        JSON.stringify(data),
        token
      );
      if (res.status_code >= 400) {
        return rejectWithValue(res.message);
      }
      return res.data;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);
export const deleteCategory = createAsyncThunk(
  "category/deleteCategory",
  async (category_id, { rejectWithValue }) => {
    try {
      console.log(category_id);
      const token = getCurrentLoggedInSessionToken();
      const res = await api.remove(
        `${CATEGORY_ENDPOINT}/${category_id}`,
        JSON.stringify({ id: category_id }),
        token
      );
      return res.data;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

const CategorySlice = createSlice({
  name: "category",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.category_ids = action.payload;
      })
      .addCase(fetchAllCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchCategoryById.pending, (state) => {
        state.data_loading = true;
      })
      .addCase(fetchCategoryById.fulfilled, (state, action) => {
        state.data_loading = false;
        state.category_data = action.payload;
      })
      .addCase(fetchCategoryById.rejected, (state, action) => {
        state.data_loading = false;
        state.data_error = action.error.message;
      })
      .addCase(updateCategory.pending, (state) => {
        state.update_loading = true;
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        state.update_loading = false;
        state.update_data = action.payload;
      })
      .addCase(updateCategory.rejected, (state, action) => {
        state.update_loading = false;
        state.update_error = action.error.message;
      })
      .addCase(createCategory.pending, (state) => {
        state.update_loading = true;
      })
      .addCase(createCategory.fulfilled, (state, action) => {
        state.update_loading = false;
        state.update_data = action.payload;
      })
      .addCase(createCategory.rejected, (state, action) => {
        state.update_loading = false;
        state.update_error = action.error.message;
      })
      .addCase(deleteCategory.pending, (state) => {
        state.delete_loading = true;
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.delete_loading = false;
        state.delete_res = action.payload;
      })
      .addCase(deleteCategory.rejected, (state, action) => {
        state.delete_loading = false;
        state.delete_error = action.error.message;
      });
  },
});

const CategoryReducer = CategorySlice.reducer;
export default CategoryReducer;
